import React, { useState } from "react";

import "./Notifications.css";

const Notifications = ({ notifications }) => {
  const [selectedButton, setSelectedButton] = useState("new_client");

  const prevWeek = () => {
    setSelectedButton("new_client");
  };

  const nextWeek = () => {
    setSelectedButton("deep_clean_schedule");
  };

  const currentWeek = () => {
    setSelectedButton("new_address");
  };

  const capitalize = (str) => {
    if (typeof str !== "string") {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.substr(1);
  };

  const filteredNotifications = notifications
  ?.filter((value) => value.notification_type === selectedButton);

  return (
    <div className="containerNotificationsDash">
      <div className="containerButtonsNotifications">
        <button
          onClick={prevWeek}
          className={
            selectedButton === "new_client"
              ? "selectedButtonStyle"
              : "notSelectedButtonStyle"
          }
        >
          New Clients
        </button>
        <button
          onClick={currentWeek}
          className={
            selectedButton === "new_address"
              ? "selectedButtonStyle"
              : "notSelectedButtonStyle"
          }
        >
          New Properties
        </button>
        <button
          onClick={nextWeek}
          className={
            selectedButton === "deep_clean_schedule"
              ? "selectedButtonStyle"
              : "notSelectedButtonStyle"
          }
        >
          Without Cleaner Cleaning
        </button>
      </div>

      <div className="containerNotificationsDashCard">
        {filteredNotifications?.length > 0 ? (
          filteredNotifications?.map((value, idx) => (
            <div key={idx} className="containerCard">
              <div className="title">{value.title}</div>
              <div className="subtitle">{value.subtitle}</div>
            </div>
          ))
        ) : (
          <div className="subtitleNone">There are no notifications of this type.</div>
        )}
      </div>
    </div>
  );
};

export default Notifications;
