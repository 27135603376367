import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { ReactSVG } from "react-svg";
import { AuthorizedLayout } from "../../Components/AuthorizedLayout";
import Input from "@mui/material/Input";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import AppContext from "../../Context/AppContext";
import Rating from "@mui/material/Rating";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import ModalCard from "./Components/ModalCard";

import loc from "../../Assets/loc.svg";
import Star from "../../Assets/Star 2.svg";
import CleaningIcon from "../../Assets/111limpezas.svg";
import HomeICON from "../../Assets/11HOME.svg";
import StrikeIcon from "../../Assets/1StrikeIcon.svg";
import hostAvatar from "../../Assets/hostAvatar.svg";

import "./Clients.css";
import api from "../../Service/api";
// import ModalCard from "./components/ModalCard";

const initialFilterState = {
  name: "",
  region: "",
  city: "",
  dateFrom: "",
  dateTo: "",
  rating: "",
  properties: "",
  ghost: false,
};

const Clients = () => {
  let navigate = useNavigate();
  const { listRegions, listCities } = useContext(AppContext);
  const [listCleaners, setListCleaners] = useState([]);
  const [filter, setFilter] = useState(initialFilterState);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searched, setSearched] = useState(false);

  const fetchData = async (page) => {
    setListCleaners([]);
    setLoading(true);
    try {
      const response = await api.get(`/admins/clients`, {
        params: {
          page: page,
          per_page: 40,
          host_name: filter.name,
          region: filter.region,
          city: filter.city,
          number_of_properties: filter.properties,
          from: filter.dateFrom
            ? moment(filter.dateFrom).utc().format("DD/MM/YYYY")
            : "",
          to: filter.dateTo
            ? moment(filter.dateTo).utc().format("DD/MM/YYYY")
            : "",
          ratting: filter.rating,
          ghost: filter.ghost,
        },
      });

      const newData = response?.clients?.data.map((value) => ({
        id: value.id,
        type: value.type,
        ...value.attributes,
      }));
      setTotal(response.total_pages);
      setListCleaners(newData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setListCleaners(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function handleClick(id, item) {
    navigate(`/clients/info/${id}`, {
      state: { item, listCities, listRegions },
    });
  }

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleRating = (value) => {
    setFilter((prevState) => ({ ...prevState, rating: value }));
  };

  // const handleChangeRegion = (event) => {
  //   setFilter((prevState) => ({ ...prevState, region: event.target.value }));
  // };

  // const handleChangeCity = (event) => {
  //   setFilter((prevState) => ({ ...prevState, city: event.target.value }));
  // };

  // const cancelCleaning = async (id) => {
  //   try {
  //     await api.delete(`admins/admin_controll/${id}`);
  //     setLoading(true);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const allCityIds =
  //   listCleaners.length > 0
  //     ? listCleaners
  //         ?.map((cleaner) => cleaner.cities.map((city) => city.id))
  //         .filter((ids) => ids.length !== 0)
  //         .flat()
  //     : [];

  // const allRegionsIds =
  //   listCleaners.length > 0
  //     ? listCleaners
  //         ?.map((cleaner) => cleaner.regions.map((regions) => regions.id))
  //         .filter((ids) => ids.length !== 0)
  //         .flat()
  //     : [];

  const handleChange = (event, value) => {
    setCurrentPage(value);
    fetchData(value);
  };

  const handleSearch = () => {
    fetchData();
    setSearched(true);
  };

  const fetchDataReset = async () => {
    setListCleaners([]);
    setLoading(true);
    try {
      const response = await api.get(`/admins/clients`, {
        params: {
          page: 1,
          per_page: 40,
          host_name: "",
          number_of_properties: "",
          region: "",
          city: "",
          from: "",
          to: "",
          ratting: "",
          ghost: false,
        },
      });
      const newData = response?.clients?.data.map((value) => ({
        id: value.id,
        type: value.type,
        ...value.attributes,
      }));
      setTotal(response.total_pages);
      setListCleaners(newData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setListCleaners(false);
      setLoading(false);
    }
  };

  const handleResetFilter = () => {
    setFilter(initialFilterState);
    fetchDataReset();
    setSearched(false);
  };

  const handleStatusDepp = (event) => {
    const { name, checked } = event.target;
    setFilter((prevState) => ({ ...prevState, ghost: checked }));
  };

  return (
    <AuthorizedLayout>
      <div className="containerOverview containerAdminControl">
        <div className="containerBox"></div>
        <div className="containerFilter">
          <div className="textTitle">Clients</div>

          <div className="titleFilter">Host</div>

          <Input
            placeholder="Host name"
            name="name"
            value={filter.name}
            onChange={handleFilterChange}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSearch();
              }
            }}
          />

          <div className="titleFilter">Region</div>

          <TextField
            name="region"
            label={filter.region !== "" ? "" : "Region"}
            select
            value={filter.region}
            onChange={handleFilterChange}
            style={{ borderBottom: "none", width: "100%" }}
          >
            <option value="">None</option>
            {listRegions.map((value, idx) => (
              <option
                value={value.name}
                key={idx}
                style={{ cursor: "pointer" }}
              >
                {value.name}
              </option>
            ))}
          </TextField>

          <TextField
            name="city"
            label={filter.city !== "" ? "" : "City"}
            select
            value={filter.city}
            onChange={handleFilterChange}
            style={{ borderBottom: "none", width: "100%" }}
          >
            <option value="">None</option>
            {listCities.map((value, idx) => (
              <option
                value={value.name}
                key={idx}
                style={{ cursor: "pointer" }}
              >
                {value.name}
              </option>
            ))}
          </TextField>

          <div className="titleFilter">Number of Properties</div>

          <Input
            placeholder="Number of Properties"
            name="properties"
            value={filter.properties}
            onChange={handleFilterChange}
            type="number"
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSearch();
              }
            }}
          />

          <div className="titleFilter">Account created between</div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "5px",
            }}
          >
            <div className="inputCOntianerMObile">
              <div className="subTitleFilter">From:</div>
              <TextField
                name="dateFrom"
                // label="From Date"
                type="date"
                value={filter.dateFrom}
                onChange={handleFilterChange}
                className="inputMObile"
              />
            </div>

            <div className="inputCOntianerMObile">
              <div className="subTitleFilter">To:</div>
              <TextField
                name="dateTo"
                // label="To Date"
                type="date"
                value={filter.dateTo}
                onChange={handleFilterChange}
                className="inputMObile"
              />
            </div>
          </div>

          <div className="titleFilter">Rating</div>

          <div className="containerFIlterRating">
            {[1, 2, 3, 4].map((value, idx) => (
              <div
                key={idx}
                className={
                  filter.rating === value
                    ? "containerCardFIlter selectedRating"
                    : "containerCardFIlter"
                }
              >
                <div style={{ display: "flex", gap: "2px" }}>
                  <div className="title">{value}</div>
                  <ReactSVG src={Star} className="starIcon" />
                </div>
                <div className="containerBOli">
                  <button
                    className="bolinha"
                    onClick={() => handleRating(value)}
                  ></button>
                  <div
                    className="linha"
                    style={{ display: idx === 4 && "" }}
                  ></div>
                </div>
              </div>
            ))}
            <div
              className={
                filter.rating === 5
                  ? "containerCardFIlter selectedRating"
                  : "containerCardFIlter"
              }
              style={{ width: "auto" }}
            >
              <div style={{ display: "flex", gap: "2px" }}>
                <div className="title">5</div>
                <ReactSVG src={Star} className="starIcon" />
              </div>
              <div className="containerBOli">
                <button
                  className="bolinha"
                  onClick={() => handleRating(5)}
                ></button>
              </div>
            </div>
          </div>

          <FormControlLabel
            control={
              <Checkbox
                checked={filter.ghost}
                onChange={handleStatusDepp}
                name="ghost"
                color="default"
                style={{ color: "#0E1F41", paddingTop: 0, paddingBottom: 0 }}
              />
            }
            label={
              <span
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "20px",
                  color: "#0E1F41",
                }}
              >
                Ghost
              </span>
            }
            style={{ paddingTop: 10, paddingBottom: 0 }}
            align="left"
          />

          <div
            style={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "20px",
              color: "#0E1F41",
              alignSelf: "center",
              margin: "30px",
            }}
          >
            Customer count: <strong>{listCleaners?.length}</strong>
          </div>

          <div
            style={{ display: "flex", gap: "10px", justifyContent: "center" }}
          >
            <button className="buttonFilter" onClick={handleResetFilter}>
              Clear Selection
            </button>

            <button
              className="buttonFilter"
              style={{ backgroundColor: "#0e1f41" }}
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div className="containerButtonNewAdmin">
            <ModalCard
              title={"CREATE GHOST CLIENT"}
              button={<div>Create ghost client</div>}
              color="#66CDAA"
              padding="10px"
              setLoading={fetchData}
            />
          </div>
          {listCleaners.length > 0 ? (
            <div className="containerPagination">
              <div style={{ alignSelf: "flex-end", marginBottom: "10px" }}>
                <Stack spacing={2}>
                  <Pagination
                    count={total}
                    page={currentPage}
                    onChange={handleChange}
                  />
                </Stack>
              </div>
              <div className="containerCard">
                {listCleaners?.map((item, idx) => (
                  <div
                    className="containerCardAdmin"
                    key={idx}
                    style={{
                      flexDirection: "column",
                      gap: "0px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleClick(item.id, item)}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: "100%",
                        gap: "10px",
                        marginBottom: 0,
                      }}
                    >
                      {item.avatar === null ? (
                        <div
                          className="userPhoto"
                          style={{
                            width: "64px",
                            height: "64px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "30px",
                            color: "#e5ebf2",
                          }}
                        >
                          ?
                        </div>
                      ) : (
                        <img
                          src={item.avatar || hostAvatar}
                          alt="user"
                          className="hostPhoto"
                          style={{
                            width: "64px",
                            height: "64px",
                            objectFit: "cover",
                          }}
                        />
                      )}
                      <div className="containerText" style={{ width: "100%" }}>
                        <div className="title">{`${item.firstName} ${item.lastName}`}</div>
                        <div
                          className="subtitle"
                          style={{
                            display: "flex",
                            gap: "5px",
                            justifyContent: "space-evenly",
                          }}
                        >
                          {/* <div><ReactSVG src={loc} /></div> '' */}
                          <div>
                            <ReactSVG src={HomeICON} />
                          </div>{" "}
                          <div>{item?.numberOfProperties}</div>
                          <div>
                            <ReactSVG src={CleaningIcon} />
                          </div>{" "}
                          <div>{item?.numberOfCleanings}</div>
                          <div>
                            <ReactSVG src={StrikeIcon} />
                          </div>{" "}
                          <div>{item?.strikes}</div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="subtitle"
                      style={{
                        fontStyle: "italic",
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      Rating Score:
                      <Stack spacing={1}>
                        <Rating
                          name="half-rating-read"
                          value={Number(item?.ratting) || 0}
                          precision={0.5}
                          readOnly
                          sx={{ color: "#66CDAA", fontSize: "40px" }}
                          size="large"
                        />
                      </Stack>
                    </div>
                  </div>
                ))}
              </div>
              <div style={{ alignSelf: "flex-end" }}>
                <Stack spacing={2}>
                  <Pagination
                    count={total}
                    page={currentPage}
                    onChange={handleChange}
                  />
                </Stack>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "80vh",
                width: "100%",
              }}
            >
              {loading ? (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress size={50} />
                </Box>
              ) : (
                <div className="titleFilter">No matching items found.</div>
              )}
            </div>
          )}
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default Clients;
