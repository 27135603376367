import React, { useState, useEffect, useContext } from "react";
import { ReactSVG } from "react-svg";
import { AuthorizedLayout } from "../../Components/AuthorizedLayout";
import { TextField } from "@material-ui/core";
import Input from "@mui/material/Input";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import AppContext from "../../Context/AppContext";
import editIcon from "../../Assets/Btn Edit.svg";

import "./AdminControl.css";
import api from "../../Service/api";
import ModalCard from "./components/ModalCard";
import DeleteUser from "./components/DeleteUser";

const initialFilterState = {
  name: "",
  type: "",
  region: "",
};

const AdminControl = () => {
  const { listRegions } = useContext(AppContext);
  const [filteredData, setFilteredData] = useState([]);
  const [listUsers, setListUsers] = useState([]);
  const [filter, setFilter] = useState(initialFilterState);
  const [loading, setLoading] = useState(true);
  const [loadingOnOff, setLoadingOnOff] = useState(false);
  //   {
  //     name: "Antônio Silva",
  //     type: "admin",
  //     id: "169",
  //     region: "california",
  //   },
  //   {
  //     name: "Maria Souza",
  //     type: "user",
  //     id: "128",
  //     region: "são paulo",
  //   },
  //   {
  //     name: "João Santos",
  //     type: "admin",
  //     id: "279",
  //     region: "rio de janeiro",
  //   },
  //   {
  //     name: "Pedro Ferreira",
  //     type: "user",
  //     id: "563",
  //     region: "minas gerais",
  //   },
  //   {
  //     name: "Juliana Rodrigues",
  //     type: "user",
  //     id: "335",
  //     region: "bahia",
  //   },
  //   {
  //     name: "Carlos Oliveira",
  //     type: "admin",
  //     id: "981",
  //     region: "pernambuco",
  //   },
  //   {
  //     name: "Luciana Costa",
  //     type: "user",
  //     id: "741",
  //     region: "paraná",
  //   },
  //   {
  //     name: "Maurício Pereira",
  //     type: "admin",
  //     id: "444",
  //     region: "rio grande do sul",
  //   },
  //   {
  //     name: "Ana Paula Santos",
  //     type: "user",
  //     id: "902",
  //     region: "distrito federal",
  //   },
  //   {
  //     name: "Fernanda Souza",
  //     type: "user",
  //     id: "622",
  //     region: "amazonas",
  //   },
  //   {
  //     name: "Antônio Silva",
  //     type: "admin",
  //     id: "169",
  //     region: "california",
  //   },
  //   {
  //     name: "Maria Souza",
  //     type: "user",
  //     id: "128",
  //     region: "são paulo",
  //   },
  //   {
  //     name: "João Santos",
  //     type: "admin",
  //     id: "279",
  //     region: "rio de janeiro",
  //   },
  //   {
  //     name: "Pedro Ferreira",
  //     type: "user",
  //     id: "563",
  //     region: "minas gerais",
  //   },
  //   {
  //     name: "Juliana Rodrigues",
  //     type: "user",
  //     id: "335",
  //     region: "bahia",
  //   },
  //   {
  //     name: "Carlos Oliveira",
  //     type: "admin",
  //     id: "981",
  //     region: "pernambuco",
  //   },
  //   {
  //     name: "Luciana Costa",
  //     type: "user",
  //     id: "741",
  //     region: "paraná",
  //   },
  //   {
  //     name: "Maurício Pereira",
  //     type: "admin",
  //     id: "444",
  //     region: "rio grande do sul",
  //   },
  //   {
  //     name: "Ana Paula Santos",
  //     type: "user",
  //     id: "902",
  //     region: "distrito federal",
  //   },
  //   {
  //     name: "Fernanda Souza",
  //     type: "user",
  //     id: "622",
  //     region: "amazonas",
  //   },
  //   {
  //     name: "Bruno Almeida",
  //     type: "admin",
  //     id: "772",
  //     region: "são paulo",
  //   },
  //   {
  //     name: "Luana Oliveira",
  //     type: "user",
  //     id: "206",
  //     region: "bahia",
  //   },
  //   {
  //     name: "Amanda Costa",
  //     type: "user",
  //     id: "521",
  //     region: "são paulo",
  //   },
  //   {
  //     name: "Rafael Santos",
  //     type: "admin",
  //     id: "812",
  //     region: "rio de janeiro",
  //   },
  // ];

  const getUsers = async () => {
    setListUsers([])
    setLoading(true);
    try {
      const { data } = await api.get("/admins/admin_controll/");
      const newData = data.map((value) => ({
        id: value.id,
        type: value.type,
        ...value.attributes,
      }));
      setListUsers(newData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setListUsers(false);
    }
  };
  
  console.log(listUsers);

  useEffect(() => {
    if (loading) {
      getUsers();
    }
  }, [loading]);

  const listTypeAdmin = listUsers
    ?.map((item) => item.adminType)
    .filter((value, index, self) => self.indexOf(value) === index);

  // console.log(listUsers);

  useEffect(() => {
    let filtered = listUsers?.filter((item) => {
      const isType = item.adminType === filter.type;
      const isTypeNotSelected = Object.values(filter.type).every(
        (item) => item === ""
      );

      const isRegion = item.adminRegions.includes(Number(filter.region));
      const isRegionNotSelected = Object.values(filter.region).every(
        (item) => item === ""
      );

      const strinName = `${item.firstName} ${item.lastName}` ?? "";

      return (
        (!filter.name ||
          strinName.toLowerCase().includes(filter.name.toLowerCase())) &&
        (isTypeNotSelected || isType) &&
        (isRegionNotSelected || isRegion)
      );
    });

    setFilteredData(filtered);
  }, [listUsers, filter]);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeType = (event) => {
    setFilter((prevState) => ({ ...prevState, type: event.target.value }));
  };

  const handleChangeRegion = (event) => {
    setFilter((prevState) => ({ ...prevState, region: event.target.value }));
  };

  const handleResetFilter = () => {
    setFilter(initialFilterState);
  };

  const halndleONoFF = async (id, type) => {
    setLoadingOnOff(true);
    try {
      await api.patch(`admins/admin_controll/${id}/toggle_active`);
      setLoadingOnOff(false);
      setLoading(true);

      if (type) {
        alert("User has been deactivated successfully!");
      } else {
        alert("User has been activated successfully!");
      }
    } catch (error) {
      setLoadingOnOff(false);
    }
  };

  return (
    <AuthorizedLayout>
      <div className="containerOverview containerAdminControl">
        <div className="containerBox"></div>
        <div className="containerFilter">
          <div className="textTitle">Admin Control</div>

          <div className="titleFilter">Admin Name</div>

          <Input
            placeholder="Admin Name"
            name="name"
            value={filter.name}
            onChange={handleFilterChange}
          />

          <div className="titleFilter">Admin Type</div>

          <TextField
            name="type"
            // label="Sort By"
            select
            value={filter.type}
            onChange={handleChangeType}
            style={{ borderBottom: "none", width: "100%" }}
          >
            {/* <option value="" style={{ cursor: "pointer" }}>
              None
            </option> */}
            {listTypeAdmin.map((item, idx) => (
              <option value={item} style={{ cursor: "pointer", marginBottom: '10px' }} key={idx}>
                {item}
              </option>
            ))}
          </TextField>

          <div className="titleFilter">Region</div>

          <TextField
            name="region"
            // label="Sort By"
            select
            value={filter.region}
            onChange={handleChangeRegion}
            style={{ borderBottom: "none", width: "100%" }}
          >
            <option value="" style={{marginBottom: '10px'}}>None</option>
            {listRegions?.map((value, idx) => (
              <option value={value.id} key={idx} style={{ cursor: "pointer", marginBottom: '10px' }}>
                {value.name}
              </option>
            ))}
          </TextField>

          <button
            className="buttonFilter"
            onClick={handleResetFilter}
            style={{ marginTop: "30px" }}
          >
            Clear Selection
          </button>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div className="containerButtonNewAdmin">
            <ModalCard
              title={"New Admin Account"}
              button={<div>Add member</div>}
              color="#66CDAA"
              padding="10px"
              setLoading={setLoading}
            />
          </div>
          {filteredData.length > 0 ? (
            <div className="containerCard">
              {filteredData?.map((item, idx) => (
                <div className="containerCardAdmin" key={idx}>
                  {item.avatar === null ? (
                    <div
                      className="userPhoto"
                      style={{
                        width: "64px",
                        height: "64px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "30px",
                        color: "#e5ebf2",
                      }}
                    >
                      ?
                    </div>
                  ) : (
                    <img
                      src={item.avatar}
                      alt="user"
                      className="userPhoto"
                      style={{
                        width: "64px",
                        height: "64px",
                        objectFit: "cover",
                      }}
                    />
                  )}
                  <div className="containerText">
                    <div className="title">{`${item.firstName} ${item.lastName}`}</div>
                    <div className="subtitle">{`Its a ${item.adminType} admin account!`}</div>
                  </div>
                  <div className="containerButtonUpdateAdmin">
                    <ModalCard
                      data={item}
                      title={"Edit Admin Account"}
                      button={<ReactSVG src={editIcon} />}
                      update={true}
                      setLoading={setLoading}
                      getUsers={getUsers}
                    />
                    <DeleteUser item={item} setLoading={setLoading} />

                    <div
                      onClick={() => halndleONoFF(item.id, item.active)}
                      className="buttonON"
                      style={{ cursor: 'pointer '}}
                    >
                      {item.active ? "ON" : "OFF"}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "80vh",
                width: "100%",
              }}
            >
              {loading ? (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress size={50} />
                </Box>
              ) : (
                <div className="titleFilter">No matching items found.</div>
              )}
            </div>
          )}
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default AdminControl;
