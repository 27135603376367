import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import "./TableFinance.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    flex: 1,
  },
  blueRow: {
    backgroundColor: "#D5E7F4",
  },
}));


const TableFinance = ({ data, title }) => {
  const classes = useStyles();

  const formatCurrency = (value) => {

    const formattedValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);

    return formattedValue;
  };

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className="titleTable">Finance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.blueRow}>
            <TableCell className="subtitleTable" style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>Revenue:</div>{" "}
              <div style={{ flex: 1 }}>{formatCurrency(data?.total_revenue)}</div>
            </TableCell>
          </TableRow>

          <TableRow className={""}>
            <TableCell className="subtitleTable" style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>Refunds:</div>{" "}
              <div style={{ flex: 1 }}>{formatCurrency(data?.refounds)}</div>
            </TableCell>
          </TableRow>

          <TableRow className={classes.blueRow}>
            <TableCell className="subtitleTable" style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>Payouts:</div>{" "}
              <div style={{ flex: 1 }}>{formatCurrency(data?.payouts)}</div>
            </TableCell>
          </TableRow>

          <TableRow className={""}>
            <TableCell className="subtitleTable" style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>Gross Income:</div>{" "}
              <div style={{ flex: 1 }}>{formatCurrency(data?.gross_income)}</div>
            </TableCell>
          </TableRow>

          <TableRow className={""}>
            <TableCell className="subtitleTable" style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>Profitability:</div>{" "}
              <div style={{ flex: 1 }}>{formatCurrency(data?.profitability)}</div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableFinance;

