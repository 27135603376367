import React, { useContext, useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import moment from "moment";
import { AuthorizedLayout } from "../AuthorizedLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import ChatScreen from "./Components/ChatScreen";
import CardPdfDetails from "./Components/CardPdfDetails";

import voltarIcon from "../../Assets/Union (4).svg";
// import "./DetailsClient.css";
import api from "../../Service/api";

const dataTable = [
  {
    quem: "esau",
    evento: "editou",
    dataHora: "17 de nov de 2023 às 17hrs",
    mudou: "alterou o status",
  },
  {
    quem: "ana",
    evento: "criou",
    dataHora: "18 de nov de 2023 às 10hrs",
    mudou: "um novo projeto",
  },
  {
    quem: "joao",
    evento: "deletou",
    dataHora: "19 de nov de 2023 às 15hrs",
    mudou: "um arquivo importante",
  },
  {
    quem: "maria",
    evento: "adicionou",
    dataHora: "20 de nov de 2023 às 14hrs",
    mudou: "um comentário",
  },
  {
    quem: "pedro",
    evento: "editou",
    dataHora: "21 de nov de 2023 às 12hrs",
    mudou: "o prazo de entrega",
  },
  {
    quem: "lucas",
    evento: "criou",
    dataHora: "22 de nov de 2023 às 09hrs",
    mudou: "um novo documento",
  },
  {
    quem: "carla",
    evento: "editou",
    dataHora: "23 de nov de 2023 às 16hrs",
    mudou: "os detalhes do projeto",
  },
  {
    quem: "felipe",
    evento: "deletou",
    dataHora: "24 de nov de 2023 às 13hrs",
    mudou: "uma tarefa concluída",
  },
  {
    quem: "gabriela",
    evento: "adicionou",
    dataHora: "25 de nov de 2023 às 11hrs",
    mudou: "um novo colaborador",
  },
  {
    quem: "rafael",
    evento: "editou",
    dataHora: "26 de nov de 2023 às 14hrs",
    mudou: "as configurações da conta",
  },
  {
    quem: "camila",
    evento: "criou",
    dataHora: "27 de nov de 2023 às 10hrs",
    mudou: "um novo evento no calendário",
  },
  {
    quem: "gustavo",
    evento: "deletou",
    dataHora: "28 de nov de 2023 às 15hrs",
    mudou: "um arquivo não utilizado",
  },
  {
    quem: "isabela",
    evento: "adicionou",
    dataHora: "29 de nov de 2023 às 12hrs",
    mudou: "um comentário em destaque",
  },
  {
    quem: "thiago",
    evento: "editou",
    dataHora: "30 de nov de 2023 às 11hrs",
    mudou: "a descrição do projeto",
  },
  {
    quem: "esau",
    evento: "editou",
    dataHora: "17 de nov de 2023 às 17hrs",
    mudou: "alterou o status",
  },
  {
    quem: "ana",
    evento: "criou",
    dataHora: "18 de nov de 2023 às 10hrs",
    mudou: "um novo projeto",
  },
  {
    quem: "joao",
    evento: "deletou",
    dataHora: "19 de nov de 2023 às 15hrs",
    mudou: "um arquivo importante",
  },
  {
    quem: "maria",
    evento: "adicionou",
    dataHora: "20 de nov de 2023 às 14hrs",
    mudou: "um comentário",
  },
  {
    quem: "pedro",
    evento: "editou",
    dataHora: "21 de nov de 2023 às 12hrs",
    mudou: "o prazo de entrega",
  },
  {
    quem: "lucas",
    evento: "criou",
    dataHora: "22 de nov de 2023 às 09hrs",
    mudou: "um novo documento",
  },
  {
    quem: "carla",
    evento: "editou",
    dataHora: "23 de nov de 2023 às 16hrs",
    mudou: "os detalhes do projeto",
  },
  {
    quem: "felipe",
    evento: "deletou",
    dataHora: "24 de nov de 2023 às 13hrs",
    mudou: "uma tarefa concluída",
  },
  {
    quem: "gabriela",
    evento: "adicionou",
    dataHora: "25 de nov de 2023 às 11hrs",
    mudou: "um novo colaborador",
  },
  {
    quem: "rafael",
    evento: "editou",
    dataHora: "26 de nov de 2023 às 14hrs",
    mudou: "as configurações da conta",
  },
  {
    quem: "camila",
    evento: "criou",
    dataHora: "27 de nov de 2023 às 10hrs",
    mudou: "um novo evento no calendário",
  },
  {
    quem: "gustavo",
    evento: "deletou",
    dataHora: "28 de nov de 2023 às 15hrs",
    mudou: "um arquivo não utilizado",
  },
  {
    quem: "isabela",
    evento: "adicionou",
    dataHora: "29 de nov de 2023 às 12hrs",
    mudou: "um comentário em destaque",
  },
  {
    quem: "thiago",
    evento: "editou",
    dataHora: "30 de nov de 2023 às 11hrs",
    mudou: "a descrição do projeto",
  },
];

const ViewChat = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { cleanerId } = location.state;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const tgoBack = () => {
    setLoading(true);
    navigate(-1);
  };

  const fetchViewChat = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/admins/chat_messages/${id}`);

      const newData = await data?.map((value) => ({
        id: value.id,
        type: value.type,
        ...value.attributes,
      }));

      setData(newData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchViewChat();
  }, []);

  return (
    <AuthorizedLayout>
      {/* <Chat /> */}
      <div className="containerDetailCleaner">
        <div
          className="containerButtonTopHeader"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <button className="buttonbACK" onClick={() => tgoBack()}>
            {" "}
            <ReactSVG src={voltarIcon} /> Back
          </button>

          {/* <CardPdfDetails data={data} id={id} /> */}
        </div>
        {data.length <= 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "40vh",
              width: "100%",
            }}
          >
            {loading ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress size={50} />
              </Box>
            ) : (
              <div className="titleFilter">No matching items found.</div>
            )}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flex: 1,
              gap: "20px",
              flexDirection: "column",
            }}
          >
            <div className="titleModal" style={{ alignSelf: "center" }}>
              Chat
            </div>
            <ChatScreen data={data} id={id} cleanerId={cleanerId} />
          </div>
        )}
      </div>
    </AuthorizedLayout>
  );
};

export default ViewChat;
