import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ReactSVG } from "react-svg";
import BtnDelete from "../../../Assets/Btn Delete.svg";
import api from "../../../Service/api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  '@media (max-width: 700px)': {
    width: '80%',
  },
};

export default function DeleteCoupon({ item, setLoading }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loadingButton, setLoadingButton] = React.useState(false);

  const cancelCleaning = async (id) => {
    try {
      setLoadingButton(true);
      await api.delete(`admins/cleaning_coupons/${id}`);
      setLoading(true);
      setLoadingButton(false);
      handleClose();
    } catch (error) {
      console.log(error);
      setLoadingButton(false);
    }
  };

  // console.log(item);

  return (
    <div>
      <button className="buttonDeleteAdmin" onClick={handleOpen}>
        <ReactSVG src={BtnDelete} />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="titleModalDelete">
          Are you sure you want to delete the {" "}
            <strong>{`${item.title}`}</strong> coupon?
          </div>
          <div className="containerButtonDeleteUser">
            <button
              style={{ backgroundColor: "#66cdaa" }}
              onClick={handleClose}
            >
              CANCEL
            </button>
            <button
              style={{ backgroundColor: "#ea6868" }}
              onClick={() => cancelCleaning(item.id)}
            >
              {loadingButton ? "LOADING" : "DELETE"}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
