import React from "react";
import { Modal, makeStyles } from "@material-ui/core";
import { Font } from "@react-pdf/renderer";
import logo from "../../../../Assets/Sett Logo (2).png";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

Font.register({
  family: "Helvetica-Bold",
  src: "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.2.228/pdf.viewer.css",
  fontWeight: "bold",
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    padding: 20,
    position: "relative",
  },
  header: {
    fontSize: 18,
    marginBottom: 30,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
    textTransform: "uppercase",
    borderBottom: 1,
    borderColor: '#0E1F41'
  },
  text: {
    fontSize: 8,
    marginBottom: 8,
    fontWeight: "bold",
    textAlign: "justify",
    fontFamily: "Helvetica-Bold",
  },
  text2: {
    fontSize: 8,
    marginBottom: 30,
    fontWeight: "bold",
    textAlign: "justify",
    fontFamily: "Helvetica-Bold",
  },
  container: {
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  section: {
    width: "100%",
    marginBottom: 18,
  },
  label: {
    marginBottom: 5,
    fontWeight: "bold",
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
  },
  inputBox: {
    border: 1,
    borderColor: "#000",
    padding: 15,
    width: "100%",
  },
  inputText: {
    fontSize: "15px",
  },
  logoContainer: {
    position: "absolute",
    bottom: 20,
    right: 20,
    width: 100,
  },
  logoImage: {
    width: "100%",
    height: "100%",
  },
});

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  modalContent: {
    padding: theme.spacing(3),
    width: "700px",
    textAlign: "left",
    position: "relative",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#fff",
    overflowY: "auto",
    maxHeight: "80vh",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: "#000",
  },
  formHeader: {
    marginBottom: theme.spacing(2),
    borderBottom: "2px solid #000",
    fontWeight: "bold",
  },
  formSection: {
    marginBottom: theme.spacing(2),
  },
  formField: {
    marginBottom: theme.spacing(1),
    backgroundColor: "#f5f5f5",
    border: "1px solid #ccc",
    padding: theme.spacing(1),
    borderRadius: "4px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
  watermark: {
    position: "absolute",
    opacity: 0.5,
    fontSize: "3em",
    transform: "rotate(-45deg)",
    pointerEvents: "none",
    zIndex: -1,
    top: "50%",
    left: "50%",
    color: "#e1e1e1",
  },
}));

const W9FormModal = ({ data, open, onClose }) => {
  const classes = useStyles();
console.log(data);
  return (
    <Modal open={open} onClose={onClose} className={classes.modalContainer}>
      <div>
        <PDFViewer width="800px" height="800px">
          <Document>
            <Page size="A4" style={styles.page}>
              <Text style={styles.header}>Substitute W-9 Form</Text>
              <View style={styles.container}>
                <View style={styles.section}>
                  <Text style={styles.label}>Taxpayer or company name:</Text>
                  <View style={styles.inputBox}>
                    <Text style={styles.inputText}>{data.taxpayer_company_name}</Text>
                  </View>
                </View>

                <View style={styles.section}>
                  <Text style={styles.label}>
                    Indistinct entity name (optional):
                  </Text>
                  <View style={styles.inputBox}>
                    <Text style={styles.inputText}>{data.insdistinct_entity_name}</Text>
                  </View>
                </View>

                <View style={styles.section}>
                  <Text style={styles.label}>
                    US tax identification number:
                  </Text>
                  <View style={styles.inputBox}>
                    <Text style={styles.inputText}>{`${data.tax_identification_number} / ${data.ssn ? 'SSN' : 'EIN'}`}</Text>
                  </View>
                </View>

                <View style={styles.section}>
                  <Text style={styles.label}>Tax classification:</Text>
                  <View style={styles.inputBox}>
                    <Text style={styles.inputText}>{data.tax_classification}</Text>
                  </View>
                </View>
              </View>

              <View style={{...styles.container, flexDirection: 'row'}}>
                <View style={{...styles.section, width: '48%'}}>
                  <Text style={styles.label}>Address:</Text>
                  <View style={styles.inputBox}>
                    <Text style={styles.inputText}>{data.address}</Text>
                  </View>
                </View>

                <View style={{...styles.section, width: '48%'}}>
                  <Text style={styles.label}>City:</Text>
                  <View style={styles.inputBox}>
                    <Text style={styles.inputText}>{data.city}</Text>
                  </View>
                </View>

                <View style={{ ...styles.section, marginBottom: "30px", width: '48%' }}>
                  <Text style={styles.label}>State:</Text>
                  <View style={styles.inputBox}>
                    <Text style={styles.inputText}>{data.state}</Text>
                  </View>
                </View>

                <View style={{ ...styles.section, marginBottom: "30px", width: '48%' }}>
                  <Text style={styles.label}>Zipcode:</Text>
                  <View style={styles.inputBox}>
                    <Text style={styles.inputText}>{data.cep}</Text>
                  </View>
                </View>
              </View>

              <View style={{...styles.container, flexDirection: 'row'}}>
                <Text style={styles.text}>
                  Under penalty of perjury, I confirm that:
                </Text>
                <Text style={styles.text}>
                  The number shown on this form is my correct taxpayer
                  identification number (or I am waiting for a number to be
                  issued to me); and
                </Text>
                <Text style={styles.text}>
                  I am not subject to withholding tax because: (a) I am exempt
                  from withholding tax, or (b) I have not been notified by the
                  Internal Revenue Service (IRS) that I am subject to
                  withholding tax as a result of an error in reporting all
                  interest or dividends, or (c) the IRS has notified me that I
                  am no longer subject to withholding tax; and I am a US citizen
                  or person with other US resident status; and
                </Text>
                <Text style={styles.text}>
                  the FATCA code (or codes) entered on this form (if any)
                  indicating that I am exempt from FATCA reporting is correct.
                </Text>
                <Text style={styles.text2}>
                  he IRS does not require your consent to any provision of this
                  document other than the necessary certifications to avoid
                  withholding tax.
                </Text>

                <View style={{...styles.section, width: '48%'}}>
                  <Text style={styles.label}>Signature:</Text>
                  <View style={styles.inputBox}>
                    <Text style={styles.inputText}>{data.signature}</Text>
                  </View>
                </View>

                <View style={{...styles.section, width: '48%'}}>
                  <Text style={styles.label}>Signature Date:</Text>
                  <View style={styles.inputBox}>
                    <Text style={styles.inputText}>{data.signature_date}</Text>
                  </View>
                </View>
              </View>
              <View style={styles.logoContainer}>
                <Image src={logo} style={styles.logoImage} />
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </div>
    </Modal>
  );
};

export default W9FormModal;
