import React from "react";
import { ReactSVG } from "react-svg";
import { useFormik } from "formik";
import * as yup from "yup";
import { ModalContainer } from "../../../../Components/ModalContainer";
import { Input } from "../../../../Components/Input";
import { InputsContainer } from "../../../../Components/InputsContainer";

import editIcon from "../../../../Assets/edit 1.svg";
import api from "../../../../Service/api";

const EditModal = ({ data, setLoadingButton }) => {

  const handleItens = async (values) => {
    // setLoadingButton(true);
    try {
      await api.put(`admins/cleaners/${values.id}`, {
        data: {
          attributes: {
            id_files: data?.id_files || '',
            documents: data?.documents || '',
            firstName: values?.firstName,
            lastName: values?.lastName,
            phone: values?.phone,
            itinNumber: data?.itinNumber || '',
            experience: data?.experience || '',
            maxDistance: data?.maxDistance || '',
            maxDistanceReference:data?.maxDistanceReference || '',
            zip_code: data?.zip_code || '',
            city: data?.city || '',
            state: data?.state || '',
            startHour: data?.startHour || '',
            endHour: data?.endHour || '',
            deviceToken: data?.deviceToken || '',
            cleaningDays: data?.cleaningDays || '',
            timeOffsAttributes: data?.timeOffsAttributes || [],
            cleaning_types: data?.cleaning_types || [],
            allergieTypes: data?.allergieTypes || [],
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const editForm = useFormik({
    initialValues: {
      id: data?.id || "",
      firstName: data?.firstName || "",
      lastName: data?.lastName || "",
      phone: data?.phone || "",
    },
    validationSchema: yup.object({
      firstName: yup
        .string()
        .min(2, "First Name must have at least 2 characters.")
        .max(50, "First Name must have at most 50 characters.")
        .required("First Name is required!"),
      lastName: yup
        .string()
        .min(2, "Last Name must have at least 2 characters.")
        .max(50, "Last Name must have at most 50 characters.")
        .required("Last Name is required!"),
      // phone: yup
      //   .string()
      //   .matches(/^\d{10}$/, "Phone number must be 10 digits.")
      //   .required("Phone number is required!"),
    }),
    onSubmit: (values) => handleItens(values),
  });

  return (
    <ModalContainer
      type={false}
      title="Edit cleaner"
      onClickSave={editForm.submitForm}
      setLoadingButton={setLoadingButton}
      customButton={
        <div
          style={{
            flex: 0.5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            flexDirection: "column",
          }}
        >
          <ReactSVG src={editIcon} st />
        </div>
      }
    >
      <InputsContainer>
        <Input
          placeholder="First Name"
          field="firstName"
          form={editForm}
          assistiveText={{ color: "#0E1F41" }}
        />

        <Input
          placeholder="Last Name"
          field="lastName"
          form={editForm}
          assistiveText={{ color: "#0E1F41" }}
        />

        <Input
          placeholder="Phone"
          field="phone"
          form={editForm}
          assistiveText={{ color: "#0E1F41" }}
          type="number"
        />
      </InputsContainer>
    </ModalContainer>
  );
};

export default EditModal;
