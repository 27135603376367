import React from "react";
import { ReactSVG } from "react-svg";

import PhotoCardCarousel from "../PhotoCardCarousel";

const Step2 = ({ form }) => {

  const handleInputChange = (event) => {
    const { value } = event.target;
    form.setFieldValue(`incident_description`, value)
  };

  return (
    <div className="containerStep1">
      <div className="headerStep" style={{ flex: 1}}>
        <div className="title">Damage or Missing Items, Lost and Found</div>
        <div className="subtitle">Insert photos of the incident.</div>
      </div>

      <PhotoCardCarousel form={form} objectPhotos={"objectPhotos"} />

      <div className="title">Describe the incident or lost and found</div>
      <textarea
        rows="5"
        style={{
          width: "98%",
          resize: "none",
          border: '1px solid #0E1F41',
          borderRadius: '10px',
          padding: '5px',
          marginTop: '10px'
        }}
        value={form.values.incident_description}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default Step2;
