import React, { useState, useEffect, useContext } from "react";
import { AuthorizedLayout } from "../../Components/AuthorizedLayout";

import General from "./components/General";
import Region from './components/Region';

import "./Reports.css";

const Reports = () => {
  const [selectedMenu, setSelectedMenu] = useState(true);

  return (
    <AuthorizedLayout>
      <div className="containerButonsReportsMenu">
        <button
          className={selectedMenu && "selected"}
          onClick={() => setSelectedMenu(true)}
        >
          General
        </button>
        <button
          className={!selectedMenu && "selected"}
          onClick={() => setSelectedMenu(false)}
        >
          Region
        </button>
      </div>
      {selectedMenu ? <General /> : <Region />}
    </AuthorizedLayout>
  );
};

export default Reports;
