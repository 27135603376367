import React, { useState } from "react";
import { Box } from "@mui/system";

import { MainTopbar } from "../MainTopbar";
import { MainSidebar } from "../MainSidebar";
import { ContentContainer } from "./ContentContainer";

export const AuthorizedLayout = ({ children }) => {
  const [mainSidebarVisible, setMainSidebarVisible] = useState(false);

  return (
    <Box style={{ backgroundColor: "#e5ebf2", display: "flex", width: "100%" }}>
      <MainSidebar
        visible={mainSidebarVisible}
        setVisible={setMainSidebarVisible}
      />

      <div style={{ width: "100%" }}>
        <MainTopbar
          mainSidebarVisible={mainSidebarVisible}
          setMainSidebarVisible={setMainSidebarVisible}
        />
        <ContentContainer>{children}</ContentContainer>
      </div>
    </Box>
  );
};
