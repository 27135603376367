import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";

import './BestPropertiesTable.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    flex: 1
  },
  table: {
    flex: 1
  },
  blueRow: {
    backgroundColor: "#D5E7F4",
  },
}));

const BestPropertiesTable = ({data }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className="titleTable">Best Properties</TableCell>
            <TableCell className="titleTable">Clients</TableCell>
            <TableCell className="titleTable">Jobs</TableCell>
            <TableCell className="titleTable">Revenue</TableCell>
            <TableCell className="titleTable">Profit %</TableCell>
            <TableCell className="titleTable">Active Since</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) => (
            <TableRow key={index} className={index % 2 !== 0 ? "" : classes.blueRow}>
              <TableCell className="subtitleTable">{row.property_address}</TableCell>
              <TableCell className="subtitleTable">{row.client_name}</TableCell>
              <TableCell className="subtitleTable">{row.job_count}</TableCell>
              <TableCell className="subtitleTable">{row.revenue}</TableCell>
              <TableCell className="subtitleTable">{row.profit_percentage || 0}%</TableCell>
              <TableCell className="subtitleTable">{row.active_since}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BestPropertiesTable;
