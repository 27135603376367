import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";

import './BestClientsTable.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    flex: 1.5
  },
  table: {
    flex: 1
  },
  blueRow: {
    backgroundColor: "#D5E7F4",
  },
}));

const BestClientsTable = ({data }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className="titleTable">Best Clients</TableCell>
            <TableCell className="titleTable">Revenue</TableCell>
            <TableCell className="titleTable">Property</TableCell>
            <TableCell className="titleTable">Jobs</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) => (
            <TableRow key={index} className={index % 2 !== 0 ? "" : classes.blueRow}>
              <TableCell className="subtitleTable">{row.name}</TableCell>
              <TableCell className="subtitleTable">{row.revenue}</TableCell>
              <TableCell className="subtitleTable">{row.property_count}</TableCell>
              <TableCell className="subtitleTable">{row.job_count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BestClientsTable;
