import React, { useState, useContext, useEffect } from "react";
import moment from "moment";
import Modal from "@mui/material/Modal";
import AppContext from "../../Context/AppContext";
import { ModalContainer } from "../ModalContainer";
import api from "../../Service/api";
import CompleteCleaning from "../../Pages/Overview/CompleteCleaning";
import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import EditDateSchedule from "../EditDateSchedule";
import Autocomplete from "../Autocomplete";

import "./DetailsSchedule.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const DetailsScheduleModal = ({
  data,
  updateItens,
  audit = true,
  isLoading,
  setIsLOading,
}) => {
  let navigate = useNavigate();
  const { listCleaners, listCities, listRegions } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [nameCleaner, setNameCleaner] = useState([]);
  const [idCleaner, setIdCleaner] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loadingInvite, setLoadingInvite] = useState(false);
  const [loadingComplete, setLoadingComplete] = useState(false);

  useEffect(() => {
    setNameCleaner({
      name: data.cleaner,
      id: data?.cleanerId,
    });
  }, [data]);

  const newListCleaner = listCleaners
    ?.map((value) => ({
      id: value.id,
      name: `${value.attributes.firstName} ${value.attributes.lastName}`,
    }))
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  // console.log(nameCleaner);

  const selectedId = newListCleaner?.find(
    (value) => Number(value.id) === Number(idCleaner)
  );

  const inviteCleaner = async () => {
    setLoadingInvite(true);
    try {
      await api.patch(`/admins/overview/${data.id}/invite_cleaner`, {
        data: {
          attributes: {
            cleanerId: Number(selectedId.id),
          },
        },
      });
      setLoadingInvite(false);
      alert("Cleaner has been successfully added.");
      handleClose();
      updateItens();
    } catch (error) {
      console.log(error);
      setLoadingInvite(false);
      alert("Error: Cleaner was not added.");
    }
  };

  const cancelCleaning = async () => {
    setLoadingCancel(true);
    try {
      await api.patch(`admins/overview/${data.id}/cancel_schedule`);
      setOpen(false);
      setLoadingCancel(false);
      alert("The schedule has been canceled.");
      updateItens();
    } catch (error) {
      console.log(error);
      setLoadingCancel(false);
      alert("Error: Failed to cancel the schedule.");
    }
  };

  const completeCleaning = async () => {
    setLoadingComplete(true);
    try {
      await api.patch(`/admins/overview/${data.id}/finish_schedule`);
      setLoadingComplete(false);
      alert("The schedule has been completed.");
      setOpen(false);
      updateItens();
    } catch (error) {
      console.log(error);
      setLoadingComplete(false);
      alert("Error: Failed to complete the schedule.");
    }
  };

  function extrairHorarioAmPm(inputString) {
    const regex = /(\d{2}:\d{2}:\d{2})/;

    if (inputString !== null) {
      const match = inputString.match(regex);
      if (match && match.length > 1) {
        const horario = match[1];
        const [horas, minutos] = horario.split(":");
        const horaInt = parseInt(horas, 10);

        if (horaInt >= 12) {
          const horaFormatada = horaInt === 12 ? 12 : horaInt - 12;
          return `${horaFormatada}:${minutos} pm`;
        } else {
          return `${horas}:${minutos} am`;
        }
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  const formattedDateNext = (date, time) => {
    return `${date ? moment(date).utc().format("MMM. DD, YYYY") : ""} - ${
      time?.length > 20 ? extrairHorarioAmPm(time) : time || ""
    }`;
  };

  const formattedDate = (date, time) => {
    return `${date ? moment(date).utc().format("MMM. DD, YYYY") : ""} - ${
      time?.length > 20 ? extrairHorarioAmPm(time) : time || ""
    }`;
  };

  const capitalize = (str) => {
    if (typeof str !== "string") {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.substr(1);
  };

  const handleIdByChange = (newValue) => {
    setNameCleaner(newValue);
    setIdCleaner(newValue.id);
  };

  const checkStatus = (status) => {
    switch (status) {
      case "done":
        return "#b3ebb3";
      case "canceled":
        return "#c4a883";
      case "scheduled":
        return "#b5bdda";
      default:
        return "#faef8f";
    }
  };

  function handleClick(id, item) {
    navigate(`/clients/info/${id}`, {
      state: { item, listCities, listRegions },
    });
  }

  function navegateLog(id) {
    navigate(`/overview/log/${id}`);
  }

  const navegateChat = (id) => {
    navigate(`/overview/chat/${id}`, {
      state: { cleanerId: data.cleanerId },
    });
  };

  // console.log(data);

  return (
    <div className="containerDetailsSchedule">
      <button className="buttonCardDetails" onClick={handleOpen}>
        Details
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className={`containerModal`}
          style={{ ...style, backgroundColor: `${checkStatus(data.status)}` }}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="titleModal">{`Cleaning #${data.id}`}</div>
              <button
                className="buttonViewChat"
                onClick={() => navegateChat(data.id)}
              >
                View chat
              </button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "12px",
              }}
            >
              <div
                className="subTitleModal"
                onClick={() => handleClick(data.id, data)}
                style={{ cursor: "pointer" }}
              >
                {data.client}
              </div>
              <div className="textStatusCleaning">{`Cleaning ${data.status}`}</div>
            </div>
          </div>

          <div className="lineDivisionModal"></div>

          {data.status !== "scheduled" && (
            <div className="textCardModal" style={{ marginBottom: "10px" }}>
              {data.cleaner || "No cleaner assigned"}
            </div>
          )}

          <div>
            {data.status === "scheduled" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
              >
                <Autocomplete
                  options={newListCleaner}
                  data={data}
                  placeholder="Select cleaner"
                  onChange={handleIdByChange}
                  // onSelect={...props}
                />
                {/* <Autocomplete
                  id="combo-box-demo"
                  options={newListCleaner}
                  getOptionLabel={(option) => option.name}
                  value={nameCleaner}
                  onChange={handleIdByChange}
                  disablePortal
                  disableClearable
                  sx={{
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    }, 
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    border: "1.6px solid #0E1F41",
                    backgroundColor: "#FAFAFA",
                    borderRadius: "4px",
                    width: "100%",
                    height: "44px",
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="" />
                  )}
                /> */}
                {/* <select
                  value={nameCleaner}
                  onChange={handleIdByChange}
                  style={{
                    border: "1.6px solid #0E1F41",
                    backgroundColor: "#FAFAFA",
                    borderRadius: "4px",
                    width: "100%",
                    height: "44px",
                  }}
                >
                  <option value="None"></option>
                  {newListCleaner.map((value, idx) => (
                    <option value={value.id} key={idx}>
                      {value.name}
                    </option>
                  ))}
                </select> */}

                <button
                  className="completeButtonModal"
                  onClick={() => inviteCleaner()}
                >
                  {loadingInvite ? "Loading..." : "Invite Cleaner"}
                </button>
              </div>
            )}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div className="textCardModal">
                  <strong>Checkout:</strong>
                  {` ${formattedDate(data.date, data.time)}`}
                </div>
                <div className="textCardModal">
                  <strong>Next Check-in:</strong>
                  {` ${formattedDateNext(
                    data?.nextCheckinDate,
                    data?.nextCheckinTime
                  )}`}
                </div>
              </div>

              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                {data.status === "scheduled" && (
                  <EditDateSchedule
                    data={data}
                    setLoadingButton={updateItens}
                  />
                )}

                {data?.extended && <div className="extended">Extended</div>}
              </div>
            </div>
            {/* <div className="textCardModal">
              <strong>Next guest:</strong>
              {` none`}
            </div> */}
          </div>

          <div className="lineDivisionModal"></div>

          <div>
            <div className="textCardModal">
              <strong>Address:</strong>
              {` ${data?.address?.address || data?.address}`}
            </div>
            <div className="textCardModal">
              <strong>{`${capitalize(data?.getIn?.title)}:`}</strong>
              {` ${data?.getIn?.description}`}
            </div>
            <div className="textCardModal">
              <strong>Rooms:</strong>
              {` ${data.bedroomsAmount}`}
            </div>
            <div className="textCardModal">
              <strong>Bathrooms:</strong>
              {` ${data.bathroomsAmount}`}
            </div>
            <div className="textCardModal">
              <strong>Value:</strong>
              {` ${Number(data.value).toFixed(2)} USD`}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
              gap: "10px",
            }}
          >
            {(data?.status === "scheduled" || data?.status === "started") && (
              <ModalContainer
                button=""
                type={true}
                title="Cancel Cleaning"
                onClickSave={cancelCleaning}
                customButton={
                  <button className="cancelButtonModal">Cancel Cleaning</button>
                }
              >
                <div className="textCardModal" style={{ marginBottom: "20px" }}>
                  Are you sure you want to cancel cleaning?
                </div>
              </ModalContainer>
            )}

            {/* 
            <button
              className="completeButtonModal"
              onClick={() => completeCleaning()}
            >
              {loadingComplete ? "Loading..." : "Complete Cleaning"}
            </button> */}

            {(data?.status === "scheduled" || data?.status === "started") && (
              <CompleteCleaning
                data={data}
                Put={updateItens}
                closeModal={handleClose}
              />
            )}
            {audit && (
              <button
                className="completeButtonModal"
                style={{ backgroundColor: "#0e1f41" }}
                onClick={() => navegateLog(data.id)}
              >
                View Log
              </button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DetailsScheduleModal;
