import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthorizedLayout } from "../../Components/AuthorizedLayout";

import voltarIcon from "../../Assets/Union (4).svg";

import CardsTemplates from "./Components/CardsTemplates";

import "./AllTemplates.css";
import { ReactSVG } from "react-svg";
import AppContext from "../../Context/AppContext";
import api from "../../Service/api";

const AllTemplates = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { typeCLeaner, setTypeCleaner, typePush } = useContext(AppContext);
  const [typeEmail, setTYpeEmail] = useState([]);
  const [filterName, setFilterName] = useState([]);
  const [loading, setLoading] = useState(true);
  const [emailsTemplatesClients, setEmailsTemplatesClients] = useState([]);
  const [emailsTemplatesCleaners, setEmailsTemplatesCleaners] = useState([]);
  const [pushTemplatesClients, setPushTemplatesClients] = useState([]);
  const [pushTemplatesCleaners, setPushTemplatesCleaners] = useState([]);

  const tgoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const templatesClients = await api.get(
          "/admins/message_templates?template_type=mail&subject_type=client"
        );
        const templatesCleaners = await api.get(
          "/admins/message_templates?template_type=mail&subject_type=cleaner"
        );
        const templatesPushClients = await api.get(
          "/admins/message_templates?template_type=push_notification&subject_type=client"
        );
        const templatesPushCleaners = await api.get(
          "/admins/message_templates?template_type=push_notification&subject_type=cleaner"
        );

        setEmailsTemplatesCleaners(templatesCleaners);
        setEmailsTemplatesClients(templatesClients);
        setPushTemplatesCleaners(templatesPushCleaners);
        setPushTemplatesClients(templatesPushClients);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    if (loading) {
      fetchData();
    }
  }, [loading]);

  return (
    <AuthorizedLayout>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div className="containerMenuHeaderTampletas">
          <button className="buttonbACK" onClick={() => tgoBack()}>
            {" "}
            <ReactSVG src={voltarIcon} />
            {` Back to ${typeCLeaner} ${typePush ? 'push notification' : 'emails'}`}
          </button>

          <div className="containerMenuHeaderEmails">
            <div
              className={
                typeCLeaner === "Clients"
                  ? "selectedCLeaner"
                  : "notSelectedCLeaner"
              }
              onClick={() => setTypeCleaner("Clients")}
            >
              Clients
            </div>
            <div
              className={
                typeCLeaner === "Cleaners"
                  ? "selectedCLeaner"
                  : "notSelectedCLeaner"
              }
              onClick={() => setTypeCleaner("Cleaners")}
            >
              Cleaners
            </div>
          </div>
        </div>

        <div>
          {typeCLeaner === "Cleaners" ? (
            typePush ? (
              <CardsTemplates
                items={pushTemplatesCleaners}
                typeSelected={typeCLeaner}
                setLoading={setLoading}
                typePush={typePush}
                loading={loading}
              />
            ) : (
              <CardsTemplates
                items={emailsTemplatesCleaners}
                typeSelected={typeCLeaner}
                setLoading={setLoading}
                typePush={typePush}
                loading={loading}
              />
            )
          ) : typePush ? (
            <CardsTemplates
              items={pushTemplatesClients}
              typeSelected={typeCLeaner}
              setLoading={setLoading}
              typePush={typePush}
              loading={loading}
            />
          ) : (
            <CardsTemplates
              items={emailsTemplatesClients}
              typeSelected={typeCLeaner}
              setLoading={setLoading}
              typePush={typePush}
              loading={loading}
            />
          )}
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default AllTemplates;
