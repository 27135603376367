import React, { useContext, useEffect, useState } from "react";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import CardTable from "./components/CardTable";
import TableFinance from "./components/TableFinance";
import BestClientsTable from "./components/BestClientsTable";
import BestCleanersTable from "./components/BestCleanersTable";
import BestPropertiesTable from "./components/BestPropertiesTable";

import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import EventIcon from "@mui/icons-material/Event";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import iconPrint from "../../../../Assets/🦆 icon _printer alt_.svg";
import iconDonwload from "../../../../Assets/Union (5).svg";

import "./Region.css";
import AppContext from "../../../../Context/AppContext";
import api from "../../../../Service/api";
import moment from "moment";
import { ReactSVG } from "react-svg";

const renderCustomIcon = (props) => {
  return (
    <ExpandMoreIcon {...props} style={{ color: "#FAFAFA", fontSize: "40px" }} />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    flex: 1,
  },
  blueRow: {
    backgroundColor: "#D5E7F4",
  },
}));

const CustomInput = ({ value, onClick, props }) => (
  <button
    onClick={onClick}
    className="containerIcon"
    style={{ cursor: "pointer", height: "50px" }}
  >
    <ExpandMoreIcon {...props} style={{ color: "#FAFAFA", fontSize: "40px" }} />
  </button>
);

const bestHosts = [
  { cleaners: "Luzia olinda", Rating: 5 },
  { cleaners: "SImone sales", Rating: 8 },
  { cleaners: "GUstavo minota", Rating: 6 },
  { cleaners: "Manuel borges", Rating: 8 },
  { cleaners: "Denis nascimentos", Rating: 6 },
];

const initialFilterState = {
  region: {
    id: "1",
    name: "Los Angeles",
  },
  city: "",
  dateFrom: "",
  dateTo: "",
  rating: "",
};

const Region = () => {
  const classes = useStyles();
  const { listRegions, listCities } = useContext(AppContext);
  const [filter, setFilter] = useState(initialFilterState);
  const [listGeneral, setListGeneral] = useState([]);
  const [searched, setSearched] = useState(false);
  const [isLoading, setIsLOading] = useState(true);

  const getList = async () => {
    setIsLOading(true);
    try {
      const data = await api.get("/admins/reports/region", {
        params: {
          from: filter.dateFrom
            ? moment(filter.dateFrom).utc().format("DD/MM/YYYY")
            : "",
          to: filter.dateTo
            ? moment(filter.dateTo).utc().format("DD/MM/YYYY")
            : "",
          city: filter.city.id,
          region: filter.region.id,
        },
      });
      setListGeneral(data);
      setIsLOading(false);
    } catch (erro) {
      console.log(erro);
      setIsLOading(false);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const handleSearch = () => {
    setListGeneral([]);
    getList();
    setSearched(true);
  };

  const fetchDataReset = async () => {
    setListGeneral([]);
    setIsLOading(true);
    try {
      const data = await api.get("/admins/reports/region", {
        params: {
          region: 1,
        },
      });
      setListGeneral(data);
      setIsLOading(false);
    } catch (erro) {
      console.log(erro);
      setIsLOading(false);
    }
  };

  const handleResetFilter = () => {
    setFilter(initialFilterState);
    fetchDataReset();
    setSearched(false);
  };

  const handleFromDateChange = (date) => {
    setFilter((prevState) => ({
      ...prevState,
      dateFrom: moment(date).utc().format("MM/DD/YYYY"),
    }));
  };

  const handleToDateChange = (date) => {
    setFilter((prevState) => ({
      ...prevState,
      dateTo: moment(date).utc().format("MM/DD/YYYY"),
    }));
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter((prevState) => ({ ...prevState, [name]: value }));

    if (name === "region") {
      setFilter((prevState) => ({ ...prevState, city: "" }));
    }
  };

  const filterCities = listCities?.filter((item) => {
    if (filter.region === "") {
      return true;
    }
    return Number(item.regionId) === Number(filter.region.id);
  });

  return (
    <div className="containerGeneralReports">
      <div className="titleHeader">Reports</div>

      <div className="containerHeaderReports">
        <div className="containerFilterReports">
          <div className="timeContainerButtons">
            <div className="containerSelectAdmin">
              <div className="selectedCONtainer containerBox"  style={{marginTop: '10px'}}>
                <span className="contentText">Region</span>
                <div>{filter.region.name}</div>
              </div>
              <Select
                className="containerIcon"
                onChange={handleFilterChange}
                name="region"
                style={{ height: "50px" }}
                displayEmpty
                renderValue={() => null}
                IconComponent={renderCustomIcon}
              >
                {listRegions?.map((item, idx) => (
                  <MenuItem value={item} key={idx}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="containerSelectAdmin">
              <div className="selectedCONtainer containerBox">
                <span className="contentText">City</span>
                <div>{filter.city.name}</div>
              </div>
              <Select
                className="containerIcon"
                onChange={handleFilterChange}
                name="city"
                style={{ height: "50px" }}
                displayEmpty
                renderValue={() => null}
                IconComponent={renderCustomIcon}
              >
                {filterCities?.map((item, idx) => (
                  <MenuItem value={item} key={idx}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className="containerSelectAdmin">
              <div className="selectedCONtainer containerBox">
                <span className="contentText">From</span>
                <div>{filter.dateFrom}</div>
              </div>

              <DatePicker
                onChange={handleFromDateChange}
                dateFormat="dd/MM/yyyy"
                customInput={<CustomInput />}
              />
            </div>

            <div className="containerSelectAdmin">
              <div className="selectedCONtainer containerBox">
                <span className="contentText">To</span>
                <div>{filter.dateTo}</div>
              </div>

              <DatePicker
                onChange={handleToDateChange}
                dateFormat="dd/MM/yyyy"
                customInput={<CustomInput />}
              />
            </div>

            {searched ? (
              <button className="buttonFilter" onClick={handleResetFilter}>
                Clear Selection
              </button>
            ) : (
              <button
                className="buttonFilter"
                style={{ backgroundColor: "#0e1f41" }}
                onClick={handleSearch}
              >
                Search
              </button>
            )}
          </div>

          {/* <div style={{ position: "relative" }}>
            <div className="textCompare">Compare to</div>
            <div className="timeContainerButtons">
              <div className="containerSelectAdmin">
                <div className="selectedCONtainer containerBox">
                  <span className="contentText">Region</span>
                  <div>{filter.region}</div>
                </div>
                <Select
                  className="containerIcon"
                  onChange={handleFilterChange}
                  name="region"
                  style={{ height: "50px" }}
                  displayEmpty
                  renderValue={() => null}
                  IconComponent={renderCustomIcon}
                >
                  {listRegions?.map((item, idx) => (
                    <MenuItem value={item?.name} key={idx}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <div className="containerSelectAdmin">
                <div className="selectedCONtainer containerBox">
                  <span className="contentText">City</span>
                  <div>{filter.city}</div>
                </div>
                <Select
                  className="containerIcon"
                  onChange={handleFilterChange}
                  name="city"
                  style={{ height: "50px" }}
                  displayEmpty
                  renderValue={() => null}
                  IconComponent={renderCustomIcon}
                >
                  {filterCities?.map((item, idx) => (
                    <MenuItem value={item?.name} key={idx}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <div className="containerSelectAdmin">
                <div className="selectedCONtainer containerBox">
                  <span className="contentText">From</span>
                  <div>{filter.dateFrom}</div>
                </div>

                <DatePicker
                  onChange={handleFromDateChange}
                  dateFormat="dd/MM/yyyy"
                  customInput={<CustomInput />}
                />
              </div>

              <div className="containerSelectAdmin">
                <div className="selectedCONtainer containerBox">
                  <span className="contentText">To</span>
                  <div>{filter.dateTo}</div>
                </div>

                <DatePicker
                  onChange={handleToDateChange}
                  dateFormat="dd/MM/yyyy"
                  customInput={<CustomInput />}
                />
              </div>
            </div>
          </div> */}
        </div>

        {/* <div className="reportsDoc">
          <div className="containerButon">
            <button>Download</button>
            <ReactSVG src={iconDonwload} />
          </div>

          <div className="containerButon">
            <button>Print</button>
            <ReactSVG src={iconPrint} />
          </div>
        </div> */}
      </div>

      {listGeneral?.clients ? (
        <>
          <div className="containerGeneralReportsScroll ">
            <div className="containerCardsTable flexDirecitonCOllum">
              <CardTable data={listGeneral?.clients} title="Clients" />

              <CardTable data={listGeneral?.properties} title="Properties" />

              <CardTable data={listGeneral?.cleaners} title="Cleaners" />
            </div>

            <div className="containerFinanceReports flexDirecitonCOllum">
              <div style={{ flex: 1 }}>
                <TableFinance data={listGeneral?.financials} />
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <div style={{ flex: 1 }}>
                  <TableContainer component={Paper} className={classes.root}>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell className="titleTable">Jobs</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow className={classes.blueRow}>
                          <TableCell
                            className="subtitleTable"
                            style={{ display: "flex" }}
                          >
                            <div style={{ flex: 1 }}>Total:</div>{" "}
                            <div style={{ flex: 1 }}>
                              {listGeneral?.jobs?.total}
                            </div>
                          </TableCell>
                        </TableRow>

                        <TableRow className={""}>
                          <TableCell
                            className="subtitleTable"
                            style={{ display: "flex" }}
                          >
                            <div style={{ flex: 1 }}>Deep Cleanings:</div>{" "}
                            <div style={{ flex: 1 }}>
                              {listGeneral?.jobs?.deep_clean}
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>

                <div className="containerRentation" style={{ flex: 1 }}>
                  <div className="subtitle">Costumes Retantion</div>
                  <div className="title">
                    {listGeneral?.customer_retention}%
                  </div>
                </div>
              </div>
            </div>
            <div className="containerBestsReports flexDirecitonCOllum">
              <BestClientsTable data={listGeneral?.best_clients} />
              <BestCleanersTable data={listGeneral?.best_cleaners} />
            </div>

            <div>
              <BestPropertiesTable data={listGeneral?.best_properties} />
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "40vh",
            width: "100%",
          }}
        >
          {isLoading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress size={50} />
            </Box>
          ) : (
            <div className="titleFilter">No matching items found.</div>
          )}
        </div>
      )}
    </div>
  );
};

export default Region;
