import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { ReactSVG } from "react-svg";
import { Stack } from "@mui/material";
import Rating from "@mui/material/Rating";
import W9FormModal from "../W9FormModal";
import Argreement from "../Argreement";

import StikeIcon from "../../../../Assets/Strike Icon.svg";
import CleaningIcon from "../../../../Assets/Cleaning Icon2.svg";
import MedalIcon from "../../../../Assets/Vector.svg";

import PhotosDocumentsModal from "./Components/PhotosDocumentsModal";

import "./ModalDocuments.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const ModalDocuments = ({ item }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [modalOpenAgre, setModalOpenAgre] = useState(false);

  const handleOpenModalAgre = () => {
    setModalOpenAgre(true);
  };

  const handleCloseModalAgre = () => {
    setModalOpenAgre(false);
  };

  // console.log(data);

  return (
    <div>
      <button
        className="buttonDocument"
        onClick={handleOpen}
        style={{ backgroundColor: "#66CDAA" }}
      >
        See Files
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className="containerDocuments">
          <div
            className="conatainerHeader"
            style={{ display: "flex", gap: "100px" }}
          >
            <div
              className="containerUserHeader"
              style={{ display: "flex", alignItems: "center", gap: "20px" }}
            >
              {item?.avatar === null ? (
                <div
                  className="userPhoto"
                  style={{
                    width: "64px",
                    height: "64px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "30px",
                    color: "#e5ebf2",
                  }}
                >
                  ?
                </div>
              ) : (
                <img
                  src={item?.avatar}
                  alt="user"
                  className="userPhoto"
                  style={{
                    width: "64px",
                    height: "64px",
                    objectFit: "cover",
                  }}
                />
              )}
              <div>
                <div className="title">{`${item?.firstName} ${item?.lastName}`}</div>
                <div>
                  <Stack spacing={1}>
                    <Rating
                      name="half-rating-read"
                      defaultValue={item?.ratting}
                      precision={0.5}
                      readOnly
                      sx={{ color: "#0E1F41", fontSize: "25px" }}
                    />
                  </Stack>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                gap: "5px",
                justifyContent: "center",
                // alignItems: 'center',
              }}
            >
              <div className="containerIcon">
                <ReactSVG src={CleaningIcon} /> {item?.totalCleanings} Cleanings
              </div>
              <div className="containerIcon">
                <ReactSVG src={StikeIcon} /> {item?.strikes} Strikes
              </div>
              <div className="containerIcon">
                <ReactSVG src={MedalIcon} /> Level {item?.level}
              </div>
            </div>
          </div>
          <div className="file">Files</div>
          {item.wNineForms !== null && (
            <>
              <div
                onClick={handleOpenModal}
                className="titleDOcument"
                style={{ border: "none", cursor: "pointer" }}
              >
                Form W-9
              </div>
              <div>
                <W9FormModal
                  data={item.wNineForms}
                  open={modalOpen}
                  onClose={handleCloseModal}
                />
              </div>
            </>
          )}
          <>
            <div
              onClick={handleOpenModalAgre}
              className="titleDOcument"
              style={{ border: "none", cursor: "pointer" }}
            >
              Terms and Agreement
            </div>
            <div>
              <Argreement
                data={item}
                open={modalOpenAgre}
                onClose={handleCloseModalAgre}
              />
            </div>
          </>

          <PhotosDocumentsModal item={item} />
        </div>
      </Modal>
    </div>
  );
};

export default ModalDocuments;
