import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import { ReactSVG } from "react-svg";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { ListItem } from "@mui/material";
import { SidebarLink } from "../../../Components/MainSidebar/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import api from "../../../Service/api";

import setaGRaph from "../../../Assets/Vector 25.svg";
import GraphCard from "./components/GraphCard";

import "./Sumary.css";

const itensCleanings = [
  "Manual",
  "Airb&b",
  "Vrbo",
  "Nomah",
  "Booking",
  "Plum Guide",
  "Agoda",
  "Trustedhousesitters",
];

const renderCustomIcon = (props) => {
  return (
    <ExpandMoreIcon {...props} style={{ color: "#FAFAFA", fontSize: "40px" }} />
  );
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // width: "120px",
  border: `none`,
  "&:not(:last-child)": {},
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <MuiAccordionSummary
      expandIcon={
        <ReactSVG src={setaGRaph} style={{ transform: "rotate(90deg)" }} />
      }
      {...props}
      onClick={() => setExpanded(!expanded)}
    />
  );
})(({ theme }) => ({
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(178deg)",
  },
  "& .MuiAccordionSummary-content": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    margin: "0 15px",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({}));

const initialFilterState = {
  region: "General",
};

const Sumary = () => {
  const { metrics, user, listRegions } = useContext(AppContext);
  const [filter, setFilter] = useState(initialFilterState);
  const [selectedCleaner, setSelectedCleaners] = useState("Cleanings");
  const [selectedHost, setSelectedHost] = useState("Cleanings");
  const [expanded, setExpanded] = useState("");
  const [expanded2, setExpanded2] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLOading] = useState(true);
  const [searched, setSearched] = useState(false);

  const getInfoSumary = async () => {
    setIsLOading(true);
    try {
      const { data } = await api.get("/admins/payments/sumary", {
        params: {
          region: filter.region.id || '',
        },
      });
      setData(data);
      setIsLOading(true);
    } catch (error) {
      console.log(error);
      setIsLOading(false);
    }
  };

  useEffect(() => {
    getInfoSumary();
  }, []);

  const handleSearch = () => {
    getInfoSumary();
    setSearched(true);
    setData([]);
  };

  const fetchDataReset = async () => {
    setData([]);
    setIsLOading(true);
    try {
      const { data } = await api.get("/admins/payments/sumary");
      setData(data);
      setIsLOading(false);
    } catch (erro) {
      console.log(erro);
      setIsLOading(false);
    }
  };

  const handleResetFilter = () => {
    setFilter(initialFilterState);
    fetchDataReset();
    setSearched(false);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter((prevState) => ({ ...prevState, [name]: value }));

    if (name === "region") {
      setFilter((prevState) => ({ ...prevState, city: "" }));
    }
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleItemClick = (event) => {
    const item = event.target.value;

    if (item === "Cleanings") {
      setSelectedCleaners("Cleanings");
      setExpanded("");
    } else {
      setSelectedCleaners("Rating");
      setExpanded("");
    }
  };

  const handleChange2 = (panel) => (event, newExpanded) => {
    setExpanded2(newExpanded ? panel : false);
  };

  const handleItemClick2 = (event) => {
    const item = event.target.value;

    if (item === "Cleanings") {
      setSelectedHost("Cleanings");
      setExpanded2("");
    } else {
      setSelectedHost("Rating");
      setExpanded2("");
    }
  };

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);

    return formattedValue;
  };

  function sumNumbers(finalBalance) {
    const sum = Object.values(finalBalance).reduce(
      (acc, currentValue) => acc + parseFloat(currentValue),
      0
    );
    return sum;
  }

  return (
    <>
      <div className="containerHeaderReports">
        <div className="timeContainerButtons flexDirecitonCOllum">
          <div className="containerSelectAdmin">
            <div
              className="selectedCONtainer containerBox"
              style={{ minWidth: "200px", height: "35px" }}
            >
              <span className="contentText">Region</span>
              <div>{filter.region.name || filter.region}</div>
            </div>
            <Select
              className="containerIcon"
              onChange={handleFilterChange}
              name="region"
              style={{ height: "100%" }}
              displayEmpty
              renderValue={() => null}
              IconComponent={renderCustomIcon}
            >
              <MenuItem value={"General"}>General</MenuItem>
              {listRegions?.map((item, idx) => (
                <MenuItem value={item} key={idx}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </div>

          {searched ? (
            <button className="buttonFilter" onClick={handleResetFilter}>
              Clear Selection
            </button>
          ) : (
            <button
              className="buttonFilter"
              style={{ backgroundColor: "#0e1f41" }}
              onClick={handleSearch}
            >
              Search
            </button>
          )}
        </div>
        {/* 
        <div className="reportsDoc">
          <div className="containerButon">
            <button>Download</button>
            <ReactSVG src={iconDonwload} />
          </div>

          <div className="containerButon">
            <button>Print</button>
            <ReactSVG src={iconPrint} />
          </div>
        </div> */}
      </div>
      {data.finalBalance ? (
        <div className="containerSumary">
          <div className="containerCards">
            <div className="card">
              <div className="title">{`Final Balance: ${formatCurrency(
                sumNumbers(data?.finalBalance || 0)
              )}`}</div>
              <div className="containerText">
                <div className="subtitle">Amount Reserved:</div>
                <div className="subtitle">
                  {formatCurrency(data?.finalBalance?.amountReserved) || 0}
                </div>
              </div>
              <div className="containerText">
                <div className="subtitle">Amount Refunded:</div>
                <div className="subtitle">
                  {formatCurrency(data?.finalBalance?.amountRefunded) || 0}
                </div>
              </div>
              <div className="containerText">
                <div className="subtitle">Amount Pending:</div>
                <div className="subtitle">
                  {formatCurrency(data?.finalBalance?.amountPending) || 0}
                </div>
              </div>
              <div className="containerText">
                <div className="subtitle">Amount Recived:</div>
                <div className="subtitle">
                  {formatCurrency(data?.finalBalance?.amountReceived) || 0}
                </div>
              </div>
              <div className="containerText">
                <div className="subtitle">Amount Paid:</div>
                <div className="subtitle">
                  {formatCurrency(data?.finalBalance?.amountPaid) || 0}
                </div>
              </div>
            </div>

            <div className="card">
              <div className="title">{`Total Entries: ${formatCurrency(
                sumNumbers(data?.totalEntries || 0)
              )}`}</div>
              <div className="containerText">
                <div className="subtitle">Amount Received:</div>
                <div className="subtitle">
                  {formatCurrency(data?.totalEntries?.amountReceived) || 0}
                </div>
              </div>
              <div className="containerText">
                <div className="subtitle">Reserved Amount:</div>
                <div className="subtitle">
                  {formatCurrency(data?.totalEntries?.amountReserved) || 0}
                </div>
              </div>
              <div className="containerText">
                <div className="subtitle">Pending Amount:</div>
                <div className="subtitle">
                  {formatCurrency(data?.totalEntries?.amountPending) || 0}
                </div>
              </div>
            </div>

            <div className="card">
              <div className="title">{`Total Exits: ${formatCurrency(
                sumNumbers(data?.totalExits || 0)
              )}`}</div>
              <div className="containerText">
                <div className="subtitle">Amount Received:</div>
                <div className="subtitle">
                  {formatCurrency(data?.totalExits?.amountReceived) || 0}
                </div>
              </div>
              <div className="containerText">
                <div className="subtitle">Reserved Amount:</div>
                <div className="subtitle">
                  {formatCurrency(data?.totalExits?.amountReserved) || 0}
                </div>
              </div>
              <div className="containerText">
                <div className="subtitle">Pending Amount:</div>
                <div className="subtitle">
                  {formatCurrency(data?.totalExits?.amountPending) || 0}
                </div>
              </div>
            </div>
          </div>

          <div className="containerCards">
            <div className="containerCenter">
              <div className="containerHeader title">{`Cleanings: ${formatCurrency(
                data.cleanings.total
              )}`}</div>
              <div className="containberCards flexDirecitonCOllum">
                <div className="cardItens">
                  <div className="subtitle">Manual</div>
                  <div className="subtitle">
                    {formatCurrency(data.cleanings.manual)}
                  </div>
                </div>
                <div className="cardItens">
                  <div className="subtitle">Airbnb</div>
                  <div className="subtitle">
                    {formatCurrency(data.cleanings.airbnb)}
                  </div>
                </div>
                <div className="cardItens">
                  <div className="subtitle">Vrbo</div>
                  <div className="subtitle">
                    {formatCurrency(data.cleanings.vrbo)}
                  </div>
                </div>
              </div>
              {/* <div className="containberCards">
                {itensCleanings.map((item, idx) => (
                  <div key={idx} className="cardItens">
                    <div className="subtitle">{item}</div>
                    <div className="subtitle">0</div>
                  </div>
                ))}
              </div> */}
            </div>
          </div>

          <div className="containerGRaph">
            <div style={{ gap: "25px", display: "flex" }} className="flexDirecitonCOllum">
              <div className="boxShadon">
                <div className="containerTitleGraph">
                  <SidebarLink to={"/dashboard"}>
                    <ListItem style={{ margin: 0, padding: 0 }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          flex: 1,
                        }}
                      >
                        <div className="titleGraph">Top Cleaners</div>
                        <div>
                          <ReactSVG src={setaGRaph} />
                        </div>
                      </div>
                    </ListItem>
                  </SidebarLink>
                </div>

                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      right: 30,
                      marginTop: "10px",
                    }}
                  >
                    <div className="titleByGraph">Top Cleaners</div>
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                      style={{ backgroundColor: "transparent" }}
                    >
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        style={{ padding: "0" }}
                      >
                        <div
                          className={"graphText"}
                        >{`By: ${selectedCleaner}`}</div>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{
                          marginTop: "0",
                          paddingTop: "0",
                          width: "100%",
                        }}
                      >
                        <button
                          onClick={(event) => handleItemClick(event)}
                          value={
                            selectedCleaner !== "Cleanings"
                              ? "Cleanings"
                              : "Rating"
                          }
                          className="buttonGraph"
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            margin: 0,
                            padding: 0,
                            cursor: "pointer",
                          }}
                        >
                          {selectedCleaner !== "Cleanings"
                            ? "Cleanings"
                            : "Rating"}
                        </button>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>

                <GraphCard
                  dataMetric={
                    selectedCleaner === "Rating"
                      ? data.topCleanersRating
                      : data.topCleanersCleanings
                  }
                />
              </div>

              <div className="boxShadon ">
                <div className="containerTitleGraph">
                  <SidebarLink to={"/dashboard"}>
                    <ListItem style={{ margin: 0, padding: 0 }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          flex: 1,
                        }}
                      >
                        <div className="titleGraph">Top Hosts</div>
                        <div>
                          <ReactSVG src={setaGRaph} />
                        </div>
                      </div>
                    </ListItem>
                  </SidebarLink>
                </div>

                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      right: 30,
                      marginTop: "10px",
                    }}
                  >
                    <div className="titleByGraph">Top Hosts</div>
                    <Accordion
                      expanded={expanded2 === "panel1"}
                      onChange={handleChange2("panel1")}
                      style={{ backgroundColor: "transparent" }}
                    >
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        style={{ padding: "0" }}
                      >
                        <div
                          className={"graphText"}
                        >{`By: ${selectedHost}`}</div>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{
                          marginTop: "0",
                          paddingTop: "0",
                          width: "100%",
                        }}
                      >
                        <button
                          onClick={(event) => handleItemClick2(event)}
                          value={
                            selectedHost !== "Cleanings"
                              ? "Cleanings"
                              : "Rating"
                          }
                          className="buttonGraph"
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            margin: 0,
                            padding: 0,
                            cursor: "pointer",
                          }}
                        >
                          {selectedHost !== "Cleanings"
                            ? "Cleanings"
                            : "Rating"}
                        </button>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>

                <GraphCard
                  dataMetric={
                    selectedHost === "Rating"
                      ? data.topHostsRating
                      : data.topHostsCleanings
                  }
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "40vh",
            width: "100%",
          }}
        >
          {isLoading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress size={50} />
            </Box>
          ) : (
            <div className="titleFilter">No matching items found.</div>
          )}
        </div>
      )}
    </>
  );
};

export default Sumary;
