import React, { useState } from "react";
import { ReactSVG } from "react-svg";

import setaBotton from "../../../Assets/Vector 25 (1).svg";

import "./CardListCIty.css";

const CardListCity = ({ list }) => {
  const [showAll, setShowAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const citiesToShow = showAll ? list : list?.slice(0, 4);
  const remainingCities = showAll ? [] : list?.slice(4);

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  return (
    <>
      <div className="city-list-container">
        <div className="city-list">
          {citiesToShow?.map((city, index) => (
            <div key={index} className="city-item">
              {city}
            </div>
          ))}
          {remainingCities?.length > 0 && (
            <>
              <div className="city-item">
                {"+" + remainingCities?.length || ""}
              </div>
              <div style={{ cursor: "pointer" }} onClick={toggleModal}>
                <ReactSVG src={setaBotton} />
              </div>
            </>
          )}
        </div>
        {showModal && (
          <div className="modal-container" onClick={toggleModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <div className="modal-header">
                <h3>Other cities</h3>
              </div>
              <div className="modal-body">
                {remainingCities?.map((city, index) => (
                  <div key={index} className="city-item">
                    {city}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CardListCity;
