import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useFormik } from "formik";
import * as yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { ModalContainer } from "../../../../Components/ModalContainer";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import editIcon from "../../../../Assets/edit 2 (1).svg";
import CardTable from "../CardTable";

import "./TablePriceEdit.css";
import api from "../../../../Service/api";
import AppContext from "../../../../Context/AppContext";

const renderCustomIcon = (props) => {
  return (
    <ExpandMoreIcon {...props} style={{ color: "#FAFAFA", fontSize: "40px" }} />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  blueRow: {
    backgroundColor: "#D5E7F4",
  },
  borderHeader: {
    borderBottom: "2px solid #0E1F41",
    borderRight: "2px solid #0E1F41",
  },
  borderContent: {
    borderBottom: "1px solid #0E1F41",
    borderRight: "2px solid #0E1F41",
  },
}));

const TablePriceEdit = () => {
  const { listRegions } = useContext(AppContext);
  let navigate = useNavigate();
  const classes = useStyles();
  const [region, setRegion] = useState({
    id: "1",
    name: "Los Angeles",
  });
  const [service, setService] = useState({ service_name: "Service" });
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listService, setListService] = useState([]);
  const [isLoading, setIsLOading] = useState(false);

  const getListServcies = async (id) => {
    setIsLOading(true);
    try {
      const { data } = await api.get(`/admins/pricing_tables/`, {
        params: {
          region: id,
        },
      });

      const newData = await data?.map((value) => ({
        id: value.id,
        type: value.type,
        ...value.attributes,
      }));

      setData(newData);
      setIsLOading(false);
    } catch (error) {
      console.log(error);
      setIsLOading(false);
    }
  };

  useEffect(() => {
    getListServcies(1);
  }, []);

  useEffect(() => {
    const getServcies = async () => {
      try {
        const { data } = await api.get("/admins/cleaning_service");

        const newData = await data?.map((value) => ({
          id: value.id,
          type: value.type,
          ...value.attributes,
        }));
        setListService(newData);
      } catch (error) {
        console.log(error);
      }
    };

    getServcies();
  }, []);

  const handleChange = (event) => {
    const { id } = event.target.value;
    setData([]);
    getListServcies(id);
    setRegion(event.target.value);
  };

  const handleChangeService = (event) => {
    setService(event.target.value);
  };

  // console.log(data);

  useEffect(() => {
    let filtered =
      data.length > 0
        ? data?.filter((item) => {
            const isService =
              Number(item?.cleaningServiceId) === Number(service.id);
            const isServiceNotSelected = service.service_name === "Service";

            return isServiceNotSelected || isService;
          })
        : [];

    setFilteredData(filtered);
  }, [data, region, service]);

  const setNewService = async (values) => {
    try {
      await api.post(`/admins/cleaning_service`, {
        data: { attributes: { serviceName: values.serviceName } },
      });
      alert("New service has been successfully added.");
      form.setFieldValue("serviceName", "");
      setLoading(true);
    } catch (error) {
      console.log(error);
      alert("Error: Failed to new service.");
    }
  };

  const setMaxPayoutValue = async (values) => {
    try {
      await api.put(`/admins/princing_table/max_payout_value`, {
        data: {
          attributes: {
            payoutMaxValue: values.payoutMaxValue,
            regionId: region.id,
          },
        },
      });
      alert("New value has been successfully added.");
      getListServcies(region.id);
    } catch (error) {
      console.log(error);
      alert("Error: Failed to new value.");
    }
  };

  const form = useFormik({
    initialValues: {
      // serviceName: "",
      payoutMaxValue: 0,
    },
    validationSchema: yup.object({
      // serviceName: yup
      //   .string()
      //   .min(2, "Title must have at least 2 characters.")
      //   .max(50, "Title must have at most 50 characters.")
      //   .required("Title is required!"),
    }),
    onSubmit: (values) => setMaxPayoutValue(values),
  });

  function handleClick(values) {
    navigate(`/pricing-table/new-service/${values.id}`, {
      state: { ...values },
    });
  }

  const sortedArray = (a, b) => {
    if (Number(a.bedroomsAmount) < Number(b.bedroomsAmount)) {
      return -1;
    }
    if (Number(a.bedroomsAmount) > Number(b.bedroomsAmount)) {
      return 1;
    }

    if (Number(a.bathroomsAmount) < Number(b.bathroomsAmount)) {
      return -1;
    }
    if (Number(a.bathroomsAmount) > Number(b.bathroomsAmount)) {
      return 1;
    }
    return 0;
  };

  return (
    <div>
      <div className="containerHeaderTablePrice">
        <div className="left">
          <div className="title">Pricing Table</div>
          <div className="containerSelectAdmin">
            <div className="selectedContainer">
              <div>{region.name}</div>
            </div>
            <Select
              className="containerIcon"
              onChange={handleChange}
              style={{ height: "50px" }}
              displayEmpty
              renderValue={() => null}
              IconComponent={renderCustomIcon}
            >
              {/* <MenuItem value={{name: "Region"}}>All</MenuItem> */}
              {[
                ...listRegions,
                {
                  id: "default",
                  type: "adminRegion",
                  name: "Pricing Table Default",
                },
              ]?.map((item, idx) => (
                <MenuItem value={item} key={idx}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className="containerSelectAdmin">
            <div className="selectedContainer">
              <div>{service?.service_name}</div>
            </div>
            <Select
              className="containerIcon"
              onChange={handleChangeService}
              style={{ height: "50px" }}
              displayEmpty
              renderValue={() => null}
              IconComponent={renderCustomIcon}
            >
              <MenuItem value={{ service_name: "Service" }}>All</MenuItem>
              {listService?.map((item, idx) => (
                <MenuItem value={item} key={idx}>
                  {item?.service_name}
                </MenuItem>
              ))}
            </Select>
          </div>

          {/* <ModalContainer
            customButton={<button>New Service</button>}
            type={true}
            title="New Service"
            onClickSave={form.submitForm}
          >
            <div className="titleINputModal">Title:</div>
            <div className="containerInputText">
              <input
                type="text"
                className="inputText"
                value={form.values.serviceName}
                onChange={(event) =>
                  form.setFieldValue("serviceName", event.target.value)
                }
              />
            </div>
          </ModalContainer> */}

          <button onClick={() => navigate("/pricing-table/new-service")}>
            New Table
          </button>
        </div>
        {/* <ModalContainer
          customButton={
            <div className="rigth">
              <div className="title">{`Maximum Payout Value: $${
                data[0]?.payout_max_value || 0
              }`}</div>
              <ReactSVG src={editIcon} />
            </div>
          }
          type={true}
          title="Edite Maximum Payout Value"
          onClickSave={form.submitForm}
        >
          <div className="titleINputModal">value:</div>
          <div className="containerInputText">
            <input
              type="number"
              className="inputText"
              value={form.values.payoutMaxValue}
              onChange={(event) =>
                form.setFieldValue("payoutMaxValue", event.target.value)
              }
            />
          </div>
        </ModalContainer> */}
      </div>

      <div className="containerScrollTable">
        {filteredData.length > 0 &&
          filteredData
            ?.slice()
            .sort((a, b) => sortedArray(a, b))
            .map((row, index) => (
              <CardTable
                filteredData={row}
                isLoading={isLoading}
                getListServcies={getListServcies}
              />
            ))}

        {filteredData.length <= 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "30vh",
              width: "100%",
            }}
          >
            {isLoading ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress size={50} />
              </Box>
            ) : (
              <div
                className="titleFilter"
                style={{ textAlign: "center", width: "100%" }}
              >
                No matching items found.
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TablePriceEdit;
