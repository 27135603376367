import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";

import "./EditDateSchedule.css";
import { ReactSVG } from "react-svg";

import scheduleIcon from "../../Assets/Btn Edit (2).svg";
import Step1 from "./Step1";

import api from "../../Service/api";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const EditDateSchedule = ({ data, setLoadingButton }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleItens = async (values) => {

    setLoading(true);
    try {
      await api.put(`admins/schedules/${values.cleaningId}`, {
        data: {
          attributes: {
            date: moment(values.date).utc().format("YYYY-MM-DD"),
            time: values.time,
            nextCheckinDate: moment(values.nextCheckinDate).utc().format("YYYY-MM-DD"),
            nextCheckinTime: values.nextCheckinTime,
          },
        },
      });
      alert("Schedule update successfully!");
      setLoading(false);
      handleClose();
      setLoadingButton();
    } catch (error) {
      alert("Error update to schedule. Please try again.");
      setLoading(false);
      console.log(error);
    }
  };

  const formattedDate = (date) => {
    return `${moment(date).utc().format("YYYY-MM-DD")}`;
  };

  const form = useFormik({
    initialValues: {
      date: formattedDate(data.date) || "",
      time: "",
      nextCheckinDate: formattedDate(data.nextCheckinDate) || "",
      nextCheckinTime: "",
      cleaningId: data?.id,
    },
    validationSchema: yup.object({
      date: yup.string().required('Date is required'),
      time: yup.string().required('Time is required'),
      nextCheckinDate: yup.string().required('Next check-in date is required'),
      nextCheckinTime: yup.string().required('Next check-in time is required'),
    }),
    onSubmit: (values) => handleItens(values),
  });

  // console.log(data);

  return (
    <div className="containerModalScree">
      <div
        className=""
        onClick={handleOpen}
        style={{ cursor: "pointer" }}
      >
        <ReactSVG src={scheduleIcon} />
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className="containerModalcomplete3">
          <div className="headerComplete">
            <div className="title">Edit date</div>
            {/* <div className="subtitle">
              Effortlessly add a schedule to your property.
            </div> */}
          </div>

          <Step1 form={form} />

          <div className="containerButtonModal">
            <button className="cancel" onClick={handleClose}>
              Cancel
            </button>
            <button className={"save"} onClick={form.submitForm}>
              {loading ? "Loading..." : "Save"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditDateSchedule;
