import React, { useState, useContext, useEffect } from "react";
import AppContext from "../../../../Context/AppContext";
import { ModalContainer } from "../../../../Components/ModalContainer";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useFormik } from "formik";
import * as yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import "./RegionalAdmin.css";
import api from "../../../../Service/api";

const statusPayments = ["João", "Maria", "José", "Matheus", "Esau"];

const renderCustomIcon = (props) => {
  return (
    <ExpandMoreIcon {...props} style={{ color: "#FAFAFA", fontSize: "40px" }} />
  );
};

const RegionalAdmin = () => {
  const { listRegions, regionId, isLoadingRegionId, setIsLoadingId } =
    useContext(AppContext);
  const [user, setUser] = useState("");
  const [region, setRegion] = useState("");
  const [loading, setLoading] = useState(true);
  const [listAdmins, setListAdmins] = useState([]);
  const [listUses, setListUsers] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/admins/admin_controll/");

        
      const newData = await data?.map((value) => ({
        id: value.id,
        type: value.type,
        ...value.attributes,
      }));

        setListUsers(newData);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setListUsers(false);
      }
    };

    if (loading) {
      getUsers();
    }
  }, [loading]);

  useEffect(() => {
    const getCity = async () => {
      setListAdmins([]);
      try {
        setLoading(true);
        const data = await api.get(`/admins/regions/${regionId?.id}/admins`);
        setListAdmins(data);
        setLoading(false);
        setIsLoadingId(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        setIsLoadingId(false);
      }
    };

    if (loading || isLoadingRegionId) {
      getCity();
    }
  }, [isLoadingRegionId, loading, regionId.id, setIsLoadingId]);

  const handleChange = (event) => {
    setUser(event.target.value);
  };

  const handleChangeRegion = (event) => {
    setRegion(event.target.value);
  };

  const addAdminRegion = async (values) => {
    try {
      await api.post(`admins/regions/${region.id}/addAdmin`, {
        data: { attributes: { adminId: Number(user.id) } },
      });
      alert("Admin has successfully added the region.");
      setLoading(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className="containerCardRegions" style={{ flex: 1, height: "100%" }}>
      <div className="header">
        <div className="title">Regional Admin</div>
      </div>
      {listAdmins.length > 0 ? (
        <div
          className="containerAllCardCities"
          style={{ flexDirection: "column" }}
        >
          {listAdmins?.map((region, idx) => (
            <div
              key={idx}
              className={`card selected`}
              style={{ cursor: "auto" }}
            >
              {`${region.first_name} ${region.last_name}`}
            </div>
          ))}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "20vh",
            width: "100%",
          }}
        >
          {loading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress size={50} />
            </Box>
          ) : (
            <div className="titleFilter">No matching items found.</div>
          )}
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
        }}
      >
        <ModalContainer
          button="+ New Admin"
          type={true}
          title="New Regional Admin"
          onClickSave={addAdminRegion}
        >
          <div className="titleINputModal">Admin</div>
          <div className="containerSelectAdmin">
            <div className="selectedCONtainer">
              <div>{user && `${user.firstName} ${user.lastName}`}</div>
            </div>
            <Select
              className="containerIcon"
              onChange={handleChange}
              style={{ height: "50px" }}
              displayEmpty
              renderValue={() => null}
              IconComponent={renderCustomIcon}
            >
              {listUses?.map((item, idx) => (
                <MenuItem value={item} key={idx}>
                  {`${item.firstName} ${item.lastName}`}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className="titleINputModal" style={{ marginTop: "15px" }}>
            Region
          </div>
          <div className="containerSelectAdmin">
            <div className="selectedCONtainer">
              <div>{region.name}</div>
            </div>
            <Select
              className="containerIcon"
              onChange={handleChangeRegion}
              style={{ height: "50px" }}
              displayEmpty
              renderValue={() => null}
              IconComponent={renderCustomIcon}
            >
              {listRegions?.map((item, idx) => (
                <MenuItem value={item} key={idx}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </ModalContainer>
      </div>
    </div>
  );
};

export default RegionalAdmin;
