import React, { useState } from "react";
import {
  makeStyles,
  Card,
  CardMedia,
  CardActions,
  IconButton,
} from "@material-ui/core";
import { ReactSVG } from "react-svg";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import iconPhoto from "../../../../Assets/tabler_photo-plus.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    overflowX: "auto",
  },
  title: {
    fontSize: 20,
    textAlign: "center",
    margin: theme.spacing(2),
  },
  card: {
    minWidth: 200,
    margin: theme.spacing(2),
    position: "relative",
  },
  media: {
    height: 200,
  },
  actions: {
    position: "absolute",
    top: "-10px",
    right: "-20px",
  },
  scrollbarContainer: {
    overflowX: "auto",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      height: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "blue",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "darkblue",
    },
  },
}));

const PhotoCardCarousel = ({ form, objectPhotos }) => {
  const classes = useStyles();

  // const handlePhotoSelection = async (event) => {
  //   const files = event.target.files;
  //   if (files) {
  //     const newPhotos = await Promise.all(
  //       Array.from(files).map(async (file) => {
  //         const base64 = await convertToBase64(file);
  //         return { base64, file };
  //       })
  //     );
  //     form.setFieldValue(`${objectPhotos}`, [
  //       ...form.values[objectPhotos],
  //       ...newPhotos,
  //     ]);
  //   }
  // };

  const handlePhotoSelection = async (event) => {
    const files = event.target.files;

    if (files) {
      const newPhotos = await Promise.all(
        Array.from(files).map(async (file) => {
          const lowResolutionFile = await reduceImageResolution(file);
          const base64 = await convertToBase64(lowResolutionFile);
          return { base64, file: lowResolutionFile };
        })
      );

      form.setFieldValue(`${objectPhotos}`, [
        ...form.values[objectPhotos],
        ...newPhotos,
      ]);
    }
  };

  // Função para reduzir a resolução da imagem
  const reduceImageResolution = (file) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Define a nova largura e altura desejadas
        const maxWidth = 700;
        const maxHeight = 500;

        let width = img.width;
        let height = img.height;

        // Redimensiona a imagem mantendo a proporção
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;

        // Desenha a imagem no canvas com as novas dimensões
        ctx.drawImage(img, 0, 0, width, height);

        // Converte o canvas de volta para um novo objeto Blob com a menor resolução
        canvas.toBlob(
          function (blob) {
            // Resolução reduzida
            const lowResolutionFile = new File([blob], file.name, {
              type: "image/jpeg", // Tipo de imagem
              lastModified: Date.now(), // Última modificação (opcional)
            });

            resolve(lowResolutionFile);
          },
          "image/jpeg",
          0.8
        ); // Qualidade de compressão JPEG (0.8 é um valor de exemplo)
      };
      img.src = URL.createObjectURL(file);
    });
  };

  const handlePhotoDelete = (index) => {
    const updatedformPhotos = [...form.values[objectPhotos]];
    updatedformPhotos.splice(index, 1);
    form.setFieldValue(`${objectPhotos}`, updatedformPhotos);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <div style={{ flex: 1 }}>
      <div className="containerBuntoSelectPhoto">
        <label htmlFor="photo-upload">
          <div onChange={handlePhotoSelection} style={{ cursor: "pointer" }}>
            <ReactSVG src={iconPhoto} />
          </div>
        </label>
        <input
          accept="image/*"
          id="photo-upload"
          type="file"
          onChange={handlePhotoSelection}
          style={{ display: "none" }}
          multiple
        />
      </div>
      <div className={classes.container}>
        <div style={{ display: "flex" }}>
          {form.values[objectPhotos].map((photo, index) => (
            <Card key={index} className={classes.card}>
              <div className={classes.scrollbarContainer}>
                <CardMedia
                  className={classes.media}
                  image={photo.base64}
                  title={`Photo ${index + 1}`}
                />
                <CardActions className={classes.actions}>
                  <IconButton
                    aria-label="Add to favorites"
                    onClick={() => handlePhotoDelete(index)}
                  >
                    <DeleteForeverIcon
                      style={{ fontSize: "40px", color: "red" }}
                    />
                  </IconButton>
                </CardActions>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PhotoCardCarousel;
