import React, { useContext, useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import moment from "moment";
import { AuthorizedLayout } from "../../Components/AuthorizedLayout";
import { ModalContainer } from "../../Components/ModalContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import Rating from "@mui/material/Rating";
import AppContext from "../../Context/AppContext";

import CardListCIty from "./Components/CardListCIty";
import CalendarCard from "./Components/CalendarCard";
import ModalDocuments from "./Components/ModalDocuments";
import AddDocuments from "./Components/AddDocuments";
import Chat from "./Components/Chat";
import EditModal from "./Components/EditModal";

import DetailsScheduleModal from "../../Components/DetailsScheduleModal";
import DetailsCleaningModal from "../../Components/DetailsCleaningModal";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Input } from "../../Components/Input";
import { InputsContainer } from "../../Components/InputsContainer";
import { useFormik } from "formik";
import * as yup from "yup";

import StikeIcon from "../../Assets/Strike Icon.svg";
import CleaningIcon from "../../Assets/Cleaning Icon2.svg";
import MedalIcon from "../../Assets/Vector.svg";
import bedroomsIcon from "../../Assets/bedroomsAmount.svg";
import bathroomsIcon from "../../Assets/bathrooms.svg";
import voltarIcon from "../../Assets/Union (4).svg";
import iconOffDay from "../../Assets/Day Off Icon.svg";

import "./DetailsCleaner.css";
import api from "../../Service/api";
import ModalIssuers from "./Components/ModalIssuers";

const daysOfTheWeek = ["S", "M", "T", "W", "T", "F", "S"];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const DetailsCleaner = () => {
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { item, listCities, listRegions } = location.state;
  const [selectedSchedule, setSelectedSchedule] = useState("Cleanings");
  const [loading, setLoading] = useState(false);
  const [detailCleaner, setDetailCLeaner] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadignCOntracts, setLoadingContracts] = useState(false);
  const [issuers, setIssuers] = useState({});

  const getDetailUser = async (type) => {
    try {
      setLoading(true);
      const { data } = await api.get(`/admins/cleaners/${id}`);

      setDetailCLeaner({ id: data.id, type: data.type, ...data.attributes });
      setLoading(false);
      setLoadingButton(false);
      setLoadingContracts(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getIssuers = async () => {
    try {
      const data = await api.get(`/admins/cleaners_reports/${id}`);
      if (data) {
        setIssuers(data.weeks.filter((item) => item.csv_link));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDetailUser();
    getIssuers();
  }, [id, setDetailCLeaner, loadingButton, loadignCOntracts]);

  function converterHorario(horarioOriginal) {
    const horarioConvertido = moment(horarioOriginal)
      .subtract(moment().utcOffset(), "minutes")
      .format("h:mm A");
    return horarioConvertido;
  }

  // const allCityIds = listCleaners
  //   ?.map((cleaner) => cleaner.cities.map((city) => city.id))
  //   .filter((ids) => ids.length !== 0)
  //   .flat();

  // console.log(api.get("/admins/cleaners_reports/week_with_cleanings"));
  // console.log(api.get(`/admins/cleaners_reports`));
  // console.log(api.get(`/admins/cleaners_reports/${id}`));
  // console.log(api.get("/reports/general?from=&to="));

  const citiesList = detailCleaner?.cities?.map((item) => item.name);
  const regionsListIds = detailCleaner?.regions?.map((item) => item.id);
  const filteredRegion = listRegions
    ?.filter((item) => regionsListIds?.includes(Number(item.id)))
    .map((regi) => regi.name);

  const formattedDate = (date) => {
    return moment(date).utc().format("MMM. DD, YYYY | hh:mm A");
  };

  function getDatesBetween(initialDate, endDate) {
    const dates = [];
    let currentDate = new Date(initialDate);
    while (currentDate <= new Date(endDate)) {
      dates.push(currentDate.toISOString().slice(0, 10));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  const timeOfssDate = detailCleaner?.timeOffs?.reduce((dates, timeOff) => {
    const { initialDate, endDate } = timeOff;
    const datesBetween = getDatesBetween(initialDate, endDate);
    return [...dates, ...datesBetween].filter((date, index, array) => {
      return array.indexOf(date) === index;
    });
  }, []);

  const dateSchedules = detailCleaner?.schedules
    ?.map((item) => moment(item.date).utc().format("YYYY-MM-DD"))
    .filter((date, index, array) => {
      return array.indexOf(date) === index;
    });

  // console.log(detailCleaner);

  const tgoBack = () => {
    setLoading(true);
    navigate(-1);
  };

  const now = moment().startOf("day");

  const filteredSchedules = detailCleaner?.schedules
    ?.filter((schedule) =>
      moment(schedule.date).utc().startOf("day").isSameOrAfter(now)
    )
    .sort((a, b) => moment(a.date).utc().diff(moment(b.date)));

  const schedulesCards =
    selectedSchedule === "Cleanings"
      ? filteredSchedules
      : detailCleaner?.schedules.sort((a, b) =>
          moment(b.date).utc().diff(moment(a.date))
        );
  // console.log(schedulesCards);

  const handleNote = async (values) => {
    setLoadingButton(true);
    try {
      await api.post(`admins/cleaners/${detailCleaner?.id}/create_note`, {
        data: { attributes: { note: values.note } },
      });
      noteForm.setFieldValue("note", "");
      handleClose();
      setLoading(true);
      setLoadingButton(false);
    } catch (error) {
      console.log(error);
      setLoadingButton(false);
    }
  };

  const noteForm = useFormik({
    initialValues: {
      note: "",
    },
    validationSchema: yup.object({
      note: yup
        .string()
        .min(2, "Description must have at least 5 characters.")
        .required("Description is required!"),
    }),
    onSubmit: (values) => handleNote(values),
  });

  const aproveContract = async () => {
    try {
      setLoading(true);
      await api.patch(`/admins/cleaners/${id}/accept_cleaner`);
      setLoading(false);
      setLoadingContracts(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const declineContract = async () => {
    try {
      setLoading(true);
      await api.patch(`/admins/cleaners/${id}/decline_cleaner`);
      setLoading(false);
      setLoadingContracts(true);
      navigate(-1);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const endContract = async () => {
    try {
      setLoading(true);
      await api.patch(`/admins/cleaners/${id}/end_contract`);
      setLoading(false);
      setLoadingContracts(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const reactivateContract = async () => {
    try {
      setLoading(true);
      await api.patch(`/admins/cleaners/${id}/reactivate_contract`);
      setLoading(false);
      setLoadingContracts(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const addStrike = async (values) => {
    try {
      await api.patch(`/admins/cleaners/${id}/strike`, {
        data: { attributes: { ...values } },
      });
      alert("Strike has been successfully added.");
      setLoadingButton(true);
    } catch (error) {
      console.log(error);
      alert("Error: Failed to add the strike.");
    }
  };

  const form = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema: yup.object({
      reason: yup
        .string()
        .min(2, "City must have at least 2 characters.")
        .max(50, "City must have at most 50 characters.")
        .required("City is required!"),
    }),
    onSubmit: (values) => addStrike(values),
  });

  // console.log(detailCleaner);

  return (
    <AuthorizedLayout>
      {/* <Chat /> */}
      <div className="containerDetailCleaner non-printable-element">
        <div className="containerButtonTopHeader">
          <button className="buttonbACK" onClick={() => tgoBack()}>
            {" "}
            <ReactSVG src={voltarIcon} /> Back to cleaners
          </button>
          {/* <button className="buttonSend">Send Message</button> */}
        </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "80vh",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <CircularProgress size={50} />
            </Box>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flex: 1,
              gap: "20px",
              flexDirection: "column",
            }}
          >
            <div>
              <div
                style={{ display: "flex", flex: 1, gap: "20px" }}
                className="flexDirecitonCOllum"
              >
                <div
                  style={{
                    flex: 5,
                    gap: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="conatainerHeader propertiesItensCOntainer">
                    <div className="containerUserHeader">
                      {detailCleaner?.avatar === null ? (
                        <div
                          className="userPhoto"
                          style={{
                            width: "64px",
                            height: "64px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "30px",
                            color: "#e5ebf2",
                          }}
                        >
                          ?
                        </div>
                      ) : (
                        <img
                          src={detailCleaner?.avatar}
                          alt="user"
                          className="userPhoto"
                          style={{
                            width: "64px",
                            height: "64px",
                            objectFit: "cover",
                          }}
                        />
                      )}
                      <div>
                        <div className="title">{`${detailCleaner?.firstName} ${detailCleaner?.lastName}`}</div>
                        <div>
                          <Stack spacing={1}>
                            <Rating
                              name="half-rating-read"
                              defaultValue={detailCleaner?.ratting}
                              precision={0.5}
                              readOnly
                              sx={{ color: "#0E1F41" }}
                              size="small"
                            />
                          </Stack>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        gap: "5px",
                        flexDirection: "column",
                        justifyContent: "center",
                        // alignItems: 'center',
                      }}
                      className="propertiesItens"
                    >
                      <div className="containerIcon">
                        <ReactSVG src={MedalIcon} /> Level{" "}
                        {detailCleaner?.level}
                      </div>
                      <div className="containerIcon">
                        <ReactSVG src={StikeIcon} /> {detailCleaner?.strikes}{" "}
                        Strikes
                      </div>
                      <div className="containerIcon">
                        <ReactSVG src={CleaningIcon} />{" "}
                        {detailCleaner?.totalCleanings} Cleanings
                      </div>
                    </div>

                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                      className="cntainerBUttonDetailsCLients"
                    >
                      {detailCleaner?.finishedContractAt === null &&
                        detailCleaner?.approvedAt !== null && (
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <ModalContainer
                              customButton={
                                <button
                                  className="button buttonModalMObile"
                                  style={{ backgroundColor: "#0E1F41" }}
                                >
                                  Strike Cleaner
                                </button>
                              }
                              type={true}
                              title="Add Strike"
                              onClickSave={form.submitForm}
                            >
                              <div className="titleINputModal">Reason:</div>
                              <div className="containerInputText">
                                <input
                                  type="text"
                                  className="inputText"
                                  value={form.values.reason}
                                  onChange={(event) =>
                                    form.setFieldValue(
                                      "reason",
                                      event.target.value
                                    )
                                  }
                                />
                              </div>
                            </ModalContainer>

                            <button
                              className="button buttonModalMObile"
                              style={{ backgroundColor: "#E74242" }}
                              onClick={() => endContract()}
                            >
                              End Contract
                            </button>
                          </div>
                        )}

                      {detailCleaner?.finishedContractAt === null &&
                        detailCleaner?.approvedAt === null && (
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "10px",
                              flexDirection: "column",
                            }}
                          >
                            <button
                              className="button buttonModalMObile"
                              style={{ backgroundColor: "#0E1F41" }}
                              onClick={() => aproveContract()}
                            >
                              Aprove Contract
                            </button>

                            <ModalContainer
                              customButton={
                                <button
                                  className="button buttonModalMObile"
                                  style={{ backgroundColor: "#E74242" }}
                                >
                                  Decline Contract
                                </button>
                              }
                              type={true}
                              title="Decline Contract"
                              onClickSave={declineContract}
                            >
                              <div className="titleINputModal">
                                Are you certain about declining this cleaner?
                              </div>
                            </ModalContainer>
                          </div>
                        )}

                      {detailCleaner?.finishedContractAt !== null &&
                        detailCleaner?.approvedAt !== null && (
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "10px",
                              flexDirection: "column",
                            }}
                          >
                            <button
                              className="button buttonModalMObile"
                              style={{
                                backgroundColor: "#66CDAA",
                                width: "100%",
                              }}
                              onClick={() => reactivateContract()}
                            >
                              Reativate Contract
                            </button>
                          </div>
                        )}

                      {user.adminType === "master" && (
                        <EditModal
                          data={detailCleaner}
                          setLoadingButton={setLoadingButton}
                        />
                      )}
                    </div>
                  </div>
                  <div className="containerInfo flexDirecitonCOllum">
                    <div className="containerLeft">
                      <div className="card" style={{ flex: 1 }}>
                        <div className="title">General Information</div>
                        <div className="subtile">
                          <strong>Email:</strong>
                          {` ${detailCleaner?.email}`}
                        </div>
                        <div className="subtile">
                          <strong>Phone:</strong>
                          {` ${detailCleaner?.phone}`}
                        </div>
                        {user.adminType === "master" && (
                          <div className="subtile">
                            <strong>SSN/ ITIN:</strong>
                            {` ${detailCleaner?.itinNumber}`}
                          </div>
                        )}
                        <div className="subtile">
                          <strong>Address:</strong>
                          {` ${detailCleaner?.fullAddress}` || ""}
                        </div>

                        {/* <div className="subtile" style={{ display: "flex", gap: "5px" }}>
               <strong>Allergies:</strong>{" "}
               {item?.allergieTypes.map((aler, idx) => (
                 <div key={idx}>{`${aler}${
                   item.allergieTypes.length - 1 === idx ? "." : ","
                 }`}</div>
               ))}
             </div> */}
                      </div>

                      {user.adminType === "master" && (
                        <div className="card" style={{ flex: 1.5 }}>
                          <div className="title">Payment Data</div>
                          <div className="subtile">
                            <strong>Type:</strong>
                            {` ${detailCleaner?.bankAccount?.account_type}`}
                          </div>
                          <div className="subtile">
                            <strong>Bank:</strong>
                            {` ${detailCleaner?.bankAccount?.bank}`}
                          </div>
                          <div className="subtile">
                            <strong>Acc Number:</strong>
                            {` ${detailCleaner?.bankAccount?.account_number}`}
                          </div>
                          <div className="subtile">
                            <strong>Name on Acc.:</strong>
                            {` ${detailCleaner?.bankAccount?.account_name}`}
                          </div>
                          <div className="subtile">
                            <strong>Routing code:</strong>
                            {` ${detailCleaner?.bankAccount?.routing_code}`}
                          </div>
                          <div className="subtile">
                            <strong>Acc Type:</strong>
                            {` ${detailCleaner?.bankAccount?.account_type}`}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="containerLeft" style={{ flex: 2 }}>
                      <div className="card" style={{ flex: 2 }}>
                        <div className="containerCard">
                          <CalendarCard
                            schedules={detailCleaner?.schedules}
                            timeOfssDate={timeOfssDate}
                            dateSchedules={dateSchedules}
                          />
                        </div>
                      </div>

                      {/* {user.adminType === "master" && (
                 <div style={{ flex: 1, display: "flex", gap: "20px" }}>
                   <div className="card" style={{ flex: 1 }}>
                     <div className="title">ID Photo</div>
                   </div>
                   <div className="card" style={{ flex: 1 }}>
                     <div className="title">Documents</div>
                   </div>
                 </div>
               )} */}

                      <div className="card" style={{ flex: 1 }}>
                        <div className="title">Cities</div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <div className="subtile">
                            <strong>Regions</strong>
                          </div>
                          <CardListCIty list={filteredRegion} />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div className="subtile">
                            <strong>Cities</strong>
                          </div>
                          <CardListCIty list={citiesList} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ flex: 2 }} className="containerInfo">
                  <div style={{ flex: 1 }} className="containerLeft">
                    <div className="card" style={{ flex: 3 }}>
                      <div className="titleSchedules">
                        <div
                          onClick={() => setSelectedSchedule("Cleanings")}
                          className={
                            selectedSchedule === "Cleanings"
                              ? "selectedSchedule"
                              : "noSelectedSchedule"
                          }
                        >
                          Cleanings
                        </div>
                        <div
                          onClick={() => setSelectedSchedule("History")}
                          className={
                            selectedSchedule === "History"
                              ? "selectedSchedule"
                              : "noSelectedSchedule"
                          }
                        >
                          History
                        </div>

                        <div
                          onClick={() => setSelectedSchedule("Day Offs")}
                          className={
                            selectedSchedule === "Day Offs"
                              ? "selectedSchedule"
                              : "noSelectedSchedule"
                          }
                        >
                          Day Offs
                        </div>
                      </div>
                      <div className="containerCard">
                        {selectedSchedule === "Day Offs" ? (
                          timeOfssDate.map((item, idx) => (
                            <div className="cardTime">
                              <div
                                className="subtile"
                                style={{
                                  display: "flex",
                                  gap: "15px",
                                  alignItems: "center",
                                }}
                              >
                                <ReactSVG src={iconOffDay} />{" "}
                                {moment(item).utc().format("MMM. DD YYYY")}
                              </div>
                            </div>
                          ))
                        ) : schedulesCards?.length > 0 ? (
                          schedulesCards
                            .slice()
                            .sort((a, b) => new Date(b.date) - new Date(a.date))
                            .map((item, idx) => (
                              <div className="cardInfos" key={idx}>
                                <div className="cardId">
                                  <div>{`#${item.id}`}</div>
                                  <div className="textStatusCleaning">{`${item.status}`}</div>
                                </div>
                                <div className="textDate">
                                  {formattedDate(item.date)}
                                </div>

                                <div style={{ display: "flex", gap: "15px" }}>
                                  <div className="textCardOver">
                                    {`$${Number(item.value).toFixed(2)}`}
                                  </div>
                                  <div
                                    className="textCardOver"
                                    style={{ display: "flex", gap: "5px" }}
                                  >
                                    <ReactSVG
                                      src={bathroomsIcon}
                                      style={{
                                        backgroundColor: "rgba(0, 0, 0, 0)",
                                      }}
                                    />
                                    {item.bathroomsAmount}
                                  </div>
                                  <div
                                    className="textCardOver"
                                    style={{ display: "flex", gap: "5px" }}
                                  >
                                    <ReactSVG
                                      src={bedroomsIcon}
                                      style={{
                                        backgroundColor: "rgba(0, 0, 0, 0)",
                                      }}
                                    />
                                    {item.bedroomsAmount}
                                  </div>
                                </div>

                                <div className="textCardOver">
                                  {item.address}
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="textCardCleaner">
                                    {detailCleaner?.avatar === null ? (
                                      <div
                                        className="userPhoto"
                                        style={{
                                          width: "48px",
                                          height: "48px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          fontSize: "30px",
                                          color: "#e5ebf2",
                                        }}
                                      >
                                        ?
                                      </div>
                                    ) : (
                                      <img
                                        src={detailCleaner?.avatar}
                                        alt="user"
                                        className="userPhoto"
                                        style={{
                                          width: "48px",
                                          height: "48px",
                                          objectFit: "cover",
                                        }}
                                      />
                                    )}
                                    {item.cleaner || "No Cleaner"}
                                  </div>
                                  <>
                                    {item.status === "done" ? (
                                      <DetailsCleaningModal
                                        item={item}
                                        // setLoading={setIsLOading}
                                        Principal={true}
                                      />
                                    ) : (
                                      <DetailsScheduleModal
                                        data={item}
                                        Put={getDetailUser}
                                        Principal={true}
                                      />
                                    )}
                                  </>
                                </div>
                              </div>
                            ))
                        ) : (
                          <div className="subtile">no cleaning</div>
                        )}
                      </div>
                    </div>

                    {user.adminType === "master" && (
                      <div className="card" style={{ flex: 1 }}>
                        <div className="title">Documents</div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            marginTop: "10px",
                            alignItems: "center",
                          }}
                        >
                          <AddDocuments
                            item={detailCleaner}
                            setLOading={setLoadingButton}
                          />
                          <ModalDocuments item={detailCleaner} />
                          <ModalIssuers
                            item={detailCleaner}
                            issuers={issuers}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="containerInfo">
              <div className="card" style={{ flex: 1 }}>
                <div className="title">Reviews</div>
                <div className="containerReviews">
                  {detailCleaner?.cleanerReviews?.length > 0 ? (
                    detailCleaner?.cleanerReviews?.map(
                      (item, idx) =>
                        item?.incident_description && (
                          <div key={idx} style={{ marginBottom: "10px" }}>
                            <div
                              className="subtile"
                              style={{ fontWeight: "bolder" }}
                            >
                              {`${item?.client?.first_name || ""} ${
                                item?.client?.last_name || ""
                              }`}
                            </div>
                            <div className="subtile">
                              {item?.incident_description
                                ? item?.incident_description
                                : "Without description"}
                            </div>
                          </div>
                        )
                    )
                  ) : (
                    <div className="subtile">No reviews yet.</div>
                  )}
                </div>
              </div>
            </div>

            <div className="containerInfo">
              <div className="card" style={{ flex: 1 }}>
                <div
                  className="title"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>Supervisor Notes</div>
                  <button className="buttonNOte" onClick={handleOpen}>
                    Add Note
                  </button>
                </div>
                <div className="containerReviews">
                  {detailCleaner?.supervisorNotes?.length > 0 ? (
                    detailCleaner?.supervisorNotes?.map((item, idx) => (
                      <div key={idx} style={{ marginBottom: "10px" }}>
                        <div
                          className="subtile"
                          style={{ fontWeight: "bolder" }}
                        >
                          {`${item?.admin?.first_name} ${item?.admin?.last_name}`}
                        </div>
                        <div className="subtile">
                          {item?.note ? item?.note : "Without note"}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="subtile">No notes yet.</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div style={style} className="containerModalAdminControl">
            <div className="titleModalAdmin">Add Note</div>
            <div className="lineAdminControl" />
            <InputsContainer>
              <Input
                placeholder="Description"
                field="note"
                form={noteForm}
                assistiveText={{ color: "#0E1F41" }}
              />
            </InputsContainer>
            <div className="containerButtonModalAdmin">
              <button className="cancel" onClick={handleClose}>
                Cancel
              </button>
              <button className="create" onClick={noteForm.submitForm}>
                {loadingButton ? "Loading" : "Create"}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </AuthorizedLayout>
  );
};

export default DetailsCleaner;
