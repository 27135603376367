import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import DetailsScheduleModal from "./DetailsScheduleModal";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { ModalContainer } from "./TableJobs/Modal";

export default function ControlledAccordions({
  value,
  key,
  updatePaymentAdjustment,
  newExtraPaymentForm,
  setValuespAYMNETS,
}) {
  const [expanded, setExpanded] = React.useState(false);

  // console.log(value);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function calculateTotalSum(data) {
    let totalSum = 0;
    for (const schedule of data.attributes.schedules) {
      const payout = parseFloat(schedule.payout) || 0;
      const adjustment = parseFloat(schedule.payment.payment_adjustment) || 0;
      totalSum += payout + adjustment;
    }
    return toDollars(totalSum);
  }

  function toDollars(value) {
    const number = parseFloat(value) || 0;

    const formattedValue = number.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });

    return formattedValue;
  }

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{ width: "100%" }}
      >
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon sx={{ color: "#fafafa" }} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{ backgroundColor: "#0e1f41", color: "#fafafa" }}
        >
          <div style={{ fontWeight: "700", width: "33%" }}>
            {value?.attributes?.fullName}
          </div>

          <div style={{ width: "33%", textAlign: "center" }}>
            {`Total jobs: ${value?.attributes?.schedules.length}`}
          </div>

          <div style={{ width: "33%", textAlign: "end" }}>
            {`Total value: ${calculateTotalSum(value)}`}
          </div>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "0px", marginTop: "10px" }}>
          <div key={key} className="cardJobs">
            <div key={key} className="headerTitle">
              <div>ID</div>
              <div>Property name</div>
              <div>Address</div>
              <div>Payout</div>
              <div>Date</div>
              <div>Payment adjustment</div>
              <div></div>
            </div>
            {value?.attributes?.schedules.map((item, key) => (
              <div
                key={key}
                className={`contentJobs ${key % 2 === 0 ? "color" : ""}`}
              >

                  <DetailsScheduleModal
                    item={{
                      cleaningId: item.id,
                      serviceValue: item.payout,
                      paymentStatus: item.paymentStatus,
                    }}
                    button={
                      <div
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          margin : '0px',
                          paddingTop: '15px',
                          fontWeight: '700'
                        }}
                      >{`#${item.id}`}</div>
                    }
                    entries={"entries"}
                  />
  
                <div>{item.property_name}</div>
                <div>{item.address}</div>
                <div>{toDollars(item.payout || 0)}</div>
                <div>{item.cleaningDate}</div>
                <div>{toDollars(item.payment.payment_adjustment || 0)}</div>

                <ModalContainer
                  onClickSave={() =>
                    updatePaymentAdjustment(
                      newExtraPaymentForm.values.scheduleId,
                      newExtraPaymentForm.values.service_value,
                      key
                    )
                  }
                  title="Adjustment Payment"
                >
                  <div className="titleINputModal">value:</div>
                  <div
                    className="containerInputText"
                    style={{ marginBottom: "10px" }}
                  >
                    <input
                      type="number"
                      className="inputText"
                      value={newExtraPaymentForm.values.service_value}
                      onChange={(event) => setValuespAYMNETS(event, item)}
                    />
                  </div>
                </ModalContainer>
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
