import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import { UseZipCodeAPI } from "../../../../../Service/api";

import iconBath from "../../../../../Assets/Bath Icon1 1.svg";
import iconBad from "../../../../../Assets/Bed1 1.svg";
import iconMenos from "../../../../../Assets/Frame 1844.svg";
import iconPLus from "../../../../../Assets/Frame 1843.svg";

import "./Step1.css";

const Step1 = ({ form }) => {
  const [editable, setEditable] = useState(false);

  function addOrSubtractWithMin(type, forms) {
    if (type === "sub") {
      form.setFieldValue(`${forms}`, Math.max(0, form.values[forms] - 1));
    } else {
      form.setFieldValue(`${forms}`, Number(form.values[forms]) + 1);
    }
  }

  const verifyZipCode = async () => {
    if (form.values.zipCode.toString().length === 5) {
      const { city, state } = await UseZipCodeAPI(form.values.zipCode.toString());

      if (city && state) {
        form.setFieldValue("city", city);
        form.setFieldValue("state", state);
      } else {
        setEditable(true);
      }
    }
    setEditable(true);
  };

  return (
    <div className="containerStep1">
      <div className="conatinerSLider" style={{ marginTop: "20px" }}>
        <label className="switch2">
          <input
            type="checkbox"
            checked={form.values.laundry}
            onChange={() =>
              form.setFieldValue(`laundry`, !form.values.laundry)
            }
          />
          <span className="slider"></span>
        </label>
        <div className="textSlider">This cleaning needs landry</div>
      </div>

      <div className="containerRooms mobileROoms" style={{ marginTop: "10px" }}>
        <div className="bedBath itensMObi">
          <ReactSVG src={iconBad} />
          <div className="text">Bedrooms</div>
          <div
            onClick={() => addOrSubtractWithMin("sub", "bedroomsAmount")}
            style={{ cursor: "pointer" }}
          >
            <img src={iconMenos}   alt="remove" className="imgs"/>
          </div>
          <div className="text">{form.values.bedroomsAmount}</div>
          <div
            onClick={() => addOrSubtractWithMin("add", "bedroomsAmount")}
            style={{ cursor: "pointer" }}
          >
            <img src={iconPLus}  alt="add" className="imgs"/>
          </div>
        </div>

        <div className="bedBath itensMObi">
          <ReactSVG src={iconBad} />
          <div className="text">Bathrooms</div>
          <div
            onClick={() => addOrSubtractWithMin("sub", "bathroomsAmount")}
            style={{ cursor: "pointer" }}
          >
            <img src={iconMenos}  alt="remove" className="imgs"/>
          </div>
          <div className="text">{form.values.bathroomsAmount}</div>
          <div
            onClick={() => addOrSubtractWithMin("add", "bathroomsAmount")}
            style={{ cursor: "pointer" }}
          >
            <img src={iconPLus} alt="add" className="imgs"/>
          </div>
        </div>
      </div>

      <div style={{ marginTop: "10px" }}>
        <div className="titleINputModalStep">Address nickname</div>
        <div className="containerInputText">
          <input
            type="text"
            className="inputText"
            value={form.values.addressNickname}
            onChange={(event) => form.setFieldValue("addressNickname", event.target.value)}
          />
        </div>

        <div>
          <div className="cnotainerAddresStep">
            <div>
              <div className="titleINputModalStep">Address*</div>
              <div className="containerInputText">
                <input
                  type="text"
                  className="inputText"
                  value={form.values.address}
                  onChange={(event) =>
                    form.setFieldValue("address", event.target.value)
                  }
                />
              </div>
            </div>

            <div>
              <div className="titleINputModalStep">Zip code*</div>
              <div className="containerInputText">
                <input
                  type="number"
                  className="inputText"
                  value={form.values.zipCode}
                  onChange={(event) =>
                    form.setFieldValue("zipCode", event.target.value)
                  }
                  onBlur={verifyZipCode}
                />
              </div>
            </div>
          </div>

          <div className="cnotainerAddresStep">
            <div>
              <div className="titleINputModalStep">State</div>
              <div className="containerInputText">
                <input
                  type="text"
                  className="inputText"
                  value={form.values.state}
                  onChange={(event) =>
                    form.setFieldValue("state", event.target.value)
                  }
                  disabled={editable}
                />
              </div>
            </div>

            <div>
              <div className="titleINputModalStep">City</div>
              <div className="containerInputText">
                <input
                  type="text"
                  className="inputText"
                  value={form.values.city}
                  onChange={(event) =>
                    form.setFieldValue("city", event.target.value)
                  }
                  disabled={editable}
                />
              </div>
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <div className="titleINputModalStep">
              Default cleaning start time
            </div>
            <div className="containerInputText">
              <input
                type="time"
                className="inputText"
                value={form.values.name}
                onChange={(event) =>
                  form.setFieldValue("name", event.target.value)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
