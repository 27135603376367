import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import editIcon from "../../../../Assets/edit 2 (1).svg";

import "./Table.css";
import api from "../../../../Service/api";
import AppContext from "../../../../Context/AppContext";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  blueRow: {
    backgroundColor: "#D5E7F4",
  },
  borderHeader: {
    borderBottom: "2px solid #0E1F41",
    borderRight: "2px solid #0E1F41",
  },
  borderContent: {
    borderBottom: "1px solid #0E1F41",
    borderRight: "2px solid #0E1F41",
  },
}));

const parseData = (date) => {
  return moment(date).utc().add(-5, "hours");
};

const parseDataAirBnb = (date) => {
  return moment(date).utc().add(-4, "hours");
};

const formattedDateTime = (date) => {
  return moment(date).isValid()
    ? parseData(date).format("MMM. DD, YYYY | hh:mm A")
    : "Invalid Date";
};

const formattedDateTimeAirBnb = (date) => {
  return moment(date).isValid()
    ? parseDataAirBnb(date).format("MMM. DD, YYYY | hh:mm A")
    : "Invalid Date";
};

const formattedDate = (date) => {
  return moment(date).isValid()
    ? parseData(date).format("MMM. DD, YYYY")
    : "Invalid Date";
};

const formattedTime = (date) => {
  return moment(date).isValid()
    ? parseData(date).format("hh:mm A")
    : "Invalid Date";
};

const capitalize = (str) => {
  if (typeof str !== "string") {
    return "";
  }
  return str.charAt(0).toUpperCase() + str.substr(1);
};

const handleTableItem = (label, value) => {
  if (value) {
    switch (label) {
      case "airbnb_check_in_at":
        return formattedDateTimeAirBnb(value);
      case "airbnb_check_out_at":
        return formattedDateTimeAirBnb(value);
      case "date":
        return formattedDate(value);
      case "time":
        return formattedTime(value);
      case "next_checkin_date":
        return formattedDate(value);
      case "next_checkin_time":
        return formattedTime(value);
      case "active_sync":
        return String(value);
      default:
        return value;
    }
  } else {
    return "none";
  }
};

const handleLabel = (label) => {
  switch (label) {
    case "date":
      return "checkout_date";
    case "time":
      return "checkout_time";
    default:
      return label;
  }
};

const TablePriceEdit = ({ data, id }) => {
  let navigate = useNavigate();
  const classes = useStyles();

  console.log(data);

  return (
    <div className="containerScrollTableLog">
      <TableContainer component={Paper} className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.blueRow}>
              <TableCell className={`${classes.borderHeader} titleTable`}>
                Responsible
              </TableCell>
              <TableCell className={`${classes.borderHeader} titleTable`}>
                Event
              </TableCell>
              <TableCell className={`${classes.borderHeader} titleTable`}>
                Date/Time
              </TableCell>
              <TableCell className={`${classes.borderHeader} titleTable`}>
                Changes
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={`${classes.borderContent} subtitleTable`}>
                  <div className="textTable">
                    {row.changed_by_user_id || "x"}
                  </div>
                </TableCell>
                <TableCell className={`${classes.borderContent} subtitleTable`}>
                  <div className="textTable">
                    {capitalize(row?.operation) || "x"}
                  </div>
                </TableCell>
                <TableCell className={`${classes.borderContent} subtitleTable`}>
                  <div className="textTable">
                    {formattedDateTime(row.changes_made.updated_at.after) ||
                      "x"}
                  </div>
                </TableCell>
                <TableCell className={`${classes.borderContent} subtitleTable`}>
                  {row.changes_made && Object.keys(row.changes_made).length > 0
                    ? Object.keys(row.changes_made)
                        .filter(
                          (item) =>
                            item !== "created_at" && item !== "updated_at"
                        )
                        .map((item, index) => {
                          return (
                            <div
                              className="containerTextTable"
                              style={{ marginTop: "5px" }}
                            >
                              <div className="text title">
                                {handleLabel(item)}:
                              </div>
                              <div className="text before">
                                {` ${
                                  handleTableItem(
                                    item,
                                    row.changes_made[item].before
                                  ) || "none"
                                } `}
                              </div>
                              <div className="text">{`to `}</div>
                              <div className="text after">
                                {handleTableItem(
                                  item,
                                  row.changes_made[item].after
                                )}
                              </div>
                            </div>
                          );
                        })
                    : "x"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TablePriceEdit;
