import React, { useState, useEffect } from "react";
import { AuthorizedLayout } from "../../Components/AuthorizedLayout";
import api from "../../Service/api";

import CardsEntrieAndExits from "./Components/CardsEntrieAndExits";
import Sumary from "./Components/Sumary";

import "./Payments.css";
import moment from "moment";
import IndexIssuer from "./Components/IndexIssuer";

const initialFilterState = {
  fromDate: "",
  toDate: "",
  cleanerName: "",
  hostName: "",
  status: {
    pending: false,
    succeeded: false,
    failed: false,
    refunded: false,
    paid: false,
    waiting_charge: false,
    waiting_capture: false,
    pending_invoice: false,
    deepCleaning: false,
    penalty: false,
    partially: false,
  },
  region: "",
  city: "",
};

const statusEntrie = [
  { name: "pending", code: 0 },
  { name: "succeeded", code: 1 },
  { name: "failed", code: 2 },
  { name: "refunded", code: 3 },
  { name: "paid", code: 4 },
  { name: "waiting_charge", code: 5 },
  { name: "waiting_capture", code: 6 },
  { name: "pending_invoice", code: 7 },
];

const statusExits = [
  { name: "pending", code: 0 },
  { name: "paid", code: 1 },
  { name: "penalty", code: 2 },
  { name: "partially", code: 3 },
];

const Payments = () => {
  const [selected, setSelected] = useState("entries");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searched, setSearched] = useState(false);
  const [filter, setFilter] = useState(initialFilterState);
  const [isLoading, setIsLOading] = useState(true);
  const [statusSelected, setStatusSelected] = useState("");
  const [selectedCards, setSelectedCards] = useState([]);

  const statusPage = selected === "entries" ? statusEntrie : statusExits;

  const fetchData = async (status, page) => {
    setData([]);
    setIsLOading(true);

    try {
      const endpoint = `/admins/payments/${selected}/`;
      const params = {
        page: page || 1,
        per_page: 10,
        cleaner_name: filter.cleanerName,
        host_name: filter.hostName,
        region: filter.region,
        from: filter.fromDate
          ? moment(filter.fromDate).utc().format("DD/MM/YYYY")
          : "",
        to: filter.toDate
          ? moment(filter.toDate).utc().format("DD/MM/YYYY")
          : "",
        deep_cleaning: filter.deepCleaning,
      };

      if (selected !== "entries") {
        params["payout"] = Object.keys(filter.status)
          .filter((statusName) => filter.status[statusName])
          .map(
            (statusName) =>
              statusPage.find((item) => item.name === statusName).code
          );
      } else {
        params["status"] = Object.keys(filter.status)
          .filter((statusName) => filter.status[statusName])
          .map(
            (statusName) =>
              statusPage.find((item) => item.name === statusName).code
          );
      }

      const response = await api.get(endpoint, { params });

      setTotal(response.total_pages);
      const newData = await response?.payments?.data?.map((value) => ({
        id: value.id,
        type: value.type,
        ...value.attributes,
      }));
      setData(newData);
      setLoading(false);
      setIsLOading(false);
    } catch (erro) {
      setData([]);
      console.log(erro);
      setIsLOading(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [loading]);

  const handleSearch = () => {
    fetchData();
    setSearched(true);
  };

  const fetchDataReset = async (status) => {
    setData([]);
    setIsLOading(true);
    try {
      const response = await api.get(
        `/admins/payments/${status || selected}/`,
        {
          params: {
            page: 1,
            per_page: 10,
            cleaner_name: "",
            host_name: "",
            region: "",
            from: "",
            to: "",
            status: "",
            deep_cleaning: "",
          },
        }
      );
      setTotal(response.total_pages);
      const newData = await response?.payments?.data?.map((value) => ({
        id: value.id,
        type: value.type,
        ...value.attributes,
      }));
      setData(newData);
      setLoading(false);
    } catch (error) {
      console.error("Error:");
      setLoading(false);
      setIsLOading(false);
    }
  };

  const setStatusFetch = (status) => {
    setSelected(status);
    fetchDataReset(status);
    setFilter(initialFilterState);
    setStatusSelected("");
    setSelectedCards([]);
    setSearched(false);
  };

  const handleResetFilter = () => {
    setFilter(initialFilterState);
    fetchDataReset();
    setSearched(false);
  };

  const setStatus = async (code, id) => {
    try {
      await api.patch(`/admins/payments/${id}`, {
        data: { attributes: { status: code } },
      });
      setLoading(true);
      // alert("Payment status has been successfully changed.");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleChange = (event, value) => {
    setCurrentPage(value);
    fetchData(selected, value);
  };

  const selectScrenn = (name) => {
    switch (name) {
      case "entries":
        return (
          <CardsEntrieAndExits
            data={data}
            entries={true}
            setStatus={setStatus}
            setLoading={setLoading}
            filter={filter}
            setFilter={setFilter}
            handleSearch={handleSearch}
            searched={searched}
            handleResetFilter={handleResetFilter}
            total={total}
            handleChange={handleChange}
            currentPage={currentPage}
            isLoading={isLoading}
            statusSelected={statusSelected}
            selected={selected}
            setStatusSelected={setStatusSelected}
            setSelectedCards={setSelectedCards}
            selectedCards={selectedCards}
          />
        );
      case "exits":
        return (
          <CardsEntrieAndExits
            data={data}
            selected={selected}
            entries={false}
            setStatus={setStatus}
            setLoading={setLoading}
            filter={filter}
            setFilter={setFilter}
            handleSearch={handleSearch}
            searched={searched}
            handleResetFilter={handleResetFilter}
            total={total}
            handleChange={handleChange}
            currentPage={currentPage}
            isLoading={isLoading}
            statusSelected={statusSelected}
            setStatusSelected={setStatusSelected}
            setSelectedCards={setSelectedCards}
            selectedCards={selectedCards}
          />
        );
      case "Issuers":
        return <IndexIssuer />;
      default:
        return <Sumary />;
    }
  };

  return (
    <AuthorizedLayout>
      <div className="containerPayments">
        <div className="containerButtons">
          <button
            onClick={() => setStatusFetch("entries")}
            className={
              selected === "entries" ? "selectedStyle" : "notSelectedStyle"
            }
          >
            Entries
          </button>
          <button
            onClick={() => setStatusFetch("exits")}
            className={
              selected === "exits" ? "selectedStyle" : "notSelectedStyle"
            }
          >
            Exits
          </button>
          <button
            onClick={() => setSelected("Issuers")}
            className={
              selected === "Issuers" ? "selectedStyle" : "notSelectedStyle"
            }
          >
            Issuers
          </button>
          <button
            onClick={() => setSelected("Summary")}
            className={
              selected === "Summary" ? "selectedStyle" : "notSelectedStyle"
            }
          >
            Summary
          </button>
        </div>
        {selectScrenn(selected)}
      </div>
    </AuthorizedLayout>
  );
};

export default Payments;
