import React, { useState, useContext, useEffect } from "react";
import moment from "moment";
import Modal from "@mui/material/Modal";
import AppContext from "../../Context/AppContext";
import { ModalContainer } from "../ModalContainer";
import api from "../../Service/api";
import CompleteCleaning from "../../Pages/Overview/CompleteCleaning";
import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import EditDateSchedule from "../EditDateSchedule";
import cleaners from "../../Assets/cleaners.svg";
import Check from "../../Assets/check.svg";
import clock from "../../Assets/clockDark.svg";
import clients from "../../Assets/Clients Icon.svg";
import Autocomplete from "../Autocomplete";

import "./DetailsScheduleCalendar.css";
import { ReactSVG } from "react-svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const DetailsScheduleCalendar = ({
  data,
  updateItens,
  isLoading,
  setIsLOading,
  Principal,
  Put,
  CalendarType,
}) => {
  let navigate = useNavigate();

  // console.log(data);

  const { listCleaners, listCities, listRegions } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [nameCleaner, setNameCleaner] = useState([]);
  const [nameValue, setNameValue] = useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loadingInvite, setLoadingInvite] = useState(false);
  const [loadingComplete, setLoadingComplete] = useState(false);

  const newListCleaner = listCleaners
    ?.map((value) => ({
      id: value.id,
      name: `${value.attributes.firstName} ${value.attributes.lastName}`,
    }))
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  const selectedId = newListCleaner?.find(
    (value) => value.name === nameCleaner
  );

  useEffect(() => {
    const newListCleaner2 = listCleaners?.map((value) => ({
      id: value.id,
      name: `${value.attributes.firstName} ${value.attributes.lastName}`,
    }));

    setNameCleaner(data?.cleaner);
    setNameValue(
      newListCleaner2?.find((value) => value.name === data?.cleaner)
    );
  }, [data, listCleaners]);

  const inviteCleaner = async () => {
    setLoadingInvite(true);
    try {
      await api.patch(`/admins/overview/${data.id}/invite_cleaner`, {
        data: {
          attributes: {
            cleanerId: Number(selectedId.id),
          },
        },
      });
      setLoadingInvite(false);
      alert("Cleaner has been successfully added.");
      handleClose();
      Put();
    } catch (error) {
      console.log(error);
      setLoadingInvite(false);
      alert("Error: Cleaner was not added.");
    }
  };

  const cancelCleaning = async () => {
    setLoadingCancel(true);
    try {
      await api.patch(`admins/overview/${data.id}/cancel_schedule`);
      setOpen(false);
      setLoadingCancel(false);
      alert("The schedule has been canceled.");
      updateItens();
    } catch (error) {
      console.log(error);
      setLoadingCancel(false);
      alert("Error: Failed to cancel the schedule.");
    }
  };

  const completeCleaning = async () => {
    setLoadingComplete(true);
    try {
      await api.patch(`/admins/overview/${data.id}/finish_schedule`);
      setLoadingComplete(false);
      alert("The schedule has been completed.");
      setOpen(false);
      updateItens();
    } catch (error) {
      console.log(error);
      setLoadingComplete(false);
      alert("Error: Failed to complete the schedule.");
    }
  };

  const formattedDate = (date, time) => {
    return `${moment(date).utc().format("MMM. DD, YYYY")} - ${time}`;
  };

  function extrairHorarioAmPm(inputString) {
    const regex = /(\d{2}:\d{2}:\d{2})/;

    if (inputString !== null) {
      const match = inputString.match(regex);
      if (match && match.length > 1) {
        const horario = match[1];
        const [horas, minutos] = horario.split(":");
        const horaInt = parseInt(horas, 10);

        if (horaInt >= 12) {
          const horaFormatada = horaInt === 12 ? 12 : horaInt - 12;
          return `${horaFormatada}:${minutos} pm`;
        } else {
          return `${horas}:${minutos} am`;
        }
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  const formattedNextDate = (date, time) => {
    return `${date ? moment(date).utc().format("MMM. DD, YYYY") : ""} - ${
      time?.length > 20 ? extrairHorarioAmPm(time) : time || ""
    }`;
  };

  const capitalize = (str) => {
    if (typeof str !== "string") {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.substr(1);
  };

  const handleIdByChange = (newValue) => {
    setNameCleaner(newValue.name);
    setNameValue(nameValue);
  };

  const checkStatus = (status) => {
    switch (status) {
      case "done":
        return "#b3ebb3";
      case "canceled":
        return "#c4a883";
      case "scheduled":
        return "#b5bdda";
      default:
        return "#faef8f";
    }
  };

  function handleClick(id, item) {
    navigate(`/clients/info/${id}`, {
      state: { item, listCities, listRegions },
    });
  }

  function navegateLog(id) {
    navigate(`/calendar/log/${id}`);
  }

  const navegateChat = (id) => {
    navigate(`/calendar/chat/${id}`, {
      state: { cleanerId: data.cleanerId },
    });
  };

  const status = Principal ? data?.status : data?.scheduleStatus;

  const hostName = data?.hostName || data?.host;

  // console.log(data);

  return (
    <div className="containerDetailsSchedule">
      <button
        style={{
          border: "none",
          backgroundColor: "transparent",
          cursor: "pointer",
          width: "100%",
        }}
        onClick={handleOpen}
        className="cardCalendarItnes"
      >
        <div
          key={data?.id}
          className={
            Principal
              ? data?.status
              : Principal
              ? data?.status
              : data?.scheduleStatus
          }
          style={{ marginTop: "5px" }}
        >
          <div
            className="nameCalendar"
            style={{
              textTransform: "uppercase",
              width: "100%",
              textAlign: "center",
            }}
          >
            {data?.addressNickname || "No nickname yet"}
          </div>

          <div
            style={{
              display: "flex",
              gap: "5px",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <div
              className="nameCalendar"
              style={{
                fontSize: "11px",
                display: "flex",
                alignItems: "center",
                lineHeight: "2px",
              }}
            >
              <ReactSVG className="iconMenuCard" src={clients} />
              {`${hostName} ${
                data.ghost_client
                  ? "(ghost)"
                  : "" || data.ghostClient
                  ? "(ghost)"
                  : ""
              }` || "No clients yet"}
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
              <div
                className="nameCalendar"
                style={{
                  fontSize: "11px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ReactSVG
                  className="iconMenuCard"
                  src={cleaners}
                  style={{ patch: "red" }}
                />
                {`${data?.cleaner || "No cleaner yet"}`}
              </div>
              <div
                className="nameCalendar"
                style={{
                  fontSize: "11px",
                  display: "flex",
                  alignItems: "center",
                  color:
                    data.acceptanceStatus === "pending" ? "#f7dd52" : "green",
                }}
              >
                {data?.cleaner && `(${data.acceptanceStatus})`}
              </div>
            </div>

            <div
              className="nameCalendar"
              style={{
                fontSize: "11px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ReactSVG className="iconMenuCard" src={clock} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    lineHeight: "10px",
                    marginBottom: "3px",
                  }}
                >
                  {data?.date && (
                    <div style={{ fontSize: "9px", color: "green" }}>
                      Checkout
                    </div>
                  )}
                  <div style={{ textAlign: "start" }}>
                    {data?.date
                      ? formattedDate(data?.date, data?.time || "")
                      : ""}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    lineHeight: "10px",
                  }}
                >
                  {data?.nextCheckinDate && (
                    <div style={{ fontSize: "9px", color: "green" }}>
                      Next Check-in
                    </div>
                  )}
                  <div style={{ textAlign: "start" }}>
                    {data?.nextCheckinDate
                      ? formattedDate(
                          data?.nextCheckinDate,
                          data?.nextCheckinTime || ""
                        )
                      : ""}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="nameCalendar"
              style={{
                fontSize: "11px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ReactSVG className="iconMenuCard" src={Check} />
              {Principal
                ? data?.status
                : data?.scheduleStatus
                ? capitalize(Principal ? data?.status : data?.scheduleStatus)
                : ""}
            </div>
          </div>
        </div>
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className={`containerModal`}
          style={{
            ...style,
            backgroundColor: `${checkStatus(
              Principal ? data?.status : data?.scheduleStatus
            )}`,
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="titleModal">{`Cleaning #${data.id}`}</div>
              <button
                className="buttonViewChat"
                onClick={() => navegateChat(data.id)}
              >
                View chat
              </button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "12px",
              }}
            >
              <div
                className="subTitleModal"
                onClick={() => handleClick(data.clientId, data)}
                style={{ cursor: "pointer" }}
              >
                {Principal ? data?.client : data?.host}
              </div>
              <div className="textStatusCleaning">{`Cleaning ${
                Principal ? data?.status : data?.scheduleStatus
              }`}</div>
            </div>
          </div>

          <div className="lineDivisionModal"></div>

          {data.status === "scheduled" ||
          data.scheduleStatus === "scheduled" ? null : (
            <div className="textCardModal" style={{ marginBottom: "10px" }}>
              {data?.cleaner || "No cleaner assigned"}
            </div>
          )}

          <div>
            {data.status === "scheduled" ||
            data.scheduleStatus === "scheduled" ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
              >
                <Autocomplete
                  options={newListCleaner}
                  data={data}
                  placeholder="Select cleaner"
                  onChange={handleIdByChange}
                  // onSelect={...props}
                />

                {/* <Autocomplete
                  id="combo-box-demo"
                  options={newListCleaner}
                  getOptionLabel={(option) => option.name}
                  value={nameValue}
                  onChange={handleIdByChange}
                  disablePortal
                  disableClearable
                  sx={{
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    border: "1.6px solid #0E1F41",
                    backgroundColor: "#FAFAFA",
                    borderRadius: "4px",
                    width: "100%",
                    height: "44px",
                  }}
                  renderInput={(params) => <TextField {...params} label="" />}
                /> */}
                {/* <select
                  value={nameCleaner}
                  onChange={handleIdByChange}
                  style={{
                    border: "1.6px solid #0E1F41",
                    backgroundColor: "#FAFAFA",
                    borderRadius: "4px",
                    width: "100%",
                    height: "44px",
                  }}
                >
                  <option value="None"></option>
                  {newListCleaner.map((value, idx) => (
                    <option value={value.name} key={idx}>
                      {value.name}
                    </option>
                  ))}
                </select> */}

                <button
                  className="completeButtonModal"
                  onClick={() => inviteCleaner()}
                >
                  {loadingInvite ? "Loading..." : "Invite Cleaner"}
                </button>
              </div>
            ) : null}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div className="textCardModal">
                  <strong>Checkout:</strong>
                  {` ${formattedDate(data.date, data?.time || "")}`}
                </div>
                <div className="textCardModal">
                  <strong>Next Check-in:</strong>
                  {` ${formattedNextDate(
                    data.nextCheckinDate,
                    data.nextCheckinTime
                  )}` || ""}
                </div>
              </div>

              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                {status === "scheduled" && (
                  <EditDateSchedule data={data} setLoadingButton={Put} />
                )}

                {data?.extended && <div className="extended">Extended</div>}
              </div>
            </div>

            {/* <div className="textCardModal">
              <strong>Next guest:</strong>
              {` none`}
            </div> */}
          </div>

          <div className="lineDivisionModal"></div>

          <div>
            <div className="textCardModal">
              <strong>Address:</strong>
              {` ${data?.address?.address || data?.address}`}
            </div>
            <div className="textCardModal">
              <strong>{`${capitalize(data?.getIn?.title)}:`}</strong>
              {` ${data?.getIn?.description}`}
            </div>
            <div className="textCardModal">
              <strong>Rooms:</strong>
              {` ${data?.bedroomsAmount}`}
            </div>
            <div className="textCardModal">
              <strong>Bathrooms:</strong>
              {` ${data?.bathroomsAmount}`}
            </div>
            <div className="textCardModal">
              <strong>Value:</strong>
              {` ${Number(data?.value || 0).toFixed(2)} USD`}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            {(status === "scheduled" || status === "started") && (
              <ModalContainer
                button=""
                type={true}
                title="Cancel Cleaning"
                onClickSave={cancelCleaning}
                customButton={
                  <button className="cancelButtonModal">Cancel Cleaning</button>
                }
              >
                <div className="textCardModal" style={{ marginBottom: "20px" }}>
                  Are you sure you want to cancel cleaning?
                </div>
              </ModalContainer>
            )}

            {/* 
            <button
              className="completeButtonModal"
              onClick={() => completeCleaning()}
            >
              {loadingComplete ? "Loading..." : "Complete Cleaning"}
            </button> */}

            {(status === "scheduled" || status === "started") && (
              <CompleteCleaning
                data={data}
                updateItens={updateItens}
                closeModal={handleClose}
                Put={Put}
              />
            )}

            <button
              className="completeButtonModal"
              style={{ backgroundColor: "#0e1f41" }}
              onClick={() => navegateLog(data.id)}
            >
              View Log
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DetailsScheduleCalendar;
