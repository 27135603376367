import React, { useState } from "react";
import moment from "moment";

import "./CalendarCard.css";

const CalendarCard = ({ schedules, timeOfssDate, dateSchedules }) => {
  const [selectedMonth, setSelectedMonth] = useState(moment().month());
  const [selectedYear, setSelectedYear] = useState(moment().year());

  const prevMonth = () => {
    const newMonth = moment()
    .utc()
      .year(selectedYear)
      .month(selectedMonth)
      .subtract(1, "M");
    if (newMonth.year() < 2021) {
      setSelectedMonth(moment().utc().year(2021).month(0));
      setSelectedYear(2021);
    } else {
      setSelectedMonth(newMonth.month());
      setSelectedYear(newMonth.year());
    }
  };

  const nextMonth = () => {
    const newMonth = moment()
    .utc()
      .year(selectedYear)
      .month(selectedMonth)
      .add(1, "M");
    if (newMonth.year() > 2025) {
      setSelectedMonth(moment().utc().year(2025).month(11));
      setSelectedYear(2025);
    } else {
      setSelectedMonth(newMonth.month());
      setSelectedYear(newMonth.year());
    }
  };

  const daysOfMonth = () => {
    const days = [];
    const startOfMonth = moment()
    .utc()
      .year(selectedYear)
      .month(selectedMonth)
      .startOf("month")
      .day(0);
    const endOfMonth = moment()
    .utc()
      .year(selectedYear)
      .month(selectedMonth)
      .endOf("month")
      .day(6);
    let day = startOfMonth.clone();

    while (day <= endOfMonth) {
      days.push(day);
      day = day.clone().add(1, "d");
    }

    const daysBefore = startOfMonth.diff(
      moment().utc().year(selectedYear).month(selectedMonth).startOf("month"),
      "days"
    );
    for (let i = 1; i <= daysBefore; i++) {
      const newDay = startOfMonth.clone().subtract(i, "d");
      days.unshift(newDay);
    }

    const daysAfter = endOfMonth.diff(
      moment().utc().year(selectedYear).month(selectedMonth).endOf("month"),
      "days"
    );
    for (let i = 1; i <= -daysAfter; i++) {
      const newDay = endOfMonth.clone().add(i, "d");
      days.push(newDay);
    }

    while (days.length < 42) {
      if (daysBefore > daysAfter) {
        const newDay = startOfMonth.clone().subtract(days.length, "d");
        days.unshift(newDay);
      } else {
        const newDay = endOfMonth.clone().add(days.length - days.length, "d");
        days.push(newDay);
      }
    }

    return days;
  };

  const capitalize = (str) => {
    if (typeof str !== "string") {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.substr(1);
  };

  return (
    <div className="containerCalendarCleaner">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div
          className="title"
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className="titleCalendar">Cleaner Calendar</div>
          <div style={{ width: "auto", height: "34px" }}></div>

          <div className="containerButtons">
            <button onClick={prevMonth}>&lt;</button>
            <div>
              {moment()
              .utc()
                .year(selectedYear)
                .month(selectedMonth)
                .format("MMMM,  YYYY")}
            </div>
            <button onClick={nextMonth}>&gt;</button>
          </div>
        </div>
      </div>
      <table>
        <thead>
          <tr style={{ color: "#fff" }}>
            <th>Sun</th>
            <th>Mon</th>
            <th>Tue</th>
            <th>Wed</th>
            <th>Thu</th>
            <th>Fri</th>
            <th>Sat</th>
          </tr>
        </thead>
        <tbody>
          {daysOfMonth()
            .reduce((rows, day, i) => {
              if (i % 7 === 0) rows.push([]);
              rows[rows.length - 1].push(day);
              return rows;
            }, [])
            .map((week, i) => (
              <tr key={i}>
                {week.map((day, j) => {
                  const isCurrentMonth = day.month() === selectedMonth;
                  const isToday = day.isSame(moment(), "day");
                  const textColor = isCurrentMonth ? "#000000" : "#a5aebd";
                  return (
                    <td
                      key={j}
                      style={{
                        color: timeOfssDate?.includes(
                          moment(day).utc().format("YYYY-MM-DD")
                        )
                          ? "#FFF"
                          : textColor,
                        backgroundColor: timeOfssDate?.includes(
                          moment(day).utc().format("YYYY-MM-DD")
                        )
                          ? "#EE6161"
                          : "" ||
                            dateSchedules?.includes(
                              moment(day).utc().format("YYYY-MM-DD")
                            )
                          ? "#75CBEE"
                          : "",
                      }}
                    >
                      <div style={{ height: "100%" }}>
                        <div className="currentDay">
                          <div
                            className={
                              isToday
                                ? "textCurrentDay"
                                : isCurrentMonth
                                ? "currentDay"
                                : "nonCurrentMonthDay"
                            }
                          >
                            {day.format("DD")}
                          </div>
                        </div>
                        <div className="containerCardSchedulesCleaning"></div>
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
export default CalendarCard;
