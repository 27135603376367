import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import DatePicker from "react-datepicker";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionsIssuers from "./components/AccordionsIssuers";
import api from "../../../Service/api";

import "./Issuers.css";
import moment from "moment";

const initialFilterState = {
  dateFrom: "",
  dateTo: "",
};

const CustomInput = ({ value, onClick, props }) => (
  <button
    onClick={onClick}
    className="containerIcon"
    style={{ cursor: "pointer", height: "55px" }}
  >
    <ExpandMoreIcon {...props} style={{ color: "#FAFAFA", fontSize: "40px" }} />
  </button>
);

const Issuers = ({ setWeekData, weekData, setLoadingWeek, loadingWeek }) => {
  const [filter, setFilter] = useState(initialFilterState);
  const [searched, setSearched] = useState(false);

  const getList = async () => {
    setLoadingWeek(true);
    setWeekData([]);
    try {
      const data = await api.get("/admins/cleaners_reports/", {
        params: {
          start_date: filter.dateFrom
            ? moment(filter.dateFrom).utc().format("DD/MM/YYYY")
            : "",
          end_date: filter.dateTo
            ? moment(filter.dateTo).utc().format("DD/MM/YYYY")
            : "",
        },
      });

      console.log(data);

      if (data) {
        setWeekData(data.filter((item) => item.csv_links !== null));
      }

      setLoadingWeek(false);
    } catch (erro) {
      console.log(erro);
      setLoadingWeek(false);
      setWeekData(null);
    }
  };

  const handleSearch = () => {
    getList();
    setSearched(true);
  };

  const handleResetFilter = () => {
    setFilter(initialFilterState);
    setWeekData(null);
    setSearched(false);
  };

  const handleFromDateChange = (date) => {
    setFilter((prevState) => ({
      ...prevState,
      dateFrom: moment(date).utc().format("MM/DD/YYYY"),
    }));
  };

  const handleToDateChange = (date) => {
    setFilter((prevState) => ({
      ...prevState,
      dateTo: moment(date).utc().format("MM/DD/YYYY"),
    }));
  };

  const handleDateChange = (event, type) => {
    let inputValue = event.target.value;

    inputValue = inputValue.replace(/\D/g, "");

    // Adiciona as barras conforme o usuário digita
    if (inputValue.length > 2) {
      inputValue = inputValue.substring(0, 2) + "/" + inputValue.substring(2);
    }
    if (inputValue.length > 5) {
      inputValue =
        inputValue.substring(0, 5) + "/" + inputValue.substring(5, 9);
    }

    setFilter({ ...filter, [type]: inputValue });
  };

  return (
    <>
      <div className="cardIssuer" style={{ overflowY: "auto" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "20px",
            marginBottom: "20px",
          }}
        >
          <div className="containerSelectAdmin">
            <div
              className="selectedCONtainer containerBox"
              style={{ width: "105px", padding: "5px" }}
            >
              <span className="contentText">From</span>
              <input
                type="text"
                value={filter.dateFrom}
                onChange={(date) => handleDateChange(date, "dateFrom")}
                placeholder="MM/DD/YYYY"
                style={{ width: "100px", border: "none", fontSize: "15px" }}
              />
            </div>

            <DatePicker
              onChange={handleFromDateChange}
              dateFormat="dd/MM/yyyy"
              customInput={<CustomInput />}
            />
          </div>

          <div className="containerSelectAdmin">
            <div
              className="selectedCONtainer containerBox"
              style={{ width: "105px", padding: "5px" }}
            >
              <span className="contentText">To</span>
              <input
                type="text"
                value={filter.dateTo}
                onChange={(date) => handleDateChange(date, "dateTo")}
                placeholder="MM/DD/YYYY"
                style={{ width: "100px", border: "none", fontSize: "15px" }}
              />
            </div>

            <DatePicker
              onChange={handleToDateChange}
              dateFormat="dd/MM/yyyy"
              customInput={<CustomInput />}
            />
          </div>
          <button
            className="buttonFilter"
            style={{ backgroundColor: "#0e1f41" }}
            onClick={handleSearch}
          >
            Search
          </button>

          <button className="buttonFilter" onClick={handleResetFilter}>
            Clear Selection
          </button>
        </div>
      </div>
      <div className="containerHeaderReports"></div>
      <div className="containerSumary">
        <div className="containerCards">
          <div style={{ width: "100%" }}>
            <div className="cardIssuer" style={{ overflowY: "auto" }}>
              <div className="title">Cleaners with Issuers</div>
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  // borderTop: "1px solid #0E1F41",
                  // flexWrap: "wrap",
                  // gap: "5px",
                }}
              >
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                    border: "1px solid #0E1F41",
                    borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px",
                    alignItems: "center",
                    background: "#0E1F41",
                    color: "white",
                    // borderRadius: "15px",
                  }}
                >
                  <div
                    style={{
                      width: "20%",
                      marginLeft: "10px",
                      fontSize: "16px",
                      fontWeight: "600",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    Cleaner
                  </div>
                  <div
                    style={{
                      width: "20%",
                      fontSize: "16px",
                      fontWeight: "600",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    Region
                  </div>
                  <div
                    style={{
                      width: "15%",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "16px",
                      fontWeight: "600",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    Total Revenue
                  </div>
                  <div
                    style={{
                      width: "12.5%",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "16px",
                      fontWeight: "600",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    Jobs Performed
                  </div>
                  <div
                    style={{
                      width: "12.5%",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "16px",
                      fontWeight: "600",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    Properties Serviced
                  </div>
                  <div style={{ width: "20%", padding: "5px" }}></div>
                </div>
                {!loadingWeek && weekData !== null ? (
                  <>
                    {weekData.map((item, index) => (
                      <AccordionsIssuers item={item} index={index} />
                    ))}
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "40vh",
                      width: "100%",
                    }}
                  >
                    {loadingWeek && weekData !== null ? (
                      <Box sx={{ display: "flex" }}>
                        <CircularProgress size={50} />
                      </Box>
                    ) : (
                      <div className="titleFilter">
                        No matching items found.
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Issuers;
