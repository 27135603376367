import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

export default function ControlledAccordions({ value, item, index }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function obterNomeArquivoDoLink(link) {
    const decodedURL = decodeURIComponent(link);

    const regex = /filename=\"(.*?)\"/;
    const match = decodedURL.match(regex);
    if (match) {
      const fileName = match[1];
      return fileName; // Saída: cleaner_David_Leandro_cleanings.csv
    }
  }

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{ width: "100%" }}
      >
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon sx={{ color: "#0e1f41" }} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            background: index % 2 !== 0 ? "#fafafa" : "#d5e7f4",
            margin: "0px",
            padding: "0px",
          }}
        >
          <div style={{ width: "20%", marginLeft: "12px" }}>
            {item.cleaner_name}
          </div>
          <div style={{ width: "20%", marginLeft: "2px" }}>{item.region}</div>
          <div
            style={{
              width: "15%",
              justifyContent: "center",
              display: "flex",
            }}
          >
            $ {Number(item.total_revenue).toFixed(2)}
          </div>
          <div
            style={{
              width: "12.5%",
              justifyContent: "center",
              display: "flex",
            }}
          >
            {item.number_of_cleanings}
          </div>
          <div
            style={{
              width: "12.5%",
              justifyContent: "center",
              display: "flex",
            }}
          >
            {item.number_of_properties_cleaned}
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={{ padding: "0px", borderBottom: "1px solid #0e1f41" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            {item.csv_links.map((value, key) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  backgroundColor: key % 2 !== 0 ? "#fafafa" : "#BBC3C3",
                }}
              >
                <div
                  style={{
                    marginLeft: "10px",
                    fontSize: "16px",
                    color: "#0e1f41",
                  }}
                >{`#${value.id}`}</div>
                <div style={{ fontSize: "16px", color: "#0e1f41" }}>
                  {obterNomeArquivoDoLink(value.url)}
                </div>
                <div style={{ width: "200px", padding: "5px" }}>
                  <a
                    style={{ textDecoration: "none" }}
                    href={value.url}
                    download
                  >
                    <button
                      className="buttonDocument"
                      style={{
                        display: "flex",
                        width: "100%",
                        backgroundColor: "#0E1F41",
                        borderRadius: "15px",
                        // color: "white",
                        fontSize: "16px",
                        marginRight: "10px",
                      }}
                    >
                      Download Issuer
                    </button>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
