import React, { useEffect, useState } from "react";
import moment from "moment";
import api from "../../../../../Service/api";
import DetailsScheduleCalendar from "../../../../../Components/DetailsScheduleCalendar";
import DetailsCleaningModal from "../../../../../Components/DetailsCleaningModal";

import "./CalendarCard.css";

const CalendarCard = ({
  schedules,
  onClick,
  isOpen,
  Put,
  selectedMonth,
  selectedYear,
}) => {
  const [isLoading, setIsLOading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 775);
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const daysOfMonth = () => {
    const days = [];

    const startOfMonth = moment()
      .year(selectedYear)
      .month(selectedMonth)
      .startOf("month")
      .day(0);
    const endOfMonth = moment()
      .year(selectedYear)
      .month(selectedMonth)
      .endOf("month")
      .day(6);
    let day = startOfMonth.clone();

    while (day <= endOfMonth) {
      days.push(day);
      day = day.clone().add(1, "d");
    }

    const daysBefore = startOfMonth.diff(
      moment().year(selectedYear).month(selectedMonth).startOf("month"),
      "days"
    );
    for (let i = 1; i <= daysBefore; i++) {
      const newDay = startOfMonth.clone().subtract(i, "d");
      days.unshift(newDay);
    }

    const daysAfter = endOfMonth.diff(
      moment().year(selectedYear).month(selectedMonth).endOf("month"),
      "days"
    );
    
    for (let i = 1; i <= -daysAfter; i++) {
      const newDay = endOfMonth.clone().add(i, "d");
      days.push(newDay);
    }

    while (days.length < 42) {
      if (daysBefore > daysAfter) {
        const newDay = startOfMonth.clone().subtract(days.length, "d");
        days.unshift(newDay);
      } else {
        const newDay = endOfMonth.clone().add(days.length - days.length, "d");
        days.push(newDay);
      }
    }

    return days;
  };

  const compareSchedules = (a, b) => {
    const dateA = a.nextCheckinDate !== null ? moment(a.nextCheckinDate).format("YYYY-MM-DD") : moment(a.date).format("YYYY-MM-DD");
    const dateB = b.nextCheckinDate !== null ? moment(b.nextCheckinDate).format("YYYY-MM-DD") : moment(b.date).format("YYYY-MM-DD");

    const timeA = a.nextCheckinTime !== null ? (a.nextCheckinTime.split('-')[0]).trim() : (a.time.split('-')[0]).trim();
    const timeB = b.nextCheckinTime !== null ? (b.nextCheckinTime.split('-')[0]).trim() : (b.time.split('-')[0]).trim();

    const hasStartedOrScheduledA = ['started', 'scheduled'].includes(a.scheduleStatus);
    const hasStartedOrScheduledB = ['started', 'scheduled'].includes(b.scheduleStatus);

    if (hasStartedOrScheduledA && !hasStartedOrScheduledB) return -1;
    if (!hasStartedOrScheduledA && hasStartedOrScheduledB) return 1;

    if (moment(dateA).isBefore(dateB)) return -1;
    if (moment(dateA).isAfter(dateB)) return 1;
    
    return moment(timeA, 'h:mm A').isBefore(moment(timeB, 'h:mm A')) ? -1 : 1;
  };

  return (
    <div className="containerTableCalendar">
      <table>
        <thead>
          <tr style={{ color: "#fff" }}>
            <th>Sun</th>
            <th>Mon</th>
            <th>Tue</th>
            <th>Wed</th>
            <th>Thu</th>
            <th>Fri</th>
            <th>Sat</th>
          </tr>
        </thead>
        <tbody>
          {daysOfMonth()
            .reduce((rows, day, i) => {
              if (i % 7 === 0) rows.push([]);
              rows[rows.length - 1].push(day);
              return rows;
            }, [])
            .map((week, i) => (
              <tr key={i}>
                {week.map((day, j) => {
                  const isCurrentMonth = day.month() === selectedMonth;
                  const isToday = day.isSame(moment(), "day");
                  const textColor = isCurrentMonth ? "#000000" : "#a5aebd";
                  console.log(day);
                  return (
                    <td
                      key={j}
                      style={{
                        color: textColor,
                      }}
                    >
                      <div style={{ height: "100%" }}>
                        <div className="currentDay">
                          <div
                            className={
                              isToday
                                ? "textCurrentDay"
                                : isCurrentMonth
                                ? "currentDay"
                                : "nonCurrentMonthDay"
                            }
                          >
                            {moment(day).format("DD")}
                          </div>
                          <div>
                            {isMobile &&
                              ` - ${moment(day).format("dddd")}`}
                          </div>
                        </div>
                        <div className="containerCardSchedules">
                          <div
                            style={{ marginTop: "5px" }}
                            className="cardDayConainer"
                          >
                            {schedules
                              ?.filter(
                                (date) =>
                                  moment(day).format("YYYY-MM-DD") ===
                                  moment(date.date).utc().format("YYYY-MM-DD")
                              )
                              .sort(compareSchedules)
                              .map((value, idx) => (
                                <>
                                  {value.scheduleStatus === "done" ? (
                                    <DetailsCleaningModal
                                      item={value}
                                      setLoading={setIsLOading}
                                      CalendarType={true}
                                    />
                                  ) : (
                                    <DetailsScheduleCalendar
                                      data={value}
                                      Put={Put}
                                      CalendarType={true}
                                    />
                                  )}
                                </>
                              ))}
                          </div>
                        </div>
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
export default CalendarCard;
