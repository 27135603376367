import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import deleteIcon from "../../../../../Assets/Group 1825.svg";
import editIcon from "../../../../../Assets/Btn Details.svg";

import "./Step2.css";

const optionsCompliment = [
  "someone is home",
  "doorcode",
  "hidden key",
  "other",
];

const Step2 = ({ form }) => {
  const [newItem, setNewItem] = useState("");

  const handleUpdate = (newItem, idx, type, item) => {
    if (item.id) {
      const updatedItems = [...form.values[type]];
      updatedItems[idx].description = newItem;
      form.setFieldValue(`${type}`, updatedItems);
    } else {
      const updatedItems = [...form.values[type]];
      updatedItems[idx] = {description: newItem};
      form.setFieldValue(`${type}`, updatedItems);
    }
  };

  const handleAddItem = (type) => {
    form.setFieldValue(`${type}`, [...form.values[type], newItem]);
    setNewItem("");
  };

  const handleDelete = (index, type, item) => {
    if (item.id) {
      const updatedItems = [...form.values[type]];
      updatedItems[index].delete = true;
      form.setFieldValue(`${type}`, updatedItems);
    } else {
      const updatedItems = form.values[type].filter((_, i) => i !== index);
      form.setFieldValue(`${type}`, updatedItems);
    }
  };

  function replaceUnderscoreWithSpace(inputString) {
    return inputString.replace(/_/g, " ");
  }

  const handleOptionToggle = (option, type) => {
    form.setFieldValue(`${type}`, option);
  };

  const isOptionSelected = (option, type) =>
    replaceUnderscoreWithSpace(form.values[type]).includes(option);

  return (
    <div className="containerStep1">
      <div className="containerCompliment">
        <div className="title">How do we get in?</div>
        <div className="button-container">
          {optionsCompliment.map((option) => (
            <button
              key={option}
              className={`option-button ${
                isOptionSelected(option, "title") ? "selected" : "notSelected"
              }`}
              onClick={() => handleOptionToggle(option, "title")}
            >
              {option}
            </button>
          ))}
        </div>
      </div>

      <div>
        <div className="titleINputModalStep">Instructions*</div>
        <div className="containerInputText">
          <input
            type="text"
            className="inputText"
            value={form.values.description}
            onChange={(event) =>
              form.setFieldValue("description", event.target.value)
            }
          />
        </div>
      </div>

      <div className="checklist">
        <div className="titleINputModalStep">Checklist</div>
        <div className="containerCheklist">
          {form.values.addressChecklistsAttributes.length > 0 &&
            form.values.addressChecklistsAttributes.map((item, idx) => (
              <div
                className="containerInputText"
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <input
                  type="text"
                  className="inputText"
                  value={item.description}
                  style={{
                    textDecoration: `${item.delete ? "line-through" : ""}`,
                  }}
                  onChange={(event) =>
                    handleUpdate(
                      event.target.value,
                      idx,
                      "addressChecklistsAttributes",
                      item
                    )
                  }
                />
                {!item.delete && (
                  <button
                    onClick={() =>
                      handleDelete(idx, "addressChecklistsAttributes", item)
                    }
                  >
                    <ReactSVG src={deleteIcon} style={{ fontSize: "30px" }} />
                  </button>
                )}
              </div>
            ))}
        </div>
        <div
          className="containerInputText"
          style={{ display: "flex", alignItems: "center", gap: "10px" }}
        >
          <button
            onClick={() => handleAddItem("addressChecklistsAttributes")}
            className="addAnother"
          >
            + Add another
          </button>
        </div>
      </div>

      <div>
        <div className="titleINputModalStep">Storage access and location</div>
        <div className="containerInputText">
          <input
            type="text"
            className="inputText"
            value={form.values.storageAccessLocation}
            onChange={(event) =>
              form.setFieldValue("storageAccessLocation", event.target.value)
            }
          />
        </div>
      </div>

      <div className="checklist">
        <div className="titleINputModalStep">Consumables</div>
        <div className="containerCheklist">
          {form.values.consumablesAttributes.length > 0 &&
            form.values.consumablesAttributes.map((item, idx) => (
              <div
                className="containerInputText"
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <input
                  type="text"
                  className="inputText"
                  value={item.description}
                  style={{
                    textDecoration: `${item.delete ? "line-through" : ""}`,
                  }}
                  onChange={(event) =>
                    handleUpdate(
                      event.target.value,
                      idx,
                      "consumablesAttributes",
                      item
                    )
                  }
                />
                <button
                  onClick={() =>
                    handleDelete(idx, "consumablesAttributes", item)
                  }
                >
                  <ReactSVG src={deleteIcon} style={{ fontSize: "30px" }} />
                </button>
              </div>
            ))}
        </div>
        <div
          className="containerInputText"
          style={{ display: "flex", alignItems: "center", gap: "10px" }}
        >
          <button
            onClick={() => handleAddItem("consumablesAttributes")}
            className="addAnother"
          >
            + Add another
          </button>
        </div>
      </div>
    </div>
  );
};

export default Step2;
