import React, { useContext, useEffect, useState } from "react";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MyTable from "./components/MyTable";
import BestTable from "./components/BestTable";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import EventIcon from "@mui/icons-material/Event";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputAdornment from "@mui/material/InputAdornment";

import iconPrint from "../../../../Assets/🦆 icon _printer alt_.svg";
import iconDonwload from "../../../../Assets/Union (5).svg";

import "./General.css";
import AppContext from "../../../../Context/AppContext";
import api from "../../../../Service/api";
import moment from "moment";
import { ReactSVG } from "react-svg";

const renderCustomIcon = (props) => {
  return (
    <ExpandMoreIcon {...props} style={{ color: "#FAFAFA", fontSize: "40px" }} />
  );
};

const CustomInput = ({ value, onClick, props }) => (
  <button
    onClick={onClick}
    className="containerIcon"
    style={{ cursor: "pointer", height: "50px" }}
  >
    <ExpandMoreIcon {...props} style={{ color: "#FAFAFA", fontSize: "40px" }} />
  </button>
);

const initialFilterState = {
  region: "",
  city: "",
  dateFrom: "",
  dateTo: "",
  rating: "",
};

const General = () => {
  const { listRegions, listCities } = useContext(AppContext);
  const [filter, setFilter] = useState(initialFilterState);
  const [listGeneral, setListGeneral] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searched, setSearched] = useState(false);
  const [isLoading, setIsLOading] = useState(true);

  const getList = async () => {
    setIsLOading(true);
    try {
      const data = await api.get("/admins/reports/general", {
        params: {
          from: filter.dateFrom
            ? moment(filter.dateFrom).utc().format("DD/MM/YYYY")
            : "",
          to: filter.dateTo
            ? moment(filter.dateTo).utc().format("DD/MM/YYYY")
            : "",
        },
      });
      setListGeneral(data);
      setIsLOading(false);
    } catch (erro) {
      console.log(erro);
      setIsLOading(false);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const handleSearch = () => {
    getList();
    setSearched(true);
  };

  const fetchDataReset = async () => {
    setListGeneral([]);
    setIsLOading(true);
    try {
      const data = await api.get("/admins/reports/general");
      setListGeneral(data);
      setIsLOading(false);
    } catch (erro) {
      console.log(erro);
      setIsLOading(false);
    }
  };

  const handleResetFilter = () => {
    setFilter(initialFilterState);
    fetchDataReset();
    setSearched(false);
  };

  const handleFromDateChange = (date) => {
    setFilter((prevState) => ({
      ...prevState,
      dateFrom: moment(date).utc().format("MM/DD/YYYY"),
    }));
  };

  const handleToDateChange = (date) => {
    setFilter((prevState) => ({
      ...prevState,
      dateTo: moment(date).utc().format("MM/DD/YYYY"),
    }));
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter((prevState) => ({ ...prevState, [name]: value }));

    if (name === "region") {
      setFilter((prevState) => ({ ...prevState, city: "" }));
    }
  };

  const filterCities = listCities?.filter((item) => {
    if (filter.region === "") {
      return true;
    }
    return item.region.name === filter.region;
  });

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);

    return formattedValue;
  };

  return (
    <div className="containerGeneralReports">
      <div className="titleHeader">Reports</div>

      <div className="containerHeaderReports">
        <div className="timeContainerButtons flexDirecitonCOllum">
          {/* <div className="containerSelectAdmin">
            <div className="selectedCONtainer containerBox">
              <span className="contentText">Region</span>
              <div>{filter.region}</div>
            </div>
            <Select
              className="containerIcon"
              onChange={handleFilterChange}
              name="region"
              style={{ height: "50px" }}
              displayEmpty
              renderValue={() => null}
              IconComponent={renderCustomIcon}
            >
              {listRegions?.map((item, idx) => (
                <MenuItem value={item?.name} key={idx}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className="containerSelectAdmin">
            <div className="selectedCONtainer containerBox">
              <span className="contentText">City</span>
              <div>{filter.city}</div>
            </div>
            <Select
              className="containerIcon"
              onChange={handleFilterChange}
              name="city"
              style={{ height: "50px" }}
              displayEmpty
              renderValue={() => null}
              IconComponent={renderCustomIcon}
            >
              {filterCities?.map((item, idx) => (
                <MenuItem value={item?.name} key={idx}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </div> */}

          <div className="containerSelectAdmin" style={{marginTop: '10px'}}>
            <div className="selectedCONtainer containerBox">
              <span className="contentText">From</span>
              <div>{filter.dateFrom}</div>
            </div>

            <DatePicker
              onChange={handleFromDateChange}
              dateFormat="dd/MM/yyyy"
              customInput={<CustomInput />}
            />
          </div>

          <div className="containerSelectAdmin">
            <div className="selectedCONtainer containerBox">
              <span className="contentText">To</span>
              <div>{filter.dateTo}</div>
            </div>

            <DatePicker
              onChange={handleToDateChange}
              dateFormat="dd/MM/yyyy"
              customInput={<CustomInput />}
            />
          </div>

          {searched ? (
            <button className="buttonFilter" onClick={handleResetFilter}>
              Clear Selection
            </button>
          ) : (
            <button
              className="buttonFilter"
              style={{ backgroundColor: "#0e1f41" }}
              onClick={handleSearch}
            >
              Search
            </button>
          )}
        </div>
        {/* 
        <div className="reportsDoc">
          <div className="containerButon">
            <button>Download</button>
            <ReactSVG src={iconDonwload} />
          </div>

          <div className="containerButon">
            <button>Print</button>
            <ReactSVG src={iconPrint} />
          </div>
        </div> */}
      </div>

      {listGeneral?.best_cleaners ? (
        <>
          <div className="containerAllValues flexDirecitonCOllum">
            <div className="card">
              <div className="title">
                Total Revenue:{" "}
                <div className="subtitle">{`${
                  formatCurrency(listGeneral?.financials?.total_revenue) || 0
                }`}</div>
              </div>
            </div>

            <div className="card">
              <div className="title">
                Refunds:{" "}
                <div className="subtitle">
                  <div className="subtitle">{`${
                    formatCurrency(listGeneral?.financials?.refounds) || 0
                  }`}</div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="title">
                New Clients:{" "}
                <div className="subtitle">{listGeneral?.clients?.new || 0}</div>
              </div>
            </div>

            <div className="card">
              <div className="title">
                New Properties:{" "}
                <div className="subtitle">
                  {listGeneral?.properties?.new || 0}
                </div>
              </div>
            </div>
          </div>

          <MyTable data={listGeneral?.regions} />

          <div className="containerFooterGeneralReports flexDirecitonCOllum">
            <div style={{ flex: 1.5 }}>
              <BestTable
                data={listGeneral?.best_cleaners}
                title1="Best Cleaners"
                title2="Jobs"
                treeCollum={true}
                flexNumber={true}
              />
            </div>
            <div style={{ flex: 1 }}>
              <BestTable
                data={listGeneral?.best_properties}
                title1="Best Properties"
                title2="Cleanings"
                flexNumber={false}
              />
            </div>

            <div className="containerCredtardsProperties">
              <div className="title">Customer Retention</div>
              <div className="subtitle">{`${
                listGeneral?.customer_retantion || 0
              }%`}</div>
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "40vh",
            width: "100%",
          }}
        >
          {isLoading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress size={50} />
            </Box>
          ) : (
            <div className="titleFilter">No matching items found.</div>
          )}
        </div>
      )}
    </div>
  );
};

export default General;
