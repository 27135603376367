import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";

import './MyTable.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  blueRow: {
    backgroundColor: "#D5E7F4",
  },
}));


const MyTable = ({ data }) => {
  const classes = useStyles();

  const formatCurrency = (value) => {

    const formattedValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);

    return formattedValue;
  };

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className="titleTable">Regions</TableCell>
            <TableCell className="titleTable">Total Revenue</TableCell>
            <TableCell className="titleTable">Jobs</TableCell>
            <TableCell className="titleTable">Total Clients</TableCell>
            <TableCell className="titleTable">Total Properties</TableCell>
            <TableCell className="titleTable">Total Cleaners</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) => (
            <TableRow key={index} className={index % 2 !== 0 ? "" : classes.blueRow}>
              <TableCell className="subtitleTable">{row.name}</TableCell>
              <TableCell className="subtitleTable">{formatCurrency(row.total_revenue)}</TableCell>
              <TableCell className="subtitleTable">{row.jobs}</TableCell>
              <TableCell className="subtitleTable">{row.total_clients}</TableCell>
              <TableCell className="subtitleTable">{row.total_properties}</TableCell>
              <TableCell className="subtitleTable">{row.total_cleaners}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MyTable;
