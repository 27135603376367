import React from "react";
import { styled, FormHelperText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
// import TextField from "@mui/material/TextField";
import Inputs from "@mui/material/Input";

import './Input.css';

// const CssTextField = styled(TextField)({
//   "& label.Mui-focused": {
//     color: "#0E1F41",
//   },
//   "& .MuiInput-underline:after": {
//     borderBottomColor: "#0E1F41",
//   },
//   "& .MuiOutlinedInput-root": {
//     "& fieldset": {
//       borderColor: "#e5ebf2",
//     },
//     "&:hover fieldset": {
//       borderColor: "#0E1F41",
//     },
//     "&.Mui-focused fieldset": {
//       borderColor: "#0E1F41",
//     },
//   },
// });

function MyFormHelperText({ text }) {
  // const { focused } = useFormControl() || {};

  const helperText = React.useMemo(() => {
    if (text) {
      return `${text}`;
    }

    return ``;
  }, [text]);

  return <FormHelperText>{helperText}</FormHelperText>;
}

export const Input = ({
  style,
  placeholder,
  form,
  field,
  keyboardType,
  secureTextEntry,
  autoCapitalize,
  multiline,
  numberOfLines,
  textAlignVertical,
  editable,
  maxLength,
  onBlur,
  inputStyle,
  assistiveText,
  mask,
}) => {
  const renderItemMenu = () => {
    switch (maxLength) {
      case maxLength:
        return maxLength;
      default:
        return "";
    }
  };

  return (
    <FormControl style={{ width: "100%", marginLeft: 0, marginBottom: 5 }}>
      <div className="titleInput">{placeholder}</div>
      <Inputs
        id="outlined-basic"
        placeholder={placeholder}
        name="hostName"
        value={form.values[field]}
        variant="outlined"
        onChange={form.handleChange(field)}
        onBlur={onBlur || form.handleBlur(field)}
        // helperText={form.errors[field] && (form.errors[field])}
        type={keyboardType ? keyboardType : "text"}
        disabled={editable}
        inputProps={{ maxLength: renderItemMenu() }}
      />
      {/* <TextField
        id="outlined-basic"
        label={placeholder}
        value={
          mask
            ? form.values[field].replace(/(\d{2})(\d{2})/, "$1/$2")
            : form.values[field]
        }
        variant="outlined"
        onChange={form.handleChange(field)}
        onBlur={onBlur || form.handleBlur(field)}
        // helperText={form.errors[field] && (form.errors[field])}
        type={keyboardType ? keyboardType : "text"}
        style={{ borderBottom: "none", width: "100%" }}
        disabled={editable}
        inputProps={{ maxLength: renderItemMenu() }}
      /> */}
      <MyFormHelperText text={form.errors[field] && form.errors[field]} />
    </FormControl>
  );
};
