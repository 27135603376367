import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { AuthorizedLayout } from "../../../Components/AuthorizedLayout";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import "./Icalendar.css";
import api from "../../../Service/api";
import AppContext from "../../../Context/AppContext";

const Icalendar = () => {
  let navigate = useNavigate();
  const { listRegions, listCities } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getMetrics = async () => {
      try {
        const data = await api.get("/admins/icalendar/clients");

        const newData = await data?.data.map((value) => ({
          id: value.id,
          type: value.type,
          ...value.attributes,
        }));

        setData(newData);
      } catch (erro) {}
    };

    getMetrics();
  }, []);

  const formattedDate = (date) => {
    return moment(date).utc().format("MMM. DD, YYYY | hh:mm A");
  };

  const fetchSync = async () => {
    setLoading(true);
    try {
      await api.get("/admins/icalendar/sync_schedules");
      setLoading(false);
      alert("Schedules synchronized!");
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert("Error: Failed to synchronize schedules!");
    }
  };

  function handleClick(id, item) {
    navigate(`/clients/info/${id}`, {
      state: { item, listCities, listRegions },
    });
  }

  // const data = [
  //   {
  //     name: "Antônio Silva",
  //     type: "admin",
  //     id: "169",
  //     region: "california",
  //   },
  //   {
  //     name: "Maria Souza",
  //     type: "user",
  //     id: "128",
  //     region: "são paulo",
  //   },
  //   {
  //     name: "João Santos",
  //     type: "admin",
  //     id: "279",
  //     region: "rio de janeiro",
  //   },
  //   {
  //     name: "Pedro Ferreira",
  //     type: "user",
  //     id: "563",
  //     region: "minas gerais",
  //   },
  //   {
  //     name: "Juliana Rodrigues",
  //     type: "user",
  //     id: "335",
  //     region: "bahia",
  //   },
  //   {
  //     name: "Carlos Oliveira",
  //     type: "admin",
  //     id: "981",
  //     region: "pernambuco",
  //   },
  //   {
  //     name: "Luciana Costa",
  //     type: "user",
  //     id: "741",
  //     region: "paraná",
  //   },
  //   {
  //     name: "Maurício Pereira",
  //     type: "admin",
  //     id: "444",
  //     region: "rio grande do sul",
  //   },
  //   {
  //     name: "Ana Paula Santos",
  //     type: "user",
  //     id: "902",
  //     region: "distrito federal",
  //   },
  //   {
  //     name: "Fernanda Souza",
  //     type: "user",
  //     id: "622",
  //     region: "amazonas",
  //   },
  //   {
  //     name: "Antônio Silva",
  //     type: "admin",
  //     id: "169",
  //     region: "california",
  //   },
  //   {
  //     name: "Maria Souza",
  //     type: "user",
  //     id: "128",
  //     region: "são paulo",
  //   },
  //   {
  //     name: "João Santos",
  //     type: "admin",
  //     id: "279",
  //     region: "rio de janeiro",
  //   },
  //   {
  //     name: "Pedro Ferreira",
  //     type: "user",
  //     id: "563",
  //     region: "minas gerais",
  //   },
  //   {
  //     name: "Juliana Rodrigues",
  //     type: "user",
  //     id: "335",
  //     region: "bahia",
  //   },
  //   {
  //     name: "Carlos Oliveira",
  //     type: "admin",
  //     id: "981",
  //     region: "pernambuco",
  //   },
  //   {
  //     name: "Luciana Costa",
  //     type: "user",
  //     id: "741",
  //     region: "paraná",
  //   },
  //   {
  //     name: "Maurício Pereira",
  //     type: "admin",
  //     id: "444",
  //     region: "rio grande do sul",
  //   },
  //   {
  //     name: "Ana Paula Santos",
  //     type: "user",
  //     id: "902",
  //     region: "distrito federal",
  //   },
  //   {
  //     name: "Fernanda Souza",
  //     type: "user",
  //     id: "622",
  //     region: "amazonas",
  //   },
  //   {
  //     name: "Bruno Almeida",
  //     type: "admin",
  //     id: "772",
  //     region: "são paulo",
  //   },
  //   {
  //     name: "Luana Oliveira",
  //     type: "user",
  //     id: "206",
  //     region: "bahia",
  //   },
  //   {
  //     name: "Amanda Costa",
  //     type: "user",
  //     id: "521",
  //     region: "são paulo",
  //   },
  //   {
  //     name: "Rafael Santos",
  //     type: "admin",
  //     id: "812",
  //     region: "rio de janeiro",
  //   },
  // ];

  return (
    <AuthorizedLayout>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "15px",
        }}
      >
        <div className="titleTop">Connected Icalendar Accounts</div>
        <button className="buttonRunCalendar" onClick={() => fetchSync()}>
          {loading ? "Loading..." : "Run Icalendar"}
        </button>
      </div>
      <div className="containerIcalendar">
        {data?.length > 0 ? (
          <div className="containerCard">
            {data?.map((item, idx) => (
              <div
                className="containerCardAccounts"
                key={idx}
                onClick={() => handleClick(item.id, item)}
                style={{ cursor: 'pointer' }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="userPhoto"></div>
                  <div style={{ marginLeft: "10px" }}>
                    <div className="title">{item?.fullName}</div>
                    <div className="subtitle">{`${item?.propertiesImported} properties imported`}</div>
                  </div>
                </div>

                <div className="subtitlePerfil2">
                  <div>Connected since</div>
                  <div>{formattedDate(item?.connectedSince)}</div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "80vh",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <CircularProgress size={50} />
            </Box>
          </div>
        )}
      </div>
    </AuthorizedLayout>
  );
};

export default Icalendar;
