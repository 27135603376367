import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import * as yup from "yup";

import "./CompleteCleaning.css";
import { ReactSVG } from "react-svg";

import iconSet from "../../../Assets/Icon (1).svg";
import iconFInish from "../../../Assets/Vector (9).svg";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import api from "../../../Service/api";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const CompleteCleaning = ({ data, updateItens, closeModal, Put }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [currentScreen, setCurrentScreen] = useState(1);

  const handleNext = () => {
    if (currentScreen < 3) {
      setCurrentScreen(currentScreen + 1);
    }
  };

  const handlePrev = () => {
    if (currentScreen > 1) {
      setCurrentScreen(currentScreen - 1);
    }
  };

  // console.log(data);

  const handleItens = async (values) => {
    setLoading(true);

    // console.log({consumables: values.consumablesAttributes,});
    try {
      await api.patch(`/admins/schedules/${data.id}/create_review`, {
        data: {
          attributes: {
            cleaner_review_attributes: {
              // condition: values.condition,
              // smell: values.smell,
              // guest: values.guest,
              cleanPlacePhotos:
                values.cleanPlacePhotos.map((item) => ({
                  data: item.base64,
                })) || [],
              brokenObjects: values.objectPhotos.length > 0 ? true : false,
              objectPhotos:
                values.objectPhotos.map((item) => ({ data: item.base64 })) ||
                [],
              incident_description: values.incident_description,
              deepCleaning: values.deepCleaning,
              deepCleaningDescription: values.deepCleaningDescription,
              deepCleanPhotos: [],
            },
            client_review_attributes: {
              schedule_id: Number(values.schedule_id),
              // nps: Number(values.nps),
              description: values.description,
              compliments: values.compliments.map((item) => item) || [],
              went_wrong: values.went_wrong.map((item) => item) || [],
            },
          },
          consumables: values.consumablesAttributes.map((item) => item.description),
        },
      });

      handleClose();
      closeModal();
      updateItens(true);
      Put()
      setLoading(false);
      alert("Schedule and review successfully saved");
    } catch (err) {
      // alert(
      //   "Error: Unable to complete cleanup. Please check the information provided and try again."
      // );
      Put()
      console.log(err);
      setLoading(false);
    }
  };

  const form = useFormik({
    initialValues: {
      condition: true,
      smell: true,
      guest: true,
      cleanPlacePhotos: [],
      brokenObjects: false,
      objectPhotos: [],
      incident_description: "",
      deepCleaning: false,
      deepCleaningDescription: "",
      clean: [],
      schedule_id: data.id || "",
      nps: 0,
      description: "asdjasjdasjd",
      compliments: [],
      went_wrong: [],
      consumablesAttributes: [],
    },
    // validationSchema: yup.object({
    //   postTitle: yup
    //     .string()
    //     .min(2, "Post Title must have at least 2 characters.")
    //     .max(50, "Post Title must have at most 50 characters.")
    //     .required("Post Title is required!"),
    // }),
    onSubmit: (values) => handleItens(values),
  });

  let currentScreenComponent;

  if (currentScreen === 1) {
    currentScreenComponent = <Step1 form={form} />;
  } else if (currentScreen === 2) {
    currentScreenComponent = <Step2 form={form} />;
  } 
  else if (currentScreen === 3) {
    currentScreenComponent = <Step3 form={form} />;
  }

  return (
    <div className="containerModalScree">
      <button onClick={handleOpen} className="completeButtonModal">
        Complete Cleaning
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className="containerModalcomplete">
          <div className="headerComplete">
            {currentScreen === 3 ? (
              <div className="title">Check inventory</div>
            ) : (
              <>
                <div className="title">Cleaning Review</div>
                <div className="subtitle">
                  Complete the cleaning review in order to end cleaning.
                </div>
              </>
            )}
          </div>

          {currentScreenComponent}

          <div className="containerButtonsComlete">
            {currentScreen !== 1 ? (
              <button
                className="buttonCOmplete colorDisableButton"
                onClick={handlePrev}
                disabled={currentScreen === 1}
              >
                <ReactSVG
                  src={iconSet}
                  style={{ transform: `rotate(180deg)` }}
                />
              </button>
            ) : (
              <div></div>
            )}
            {currentScreen !== 3 ? (
              <button
                className="buttonCOmplete colorSetComplete"
                onClick={handleNext}
              >
                <ReactSVG src={iconSet} />
              </button>
            ) : (
              <button
                className="buttonCOmplete colorFinishComplete"
                onClick={form.submitForm}
              >
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress size={24} />{" "}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ReactSVG src={iconFInish} />
                  </div>
                )}
              </button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CompleteCleaning;
