import React from "react";
import { Modal, makeStyles } from "@material-ui/core";
import { Font } from "@react-pdf/renderer";
import logo from "../../../../Assets/Sett Logo (2).png";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";

Font.register({
  family: "Helvetica-Bold",
  src: "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.2.228/pdf.viewer.css",
  fontWeight: "bold",
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    padding: 20,
    position: "relative",
  },
  header: {
    fontSize: 12,
    marginBottom: 30,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
    textTransform: "uppercase",
    borderBottom: 1,
    borderColor: "#0E1F41",
  },
  text: {
    fontSize: 8,
    marginBottom: 8,
    fontWeight: "bold",
    textAlign: "justify",
    fontFamily: "Helvetica-Bold",
  },
  text2: {
    fontSize: 8,
    marginBottom: 30,
    fontWeight: "bold",
    textAlign: "justify",
    fontFamily: "Helvetica-Bold",
  },
  // container: {
  //   flexWrap: "wrap",
  //   justifyContent: "space-between",
  // },
  section: {
    width: "100%",
    marginBottom: 18,
  },
  label: {
    marginBottom: 5,
    fontWeight: "bold",
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
  },
  inputBox: {
    border: 1,
    borderColor: "#000",
    padding: 10,
    width: "100%",
  },
  inputText: {
    fontSize: 10,
  },
  logoContainer: {
    position: "absolute",
    bottom: 20,
    right: 20,
    width: 100,
  },
  logoImage: {
    width: "100%",
    height: "100%",
  },
  divider: {
    marginBottom: 20,
    textAlign: "justify",
    fontSize: 12,
  },
  bold: {
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    borderBottom: 1,
    borderColor: "#0E1F41",
  },
  container: {
    flex: 1,
    marginHorizontal: 20,
    marginVertical: 30,
  },
  background: {
    backgroundColor: "#fff",
    flex: 1,
  },
  title: {
    textAlign: "center",
    fontSize: 17,
    marginBottom: 0,
    fontStyle: "italic",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
  },
  subtitle: {
    textAlign: "center",
    fontSize: 17,
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    borderBottom: 1,
    borderColor: "#0E1F41",
    fontStyle: "italic",
  },
});

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  modalContent: {
    padding: theme.spacing(3),
    width: "700px",
    textAlign: "left",
    position: "relative",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#fff",
    overflowY: "auto",
    maxHeight: "80vh",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: "#000",
  },
  formHeader: {
    marginBottom: theme.spacing(2),
    borderBottom: "2px solid #000",
    fontWeight: "bold",
  },
  formSection: {
    marginBottom: theme.spacing(2),
  },
  formField: {
    marginBottom: theme.spacing(1),
    backgroundColor: "#f5f5f5",
    border: "1px solid #ccc",
    padding: theme.spacing(1),
    borderRadius: "4px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
  watermark: {
    position: "absolute",
    opacity: 0.5,
    fontSize: "3em",
    transform: "rotate(-45deg)",
    pointerEvents: "none",
    zIndex: -1,
    top: "50%",
    left: "50%",
    color: "#e1e1e1",
  },
}));

const Argreement = ({ data, open, onClose }) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose} className={classes.modalContainer}>
      <div>
        <PDFViewer width="800px" height="800px">
          <Document>
            <Page size="A4" style={styles.page}>
              <Text style={styles.title}>Sett Solutions Corp</Text>
              <Text style={styles.subtitle}>CONTRACTOR AGREEMENT</Text>
              <View style={styles.container}>
                <Text style={styles.divider}>
                  This Contractor Agreement (this “Agreement”) sets forth the
                  terms and conditions at the day of its acknowledge and
                  acceptance through our online platforms whereby and between
                  Sett Solutions Corp, an online platform with legal address at
                  9927 Abe Lincoln Rd, San Antonio, TX, USA 78240, and the
                  person who accepted as an independent service provider fully
                  licensed (to the extent required by applicable law) and
                  qualified to provide the services contemplated by this
                  Agreement, fully described at Attachment A, from time to time,
                  to be referred to you via the webbased platform managed by
                  Sett Solutions Corp, a S Corporation that works as an Online
                  Agency to refer qualified staffs to certain services
                  (Attachment A).
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>1. POURPOSE</Text>
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>1.1 </Text>The Service Professional
                  (contractor) shall be eligible to book Jobs through Sett
                  Solutions Platform or other platform managed by Sett
                  Solutions, accepting any Services that he or she is fully
                  licensed (to the extent required by applicable law) and
                  qualified to provide as specified on the option at
                  www.sett.solutions and fully described at Attachment A, as
                  selected by the Service Professional during the sign-up
                  process. In those jurisdictions where a license, permit, or
                  certification is required to perform the Services, the Service
                  Professional shall, upon request, provide proof to Sett
                  Solutions Platform of all necessary licenses, permits or
                  certifications before the Service Professional provides any
                  such Services under this Agreement.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>1.2 </Text>Sett Solutions is the
                  creator of technology platforms that provide a place to
                  connect clients (Service Requesters) with fully licensed (to
                  the extent required by applicable law) and qualified
                  professionals, such as Service Professional, in the business
                  of providing cleaning and manage cleaning services (Services).
                  Sett provides a web-based and mobile application-based portal
                  (the Sett Solutions Platform) through which clients may book
                  services opportunities to Service Professionals whom may
                  choose performed in partnership with Sett. Sett Solutions and
                  Service Professional intend that he or she will provide the
                  services strictly as an independent contractor Service
                  Professional, and not as an employee, worker, agent, joint
                  venture, partner or franchisee of Sett Solutions or any
                  costumer for any purpose.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>1.3 </Text>Beginning on date of the
                  acceptance of the terms of this Agreement, the Contractor will
                  provide cleaning and housekeeping services (collectively, the
                  "Services"). The Service Professional agree in sign up a W-9
                  in order to receive a 1099 for his own IRS obligations.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>1.4 </Text>Furthermore, the
                  Contractor has the right of control over how the Contractor
                  will perform the services. Sett Solutions does not have this
                  right of control over how the Contractor will perform the
                  services but can request the contractor to follow the
                  standards and guidelines to guarantee a minimum quality of
                  acceptance of the job requested by client.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>
                    2. PAYMENT AND FEE FOR SERVICES.
                  </Text>
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>2.1 </Text>The Client will pay
                  compensation to the Contractor for the Services. Payments will
                  be made as follows:
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>a) </Text>Sett Solutions will submit
                  payments for services provided on a weekly basis, every
                  Monday, according to the prices previously agreed while
                  accepting a certain job on Sett Cleaner Platform, and if the
                  Recipient did not receive precedents and trustful complains by
                  the Sett Solutions Clients. If the Sett Solutions complaints
                  be proved true, the contractor must commit to re-do the
                  service or refund the money for it.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>b) </Text>Sett Solutions’ clients
                  shall pay for a complete Job through Sett Solutions Platform
                  at the rates quoted by Sett Solutions at the time the Job is
                  posted on Sett Solutions Platform, which must be based on the
                  stated parameters of the Job (the Job Rate).
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>c) </Text>No other fees, expenses or
                  both will be paid to the Contractor, unless such fees or
                  expenses have been approved in advance by the appropriate
                  executive on behalf of Sett Solutions in writing. The
                  Contractor shall be solely responsible for any and all taxes,
                  Social Security contributions or payments, disability
                  insurance, unemployment taxes, and other payroll type taxes
                  applicable to such compensation. The Contractor has the right
                  of control over the method of payment for services.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>3. TERM/TERMINATION. </Text>
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>3.1 </Text>The parties acknowledge
                  that the term of this Agreement does not reflect an
                  uninterrupted service arrangement, as this Agreement
                  guarantees Service Professional the right to choose when to
                  make him or herself available, and each Job opportunity and
                  accepted one is treated as a separate service arrangement.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>3.2 </Text>The Client will pay
                  compensation to the Contractor for the Services. Payments will
                  be made as follows:
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>a) </Text>the acts or omissions
                  expressly defined as constituting a material breach herein by
                  Sett Solutions in misconduct or failure to timely remit
                  Service Fees;
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>b) </Text>as described herein by
                  Service Professional's repeated failure to complete a Job that
                  he or she has accepted on the platform in terms of quality
                  satisfaction; or,
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>c) </Text>within twenty-eight (28)
                  day period, if a Service Professional cancels or reschedules
                  three (3) or more jobs he or she has accepted with less than 6
                  hours' notice prior to the applicable Job start,
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>d) </Text>the Service Professional
                  failure in showing up to a previously scheduled job with any
                  notice;
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>e) </Text>the Service Professional
                  try, for any methods, reach one of the Sett Solutions
                  currently clients, adding to this breach a penalty of
                  $5,000.00 per costumer this Service Professional tries to get
                  for her or himself.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>3.3 </Text>At the event there is a
                  dispute whether Sett Solutions or the Service Professional
                  materially breached the agreement, and it cannot be resolved
                  by informal negotiations, the parties agree to submit any such
                  dispute to final and binding arbitration, unless the Service
                  Professional exercises his or her right to opt out of
                  arbitration.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>3.4 </Text>In addition to the
                  foregoing, the Service Professional may terminate the
                  Agreement for any reason upon fifteen (15) days' written
                  notice. If the Service Professional terminates this Agreement
                  for convenience without first providing the proper notice,
                  than the Service Provider shall pay to Sett Solutions an
                  amount equal to Three Thousand And Five Hundred U.S. Dollars
                  (U.S.$3,500.00) (the “Liquidate Damages”). The parties intend
                  that the Liquidated Damages constitute compensation, and not a
                  penalty. The parties acknowledge and agree that Sett’s harm
                  caused by Service Professional breach of Section 3.4 would be
                  impossible or very difficult to accurately estimate at the
                  time of contract, and that the Liquidated Damages are a
                  reasonable estimate of the anticipated or actual harm that
                  might arise from Service Professional breach of Section 3.4
                  Service Professional payment of the Liquidated Damages is
                  Service Professional sole liability and entire obligation and
                  Sett’s exclusive remedy for any Supplier breach of Section
                  3.4. Any Liquidated Damages must be paid to Sett within thirty
                  (30) days of effective termination date.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>3.5 </Text>A regular, ongoing
                  relationship of indefinite term is not contemplated. The
                  Recipient has no right to assign services to the Contractor
                  other than as specifically contemplated by this Agreement.
                  However, the parties may mutually agree that the Contractor
                  shall perform other services for the Recipient, pursuant to
                  the terms of this Agreement.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>3.6 </Text>Upon termination of this
                  Agreement for any reason, the Service Professional shall:
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>a) </Text>complete any outstanding
                  Jobs the Service Professional has booked (the Outstanding
                  Jobs) and;
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>b) </Text>certify in writing to Sett
                  Solutions the Service Professional has complied with the
                  requirements of this Section.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>4. RELATIONSHIP OF PARTIES.</Text>
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>4.1 </Text>It is understood by the
                  parties that the Service Professional is an independent
                  contractor with respect to the Sett Solutions, and not an
                  employee of the Sett Solutions.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>4.2 </Text>As a contractor, the
                  Service Professional is not eligible to participate in any
                  benefit plans, policies, or programs established or
                  administered by Sett Solutions, including among other things,
                  vacation, holiday pay, health or life insurance, or profit
                  sharing. For that, Sett Solutions will not provide fringe
                  benefits for the benefit of the Contractor.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>4.3 </Text>The Professional Service
                  waive any right or claim to participate in or receive benefits
                  from Sett Solutions for any time period during which the
                  Service Professional is assigned to this Agreement as a
                  Contractor.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>4.4 </Text>The Service Professional
                  understands that any issues, concerns, or grievances relating
                  to his/her assignment with Sett Solutions should be addressed
                  to him/herself.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>4.5 </Text>It is contemplated that
                  the relationship between the Contractor and Sett Solutions
                  shall be a non-exclusive one. The Contractor also performs
                  services for other organizations, individuals or both. Sett
                  Solutions has no right to further inquire into the
                  Contractor's other activities.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>5. RECIPIENT'S CONTROL. </Text>
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>5.1 </Text>Sett Solutions has no
                  right or power to control or otherwise interfere with the
                  Contractor's mode of effecting performance under this
                  Agreement. Sett Solutions’ only concern is the result of the
                  Service Professional's work, and not the means of
                  accomplishing it. That means the Service Professional must
                  guarantee the standard of service and guidelines provided by
                  Sett Solutions and its clients, what it is extremely important
                  to the hospitality service. For that, Sett Solutions, in
                  extraordinary circumstances and when necessary to achieve the
                  results expected from the service hired and provided, the
                  Service Professional shall perform the Services with direct
                  supervision by Sett Solution Representative.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>6. PROFESSIONAL CAPACITY. </Text>
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>6.1 </Text>The Service Professional
                  is a professional who uses his or her own professional and
                  business methods to perform services. The Contractor has no
                  obligation to complete any training offered by the recipient
                  regarding how to perform the standards of the services, but by
                  his optional acceptance the service professional can be part
                  of the trainings offered by Sett with the interesting of
                  acquire more capability, professionalization, and background
                  to be able to perform more jobs.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>7. FORCE MAJEURE </Text>
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>7.1 </Text>If performance of this
                  Contract or any obligation under this Contract is prevented,
                  restricted, or interfered with by causes beyond either party's
                  reasonable control ("Force Majeure"), and if the party unable
                  to carry out its obligations gives the other party prompt
                  written notice of such event, then the obligations of the
                  party invoking this provision shall be suspended to the extent
                  necessary by such event. The term Force Majeure shall include,
                  without limitation, acts of God, plague, epidemic, pandemic,
                  outbreaks of infectious disease or any other public health
                  crisis, including quarantine or other employee restrictions,
                  fire, explosion, vandalism, storm or other similar occurrence,
                  orders or acts of military or civil authority, or by national
                  emergencies, insurrections, riots, or wars, or strikes,
                  lock-outs, work stoppages or other labor disputes, or supplier
                  failures. The excused party shall use reasonable efforts under
                  the circumstances to avoid or remove such causes of
                  non-performance and shall proceed to perform with reasonable
                  dispatch whenever such causes are removed or ceased. An act or
                  omission shall be deemed within the reasonable control of a
                  party if committed, omitted, or caused by such party, or its
                  employees, officers, agents, or affiliates. Contractor consent
                  that any incident, Sett Solutions Corp will not be responsible
                  and will not take any finance, health or any other
                  responsibility kind.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>
                    8. PERSONAL SERVICES NOT REQUIRED.{" "}
                  </Text>
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>8.1 </Text>The Contractor is not
                  required to render the Services personally and may employ
                  others to perform the Services on behalf of Sett Solutions
                  without its knowledge or consent. If the Contractor has
                  assistants, it is the Contractor's responsibility to hire them
                  and to provide materials for them, also guarantee the job will
                  be executed according Sett Solutions expectation.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>8.2 </Text>In addition to any and
                  all other rights a party may have available according to law,
                  if a party defaults by failing to substantially perform any
                  provision, term or condition of this Contract, the other party
                  may terminate the Contract by providing written notice to the
                  defaulting party. Any error made by a Third Party will be
                  considered as a Contractor failure and penalties will be
                  applied to You (The Contractor).
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>9. NO LOCATION ON PREMISES. </Text>
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>9.1 </Text>The Contractor has no
                  desk or other equipment either located at or furnished by the
                  Recipient. Except for the extent that the Contractor works in
                  a territory as defined by Sett Solutions, his or her services
                  are not integrated into the mainstream of Sett Solution's
                  business.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>10. OTHER BUSINESS ACTIVITIES</Text>
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>10.1 </Text>Service Professional may
                  be engaged or employed in any other business, trade,
                  profession, or other activities, even providing services to
                  customers booked through means other than Sett Solutions
                  Platform, including other web-based portals.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>10.2 </Text>However, Service
                  Professional agrees that any client managed through Sett
                  Solutions Platform is a private Sett Solutions’ client, and
                  Service Professionals must not affirmatively solicit or accept
                  clients originally listed through Sett Solutions Platform to
                  book jobs through any means other than Sett Solutions Platform
                  while he or she performs service in partnership with Sett
                  Solutions, or during the next 3 years after terminating this
                  contract agreement by any part. Provided, however, Sett
                  Solutions has the right to legally charge Service Professional
                  a referral fee of Five Thousand U.S. Dollars ($5,000 USD) per
                  client.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>11. NO SET WORK HOURS.</Text>
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>11.1 </Text>The Service Professional
                  may perform each job in the time previous accorded and
                  accepted previously on the apps/platform. The Contractor has
                  no set hours of work. There is not any requirement about the
                  Contractor working full time or otherwise account for work
                  hours.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>
                    12. EXPENSES PAID BY CONTRACTOR.{" "}
                  </Text>
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>12.1 </Text>The Contractor's
                  business and travel expenses are to be paid by the Contractor
                  and not by Sett Solutions.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>13. CONFIDENTIALITY.</Text>
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>13.1 </Text>In connection with this
                  Agreement, Sett Solutions may disclose to Service Professional
                  certain technical and business information (including but not
                  limited to information on Sett Solutions’ internal processes,
                  systems, other vendors, rates or billing information,
                  logistical operations, any proprietary technology, clients
                  data), whether in written, graphic, machine readable or other
                  tangible form, or disclosed orally, that Sett Solutions
                  desires Service Professional to treat as confidential
                  (“Confidential Information”). Confidential Information
                  includes any information that is marked “Confidential,”
                  “Proprietary” or in some other manner to indicate its
                  confidential nature, and includes any other information that a
                  reasonable personal would understand to be confidential under
                  the circumstances of disclosure and the nature of the
                  information itself.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>13.2 </Text>Contractor may have had
                  access to proprietary, private or otherwise confidential
                  information ("Confidential Information") of Sett’s clients,
                  such as address, codes/key, name and other, also from Sett.
                  Confidential Information stands for all non-public information
                  which constitutes, relates, or refers to the operation of the
                  business, including without limitation, all financial,
                  investment, operational, personnel, sales, marketing,
                  managerial and statistical information, and any and all trade
                  secrets, customer lists, or pricing information. The nature of
                  the information and the manner of disclosure are such that a
                  reasonable person would understand it to be confidential. The
                  Contractor will not, at any time or in any manner, either
                  directly or indirectly, use for the personal benefit of the
                  Contractor, or divulge, disclose, or communicate in any manner
                  any Confidential Information. The Contractor will protect such
                  information and treat it as strictly confidential. This
                  provision must continue to be effective after the termination
                  of this Agreement.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>13.3 </Text>Supplier will treat all
                  Confidential Information provided to it by Sett Solutions, as
                  strictly confidential and will safeguard the Confidential
                  Information from unauthorized use, access, or disclosure using
                  at least the same degree of care that it uses to protect its
                  own most valuable confidential and proprietary information,
                  and no less than reasonable care. Service Professional may use
                  Confidential Information only for the purpose of providing the
                  Services.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>13.4 </Text>Service Professional
                  will not disclose or permit access to Confidential Information
                  except to its employees and representatives who have a need to
                  know the Confidential Information in order to provide the
                  Services and who are bound by obligations of confidentiality
                  no less restrictive than those contained herein. Service
                  Professional will be strictly liable for any disclosure or
                  unauthorized use of Confidential Information by any person to
                  whom it discloses the Confidential Information.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>13.5 </Text>Confidential Information
                  does not include information that (i) is publicly known or
                  generally available prior to disclosure by Sett Solutions, or
                  becomes publicly known or generally available after disclosure
                  through no action or inaction of Service Professional; (ii) is
                  in the rightful possession of Service Professional without
                  duty of confidentiality at the time of disclosure by Sett
                  Solutions; (iii) is independently developed by Service
                  Professional without use of or reference to the Confidential
                  Information. If Service Professional is required by law, rule
                  or regulation, or court order to disclose any Confidential
                  Information, it will, where permitted by law, promptly notify
                  Sett Solutions in writing prior to making any disclosure and
                  will reasonably cooperate with any effort by Sett Solutions to
                  obtain a protective order or other remedy.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>13.6 </Text>At Sett Solutions’
                  request, Service Professional will promptly return or destroy
                  all Confidential Information in its possession or control and
                  certify the same to Sett Solutions.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>13.7 </Text>Service Professional
                  agrees that any violation or threatened violation of these
                  confidentiality provisions will cause irreparable injury to
                  Sett Solutions, entitling Sett Solutions to obtain injunctive
                  relief in addition to all legal remedies without showing or
                  proving any actual damage and without any bond being required
                  to be posted.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>13.8 </Text>This Agreement is in
                  compliance with the Defend Trade Secrets Act and provides
                  civil or criminal immunity to any individual for the
                  disclosure of trade secrets: (i) made in confidence to a
                  federal, state, or local government official, or to an
                  attorney when the disclosure is to report suspected violations
                  of the law; or (ii) in a complaint or other document filed in
                  a lawsuit if made under seal.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>13.9 </Text>The obligations of
                  Service Professional under this Agreement shall survive, with
                  respect to any particular Confidential Information, until
                  three (3) years from the termination of this Agreement.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>14. INJURIES.</Text>
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>14.1 </Text>The Service Professional
                  acknowledges the Contractor's obligation to obtain appropriate
                  insurance coverage for the benefit of the Contractor (and the
                  Contractor's employees, if any). The Contractor waives any
                  rights to recovery from the Recipient for any injuries that
                  the Contractor (or Contractor's employees, or both) may
                  sustain while performing services under this Agreement and
                  that are the result of negligence from the Contractor or the
                  Contractor's employees.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>15. INDEMNIFICATION.</Text>
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>15.1 </Text>The Contractor agrees to
                  indemnify and hold harmless to Sett Solutions from all claims,
                  losses, expenses, and fees including attorney fees, costs, and
                  judgments that may be asserted against the Recipient that
                  result from the acts or omissions of the Contractor, the
                  Contractor's employees, if any, and the Contractor's agents
                  and perform the service as expected by Sett Solutions.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>15.2 </Text>Indemnification will be
                  applied to the Contractor even if the failure become form a
                  third party that the Contractor hired.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>
                    16. NO RIGHT TO ACT AS AGENT.{" "}
                  </Text>
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>16.1 </Text>An "employer-employee"
                  or "principal-agent" relationship is not created merely
                  because (1) Sett Solutions has or retains the right to
                  supervise or inspect the work as it progresses in order to
                  ensure compliance with the terms of the contract or (2) Sett
                  Solutions has or retains the right to stop the work done
                  improperly. The Contractor has no right to act as an agent for
                  Sett Solutions and has an obligation to notify any involved
                  parties that it is not a Sett Solutions’ agent.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>17. ENTIRE AGREEMENT.</Text>
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>17.1 </Text>This Agreement
                  constitutes the entire contract between the parties. All terms
                  and conditions contained in any other writings previously
                  executed by the parties regarding the matters contemplated
                  herein shall be deemed to be merged herein and superseded
                  hereby. No modification of this Agreement must be deemed
                  effective unless in writing and signed by the parties hereto.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>18. WAIVER OF BREACH.</Text>
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>18.1 </Text>The waiver by Sett
                  Solutions of a breach of any provision of this Agreement by
                  Contractor shall not operate or be construed as a waiver of
                  any subsequent breach by Contractor.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>19. SEVERABILITY.</Text>
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>19.1 </Text>If any provision of this
                  Agreement shall be held to be invalid or unenforceable for any
                  reason, the remaining provisions shall continue to be valid
                  and enforceable. If a court finds that any provision of this
                  Agreement is invalid or unenforceable, but that by limiting
                  such provision it would become valid and enforceable, then
                  such provision shall be deemed to be written, construed, and
                  enforced as so limited.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>20. APPLICABLE LAW. </Text>
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>20.1 </Text>This Agreement shall be
                  governed by the laws of the State of Texas without regard to
                  the principles of conflict of Law.
                </Text>

                <Text style={styles.divider}>
                  <Text style={styles.bold}>
                    IN WITNESS HEREOF, the parties have executed and entered
                    into this Agreement as of the Effective Date first written
                    above. BY CHECKING THE BOX STATING I AGREE TO THE SETT’S
                    AGENCY ARGREEMENT, YOU HEREBY AGREE TO THIS INDEPENDENT
                    SERVICE PROVIDER AGREEMENT.
                  </Text>
                </Text>

                <Text style={styles.divider}>
                  You understand that your electronic acceptance is as legally
                  binding as a handwritten signature.
                </Text>

                <View
                  style={{
                    ...styles.container,
                    flexDirection: "row",
                    justifyContent: 'space-between',
                  }}
                >
                  <View style={{ ...styles.section, width: "48%" }}>
                    <Text style={styles.label}>Signature:</Text>
                    <View style={styles.inputBox}>
                      <Text
                        style={styles.inputText}
                      >{`${data.firstName} ${data.lastName}`}</Text>
                    </View>
                  </View>

                  <View style={{ ...styles.section, width: "48%" }}>
                    <Text style={styles.label}>Date of Acceptance:</Text>
                    <View style={styles.inputBox}>
                      <Text style={styles.inputText}>
                        {moment(data.createdAt).utc().format("MMM. DD, YYYY")}
                      </Text>
                    </View>
                  </View>
                </View>

                <View style={styles.logoContainer}>
                  <Image src={logo} style={styles.logoImage} />
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </div>
    </Modal>
  );
};

export default Argreement;
