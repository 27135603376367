import React, { useState, useContext } from "react";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import { Input } from "../../../Components/Input";
import { InputsContainer } from "../../../Components/InputsContainer";
import { TextField } from "@material-ui/core";
import api from "../../../Service/api";
import upPhotoIcon from "../../../Assets/Union (3).svg";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "./ModalCard.css";
import { ReactSVG } from "react-svg";
import AppContext from "../../../Context/AppContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const renderCustomIcon = (props) => {
  return (
    <ExpandMoreIcon {...props} style={{ color: "#FAFAFA", fontSize: "40px" }} />
  );
};

const userLevels = {
  master: 0,
  manager: 1,
  supervisor: 2,
  director: 3,
  officer: 4,
};

const ModalCard = ({
  data,
  title,
  button,
  color,
  padding,
  update,
  setLoading,
  getUsers
}) => {
  const { listRegions, user } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [editPassword, setEditPassword] = useState(false);

  const selectedsAPi = data?.adminRegions.map((item) => item.toString()) || [];

  const [selectedRegions, setSelectedRegions] = useState(selectedsAPi);

  const handleChangeRegion = (event) => {
    const selectedValues = event.target.value;
    if(selectedValues.includes('All')) {
      setSelectedRegions([]);
      setSelectedRegions(listRegions.map((item) => item.id))
    } else {
      setSelectedRegions(selectedValues);
    }
  };

  const handleItens = async (values) => {
    setLoadingButton(true);

    try {
      const attributes =
        values.profilePhoto !== ""
          ? {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              adminType: values.adminType,
              avatar: { data: values.profilePhoto },
              region_ids: selectedRegions,
            }
          : {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              adminType: values.adminType,
              region_ids: selectedRegions,
            };

      if (values.id === "") {
        const formValues = {
          data: { attributes },
        };

        await api.post(`admins/admin_controll/`, formValues);

        setLoadingButton(false);
        handleClose();
        setLoading(true);
        getUsers();
      } else {
        const formValues = editPassword
          ? values.profilePhoto === ""
            ? {
                data: {
                  attributes: {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    adminType: values.adminType,
                    password: values.password,
                    region_ids: selectedRegions,
                  },
                },
              }
            : {
                data: {
                  attributes: {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    adminType: values.adminType,
                    password: values.password,
                    avatar: { data: values.profilePhoto },
                    region_ids: selectedRegions,
                  },
                },
              }
          : values.profilePhoto === ""
          ? {
              data: {
                attributes: {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  adminType: values.adminType,
                  region_ids: selectedRegions,
                },
              },
            }
          : {
              data: {
                attributes: {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  adminType: values.adminType,
                  avatar: { data: values.profilePhoto },
                  region_ids: selectedRegions,
                },
              },
            };
        console.log(formValues);
        await api.put(`admins/admin_controll/${values.id}`, formValues);
        setLoadingButton(false);
        handleClose();
        setLoading(true);
        getUsers();
      }
    } catch (error) {
      console.log(error);
      setLoadingButton(false);
    }
  };

  const newAdminForm = useFormik({
    initialValues: {
      id: data?.id || "",
      firstName: data?.firstName || "",
      lastName: data?.lastName || "",
      email: data?.email || "",
      password: "",
      confirmPassword: "",
      adminType: data?.adminType || "manager",
      profilePhoto: "",
    },
    validationSchema: yup.object({
      firstName: yup
        .string()
        .min(2, "First name must have at least 2 characters.")
        .max(50, "First name must have at most 50 characters.")
        .required("First name is required!"),
      lastName: yup
        .string()
        .min(2, "Last name must have at least 2 characters.")
        .max(50, "Last name must have at most 50 characters.")
        .required("Last name is required!"),
      email: yup
        .string()
        .email("Invalid email format.")
        .required("Email is required!"),
      password: yup
        .string()
        .min(8, "Password must have at least 8 characters."),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password")], "Passwords must match."),
    }),
    onSubmit: (values) => handleItens(values),
  });

  function convertImageToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  }

  function handleFileChange(event) {
    const file = event.target.files[0];

    convertImageToBase64(file)
      .then((base64String) => {
        newAdminForm.setFieldValue("profilePhoto", base64String);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const capitalize = (str) => {
    if (typeof str !== "string") {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.substr(1);
  };

  const handleChangeType = (event) => {
    newAdminForm.setFieldValue("adminType", event.target.value);
  };

  const AdminTypeDropdown = (currentUserLevel, handleChangeType) => {
    const adminOptions = [
      { value: "master", label: "Master" },
      { value: "manager", label: "Manager" },
      { value: "supervisor", label: "Supervisor" },
      { value: "director", label: "Director" },
      { value: "officer", label: "Officer" },
    ];

    const filteredAdminOptions = adminOptions.filter(
      (option) => userLevels[option.value] >= userLevels[currentUserLevel]
    );

    return (
      <TextField
        name="Type of admin"
        select
        value={newAdminForm.values.adminType}
        onChange={handleChangeType}
        style={{ borderBottom: "none", width: "100%" }}
      >
        {filteredAdminOptions.map((option) => (
          <option
            key={option.value}
            value={option.value}
            style={{ cursor: "pointer" }}
          >
            {option.label}
          </option>
        ))}
      </TextField>
    );
  };

  return (
    <div>
      <button
        className="openModalAdminCOntrol"
        onClick={handleOpen}
        style={{ backgroundColor: color && color, padding: padding && padding }}
      >
        {button}
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className="containerModalAdminControl">
          <div className="titleModalAdmin">{title}</div>
          <div className="lineAdminControl" />
          <InputsContainer>
            <Input
              placeholder="First Name"
              field="firstName"
              form={newAdminForm}
              assistiveText={{ color: "#0E1F41" }}
            />

            <Input
              placeholder="Last Name"
              field="lastName"
              form={newAdminForm}
              assistiveText={{ color: "#0E1F41" }}
            />

            <Input
              placeholder="Email"
              field="email"
              form={newAdminForm}
              keyboardType="email"
              assistiveText={{ color: "#0E1F41" }}
            />

            {update && editPassword && (
              <>
                <Input
                  placeholder="Password"
                  field="password"
                  form={newAdminForm}
                  keyboardType="password"
                  assistiveText={{ color: "#0E1F41" }}
                />

                <Input
                  placeholder="Password Confirmation"
                  field="confirmPassword"
                  form={newAdminForm}
                  keyboardType="password"
                  assistiveText={{ color: "#0E1F41" }}
                />
              </>
            )}

            {update && (
              <div
                className="conatinerSLider"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <div className="textSlider">Change password?</div>
                <label className="switch2">
                  <input
                    type="checkbox"
                    checked={editPassword}
                    onChange={() => setEditPassword(!editPassword)}
                  />
                  <span className="slider"></span>
                </label>
              </div>
            )}
          </InputsContainer>

          <div className="titleInput">User type</div>
          {/* <TextField
            name="Type of admin"
            select
            value={newAdminForm.values.adminType}
            onChange={handleChangeType}
            style={{ borderBottom: "none", width: "100%" }}
          >
            <option value="master" style={{ cursor: "pointer" }}>
              Master
            </option>
            <option value="manager" style={{ cursor: "pointer" }}>
              Manager
            </option>
            <option value="supervisor" style={{ cursor: "pointer" }}>
              Supervisor
            </option>
            <option value="director" style={{ cursor: "pointer" }}>
              Director
            </option>
            <option value="officer" style={{ cursor: "pointer" }}>
              Officer
            </option>
          </TextField> */}

          {AdminTypeDropdown(user.adminType, handleChangeType)}

          <div className="titleInput">Region</div>
          <Select
            multiple
            value={selectedRegions}
            onChange={handleChangeRegion}
            style={{ borderBottom: "none", width: "100%" }}
          >
            <MenuItem value={"All"} key={"All"}>
              All
            </MenuItem>
            {listRegions.map((item) => (
              <MenuItem value={item.id} key={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>

          <div>
            <div className="titleInput">
              Profile Photo{" "}
              <span
                style={{
                  color: "#0E1F41",
                  opacity: "0.3",
                  fontSize: "20px",
                  fontWeight: "400",
                }}
              >
                (Optional)
              </span>
            </div>
            <div
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                marginTop: "5px",
                borderBottom: "1px solid #8b8f93",
                paddingBottom: "6px",
              }}
            >
              <label
                htmlFor="profilePhoto"
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#0E1F41",
                  opacity: "0.3",
                  fontSize: "20px",
                  fontWeight: "400",
                  cursor: "pointer",
                }}
              >
                Upload
                <ReactSVG src={upPhotoIcon} />
              </label>
              <input
                id="profilePhoto"
                name="profilePhoto"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              {newAdminForm.values.profilePhoto !== "" && (
                <>
                  <span style={{ color: "red" }}>Selected photo</span>
                  <button
                    onClick={() =>
                      newAdminForm.setFieldValue("profilePhoto", "")
                    }
                    style={{ color: "red", textAlign: "center" }}
                  >
                    x
                  </button>
                </>
              )}
            </div>
          </div>

          <div className="containerButtonModalAdmin">
            <button className="cancel" onClick={handleClose}>
              Cancel
            </button>
            <button className="create" onClick={newAdminForm.submitForm}>
              {loadingButton ? "Loading" : !update ? "Create" : "Update"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalCard;
