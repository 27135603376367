import React, { useState, useEffect, useContext } from "react";
import { ReactSVG } from "react-svg";
import { AuthorizedLayout } from "../../Components/AuthorizedLayout";
import { TextField } from "@material-ui/core";
import moment from "moment";

import Input from "@mui/material/Input";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import AppContext from "../../Context/AppContext";
import editIcon from "../../Assets/Btn Edit.svg";
import BtnDelete from "../../Assets/Btn Delete.svg";

import "./Coupons.css";
import api from "../../Service/api";
import ModalCard from "./components/ModalCard";
import DeleteCoupon from "./components/DeleteCoupon";

const initialFilterState = {
  couponName: "",
  couponCode: "",
  discount: "",
  amount: "",
  fromDate: "",
  toDate: "",
};

const Coupons = () => {
  const { listRegions } = useContext(AppContext);
  const [filteredData, setFilteredData] = useState([]);
  const [listCoupons, setListCoupons] = useState([]);
  const [filter, setFilter] = useState(initialFilterState);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getUsers = async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/admins/cleaning_coupons");

        const newData = data.map((value) => ({
          id: value.id,
          type: value.type,
          ...value.attributes,
        }));
        setListCoupons(newData);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setListCoupons(false);
      }
    };

    if (loading) {
      getUsers();
    }
  }, [loading]);

  // console.log(listRegions);

  useEffect(() => {
    let filtered = listCoupons?.filter((item) => {
      const name = item?.title ?? "";
      const code = item?.code ?? "";
      const discount = item?.discountPercentage ?? 0;
      const amount = item?.amountLimit ?? 0;

      const itemDate = moment(item.dateLimit).utc();
      const fromDate = moment(filter.fromDate).utc();
      const toDate = moment(filter.toDate).utc();
      const isWithinRange =
        (filter.fromDate === "" || itemDate.isSameOrAfter(fromDate, "day")) &&
        (filter.toDate === "" || itemDate.isSameOrBefore(toDate, "day"));

      return (
        (!filter.couponName ||
          name.toLowerCase().includes(filter.couponName.toLowerCase())) &&
        (!filter.couponCode ||
          code.toLowerCase().includes(filter.couponCode.toLowerCase())) &&
        (!filter.discount || discount >= filter.discount) &&
        (!filter.couponName || amount >= filter.amount) &&
        isWithinRange
      );
    });

    setFilteredData(filtered);
  }, [listCoupons, filter]);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeType = (event) => {
    setFilter((prevState) => ({ ...prevState, type: event.target.value }));
  };

  const handleChangeRegion = (event) => {
    setFilter((prevState) => ({ ...prevState, region: event.target.value }));
  };

  const handleResetFilter = () => {
    setFilter(initialFilterState);
  };

  function formatDate(originalDate) {
    const formattedDate = moment(originalDate)
      .utc()
      .format("MMM. DD, YYYY [at] HH:mm");
    return formattedDate;
  }

  const cancelCleaning = async (id) => {
    // try {
    //   await api.delete(`admins/admin_controll/${id}`);
    //   setLoading(true);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  // console.log(filteredData);

  return (
    <AuthorizedLayout>
      <div className="containerOverview containerAdminControl">
        <div className="containerBox"></div>
        <div className="containerFilter">
          <div className="textTitle">Coupons</div>

          <div className="titleFilter">Coupon Name</div>

          <Input
            placeholder="Coupon Name"
            name="couponName"
            value={filter.couponName}
            onChange={handleFilterChange}
          />

          <div className="titleFilter">Coupon Code</div>

          <Input
            placeholder="Coupon Code"
            name="couponCode"
            value={filter.couponCode}
            onChange={handleFilterChange}
          />

          <div className="titleFilter">Discount</div>

          <Input
            placeholder="Discount"
            name="discount"
            value={filter.discount}
            onChange={handleFilterChange}
          />

          <div className="titleFilter">Amount</div>

          <Input
            placeholder="Amount"
            name="amount"
            value={filter.amount}
            onChange={handleFilterChange}
          />

          <div className="titleFilter">Valid Period</div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "5px",
            }}
          >
            <div className="inputCOntianerMObile">
              <div className="subTitleFilter">From:</div>
              <TextField
                name="fromDate"
                // label="From Date"
                type="date"
                value={filter.fromDate}
                onChange={handleFilterChange}
                className="inputMObile"
              />
            </div>

            <div className="inputCOntianerMObile">
              <div className="subTitleFilter">To:</div>
              <TextField
                name="toDate"
                // label="To Date"
                type="date"
                value={filter.toDate}
                onChange={handleFilterChange}
                className="inputMObile"
              />
            </div>
          </div>

          <button
            className="buttonFilter"
            onClick={handleResetFilter}
            style={{ marginTop: "30px" }}
          >
            Clear Selection
          </button>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div className="containerButtonNewAdmin">
            <ModalCard
              title={"New Coupon"}
              button={<div>New Coupon</div>}
              color="#66CDAA"
              padding="10px"
              setLoading={setLoading}
            />
          </div>
          {filteredData.length > 0 ? (
            <div className="containerCard">
              {filteredData?.map((item, idx) => (
                <div
                  className="containerCardAdmin"
                  key={idx}
                  style={{ height: "auto " }}
                >
                  <div
                    className="containerText"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <div className="title" style={{ fontSize: "32px" }}>
                      {item?.title}
                    </div>
                    <div
                      className="subtitleCoupon"
                      style={{ fontWeight: "700", fontSize: "24px" }}
                    >
                      {`${item?.discountPercentage}% OFF`}
                    </div>
                    <div className="subtitleCoupon">
                      <strong>Expires on:</strong> {formatDate(item?.dateLimit)}
                    </div>
                    <div className="subtitleCoupon">
                      <strong>Amount:</strong> {item?.amountLimit}
                      <strong style={{ marginLeft: '15px'}}>Used:</strong> {item?.timesUsed}
                    </div>
                    <div className="subtitleCoupon">
                      <strong>Code:</strong> {item?.code}
                    </div>
                  </div>
                  <div className="containerButtonUpdateAdmin">
                    {/* <div className="itemUsed">{`${item?.timesUsed}x`}</div> */}
                    <ModalCard
                      data={item}
                      title={"Edit Coupon"}
                      button={<ReactSVG src={editIcon} />}
                      update={true}
                      setLoading={setLoading}
                    />

                    <DeleteCoupon item={item} setLoading={setLoading} />

                    {/* <button
                      className="buttonDeleteAdmin"
                      onClick={() => cancelCleaning(item.id)}
                    >
                      <ReactSVG src={BtnDelete} />
                    </button> */}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "80vh",
                width: "100%",
              }}
            >
              {loading ? (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress size={50} />
                </Box>
              ) : (
                <div className="titleFilter">No matching items found.</div>
              )}
            </div>
          )}
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default Coupons;
