import React, { useState, useEffect, useContext } from "react";
import { AuthorizedLayout } from "../../Components/AuthorizedLayout";

import TablePriceEdit from './components/TablePriceEdit';

import "./PriceTable.css";

const PriceTable = () => {

  return (
    <AuthorizedLayout>
      <div className="containerPriceTable">
        <TablePriceEdit />
      </div>
    </AuthorizedLayout>
  );
};

export default PriceTable;
