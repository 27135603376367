import React, { useEffect, useState } from "react";
import moment from "moment";
import DetailsScheduleCalendar from "../../../../Components/DetailsScheduleCalendar";
import DetailsCleaningModal from "../../../../Components/DetailsCleaningModal";

import "./Calendar.css";

const Calendar = ({ schedules, Put }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState(moment().week());
  const [selectedButton, setSelectedButton] = useState("current");
  const [isLoading, setIsLOading] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 775);
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const prevWeek = () => {
    setSelectedWeek(moment().week() - 1);
    setSelectedButton("prev");
  };

  const nextWeek = () => {
    setSelectedWeek(moment().week() + 1);
    setSelectedButton("next");
  };

  const currentWeek = () => {
    setSelectedWeek(moment().week());
    setSelectedButton("current");
  };

  const startOfWeek = () => {
    return moment().week(selectedWeek).startOf("week");
  };

  const endOfWeek = () => {
    return moment().week(selectedWeek).endOf("week");
  };

  const daysOfWeek = () => {
    const days = [];
    let day = startOfWeek();

    while (day <= endOfWeek()) {
      days.push(day);
      day = day.clone().add(1, "d");
    }

    return days;
  };

  const capitalize = (str) => {
    if (typeof str !== "string") {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.substr(1);
  };

  const compareSchedules = (a, b) => {
    const dateA = a.nextCheckinDate !== null ? moment(a.nextCheckinDate).format("YYYY-MM-DD") : moment(a.date).format("YYYY-MM-DD");
    const dateB = b.nextCheckinDate !== null ? moment(b.nextCheckinDate).format("YYYY-MM-DD") : moment(b.date).format("YYYY-MM-DD");

    const timeA = a.nextCheckinTime !== null ? (a.nextCheckinTime.split('-')[0]).trim() : (a.time.split('-')[0]).trim();
    const timeB = b.nextCheckinTime !== null ? (b.nextCheckinTime.split('-')[0]).trim() : (b.time.split('-')[0]).trim();

    const hasStartedOrScheduledA = ['started', 'scheduled'].includes(a.status);
    const hasStartedOrScheduledB = ['started', 'scheduled'].includes(b.status);

    if (hasStartedOrScheduledA && !hasStartedOrScheduledB) return -1;
    if (!hasStartedOrScheduledA && hasStartedOrScheduledB) return 1;

    if (moment(dateA).isBefore(dateB)) return -1;
    if (moment(dateA).isAfter(dateB)) return 1;
    
    return moment(timeA, 'h:mm A').isBefore(moment(timeB, 'h:mm A')) ? -1 : 1;
  };

  return (
    <div className="containerCalendarDash">
      <div className="containerButtons">
        <button
          onClick={prevWeek}
          className={
            selectedButton === "prev"
              ? "selectedButtonStyle"
              : "notSelectedButtonStyle"
          }
        >
          Previous Week
        </button>
        <button
          onClick={currentWeek}
          className={
            selectedButton === "current"
              ? "selectedButtonStyle"
              : "notSelectedButtonStyle"
          }
        >
          Current Week
        </button>
        <button
          onClick={nextWeek}
          className={
            selectedButton === "next"
              ? "selectedButtonStyle"
              : "notSelectedButtonStyle"
          }
        >
          Next Week
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th>Sunday</th>
            <th>Monday</th>
            <th>Tuesday</th>
            <th>Wednesday</th>
            <th>Thursday</th>
            <th>Friday</th>
            <th>Saturday</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {daysOfWeek().map((day, i) => (
              <td key={i}>
                <div style={{ height: "100%" }}>
                  <div className="currentDay">
                    <div
                      className={
                        moment(day).format("YYYY/MM/DD") ===
                        moment().format("YYYY/MM/DD")
                          ? "textCurrentDay"
                          : "currentDay"
                      }
                    >
                      {moment(day).format("DD")}
                    </div>
                    <div className="selectedButtonStyle" style={{textDecoration: 'none'}}>
                      {isMobile && ` - ${moment(day).format("dddd")}`}
                    </div>
                  </div>
                  <div className="containerCardSchedules">
                    <div style={{ marginTop: "5px" }}>
                      {schedules
                        ?.filter(
                          (date) =>
                            moment(day).format("YYYY/MM/DD") ===
                            moment(date.date).utc().format("YYYY/MM/DD")
                        )
                        .sort(compareSchedules)
                        .map((value, idx) => (
                          <>
                            {value.status === "done" ? (
                              <DetailsCleaningModal
                                item={value}
                                setLoading={setIsLOading}
                                CalendarType={true}
                                Principal={true}
                              />
                            ) : (
                              <DetailsScheduleCalendar
                                data={value}
                                Principal={true}
                                Put={Put}
                              />
                            )}
                          </>
                        ))}
                    </div>
                  </div>
                </div>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Calendar;
