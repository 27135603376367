import React, { useState } from "react";
import Modal from "@mui/material/Modal";

import "./ModalContainer.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

export const ModalContainer = ({
  children,
  button,
  type,
  title,
  customButton,
  newsModal,
  onClickSave,
  setLoadingButton,
  deleteButton,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSave = async () => {
    setLoading(true);
    try {
      await onClickSave();
      handleClose();
      setLoading(false);
      setLoadingButton(true);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="containerModalScree">
      {customButton ? (
        <div
          onClick={handleOpen}
          style={{
            border: "none",
            backgroundColor: "transparent",
            cursor: "pointer",
            width: '100%'
          }}
          className="buttonMObile"
        >
          {customButton}
        </div>
      ) : (
        <button
          className={`openModalAdminCOntrol buttonMObile ${
            type ? "buttonType1" : "buttonType2"
          }`}
          onClick={handleOpen}
        >
          {button}
        </button>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className="containerModalcontainer">
          <div className="title">{title}</div>
          <div className="line" />
          {children}
          {deleteButton ? (
            <div className="containerButtonModal buttonMObile">
              <button className="save" onClick={handleClose}>
                Cancel
              </button>
              <button className={"cancel buttonMObile"} onClick={handleSave}>
                {loading ? "Deleting..." : "Delete"}
              </button>
              {/* {newsModal && <button className={"save"}>Post</button>} */}
            </div>
          ) : (
            <div className="containerButtonModal">
              <button className="cancel buttonMObile" onClick={handleClose}>
                Cancel
              </button>
              <button className={"save buttonMObile"} onClick={handleSave}>
                {loading ? "Loading..." : "Save"}
              </button>
              {/* {newsModal && <button className={"save"}>Post</button>} */}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};
