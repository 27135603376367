import React, { useState, useEffect, useContext } from "react";
import { AuthorizedLayout } from "../../Components/AuthorizedLayout";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import MeuInput from "@mui/material/Input";
import CircularProgress from "@mui/material/CircularProgress";
import AppContext from "../../Context/AppContext";
import { useFormik } from "formik";
import * as yup from "yup";
import { TextField } from "@material-ui/core";
import { Checkbox, FormControlLabel } from "@mui/material";
import { TextareaAutosize, FormHelperText } from "@mui/material";
import { Input } from "../../Components/Input";
import { InputsContainer } from "../../Components/InputsContainer";
import { ModalContainer } from "../../Components/ModalContainer";
import Box from "@mui/material/Box";

import CardNews from "./components/CardNews";

import iconSeta from "../../Assets/Vector 25.png";
import EmailIcon2 from "../../Assets/Component 1.svg";

import "./News.css";
import api from "../../Service/api";
import moment from "moment";

const initialFilterState = {
  title: "",
  type: "",
  fromDate: "",
  toDate: "",
  sortBy: "",
  status: false,
};

const data = [
  {
    title: "title 1",
    subtitle: "subtitle 1",
    date: "10/05/2023",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam massa tellus, semper eu tempor id, feugiat eget lacus. Vivamus ornare, diam ut venenatis vehicula, magna nunc laoreet nunc, non semper lacus tortor eu lectus Etiam massa tellus, semper eu tempor id, feugiat eget lacus. Vivamus ornare...",
  },
  {
    title: "title 2",
    subtitle: "subtitle 2",
    date: "10/05/2023",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam massa tellus, semper eu tempor id, feugiat eget lacus. Vivamus ornare, diam ut venenatis vehicula, magna nunc laoreet nunc, non semper lacus tortor eu lectus Etiam massa tellus, semper eu tempor id, feugiat eget lacus. Vivamus ornare...",
  },
  {
    title: "title 3",
    subtitle: "subtitle 3",
    date: "10/05/2023",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam massa tellus, semper eu tempor id, feugiat eget lacus. Vivamus ornare, diam ut venenatis vehicula, magna nunc laoreet nunc, non semper lacus tortor eu lectus Etiam massa tellus, semper eu tempor id, feugiat eget lacus. Vivamus ornare...",
  },
  {
    title: "title 4",
    subtitle: "subtitle 4",
    date: "10/05/2023",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam massa tellus, semper eu tempor id, feugiat eget lacus. Vivamus ornare, diam ut venenatis vehicula, magna nunc laoreet nunc, non semper lacus tortor eu lectus Etiam massa tellus, semper eu tempor id, feugiat eget lacus. Vivamus ornare...",
  },
  {
    title: "title 5",
    subtitle: "subtitle 5",
    date: "10/05/2023",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam massa tellus, semper eu tempor id, feugiat eget lacus. Vivamus ornare, diam ut venenatis vehicula, magna nunc laoreet nunc, non semper lacus tortor eu lectus Etiam massa tellus, semper eu tempor id, feugiat eget lacus. Vivamus ornare...",
  },
  {
    title: "title 6",
    subtitle: "subtitle 6",
    date: "10/05/2023",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam massa tellus, semper eu tempor id, feugiat eget lacus. Vivamus ornare, diam ut venenatis vehicula, magna nunc laoreet nunc, non semper lacus tortor eu lectus Etiam massa tellus, semper eu tempor id, feugiat eget lacus. Vivamus ornare...",
  },
];

const News = () => {
  let navigate = useNavigate();
  const { listRegions, typeCLeaner, setTypeCleaner } = useContext(AppContext);
  const [filter, setFilter] = useState(initialFilterState);
  const [listNews, setListNews] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [listCoupons, setListCoupons] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getUsers = async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/admins/posts");

        const newData = data.map((value) => ({
          id: value.id,
          type: value.type,
          ...value.attributes,
        }));
        setListNews(newData);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setListNews(false);
      }
    };

    if (loading) {
      getUsers();
    }
  }, [loading]);

  useEffect(() => {
    let filtered = listNews?.filter((item) => {
      const name = item?.title ?? "";

      const itemDate = moment(item.published_at).utc();
      const fromDate = moment(filter.fromDate).utc();
      const toDate = moment(filter.toDate).utc();
      const isWithinRange =
        (filter.fromDate === "" || itemDate.isSameOrAfter(fromDate, "day")) &&
        (filter.toDate === "" || itemDate.isSameOrBefore(toDate, "day"));

      return (
        (!filter.title ||
          name.toLowerCase().includes(filter.title.toLowerCase())) &&
        isWithinRange
      );
    });

    setFilteredData(filtered);
  }, [listCoupons, filter, listNews]);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleStatusChange = () => {
    setFilter((prevState) => ({
      ...prevState,
      status: !filter.status,
    }));
  };

  const handleSortByChange = (event) => {
    setFilter((prevState) => ({ ...prevState, sortBy: event.target.value }));
  };

  const handleResetFilter = () => {
    setFilter(initialFilterState);
  };

  const handleItens = async (values) => {
    const attributes = {
      title: values.postTitle,
      subtitle: values.postSubtitle,
      body: values.body,
    };

    const formValues = {
      data: { attributes },
    };

    try {
      await api.post(`/admins/posts`, formValues);

      setLoading(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const signInForm = useFormik({
    initialValues: {
      id: "",
      postTitle: "",
      postSubtitle: "",
      body: "",
      profilePhoto: "",
    },
    validationSchema: yup.object({
      postTitle: yup
        .string()
        .min(2, "Post Title must have at least 2 characters.")
        .max(50, "Post Title must have at most 50 characters.")
        .required("Post Title is required!"),
      postSubtitle: yup
        .string()
        .min(2, "Post Subtitle must have at least 2 characters.")
        .max(50, "Post Subtitle must have at most 50 characters.")
        .required("Post Subtitle is required!"),
      body: yup
        .string()
        .min(10, "body must have at least 10 characters.")
        .max(500, "body must have at most 500 characters.")
        .required("body is required!"),
    }),
    onSubmit: (values) => handleItens(values),
  });

  function MyFormHelperText({ text }) {
    // const { focused } = useFormControl() || {};

    const helperText = React.useMemo(() => {
      if (text) {
        return `${text}`;
      }

      return ``;
    }, [text]);

    return <FormHelperText>{helperText}</FormHelperText>;
  }

  function convertImageToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  }

  function handleFileChange(event) {
    const file = event.target.files[0];

    convertImageToBase64(file)
      .then((base64String) => {
        signInForm.setFieldValue("profilePhoto", base64String);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <AuthorizedLayout>
      <div className="containerOverview containerAdminControl">
        <div className="containerBox"></div>
        <div className="containerFilter">
          <div className="textTitle">News</div>

          <div className="titleTextEmailfilter">Publication Title</div>

          <MeuInput
            placeholder="Publication title"
            name="title"
            value={filter.title}
            onChange={handleFilterChange}
          />

          <div className="titleFilter">Date</div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "5px",
            }}
          >
            <div>
              <div className="subTitleFilter">From:</div>
              <TextField
                name="fromDate"
                // label="From Date"
                type="date"
                value={filter.fromDate}
                onChange={handleFilterChange}
              />
            </div>

            <div>
              <div className="subTitleFilter">To:</div>
              <TextField
                name="toDate"
                // label="To Date"
                type="date"
                value={filter.toDate}
                onChange={handleFilterChange}
              />
            </div>
          </div>

          <FormControlLabel
            control={
              <Checkbox
                checked={filter.status}
                onChange={handleStatusChange}
                name="canceled"
                color="default"
                style={{ color: "#0E1F41", paddingTop: 0, paddingBottom: 0 }}
              />
            }
            label={
              <span
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "20px",
                  color: "#0E1F41",
                }}
              >
                Favorites Only
              </span>
            }
            style={{ paddingTop: 0, paddingBottom: 0, marginTop: "20px" }}
            align="left"
          />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              marginTop: "10px",
            }}
          >
            <div className="titleFilter" style={{ margin: 0 }}>
              Sort by
            </div>

            <TextField
              name="sortBy"
              // label="Sort By"
              select
              value={filter.sortBy}
              onChange={handleSortByChange}
              style={{ borderBottom: "none" }}
            >
              <option value="">None</option>
              <option value="nameCleaner">Newest</option>
              <option value="nameHost">Host Name</option>
              <option value="dateFrom">Date</option>
            </TextField>
          </div>

          <button
            className="buttonFilter"
            onClick={handleResetFilter}
            style={{ marginTop: "30px" }}
          >
            Clear Selection
          </button>
        </div>

        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <ModalContainer
              button="New Post"
              type={true}
              title="New Post"
              newsModal={true}
              onClickSave={signInForm.submitForm}
              setLoadingButton={setLoading}
            >
              <InputsContainer>
                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      marginTop: "5px",
                      borderBottom: "1px solid #8b8f93",
                      paddingBottom: "6px",
                    }}
                  >
                    <label
                      htmlFor="profilePhoto"
                      className="containerImageNews"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#0E1F41",
                        fontSize: "20px",
                        fontWeight: "400",
                        cursor: "pointer",
                      }}
                    >
                      <div className="titleInput">Add image</div>
                      {signInForm?.values?.profilePhoto === "" ? (
                        <ReactSVG src={EmailIcon2} />
                      ) : (
                        <img
                          src={signInForm?.values?.profilePhoto}
                          alt="img"
                          style={{ width: "163px", height: "96px" }}
                        />
                      )}
                    </label>
                    <input
                      id="profilePhoto"
                      name="profilePhoto"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    {signInForm.values.profilePhoto !== "" && (
                      <>
                        <span style={{ color: "red" }}>Selected photo</span>
                        <button
                          onClick={() =>
                            signInForm.setFieldValue("profilePhoto", "")
                          }
                          style={{ color: "red", textAlign: "center" }}
                        >
                          x
                        </button>
                      </>
                    )}
                  </div>
                </div>
                <Input
                  placeholder="Post Title"
                  field="postTitle"
                  form={signInForm}
                  assistiveText={{ color: "#0E1F41" }}
                />

                <Input
                  placeholder="Post Subtitle"
                  field="postSubtitle"
                  form={signInForm}
                  assistiveText={{ color: "#0E1F41" }}
                />

                <div style={{ width: "100%", resize: "none" }}>
                  <div className="titleInput">Body</div>
                  <TextareaAutosize
                    className="textArea"
                    minRows={10}
                    maxRows={50}
                    placeholder="Body"
                    style={{ width: "100%", resize: "none" }}
                    onChange={signInForm.handleChange("body")}
                  />
                  <MyFormHelperText
                    text={
                      signInForm.errors["body"] && signInForm.errors["body"]
                    }
                  />
                </div>
              </InputsContainer>
            </ModalContainer>
          </div>

          <div>
            {filteredData.length > 0 ? (
              filteredData?.map((item, idx) => (
                <div key={idx} className="containerCardsNews">
                  <CardNews data={item} setLoading={setLoading} />
                </div>
              ))
            ) : loading ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress size={50} />
              </Box>
            ) : (
              <div
                className="titleFilter"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: '70vh'
                }}
              >
                No matching items found.
              </div>
            )}
          </div>
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default News;
