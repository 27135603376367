import React, { useState } from "react";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

export const ModalContainer = ({ children, title, onClickSave }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSave = async () => {
    onClickSave();
    handleClose();
  };

  return (
    <div className="containerModalScree">
      <button
        className="buttonAdjustment"
        style={{ width: "100%" }}
        onClick={handleOpen}
      >
        Adjustment Payment
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className="containerModalcontainer">
          <div className="title">{title}</div>
          <div className="line" />
          {children}

          <div className="containerButtonModal">
            <button className="cancel buttonMObile" onClick={handleClose}>
              Cancel
            </button>
            <button className={"save buttonMObile"} onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
