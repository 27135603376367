import React from "react";
import { Box } from "@mui/system";
import { Container } from "@mui/material";

export const ContentContainer = ({ children }) => (
  <div
      style={{
        flexGrow: 1,
        overflow: "auto",
        paddingTop: 10,
        paddingLeft: 0,
      }}
    >
      <Container maxWidth="100%" sx={{ height: "92vh" }}>
        {children}
      </Container>
  </div>
  );