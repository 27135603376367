import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ReactSVG } from "react-svg";
import BtnDelete from "../../Assets/Btn Delete.svg";
import AlertIcon from "../../Assets/Group 517.svg";
import deleteIcon from "../../Assets/Group 1825.svg";

import "./DeleteTemplate.css";

function isMobileDevice() {
  return window.innerWidth <= 768;
}

let width = isMobileDevice() ? 300 : 450;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: width,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

export default function DeleteTemplate({ funcionDelete, title, icon, text }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loadingButton, setLoadingButton] = React.useState(false);

  const cancelCleaning = async () => {
    try {
      setLoadingButton(true);
      await funcionDelete();
      setLoadingButton(false);
      handleClose();
    } catch (error) {
      console.log(error);
      setLoadingButton(false);
    }
  };

  return (
    <div>
      {icon ? (
        <button
          style={{
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
          }}
          onClick={handleOpen}
        >
          <ReactSVG src={deleteIcon} />
        </button>
      ) : (
        <button
          style={{
            backgroundColor: "#dd3545",
            marginLeft: "10px",
          }}
          onClick={handleOpen}
        >
          Delete
        </button>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="containerDeleteTemplate">
            <div className="title">{title}</div>
            <ReactSVG src={AlertIcon} />
            {text ? (
              <div className="subtitle">{text}</div>
            ) : (
              <div className="subtitle">
                This action is final and cannot be undone!
              </div>
            )}
            <div className="line"></div>
          </div>
          <div className="containerButtonDeleteTemplate">
            <button
              style={{ color: "#0E1F41", backgroundColor: "#fafafa" }}
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              style={{
                backgroundColor: "#FF4242",
                color: "#FAFAFA",
                border: "none",
              }}
              onClick={() => cancelCleaning()}
            >
              {loadingButton ? "Loading" : "Delete"}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
