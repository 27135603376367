import React, { useState, useContext } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import AppContext from "../../Context/AppContext";
import Logo from "../../Assets/2Sett Logo.svg";
import backgrounIMage from "../../Assets/223516835_Mesa de trabajo 1 1.svg";
import Elementos from "../../Assets/222.svg";
import * as yup from "yup";

import "./Login.css";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { signIn } = useContext(AppContext);

  let navigate = useNavigate();
  
  const  handleNavigate = () => {
    navigate(`/forgot-password`);
  }

  const handleCheckboxChange = () => {
    setRememberMe(!rememberMe);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle login logic, including the "rememberMe" option
  };

  const form = useFormik({
    initialValues: {
      email: "",
      password: "",
      type: "Admin",
    },

    validationSchema: yup.object({
      email: yup
        .string()
        .email("Invalid email format.")
        .required("Email is required!"),
      password: yup
        .string()
        .min(8, "Password must contain at least 8 characters.")
        .required("Password required!"),
    }),

    onSubmit: (values) => handleContinue(values),
  });

  const handleContinue = async (values) => {
    const { email, password, type } = values;
    try {
      setLoading(true);
      await signIn({ email: email, password: password, type: type });
      setLoading(false);
    } catch (error) {
      // console.log(error);
      setLoading(false);
      if (error?.response?.status) {
        if (error.response.status !== 401) {
          if (
            error?.response?.data?.errors &&
            error?.response?.data?.errors[0]?.title
          ) {
            alert(`${error.response.data.errors[0].title}`);
          } else {
            alert("Something unexpected happened.", "Please try again.");
          }
        } else {
          alert("Session expired", "Please login in again.");
        }
      } else {
        alert("Something unexpected happened.", "Please try again.");
      }
    }
    setLoading(false);
  };

  const handleChange = (event) => {
    form.setFieldValue("type", event.target.value);
  };

  return (
    <div className="containerLogin">
      <ReactSVG src={Logo} className="logoSett" />
      <div className="containerLeft"></div>
      <div className="containerRigt">
        <div className="containerINputLogin">
          <div className="title">
            Welcome back,{" "}
            <span style={{ color: "#0089ED" }}>Administrator!</span>
          </div>
          <div style={{ width: "90%" }}>
            <div className="errorINputRegi" style={{ marginBottom: "20px" }}>
              <div className="subTitle">Enter your email address</div>
              <input
                className="inputLabelRegis"
                placeholder={"E-mail"}
                type={"email"}
                onChange={(e) => {
                  form.setFieldValue("email", e.target.value);
                }}
                onBlur={() => {
                  form.setFieldTouched("email", true);
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    form.handleSubmit();
                  }
                }}
              />
              {form.touched.email && form.errors.email && (
                <Typography
                  style={{ fontSize: 14, marginLeft: 5, marginTop: 5 }}
                  color={"darkred"}
                >
                  {form.errors.email}
                </Typography>
              )}
            </div>

            <div className="errorINputRegi">
              <div className="subTitle">Enter your Password</div>

              <input
                className="inputLabelRegis"
                placeholder={"Password"}
                type={"password"}
                onChange={(e) => {
                  form.setFieldValue("password", e.target.value);
                }}
                onBlur={() => {
                  form.setFieldTouched("password", true);
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    form.handleSubmit();
                  }
                }}
              />
              {form.touched.password && form.errors.password && (
                <Typography
                  style={{ fontSize: 14, marginLeft: 5, marginTop: 5 }}
                  color={"darkred"}
                >
                  {form.errors.password}
                </Typography>
              )}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <div>
                <input
                  type="checkbox"
                  id="rememberMe"
                  name="rememberMe"
                  checked={rememberMe}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="rememberMe" className="checkBoxRemenber">
                  Remember me
                </label>
              </div>
              <div className="titleForgot" onClick={()=> handleNavigate()}>Forgot Password</div>
            </div>
          </div>

          <button
            className="buttonSigin"
            onClick={() => {
              form.handleSubmit();
            }}
          >
            {loading ? "Loading..." : "Sign in"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
