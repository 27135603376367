import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import "./CardPdfDetails.css";

import iconSeta from "../../../../../Assets/Union (6).svg";
import { ReactSVG } from "react-svg";
const XLSX = require("xlsx");

const generateExcel = (data) => {
  const formattedDate = (date) => {
    return moment(date).utc().format("MM/DD/YYYY");
  };

  // console.log(data);

  const formattedTime = (time) => {
    return moment(time).utc().format("HH:mm");
  };

  const workbook = XLSX.utils.book_new();
  const worksheetData = [
    [
      "ID",
      "Host",
      "Property nick name",
      "Date",
      "Value",
      "Cleaner",
      "Payment Status",
      "Deep clean",
      "Region",
    ],
    ...data.map((row) => [
      row.cleaningId,
      row.host ? row.host : "No Host Yet",
      row.propertyNickname ? row.propertyNickname : "No nick name Yet",
      `${formattedDate(row?.cleaningDate)}`,
      { t: "n", v: Number(row?.serviceValue).toFixed(2) },
      row.cleaner ? row.cleaner : "No Cleaner Yet",
      row?.scheduleStatus,
      row?.deepClean ? "Y" : "N",
      row?.region ? row?.region?.name : "No Region Yet",
    ]),
  ];

  // console.log(data);

  const ws = XLSX.utils.aoa_to_sheet(worksheetData);
  XLSX.utils.book_append_sheet(workbook, ws, "Payment Report");

  // Define os filtros para as colunas A até H (0 a 7)
  const filterRange = XLSX.utils.encode_range({
    s: { c: 0, r: 0 },
    e: { c: 7, r: data.length },
  });
  ws["!autofilter"] = { ref: filterRange };

  const excelFileName = "payment_report.xlsx";
  XLSX.writeFile(workbook, excelFileName);
};

const CardPdfDetails = ({ data }) => {
  return <div className="buttonPdf">{generateExcel(data)}</div>;
};

export default CardPdfDetails;
