import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";

import "./NewSchedule.css";
import { ReactSVG } from "react-svg";

import iconSet from "../../../../Assets/Icon (1).svg";
import iconFInish from "../../../../Assets/Vector (9).svg";
import scheduleIcon from "../../../../Assets/Vector (8).svg";
import editIcon from "../../../../Assets/Btn Edit (2).svg";
import addIcon from "../../../../Assets/Group 1825.svg";
import Step1 from "./Step1";

import api from "../../../../Service/api";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const NewSchedule = ({ data, setLoadingButton }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleItens = async (values) => {

    setLoading(true);
    try {
      await api.post(`/admins/addresses/${data?.id}/create_schedule`, {
        data: {
          attributes: {
            date: moment(values.date).utc().format("YYYY-MM-DD"),
            time: values.time,
            nextCheckinDate: moment(values.nextCheckinDate).utc().format("YYYY-MM-DD"),
            nextCheckinTime: values.nextCheckinTime,
            cleaningId: data?.cleaning?.id,
            cleaningCouponId: values.cleaningCouponId,
          },
        },
      });
      alert("Schedule added successfully!");
      setLoading(false);
      setLoadingButton();
    } catch (error) {
      console.log(error);
      if (error.response.data.message) {
        alert(error.response.data.message);
      } else {
        alert("Error adding schedule to property. Please try again.");
      }
      setLoading(false);
      console.log(error);
    }
  };

  const form = useFormik({
    initialValues: {
      date: "",
      time: "",
      nextCheckinDate: "",
      nextCheckinTime: "",
      cleaningId: data?.cleaning?.id,
      cleaningCouponId: "",
    },
    // validationSchema: yup.object({
    //   postTitle: yup
    //     .string()
    //     .min(2, "Post Title must have at least 2 characters.")
    //     .max(50, "Post Title must have at most 50 characters.")
    //     .required("Post Title is required!"),
    // }),
    onSubmit: (values) => handleItens(values),
  });

  // console.log(form.values);

  return (
    <div className="containerModalScree">
      <div
        className="scheduleButton"
        onClick={handleOpen}
        style={{ cursor: "pointer" }}
      >
        Schedule
        <ReactSVG src={scheduleIcon} />
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className="containerModalcomplete3">
          <div className="headerComplete">
            <div className="title">Create New Schedule</div>
            <div className="subtitle">
              Effortlessly add a schedule to your property.
            </div>
          </div>

          <Step1 form={form} />

          <div className="containerButtonModal">
            <button className="cancel" onClick={handleClose}>
              Cancel
            </button>
            <button className={"save"} onClick={form.submitForm}>
              {loading ? "Loading..." : "Save"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default NewSchedule;
