import React from "react";
import { ReactSVG } from "react-svg";

import PhotoCardCarousel from "../PhotoCardCarousel";

import "./Step1.css";

const Step1 = ({ form }) => {
  return (
    <div className="containerStep1" style={{ flex: 1}}>
      <div className="headerStep">
        <div className="title">Cleaning and Deep Cleaning evidence photos</div>
        <div className="subtitle">Insert photos of the clean place.</div>
      </div>

      <PhotoCardCarousel form={form} objectPhotos={"cleanPlacePhotos"} />

      <div className="conatinerSLider" style={{ flex: 1}}>
        <label className="switch2">
          <input
            type="checkbox"
            checked={form.values.deepCleaning}
            onChange={() =>
              form.setFieldValue(`deepCleaning`, !form.values.deepCleaning)
            }
          />
          <span className="slider"></span>
        </label>
        <div className="textSlider">Deep cleaning</div>
      </div>
    </div>
  );
};

export default Step1;
