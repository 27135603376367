import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";

import "./EditSchedule.css";
import { ReactSVG } from "react-svg";

import iconSet from "../../../../Assets/Icon (1).svg";
import iconFInish from "../../../../Assets/Vector (9).svg";
import scheduleIcon from "../../../../Assets/Vector (8).svg";
import editIcon from "../../../../Assets/Btn Edit (2).svg";
import addIcon from "../../../../Assets/Group 1825.svg";
import Step1 from "./Step1";
import Step2 from "./Step2";

import api from "../../../../Service/api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const EditSchedule = ({ data, setLoadingButton, newProperty, id }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [currentScreen, setCurrentScreen] = useState(1);

  const handleNext = () => {
    if (currentScreen < 2) {
      setCurrentScreen(currentScreen + 1);
    }
  };

  const handlePrev = () => {
    if (currentScreen > 1) {
      setCurrentScreen(currentScreen - 1);
    }
  };

  const handleItens = async (values) => {
    setLoading(true);
    try {
      if (id) {
        await api.post(`/admins/clients/${id}/add_property`, {
          data: {
            attributes: {
              addressNickname: values.addressNickname,
              address: values.address,
              zipCode: values.zipCode,
              city: values.city,
              state: values.state,
              addressChecklistsAttributes: values.addressChecklistsAttributes,
              getInAttributes: {
                title: values.title,
                description: values.description,
              },
              cleaningAttributes: {
                client_id: id,
                bedroomsAmount: values.bedroomsAmount,
                bathroomsAmount: values.bathroomsAmount,
                laundry: values.laundry,
                storageAccessLocation: values.storageAccessLocation,
                consumablesAttributes: values.consumablesAttributes,
              },
            },
          },
        });
        alert("Successfully added property!");
      } else {
        await api.put(`/admins/addresses/${data?.id}`, {
          data: {
            attributes: {
              addressNickname: values.addressNickname,
              address: values.address,
              zipCode: values.zipCode,
              city: values.city,
              state: values.state,
              getInAttributes: {
                title: values.title,
                description: values.description,
              },
              addressChecklistsAttributes: values.addressChecklistsAttributes,
              cleaningAttributes: {
                id: values.id,
                bedroomsAmount: values.bedroomsAmount,
                bathroomsAmount: values.bathroomsAmount,
                laundry: values.laundry,
                storageAccessLocation: values.storageAccessLocation,
                consumablesAttributes: values.consumablesAttributes,
              },
            },
          },
        });
        alert("Property updated successfully!");
      }
      setLoading(false);
      setLoadingButton();
    } catch (error) {
      alert("Error updating property. Please try again.");
      setLoading(false);
    }
  };

  const form = useFormik({
    initialValues: {
      addressNickname: data?.address_nickname || "",
      address: data?.address || "",
      zipCode: data?.zip_code || "",
      city: data?.city || "",
      state: data?.state || "",
      title: data?.getIn?.title || "",
      description: data?.getIn?.description || "",
      addressChecklistsAttributes:
        data?.addressChecklists?.map((item) => ({
          id: item.id,
          description: item.description,
          delete: item.delete,
        })) || [],
      id: data?.cleaning?.id || "",
      bedroomsAmount: data?.cleaning?.bedrooms_amount || 0,
      bathroomsAmount: data?.cleaning?.bathrooms_amount || 0,
      laundry: data?.cleaning?.laundry || "",
      cleaningOn: data?.cleaning?.cleaning_on || "",
      storageAccessLocation: data?.cleaning?.storage_access_location || "",
      consumablesAttributes:
        data?.consumables?.map((item) => ({
          id: item.id,
          description: item.description,
          delete: item.delete,
        })) || [],
    },
    // validationSchema: yup.object({
    //   postTitle: yup
    //     .string()
    //     .min(2, "Post Title must have at least 2 characters.")
    //     .max(50, "Post Title must have at most 50 characters.")
    //     .required("Post Title is required!"),
    // }),
    onSubmit: (values) => handleItens(values),
  });

  let currentScreenComponent;

  if (currentScreen === 1) {
    currentScreenComponent = <Step1 form={form} />;
  } else if (currentScreen === 2) {
    currentScreenComponent = <Step2 form={form} />;
  }

  // console.log(form.values);

  return (
    <div className="containerModalScree">
      {newProperty ? (
        <div
          className="scheduleButton"
          onClick={handleOpen}
          style={{ cursor: "pointer" }}
        >
          New Property
        </div>
      ) : (
        <div
          // className="scheduleButton"
          onClick={handleOpen}
          style={{ cursor: "pointer" }}
        >
          <ReactSVG src={editIcon} />
        </div>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className="containerModalcomplete2">
          <div className="headerComplete">
            <div className="title">Editing Property</div>
            <div className="subtitle">Editing property basic informations.</div>
          </div>

          {currentScreenComponent}

          <div className="containerButtonsComlete">
            {currentScreen !== 1 ? (
              <button
                className="buttonCOmplete colorDisableButton"
                onClick={handlePrev}
                disabled={currentScreen === 1}
              >
                <ReactSVG
                  src={iconSet}
                  style={{ transform: `rotate(180deg)` }}
                />
              </button>
            ) : (
              <div></div>
            )}
            {currentScreen !== 2 ? (
              <button
                className="buttonCOmplete colorSetComplete"
                onClick={handleNext}
              >
                <ReactSVG src={iconSet} />
              </button>
            ) : (
              <button
                className="buttonCOmplete colorFinishComplete"
                onClick={form.submitForm}
              >
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress size={24} />{" "}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ReactSVG src={iconFInish} />
                  </div>
                )}
              </button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditSchedule;
