import React, { useContext, useState } from "react";
import moment from "moment";
import { ModalContainer } from "../../../../Components/ModalContainer";
import bedroomsIcon from "../../../../Assets/bedroomsAmount.svg";
import bathroomsIcon from "../../../../Assets/bathrooms.svg";
import clock from "../../../../Assets/clockLight.svg";
import dollar from "../../../../Assets/Dollar.svg";
import Cleanin from "../../../../Assets/Clients Icon.svg";
import editIcon from "../../../../Assets/Btn Details.svg";
import deleteIcon from "../../../../Assets/Group 1825.svg";
import shareIcon from "../../../../Assets/Group 1827.svg";
import EditSchedule from "../EditSchedule";
import NewSchedule from "../NewSchedule";
import AppContext from "../../../../Context/AppContext";
import Autocomplete from "../../../../Components/Autocomplete";
import { FormHelperText } from "@mui/material";
import DeleteTemplate from "../../../../Components/DeleteTemplate/DeleteTemplate";

import "./CardProperty.css";
import { ReactSVG } from "react-svg";
import { useFormik } from "formik";
import * as yup from "yup";
import api from "../../../../Service/api";

const CardProperty = ({ property, key, setLoadingButton, id, editable }) => {
  const { user, listCleaners } = useContext(AppContext);
  const [loadingRestore, setLoadingRestore] = useState(false);
  const [nameCleaner, setNameCleaner] = useState([]);
  const [nameValue, setNameValue] = useState();

  const newListCleaner = listCleaners
    ?.map((value) => ({
      id: value.id,
      name: `${value.attributes.firstName} ${value.attributes.lastName}`,
    }))
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  const setDefaultCleaner = async () => {
    const selectedId = newListCleaner?.find(
      (value) => value.name === nameCleaner
    );

    try {
      await api.put(`/admins/addresses/${property.id}/default_cleaner`, {
        data: {
          attributes: {
            cleaner_id: selectedId.id,
          },
        },
      });
      setLoadingButton();
    } catch (error) {
      console.log(error);
    }
  };

  const handleIdByChange = (newValue) => {
    setNameCleaner(newValue.name);
    setNameValue(nameValue);
  };

  const handleTotalValue = async (values) => {
    try {
      await api.put(`admins/addresses/${property.id}/update_value`, {
        address: {
          new_total_value: values.new_total_value,
        },
      });
      setLoadingButton();
    } catch (error) {
      console.log(error);
    }
  };

  const formTotalValue = useFormik({
    initialValues: {
      new_total_value: property.total_value || 0,
    },
    onSubmit: (values) => handleTotalValue(values),
  });

  const handleAddIcal = async (values) => {
    try {
      await api.post(`admins/addresses/${property.id}/add_ical`, {
        data: {
          attributes: {
            ...values,
          },
        },
      });
      setLoadingButton();
    } catch (error) {
      console.log(error);
    }
  };

  const formAddIcal = useFormik({
    initialValues: {
      name: "",
      url: "",
    },
    validationSchema: yup.object({
      url: yup
        .string()
        .min(2, "URL must have at least 2 characters.")
        .required("URL is required!"),
      name: yup
        .string()
        .min(2, "name must have at least 2 characters.")
        .max(50, "name must have at most 50 characters.")
        .required("name is required!"),
    }),
    onSubmit: (values) => handleAddIcal(values),
  });

  const handleValueCleaner = async (values) => {
    try {
      await api.put(
        `admins/addresses/${property.id}/update_cleaner_service_value`,
        {
          ...values,
        }
      );
      setLoadingButton();
    } catch (error) {
      console.log(error);
    }
  };

  const formValueCleaner = useFormik({
    initialValues: {
      address_id: property.id,
      new_cleaner_service_value: property.cleaner_service_value || 0,
    },
    onSubmit: (values) => handleValueCleaner(values),
  });

  const handleIcalendar = async (values) => {
    try {
      await api.put(`/admins/clients/${values.id}/update_icalendar`, {
        data: { attributes: { url: values.url } },
      });
      setLoadingButton();
    } catch (error) {
      console.log(error);
    }
  };

  const formIcalendar = useFormik({
    initialValues: {
      url: "",
      id: "",
    },
    onSubmit: (values) => handleIcalendar(values),
  });

  const handleChange = (item, value) => {
    formIcalendar.setFieldValue("url", value);
    formIcalendar.setFieldValue("id", item.id);
  };

  const deleteLink = async (values) => {
    try {
      await api.delete(`/admins/clients/${values.id}/delete_icalendar`);
      setLoadingButton();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteDefaultCleaner = async () => {
    try {
      await api.put(`/admins/addresses/${property.id}/remove_default_cleaner`, {
        data: {
          attributes: {
            cleaner_id: property.defaultCleaner.id,
          },
        },
      });
      setLoadingButton();
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(property);

  const restoreProperty = async () => {
    setLoadingRestore(true);
    try {
      await api.patch(`/admins/clients/${property.id}/restore_property`);
      setLoadingRestore(false);
      setLoadingButton();
    } catch (error) {
      console.log(error);
      setLoadingRestore(false);
    }
  };

  const formattedDate = (date) => {
    if (!date) {
      return "None";
    }
    return moment(date).utc().format("MMM. DD, YYYY");
  };

  function MyFormHelperText({ text }) {
    // const { focused } = useFormControl() || {};

    const helperText = React.useMemo(() => {
      if (text) {
        return `${text}`;
      }

      return ``;
    }, [text]);

    return <FormHelperText>{helperText}</FormHelperText>;
  }

  return (
    <div className="containerCardProperty" key={key}>
      <div className="containerHeaderCardProperty">
        <div className="textitle">
          {property?.address_nickname || "No nickname yet"}
        </div>
        {editable ? (
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <NewSchedule data={property} setLoadingButton={setLoadingButton} />
            <EditSchedule data={property} setLoadingButton={setLoadingButton} />

            <ModalContainer
              customButton={
                <div className="scheduleButton" style={{ cursor: "pointer" }}>
                  Add iCal
                </div>
              }
              type={true}
              title="Add iCal"
              onClickSave={formAddIcal.submitForm}
            >
              <div className="containerInputText">
                <div className="titleFilter">NAME:</div>
                <input
                  type="text"
                  className="inputText"
                  value={formAddIcal.values.name}
                  onChange={(event) =>
                    formAddIcal.setFieldValue("name", event.target.value)
                  }
                />
                <MyFormHelperText
                  text={
                    formAddIcal.errors["name"] && formAddIcal.errors["name"]
                  }
                />

                <div className="titleFilter">URL:</div>

                <input
                  type="text"
                  className="inputText"
                  value={formAddIcal.values.url}
                  onChange={(event) =>
                    formAddIcal.setFieldValue("url", event.target.value)
                  }
                />
                <MyFormHelperText
                  text={formAddIcal.errors["url"] && formAddIcal.errors["url"]}
                />
              </div>
            </ModalContainer>
          </div>
        ) : (
          <div className="restoreButton" onClick={() => restoreProperty()}>
            {loadingRestore ? "Loading..." : "Restore"}
          </div>
        )}
      </div>
      <div style={{ display: "flex", gap: "15px" }}>
        <div
          className="textCardroperties"
          style={{ display: "flex", gap: "5px" }}
        >
          <ReactSVG
            src={bathroomsIcon}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0)",
            }}
          />
          {editable
            ? property?.cleaning?.bedrooms_amount
            : property?.bedroomsAmount || 0}
        </div>
        <div
          className="textCardroperties"
          style={{ display: "flex", gap: "5px" }}
        >
          <ReactSVG
            src={bedroomsIcon}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0)",
            }}
          />
          {editable
            ? property?.cleaning?.bathrooms_amount
            : property?.bathroomsAmount || 0}
        </div>
      </div>
      <div className="textitle" style={{ marginBottom: "15px" }}>
        {property.address || "No address yet"}
      </div>
      <div>
        <div
          className="textCardroperties"
          style={{ display: "flex", gap: "5px" }}
        >
          <ReactSVG
            src={clock}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0)",
            }}
          />
          <span style={{ fontWeight: "700" }}>Last Schedule:</span>
          {`${
            editable
              ? formattedDate(property?.last_schedule?.date)
              : property?.last_schedule || "Undefined"
          }`}
        </div>

        <div
          className="textCardroperties"
          style={{ display: "flex", gap: "5px", alignItems: "center" }}
        >
          <ReactSVG
            src={Cleanin}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0)",
            }}
          />
          <span style={{ fontWeight: "700" }}>Main Cleaner:</span>
          {property.defaultCleaner === "No default cleaner"
            ? "No default cleaner"
            : `${property?.defaultCleaner?.first_name || ""} ${
                property?.defaultCleaner?.last_name || ""
              }`}

          {editable && user.adminType === "master" && (
            <ModalContainer
              customButton={<ReactSVG src={editIcon} />}
              type={true}
              title="Select main cleaner:"
              onClickSave={setDefaultCleaner}
            >
              <div className="titleINputModal">Cleaner:</div>
              <Autocomplete
                options={newListCleaner}
                data={property}
                placeholder="Select cleaner"
                onChange={handleIdByChange}
                // onSelect={...props}
              />
            </ModalContainer>
          )}

          {property.defaultCleaner !== "No default cleaner" && (
            <DeleteTemplate
              icon={true}
              title="Remove default cleaner"
              funcionDelete={() => deleteDefaultCleaner()}
              text={"Are you sure you want to remove the principal cleaner?"}
            />
          )}
        </div>

        <div
          className="textCardroperties"
          style={{ display: "flex", gap: "3px", alignItems: "center" }}
        >
          <ReactSVG
            src={dollar}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0)",
            }}
          />
          <span style={{ fontWeight: "700" }}>Total Value:</span>
          {` ${property.total_value || "0"}`}

          {editable && user.adminType === "master" && (
            <ModalContainer
              customButton={<ReactSVG src={editIcon} />}
              type={true}
              title="Edit Total Value"
              onClickSave={formTotalValue.submitForm}
            >
              <div className="titleINputModal">Total value:</div>
              <div className="containerInputText">
                <input
                  type="number"
                  className="inputText"
                  value={formTotalValue.values.new_total_value}
                  onChange={(event) =>
                    formTotalValue.setFieldValue(
                      "new_total_value",
                      event.target.value
                    )
                  }
                />
              </div>
            </ModalContainer>
          )}
        </div>

        <div
          className="textCardroperties"
          style={{ display: "flex", gap: "3px", alignItems: "center" }}
        >
          <ReactSVG
            src={dollar}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0)",
            }}
          />
          <span style={{ fontWeight: "700" }}>Cleaner Service Value:</span>
          {`${property.cleaner_service_value || 0}`}

          {editable && user.adminType === "master" && (
            <ModalContainer
              customButton={<ReactSVG src={editIcon} />}
              type={true}
              title="Edit Cleaner Service Value"
              onClickSave={formValueCleaner.submitForm}
            >
              <div className="titleINputModal">Cleaner service value:</div>
              <div className="containerInputText">
                <input
                  type="number"
                  className="inputText"
                  value={formValueCleaner.values.new_cleaner_service_value}
                  onChange={(event) =>
                    formValueCleaner.setFieldValue(
                      "new_cleaner_service_value",
                      event.target.value
                    )
                  }
                />
              </div>
            </ModalContainer>
          )}
        </div>

        {property?.icalendarUrls?.map((item, idx) => (
          <div
            className="textCardroperties"
            style={{ display: "flex", alignItems: "center", gap: "10px" }}
            key={idx}
          >
            <ReactSVG
              src={shareIcon}
              style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
            />
            <div
              // style={{
              //   flex: 1,
              //   maxWidth: "200px",
              //   overflow: "hidden",
              //   textOverflow: "ellipsis",
              // }}
              className="urlMobile"
            >
              <span style={{ fontWeight: "700" }}>{item.url}</span>
            </div>

            {editable && (
              <>
                <ModalContainer
                  customButton={<ReactSVG src={editIcon} />}
                  type={true}
                  title="Edit Icalendar Url"
                  onClickSave={formIcalendar.submitForm}
                >
                  <div className="titleINputModal">URL:</div>
                  <div className="containerInputText">
                    <input
                      type="text"
                      className="inputText"
                      value={formIcalendar.values.url}
                      onChange={(event) =>
                        handleChange(item, event.target.value)
                      }
                    />
                  </div>
                </ModalContainer>

                <ModalContainer
                  customButton={<ReactSVG src={deleteIcon} />}
                  type={true}
                  title="Delete"
                  onClickSave={() => deleteLink(item)}
                >
                  <div className="titleINputModal">
                    This action will also delete all cleanings and payments
                    associated! Are you sure about this?
                  </div>
                </ModalContainer>
              </>
            )}
          </div>
        ))}
      </div>
      <div></div>
      <div></div>
    </div>
  );
};

export default CardProperty;
