import React, { useState, useContext } from "react";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import { Input } from "../../../Components/Input";
import { InputsContainer } from "../../../Components/InputsContainer";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { FormHelperText } from "@mui/material";
import { ReactSVG } from "react-svg";
import api from "../../../Service/api";
import upPhotoIcon from "../../../Assets/Union (3).svg";

import "./ModalCard.css";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const ModalCard = ({
  data,
  title,
  button,
  color,
  padding,
  update,
  setLoading,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [filter, setFilter] = useState();

  // function handleFileChange(event) {
  //   const file = event.target.files[0];
  //   setSelectedFile(file);

  // }

  // console.log(data);

  const handleStatusChange = (event) => {
    const { name, checked } = event.target;
    setFilter((prevState) => ({
      ...prevState,
      status: { ...prevState.status, [name]: checked },
    }));
  };

  const formattedDate = (date) => {
    return moment(date).utc().format("yyyy-MM-DD");
  };

  const handleItens = async (values) => {
    setLoadingButton(true);


    const attributes = {
      title: values.title,
      date_limit: formattedDate(values.date_limit),
      amount_limit: values.amount_limit,
      discount_percentage: values.type ?  values.discount : 0,
      discount_price: !values.type ?  values.discount : 0,
      code: values.code,
    }

    const formValues = {
      data: { attributes },
    };

    try {
      if (values.id === "") {

        await api.post(`/admins/cleaning_coupons`, formValues);

        setLoadingButton(false);
        handleClose();
        setLoading(true);
      } else {
        // console.log("esse");
        await api.put(`/admins/cleaning_coupons/${values.id}`, formValues);

        setLoadingButton(false);
        handleClose();
        setLoading(true);
      }
    } catch (error) {
      console.log(error);
      setLoadingButton(false);
    }
  };

  // console.log(data);

  const formCoupons = useFormik({
    initialValues: {
      id: data?.id || "",
      title: data?.title || "",
      amount_limit: formattedDate(data?.amountLimit) || "",
      code: data?.code || "",
      date_limit: data?.dateLimit || "",
      discount:
        data?.discountPercentage !== 0
          ? data?.discountPercentage
          : data?.discountPrice || "",
      type: data?.discountPercentage !== 0 ? true : false,
    },
    validationSchema: yup.object({
      title: yup
        .string()
        .min(2, "Coupon name must have at least 2 characters.")
        .max(50, "Coupon name must have at most 50 characters.")
        .required("Coupon name is required!"),
      amount_limit: yup.string().required("amount is required!"),
      code: yup
        .string()
        .min(2, "code must have at least 2 characters.")
        .max(50, "code must have at most 50 characters.")
        .required("code is required!"),
      date_limit: yup.date().required("Date limit is required!"),
      discount: yup.string().required("discount is required!"),
    }),
    onSubmit: (values) => handleItens(values),
  });

  const capitalize = (str) => {
    if (typeof str !== "string") {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.substr(1);
  };

  const handleChangeType = (event) => {
    formCoupons.setFieldValue("adminType", event.target.value);
  };

  function MyFormHelperText({ text }) {
    // const { focused } = useFormControl() || {};
  
    const helperText = React.useMemo(() => {
      if (text) {
        return `${text}`;
      }
  
      return ``;
    }, [text]);
  
    return <FormHelperText>{helperText}</FormHelperText>;
  }


  return (
    <div>
      <button
        className="openModalAdminCOntrol"
        onClick={handleOpen}
        style={{ backgroundColor: color && color, padding: padding && padding }}
      >
        {button}
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className="containerModalAdminControl">
          <div className="titleModalAdmin">{title}</div>
          <div className="lineAdminControl" />
          <InputsContainer>
            <Input
              placeholder="Coupon Title"
              field="title"
              form={formCoupons}
              assistiveText={{ color: "#0E1F41" }}
            />

            <Input
              placeholder="Amount limit"
              field="amount_limit"
              form={formCoupons}
              assistiveText={{ color: "#0E1F41" }}
            />

            <Input
              placeholder="Code"
              field="code"
              form={formCoupons}
              assistiveText={{ color: "#0E1F41" }}
            />

            <div style={{ width: "100%" }}>
              <div className="titleFilter">Date limit:</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "5px",
                }}
              >
                <div>
                  {/* <div className="subTitleFilter">Date limit:</div> */}
                  <TextField
                    name="date_limit"
                    onChange={(event) =>
                      formCoupons.setFieldValue(
                        "date_limit",
                        event.target.value
                      )
                    }
                    type="date"
                    value={formCoupons.values.date_limit}
                    defaultValue={formCoupons.values.date_limit}
                  />
                  <MyFormHelperText text={formCoupons.errors.date_limit && formCoupons.errors.date_limit} />
                </div>

                {/* <div>
                  <div className="subTitleFilter">To:</div>
                  <TextField
                    name="toDate"
                    // label="To Date"
                    type="date"
                    // value={filter.toDate}
                    // onChange={handleFilterChange}
                  />
                </div> */}
              </div>
            </div>

            <Input
              placeholder="Discount"
              field="discount"
              form={formCoupons}
              keyboardType="number"
              assistiveText={{ color: "#0E1F41" }}
            />

            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => formCoupons.setFieldValue("type", true)}
                    checked={formCoupons.values.type}
                    name="type"
                    color="default"
                    style={{
                      color: "#0E1F41",
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                  />
                }
                label={
                  <span
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "20px",
                      color: "#0E1F41",
                    }}
                  >
                    Percentage
                  </span>
                }
                style={{ paddingTop: 0, paddingBottom: 0 }}
                align="left"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => formCoupons.setFieldValue("type", false)}
                    checked={!formCoupons.values.type}
                    name="type"
                    color="default"
                    style={{
                      color: "#0E1F41",
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                  />
                }
                label={
                  <span
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "20px",
                      color: "#0E1F41",
                    }}
                  >
                    Fixed Price
                  </span>
                }
                style={{ paddingTop: 0, paddingBottom: 0 }}
                align="left"
              />
            </div>
          </InputsContainer>

          <div className="containerButtonModalAdmin">
            <button className="cancel" onClick={handleClose}>
              Cancel
            </button>
            <button className="create" onClick={formCoupons.submitForm}>
              {loadingButton ? "Loading" : data?.id ? 'Update' : 'Create'}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalCard;
