import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import AppContext from "../../Context/AppContext";
import { ReactSVG } from "react-svg";
import { AuthorizedLayout } from "../../Components/AuthorizedLayout";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import Input from "@mui/material/Input";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import DetailsScheduleModal from "../../Components/DetailsScheduleModal";
import DetailsCleaningModal from "../../Components/DetailsCleaningModal";
import bedroomsIcon from "../../Assets/bedroomsAmount.svg";
import bathroomsIcon from "../../Assets/bathrooms.svg";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Cronometro from "./Cronometro/Cronometro";

import "./Overview.css";
import api from "../../Service/api";

const initialFilterState = {
  fromDate: "",
  toDate: "",
  cleanerName: "",
  hostName: "",
  status: {
    canceled: false,
    scheduled: false,
    done: false,
    started: false,
    problem: false,
    canceled_penalty: false,
  },
  deepCleaning: false,
  region: "",
  city: "",
  sortBy: "",
};

const Overview = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(initialFilterState);
  const [isLoading, setIsLOading] = useState(true);
  const [open, setOpen] = useState(false);
  const { listRegions, listCities } = useContext(AppContext);
  const [total, setTotal] = useState(0);
  const [totalItens, setTotalItens] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searched, setSearched] = useState(false);

  const fetchData = async (page) => {
    setData([]);
    setIsLOading(true);
    try {
      const response = await api.get(`/admins/overview`, {
        params: {
          page: page,
          per_page: 40,
          cleaner_name: filter.cleanerName,
          host_name: filter.hostName,
          region: filter.region,
          city: filter.city,
          from: filter.fromDate
            ? moment(filter.fromDate).utc().format("DD/MM/YYYY")
            : "",
          to: filter.toDate
            ? moment(filter.toDate).utc().format("DD/MM/YYYY")
            : "",
          cleaning_status: Object.keys(filter.status)
            .filter((key) => filter.status[key])
            .map((key) => key.split("_").join(" ")),
          deep_cleaning: filter.deepCleaning,
        },
      });

      setTotal(response.total_pages);
      setTotalItens(response.total_items);

      const newData = response.schedules.map((value) => ({
        // totalPages: value.total_pages,
        // type: value.type,
        ...value.table,
      }));
      setData(newData);
      setIsLOading(false);
    } catch (error) {
      console.error("Error:");
      setIsLOading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (event, value) => {
    setCurrentPage(value);
    fetchData(value);
  };

  const handleSearch = () => {
    fetchData();
    setSearched(true);
  };

  const fetchDataReset = async () => {
    setData([]);
    setIsLOading(true);
    try {
      const response = await api.get(`/admins/overview`, {
        params: {
          page: 1,
          per_page: 40,
          cleaner_name: "",
          host_name: "",
          region: "",
          city: "",
          from: "",
          to: "",
          cleaning_status: "",
          deep_cleaning: false,
        },
      });

      // console.log(response.data);
      setTotal(response.total_pages);
      setTotalItens(response.total_items);

      const newData = response.schedules.map((value) => ({
        // totalPages: value.total_pages,
        // type: value.type,
        ...value.table,
      }));
      setData(newData);
      setCurrentPage(1);
      setIsLOading(false);
    } catch (error) {
      console.error("Error:");
      setIsLOading(false);
    }
  };

  const handleResetFilter = () => {
    setFilter(initialFilterState);
    fetchDataReset();
    setSearched(false);
  };

  const updateItens = async () => {
    setData([]);
    setIsLOading(true);
    fetchData();
  };

  function RegexExtrairData(dataStr) {
    let regex = /\d{4}-\d{2}-\d{2}/;
    let match = dataStr.match(regex);

    return match ? match[0] : null;
  }

  const formattedNextDate = (dateString, time) => {
    const dataComFuso = moment(RegexExtrairData(dateString)).utc();
    return `${moment(dataComFuso).format("MMM. DD, YYYY")} - ${time}`;
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleStatusChange = (event) => {
    const { name, checked } = event.target;
    setFilter((prevState) => ({
      ...prevState,
      status: {
        ...prevState.status,
        [name]: checked,
      },
    }));
  };

  const handleStatusDepp = (event) => {
    const { name, checked } = event.target;
    setFilter((prevState) => ({ ...prevState, deepCleaning: checked }));
  };

  const handleChangeRegion = (event) => {
    setFilter((prevState) => ({ ...prevState, region: event.target.value }));
  };

  const handleChangeCity = (event) => {
    setFilter((prevState) => ({ ...prevState, city: event.target.value }));
  };

  return (
    <AuthorizedLayout>
      <div className="containerOverview">
        <div className="containerBox"></div>
        <div className="containerFilter">
          <div className="textTitle">Overview</div>
          <div className="titleFilter">Date</div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "5px",
            }}
          >
            <div className="inputCOntianerMObile">
              <div className="subTitleFilter">From:</div>
              <TextField
                name="fromDate"
                // label="From Date"
                type="date"
                value={filter.fromDate}
                onChange={handleFilterChange}
                className="inputMObile"
              />
            </div>

            <div className="inputCOntianerMObile">
              <div className="subTitleFilter">To:</div>
              <TextField
                name="toDate"
                // label="To Date"
                type="date"
                value={filter.toDate}
                onChange={handleFilterChange}
                className="inputMObile"
              />
            </div>
          </div>

          <div className="titleFilter">Cleaner</div>

          <Input
            placeholder="Cleaner Name"
            name="cleanerName"
            value={filter.cleanerName}
            onChange={handleFilterChange}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSearch();
              }
            }}
          />

          <div className="titleFilter">Host</div>

          <Input
            placeholder="Host Name"
            name="hostName"
            value={filter.hostName}
            onChange={handleFilterChange}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSearch();
              }
            }}
          />

          <div className="titleFilter">Region</div>

          <TextField
            name="region"
            label={filter.region !== "" ? "" : "Region"}
            select
            value={filter.region}
            onChange={handleChangeRegion}
            style={{ borderBottom: "none", width: "100%" }}
          >
            <option value="">None</option>
            {listRegions.map((value, idx) => (
              <option
                value={value.name}
                key={idx}
                style={{ cursor: "pointer" }}
              >
                {value.name}
              </option>
            ))}
          </TextField>

          <TextField
            name="city"
            label={filter.city !== "" ? "" : "City"}
            select
            value={filter.city}
            onChange={handleChangeCity}
            style={{ borderBottom: "none", width: "100%" }}
          >
            <option value="">None</option>
            {listCities.map((value, idx) => (
              <option
                value={value.name}
                key={idx}
                style={{ cursor: "pointer" }}
              >
                {value.name}
              </option>
            ))}
          </TextField>

          <div className="titleFilter" style={{ marginBottom: "5px" }}>
            Cleaning Status
          </div>

          <FormControlLabel
            control={
              <Checkbox
                checked={filter.status.canceled}
                onChange={handleStatusChange}
                name="canceled"
                color="default"
                style={{ color: "#0E1F41", paddingTop: 0, paddingBottom: 0 }}
              />
            }
            label={
              <span
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 400,

                  color: "#0E1F41",
                }}
                className="statusMobile"
              >
                Canceled
              </span>
            }
            style={{ paddingTop: 0, paddingBottom: 0 }}
            align="left"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={filter.status.scheduled}
                onChange={handleStatusChange}
                name="scheduled"
                color="default"
                style={{ color: "#0E1F41", paddingTop: 0, paddingBottom: 0 }}
              />
            }
            label={
              <span
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 400,

                  color: "#0E1F41",
                }}
                className="statusMobile"
              >
                Scheduled
              </span>
            }
            style={{ paddingTop: 0, paddingBottom: 0 }}
            align="left"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={filter.status.done}
                onChange={handleStatusChange}
                name="done"
                color="default"
                style={{ color: "#0E1F41", paddingTop: 0, paddingBottom: 0 }}
              />
            }
            label={
              <span
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 400,

                  color: "#0E1F41",
                }}
                className="statusMobile"
              >
                Done
              </span>
            }
            style={{ paddingTop: 0, paddingBottom: "0" }}
            align="left"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={filter.status.started}
                onChange={handleStatusChange}
                name="started"
                color="default"
                style={{ color: "#0E1F41", paddingTop: 0, paddingBottom: 0 }}
              />
            }
            label={
              <span
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 400,

                  color: "#0E1F41",
                }}
                className="statusMobile"
              >
                Started
              </span>
            }
            style={{ paddingTop: 0, paddingBottom: 0 }}
            align="left"
          />

          {/* <FormControlLabel
            control={
              <Checkbox
                checked={filter.status.canceled_penalty}
                onChange={handleStatusChange}
                name="canceled_penalty"
                color="default"
                style={{ color: "#0E1F41", paddingTop: 0, paddingBottom: 0 }}
              />
            }
            label={
              <span
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 400,
                  
                  color: "#0E1F41",
                }}
              >
                Canceled Penalty
              </span>
            }
            style={{ paddingTop: 0, paddingBottom: "10px" }}
            align="left"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={filter.status.problem}
                onChange={handleStatusChange}
                name="problem"
                color="default"
                style={{ color: "#0E1F41", paddingTop: 0, paddingBottom: 0 }}
              />
            }
            label={
              <span
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 400,
                  
                  color: "#0E1F41",
                }}
              >
                Problem
              </span>
            }
            style={{ paddingTop: 0, paddingBottom: "10px" }}
            align="left"
          /> */}

          <FormControlLabel
            control={
              <Checkbox
                checked={filter.deepCleaning}
                onChange={handleStatusDepp}
                name="deepCleaning"
                color="default"
                style={{ color: "#0E1F41" }}
              />
            }
            label={
              <span
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 700,

                  color: "#0E1F41",
                }}
                className="statusMobile"
              >
                Deep Cleaning
              </span>
            }
            style={{ paddingTop: 0, paddingBottom: "10px" }}
            align="left"
          />

          {/* <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div className="titleFilter" style={{ margin: 0 }}>
              Sort by
            </div>

            <TextField
              name="sortBy"
              // label="Sort By"
              select
              value={filter.sortBy}
              onChange={handleSortByChange}
              style={{ borderBottom: "none" }}
            >
              <option value="">None</option>
              <option value="nameCleaner">Cleaner Name</option>
              <option value="nameHost">Host Name</option>
              <option value="dateFrom">Date</option>
            </TextField>
          </div> */}

          <div
            style={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: 400,

              color: "#0E1F41",
              alignSelf: "center",
              margin: "30px",
            }}
          >
            Cleaning Count: <strong>{totalItens}</strong>
          </div>

          <div
            style={{ display: "flex", gap: "10px", justifyContent: "center" }}
          >
            <button className="buttonFilter" onClick={handleResetFilter}>
              Clear Selection
            </button>

            <button
              className="buttonFilter"
              style={{ backgroundColor: "#0e1f41" }}
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
        </div>
        {data.length > 0 ? (
          <div className="containerPagination">
            <div style={{ alignSelf: "flex-end", marginBottom: "10px" }}>
              <Stack spacing={2}>
                <Pagination
                  count={total}
                  page={currentPage}
                  onChange={handleChange}
                />
              </Stack>
            </div>
            <div
              className="containerCard containerOver"
              style={{
                flexDirection: "column",
                gap: "20px",
                flex: 1,
                alignItems: "center",
              }}
            >
              {data
                ?.slice() // Cria uma cópia da lista para não afetar a original
                .sort((a, b) => {
                  // Função de comparação para ordenar por datas
                  const dateA = moment(a?.date).utc();
                  const dateB = moment(b?.date).utc();
                  return dateB - dateA; // Ordena em ordem decrescente
                })
                .map((item, idx) => (
                  <div
                    className="cardInfos"
                    key={idx}
                    style={{ width: "85%", gap: "10px" }}
                  >
                    <div className="cardId" style={{}}>
                      {`#${item.id}`}
                      <div
                        className="textCardCleaner"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "15px",
                          color:
                            item.status === "done"
                              ? "green"
                              : item.status === "canceled"
                              ? "red"
                              : "blue",
                        }}
                      >
                        {item.status || ""}
                      </div>

                      {item.status === "started" &&
                        item.startedAtTime !== "Not started" && (
                          <Cronometro
                            startedAtTime={item.startedAtTime}
                            startedAtDate={item.startedAtDate}
                          />
                        )}
                    </div>
                    <div
                      className="textDate"
                      style={{
                        textTransform: "uppercase",
                        marginRight: "50px",
                      }}
                    >
                      {item.address_nickname || "No nickname yet"}
                    </div>
                    <div
                      className="textDate"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                        flexWrap: "wrap",
                      }}
                    >
                      {formattedNextDate(item.date, item.time)}

                      {item?.extended && (
                        <div className="extended" style={{ width: "90px" }}>
                          Extended
                        </div>
                      )}
                    </div>
                    <div style={{ display: "flex", gap: "15px" }}>
                      <div className="textCardOver">
                        {`$${Number(item.value).toFixed(2)}`}
                      </div>
                      <div
                        className="textCardOver"
                        style={{ display: "flex", gap: "5px" }}
                      >
                        <ReactSVG src={bathroomsIcon} />
                        {item.bathroomsAmount}
                      </div>
                      <div
                        className="textCardOver"
                        style={{ display: "flex", gap: "5px" }}
                      >
                        <ReactSVG src={bedroomsIcon} />
                        {item.bedroomsAmount}
                      </div>
                    </div>

                    <div className="textCardOver">{item.address}</div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="textCardCleaner">
                        <div className="userPhoto"></div>
                        {item.cleaner || "No Cleaner"}
                      </div>

                      {item.status === "done" ? (
                        <DetailsCleaningModal
                          item={item}
                          setLoading={setIsLOading}
                        />
                      ) : (
                        <DetailsScheduleModal
                          data={item}
                          updateItens={updateItens}
                          isLoading={isLoading}
                          setIsLOading={setIsLOading}
                        />
                      )}
                    </div>
                  </div>
                ))}
            </div>
            <div style={{ alignSelf: "flex-end" }}>
              <Stack spacing={2}>
                <Pagination
                  count={total}
                  page={currentPage}
                  onChange={handleChange}
                />
              </Stack>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "80vh",
              width: "100%",
            }}
          >
            {isLoading ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress size={50} />
              </Box>
            ) : (
              <div className="titleFilter">No matching items found.</div>
            )}
          </div>
        )}
      </div>
    </AuthorizedLayout>
  );
};

export default Overview;
