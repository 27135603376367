import React, { useState, useEffect } from "react";
import moment from "moment";

import "./Cronometro.css";

const Cronometro = ({ startedAtTime, startedAtDate }) => {
  const [tempoDecorrido, setTempoDecorrido] = useState(0);

  useEffect(() => {
    const dataHoraInicio = moment(
      `${startedAtDate} ${startedAtTime}`,
      "YYYY-MM-DD hh:mm A"
    )
      .toDate()
      .getTime();

    const intervalId = setInterval(() => {
      const dataHoraAtual = new Date().getTime();
      const diferencaTempo = dataHoraAtual - dataHoraInicio;
      setTempoDecorrido(diferencaTempo);
    }, 1000); // atualiza a cada segundo

    return () => clearInterval(intervalId);
  }, [startedAtDate, startedAtTime]);

  const duracao = moment.duration(tempoDecorrido);

  return (
    <div className="containerCronometro">
      {duracao.days() >= 1 && (
        <div className="containersDataDay">
          <div className="text">DAYS</div>
          <div className="date">{duracao.days() < 10 ? "0" + duracao.days() : duracao.days()}</div>
        </div>
      )}

      <div className="containersData">
        <div className="text">HRS</div>
        <div className="date">
          {duracao.hours() < 10 ? "0" + duracao.hours() : duracao.hours()}
        </div>
      </div>

      <div className="containersDataPonto">:</div>

      <div className="containersData">
        <div className="text">MINS</div>
        <div className="date">
          {duracao.minutes() < 10 ? "0" + duracao.minutes() : duracao.minutes()}
        </div>
      </div>

      <div className="containersDataPonto">:</div>
      
      <div className="containersData">
        <div className="text">SECS</div>
        <div className="date">
          {duracao.seconds() < 10 ? "0" + duracao.seconds() : duracao.seconds()}
        </div>
      </div>
    </div>
  );
};

export default Cronometro;
