import React, { useState } from "react";
import { useFormik } from "formik";
import ControlledAccordions from "../ControlledAccordions";

import "./TableJobs.css";
import api from "../../../../../Service/api";

const TableJobs = ({ data }) => {
  const [jobs, setJobs] = useState(data);
  const [isLoading, setIsLoading] = useState(false);

  const updatePaymentAdjustment = (targetScheduleId, newValue, index) => {
    const updatedJobs = [...jobs];
    const scheduleIndex = jobs.findIndex((job) =>
      job.attributes.schedules.some((sched) => sched.id === targetScheduleId)
    );

    if (scheduleIndex !== -1) {
      updatedJobs[scheduleIndex].attributes.schedules[index] = {
        ...updatedJobs[scheduleIndex].attributes.schedules[index],
        payment: {
          ...updatedJobs[scheduleIndex].attributes.schedules[index].payment,
          payment_adjustment: newValue,
        },
      };
      setJobs(updatedJobs);
      newExtraPaymentForm.setFieldValue("service_value", 0);
      newExtraPaymentForm.setFieldValue("scheduleId", "");
    } else {
      console.warn("Schedule with ID", targetScheduleId, "not found in jobs");
    }
  };

  const newExtraPaymentForm = useFormik({
    initialValues: {
      service_value: 0,
      scheduleId: "",
    },
  });

  const setValuespAYMNETS = (event, item) => {
    newExtraPaymentForm.setFieldValue("service_value", event.target.value);
    newExtraPaymentForm.setFieldValue("scheduleId", item.id);
  };

  const exportCsv = async () => {
    setIsLoading(true);

    const filteredData = jobs
      .map((item) =>
        item.attributes.schedules.map((schedules) => {
          if (schedules.payment.payment_adjustment !== '') {
            return {
              id: schedules.id,
              payment: {
                payment_adjustment: Number(schedules.payment.payment_adjustment),
              },
            };
          } else {
            return { id: schedules.id };
          }
        })
      )
      .filter((array) => array.length > 0)
      .reduce((acc, curr) => acc.concat(curr), []);

      console.log({
        data: {
          attributes: {
            schedules: filteredData.map((item) => item),
          },
        },
      });

    try {
      const data = await api.post("/admins/cleaners_reports/export", {
        data: {
          attributes: {
            schedules: filteredData.map((item) => item),
          },
        },
      });

      alert(data.message || "CSVs Sent for Cleaning Successfully!");

      setIsLoading(false);
    } catch (erro) {
      console.log(erro);
      alert("Error Sending CSVs for Cleaning");
      setIsLoading(false);
    }
  };

  return (
    <div className="containerJobs">
      <button className="butonExport" onClick={() => exportCsv()}>
        {isLoading ? "SENDING..." : "GENERATE AND SEND ISSUERS"}
      </button>

      {jobs.map((value, key) => (
        <div key={key} className="cardJobs">
          <ControlledAccordions
            value={value}
            key={key}
            updatePaymentAdjustment={updatePaymentAdjustment}
            newExtraPaymentForm={newExtraPaymentForm}
            setValuespAYMNETS={setValuespAYMNETS}
          />
        </div>
      ))}
    </div>
  );
};

export default TableJobs;
