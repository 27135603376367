import React, { useContext } from "react";
import {
  Button,
  Box,
  MenuItem,
  Menu,
  Fade,
  Avatar,
  ListItemIcon,
  Toolbar,
  IconButton
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import TableRowsIcon from '@mui/icons-material/TableRows';
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AppContext from "../../Context/AppContext";

import searchIcon from "../../Assets/search.svg";
import setaUser from "../../Assets/setaUser.svg";
import sino from "../../Assets/sino.svg";
import userIcon from "../../Assets/user.svg";

import "./MainTopBar.css";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "row-reverse",
  borderRadius: "10px",
  backgroundColor: "#C5D4DF",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  height: "48px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    // width: '40%',
  },
  '@media (max-width: 700px)': {
    display: 'none',
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#EDF1F5 !important",
  fontFamily: "Roboto !important",
  fontSize: "20px !important",
  "& .MuiInputBase-input": {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px 0 !important`,
    // vertical padding + font size from searchIcon
    textShadow: `-1px -1px 0px #000, 
    -1px 1px 0px #000,                    
    1px -1px 0px #000,                  
    1px 0px 0px #000 !important`,
    paddingLeft: "10px !important",
    transition: `${theme.transitions.create("width")} !important`,
    width: "100% !important",
    fontWeight: "700 !important",
    [theme.breakpoints.up("md")]: {
      width: "100% !important",
    },
    "&::placeholder": {
      color: "#EDF1F5 !important",
      textShadow: `-1px -1px 0px #000, 
      -1px 1px 0px #000,                    
      1px -1px 0px #000,                  
      1px 0px 0px #000 !important`,
    },
  },
}));

export const MainTopbar = ({ mainSidebarVisible, setMainSidebarVisible }) => {
  const { signOut, user } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let navigate = useNavigate();

  // console.log(user);

  return (
    <Box className="containerTopBar">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setMainSidebarVisible(!mainSidebarVisible)}
          edge="start"
          style={{ color: "#fff" }}
        >
          <TableRowsIcon sx={{ color: '#0e1f41', marginLeft: '25px'}}  fontSize="large"/>
        </IconButton>

      <Search>
        <StyledInputBase
          placeholder="Search..."
          inputProps={{ "aria-label": "search" }}
          style={{ width: "100%" }}
        />
        <SearchIconWrapper>
          <img src={searchIcon} alt="icon Search" />
        </SearchIconWrapper>
      </Search>
      {/* <Button color="inherit" onClick={() => signOut()}>
        <Typography color={"black"} component="div" sx={{ flexGrow: 1 }}>
          Sair
        </Typography>
      </Button> */}
      <div className="containerRightTopBar">
        {/* <ReactSVG src={sino} /> */}
        <div className="containerText">
          <span className="name">{`${user?.firstName}` || undefined}</span>
          <span className="position">{user.adminType}</span>
        </div>
        {/* <ReactSVG src={userIcon} /> */}
        <div>
          <Button
            id="fade-button"
            aria-controls={open ? "fade-menu" : user?.firstName}
            aria-haspopup="true"
            aria-expanded={open ? "true" : user?.firstName}
            onClick={handleClick}
          >
            <ListItemIcon>
              <ReactSVG src={setaUser} style={{ margin: 'auto'}}/>
            </ListItemIcon>
          </Button>
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            {/* <MenuItem
              onClick={() =>
                navigate(`/Profile`, {
                  state: { editAccount: false },
                })
              }
            >
              Profile Settings
            </MenuItem>
            <MenuItem
              onClick={() =>
                navigate(`/Profile`, {
                  state: { editAccount: true },
                })
              }
            >
              Account Settings
            </MenuItem> */}
            <MenuItem onClick={signOut}>Sign Out</MenuItem>
          </Menu>
        </div>
      </div>
    </Box>
  );
};
