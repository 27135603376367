import React, { useState, useContext, useEffect } from "react";
import AppContext from "../../../../Context/AppContext";
import { ModalContainer } from "../../../../Components/ModalContainer";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useFormik } from "formik";
import * as yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import api from "../../../../Service/api";
import iconDelete from "../../../../Assets/Btn Delete.svg";

import "./Cities.css";
import { ReactSVG } from "react-svg";

const renderCustomIcon = (props) => {
  return (
    <ExpandMoreIcon {...props} style={{ color: "#FAFAFA", fontSize: "40px" }} />
  );
};

const Cities = () => {
  const {
    listRegions,
    getCities,
    regionId,
    isLoadingRegionId,
    setIsLoadingId,
  } = useContext(AppContext);
  const [region, setRegion] = useState("");
  const [loading, setLoading] = useState(true);
  const [listCities, setListCities] = useState([]);

  useEffect(() => {
    const getCity = async () => {
      setListCities([]);
      try {
        setLoading(true);
        const data = await api.get(`/admins/regions/${regionId?.id}/cities`);
        setListCities(data);
        setLoading(false);
        setIsLoadingId(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        setIsLoadingId(false);
      }
    };

    if (loading || isLoadingRegionId) {
      getCity();
    }
  }, [isLoadingRegionId, loading, regionId.id, setIsLoadingId]);

  const createCity = async (values) => {
    try {
      setLoading(true);
      await api.post("/admins/cities", { data: { attributes: { ...values } } });
      getCities();
      alert("City has been successfully created.");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const deleteCity = async (city) => {
    try {
      await api.delete(`/admins/cities/${city.id}`);
      getCities();
      alert("The city has been successfully deleted.");
      setLoading(true);
      setIsLoadingId(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const form = useFormik({
    initialValues: {
      name: "",
      state: "",
      country: "",
      regionId: "",
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .min(2, "City must have at least 2 characters.")
        .max(50, "City must have at most 50 characters.")
        .required("City is required!"),
      state: yup
        .string()
        .min(2, "state must have at least 2 characters.")
        .max(50, "state must have at most 50 characters.")
        .required("state is required!"),
      country: yup
        .string()
        .min(2, "country must have at least 2 characters.")
        .max(50, "country must have at most 50 characters.")
        .required("country is required!"),
    }),
    onSubmit: (values) => createCity(values),
  });

  const handleChange = (event) => {
    setRegion(event.target.value);
    form.setFieldValue("regionId", event.target.value.id);
  };

  return (
    <div className="containerCardRegions" style={{ flex: 2, height: "100%" }}>
      <div className="header">
        <div className="title">Cities</div>
      </div>
      <div className="containerAllCardCities">
        {listCities.length > 0 ? (
          <div className="line-container">
            <div className="containerCardCities">
              {listCities
                ?.filter((value) => value.id % 2 !== 0)
                .map((region, idx) => (
                  <div
                    key={idx}
                    className={`card selected`}
                    style={{
                      cursor: "auto",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {region?.name}
                    <ModalContainer
                      customButton={<ReactSVG src={iconDelete} />}
                      type={true}
                      title="Delete City"
                      onClickSave={() => deleteCity(region)}
                      deleteButton={true}
                    >
                      <div
                        className="titleINputModal"
                        style={{ fontWeight: "400" }}
                      >
                        Are you sure you want to delete the city of{" "}
                        <strong>{region.name}</strong>?
                      </div>
                    </ModalContainer>
                  </div>
                ))}
            </div>

            <div className="line" />

            <div className="containerCardCities">
              {listCities
                ?.filter((value) => value.id % 2 === 0)
                .map((region, idx) => (
                  <div
                    key={idx}
                    className={`card selected`}
                    style={{
                      cursor: "auto",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {region?.name}
                    <ModalContainer
                      customButton={<ReactSVG src={iconDelete} />}
                      type={true}
                      title="Delete City"
                      onClickSave={() => deleteCity(region)}
                    >
                      <div
                        className="titleINputModal"
                        style={{ fontWeight: "400" }}
                      >
                        Are you sure you want to delete the city of{" "}
                        <strong>{region.name}</strong>?
                      </div>
                    </ModalContainer>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "20vh",
              width: "100%",
            }}
          >
            {loading ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress size={50} />
              </Box>
            ) : (
              <div className="titleFilter">No matching items found.</div>
            )}
          </div>
        )}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
        }}
      >
        <ModalContainer
          button="+ New City"
          type={true}
          title="New City"
          onClickSave={form.submitForm}
        >
          <div className="titleINputModal">Country</div>
          <div className="containerInputText" style={{ marginBottom: "10px" }}>
            <input
              type="text"
              className="inputText"
              value={form.values.country}
              onChange={(event) =>
                form.setFieldValue("country", event.target.value)
              }
            />
          </div>

          <div className="titleINputModal">State</div>
          <div className="containerInputText" style={{ marginBottom: "10px" }}>
            <input
              type="text"
              className="inputText"
              value={form.values.state}
              onChange={(event) =>
                form.setFieldValue("state", event.target.value)
              }
            />
          </div>

          <div className="titleINputModal">City</div>
          <div className="containerInputText" style={{ marginBottom: "10px" }}>
            <input
              type="text"
              className="inputText"
              value={form.values.name}
              onChange={(event) =>
                form.setFieldValue("name", event.target.value)
              }
            />
          </div>

          <div className="titleINputModal" style={{ marginTop: "15px" }}>
            Region
          </div>
          <div className="containerSelectAdmin">
            <div className="selectedCONtainer">
              <div>{region.name}</div>
            </div>
            <Select
              className="containerIcon"
              onChange={handleChange}
              style={{ height: "50px" }}
              displayEmpty
              renderValue={() => null}
              IconComponent={renderCustomIcon}
            >
              {listRegions?.map((item, idx) => (
                <MenuItem value={item} key={idx}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </ModalContainer>
      </div>
    </div>
  );
};

export default Cities;
