import React, { useState, useEffect, useContext } from "react";
import { AuthorizedLayout } from "../../../Components/AuthorizedLayout";
import { useNavigate, useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import voltarIcon from "../../../Assets/Union (4).svg";

import "./EditCreate.css";
import api from "../../../Service/api";
import { ReactSVG } from "react-svg";
import AppContext from "../../../Context/AppContext";

const EditCreate = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const { items, news } = location.state;
  const { typeCLeaner, typePush } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const tgoBack = () => {
    navigate(-1);
  };

  const handleItens = async (values) => {
    const attributes = {
      title: values?.title,
      messageType: values.messageType,
      body: values?.body,
      subjectType: values.subjectType,
      templateType: typePush ? "push_notification" : "mail",
      protected: true,
      active: true,
    };

    const formValues = {
      data: { attributes },
    };
    setLoading(true);

    try {
      if (values.id !== "") {
        await api.put(`/admins/message_templates/${values.id}`, formValues);
        tgoBack();
        setLoading(false);
      } else {
        await api.post(`/admins/message_templates/`, formValues);
        tgoBack();
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const form = useFormik({
    initialValues: {
      id: items?.id || "",
      title: items?.title || "",
      messageType: items?.message_type || "",
      body: items?.body || "",
      subjectType: typeCLeaner === "Cleaners" ? "cleaner" : "client",
      templateType: "mail",
      protected: true,
      active: true,
    },
    // validationSchema: yup.object({
    //   firstName: yup
    //     .string()
    //     .min(2, "First name must have at least 2 characters.")
    //     .max(50, "First name must have at most 50 characters.")
    //     .required("First name is required!"),
    //   lastName: yup
    //     .string()
    //     .min(2, "Last name must have at least 2 characters.")
    //     .max(50, "Last name must have at most 50 characters.")
    //     .required("Last name is required!"),
    //   email: yup
    //     .string()
    //     .email("Invalid email format.")
    //     .required("Email is required!"),
    //   password: yup
    //     .string()
    //     .min(8, "Password must have at least 8 characters."),
    //   confirmPassword: yup
    //     .string()
    //     .oneOf([yup.ref("password")], "Passwords must match."),
    // }),
    onSubmit: (values) => handleItens(values),
  });

  return (
    <AuthorizedLayout>
      <div className="containerOverview containerAdminControl">
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div className="containerMenuHeaderTampletas">
            <button className="buttonbACK" onClick={() => tgoBack()}>
              {" "}
              <ReactSVG src={voltarIcon} />
              {` Back to ${typeCLeaner} ${
                typePush ? "push notification" : "emails"
              }`}
            </button>

            <div className="containerMenuHeaderEmails">
              <div
                className={
                  typeCLeaner === "Clients"
                    ? "selectedCLeaner"
                    : "notSelectedCLeaner"
                }
              >
                Clients
              </div>
              <div
                className={
                  typeCLeaner === "Cleaners"
                    ? "selectedCLeaner"
                    : "notSelectedCLeaner"
                }
              >
                Cleaners
              </div>
            </div>
          </div>
          <div className="containerEmails">
            <div className="healderNewEditForm">{`${news ? "Create" : "Edit"} ${
              typePush ? "Push Template" : "Email Template"
            }`}</div>

            <div className="titleTextEmail">
              {typePush ? "Push Type" : "Email Type"}
            </div>

            <Box component="form" noValidate autoComplete="off">
              <FormControl sx={{ width: "100%" }}>
                <OutlinedInput
                  placeholder="Email type"
                  value={form.values.title}
                  onChange={(event) =>
                    form.setFieldValue("title", event.target.value)
                  }
                />
              </FormControl>
            </Box>

            <div className="titleTextEmail">
              {typePush ? "Push Subject" : "Email Subject"}
            </div>

            <Box component="form" noValidate autoComplete="off">
              <FormControl sx={{ width: "100%" }}>
                <OutlinedInput
                  placeholder="Email subject"
                  value={form.values.messageType}
                  onChange={(event) =>
                    form.setFieldValue("messageType", event.target.value)
                  }
                />
              </FormControl>
            </Box>

            <div className="titleTextEmail">Message</div>

            <Box component="form" noValidate autoComplete="off">
              <TextField
                id="outlined-multiline-static"
                // label="Multiline"
                onChange={(event) =>
                  form.setFieldValue("body", event.target.value)
                }
                sx={{ width: "100%" }}
                multiline
                rows={10}
                value={form.values.body}
                placeholder="Message"
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
              />
            </Box>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <div
                className="cancelEmails"
                style={{ backgroundColor: "#E64242", cursor: "pointer" }}
                onClick={() => tgoBack()}
              >
                Cancel
              </div>
              <div
                className="cancelEmails"
                style={{ backgroundColor: "#66CDAA", cursor: "pointer" }}
                onClick={form.submitForm}
              >
                {news
                  ? loading
                    ? "Loading..."
                    : "Save Template"
                  : loading
                  ? "Loading..."
                  : "Save Edit"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default EditCreate;
