import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ReactSVG } from "react-svg";
import BtnDelete from "../../../Assets/Btn Delete.svg";
import AlertIcon from "../../../Assets/Group 517.svg";
import api from "../../../Service/api";

import "./DeleteTemplate.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
  '@media (max-width: 700px)': {
    width: '70%',
    textAlign: 'center'
  },
};

export default function DeleteTemplate({ item, setLoading }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loadingButton, setLoadingButton] = React.useState(false);

  const cancelCleaning = async (id) => {
    setLoadingButton(true);
    try {
      await api.delete(`admins/message_templates/${id}`);
      setLoading(true);
      setLoadingButton(false);
      handleClose();
    } catch (error) {
      setLoadingButton(false);
      setLoading(false)
      alert(error?.response?.data?.error)
    }
  };

  return (
    <div>
      <button className="buttonDeleteAdmin" onClick={handleOpen}>
        <ReactSVG src={BtnDelete} />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="containerDeleteTemplate">
            <div className="title">
              Are you sure you want to delete this template?
            </div>
            <ReactSVG src={AlertIcon} />
            <div className="subtitle">
              This action is final and cannot be undone!
            </div>
          <div className="line"></div>
          </div>
          <div className="containerButtonDeleteTemplate">
            <button
              style={{ color: '#0E1F41', backgroundColor: '#fafafa' }}
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              style={{ backgroundColor: "#FF4242", color: '#FAFAFA', border: 'none' }}
              onClick={() => cancelCleaning(item.id)}
            >
              {loadingButton ? "Loading" : "Delete"}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
