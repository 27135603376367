import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import { Input } from "../../../Components/Input";
import { InputsContainer } from "../../../Components/InputsContainer";
import api from "../../../Service/api";


import "./ModalCard.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};


const ModalCard = ({
  title,
  button,
  color,
  padding,
  update,
  setLoading,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loadingButton, setLoadingButton] = useState(false);


  const handleItens = async (values) => {
    setLoadingButton(true);
    try {
      const formValues = {
        data: {
          type: "clients",
          attributes: {
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            phone: values.phone,
          },
        },
      };

      await api.post(`admins/clients/create_ghost_client`, formValues);


      setLoadingButton(false);
      handleClose();
      setLoading();
    } catch (error) {
      console.log(error);
      setLoadingButton(false);
    }
  };

  const phoneRegExp = /^\(\d{3}\)\s*\d{3}-\d{4}$/;

  const newAdminForm = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },
    validationSchema: yup.object({
      firstName: yup
        .string()
        .min(2, "First name must have at least 2 characters.")
        .max(50, "First name must have at most 50 characters.")
        .required("First name is required!"),
      lastName: yup
        .string()
        .min(2, "Last name must have at least 2 characters.")
        .max(50, "Last name must have at most 50 characters.")
        .required("Last name is required!"),
      email: yup
        .string()
        .email("Invalid email format.")
        .required("Email is required!"),
      phone: yup
        .string()
        .min(8, "Phone number must have at least 8 characters."),
    }),
    onSubmit: (values) => handleItens(values),
  });

  return (
    <div>
      <button
        className="openModalAdminCOntrol"
        onClick={handleOpen}
        style={{ backgroundColor: color && color, padding: padding && padding }}
      >
        {button}
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className="containerModalAdminControl">
          <div className="titleModalAdmin">{title}</div>
          <div className="lineAdminControl" />
          <InputsContainer>
            <Input
              placeholder="First Name"
              field="firstName"
              form={newAdminForm}
              assistiveText={{ color: "#0E1F41" }}
            />

            <Input
              placeholder="Last Name"
              field="lastName"
              form={newAdminForm}
              assistiveText={{ color: "#0E1F41" }}
            />

            <Input
              placeholder="Email"
              field="email"
              form={newAdminForm}
              keyboardType="email"
              assistiveText={{ color: "#0E1F41" }}
            />

            <Input
              placeholder="Phone"
              field="phone"
              form={newAdminForm}
              keyboardType="number"
              assistiveText={{ color: "#0E1F41" }}
            />

          </InputsContainer>


          <div className="containerButtonModalAdmin">
            <button className="cancel" onClick={handleClose}>
              Cancel
            </button>
            <button className="create" onClick={newAdminForm.submitForm}>
              {loadingButton ? "Loading" : !update ? "Create" : "Update"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalCard;
