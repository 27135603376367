import React, { useState, useContext } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useFormik } from "formik";
import AppContext from "../../Context/AppContext";
import Logo from "../../Assets/2Sett Logo.svg";
import backgrounIMage from "../../Assets/223516835_Mesa de trabajo 1 1.svg";
import Elementos from "../../Assets/222.svg";
import * as yup from "yup";
import api from "../../Service/api";

import "./ForgotPassword.css";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { signIn } = useContext(AppContext);

  let navigate = useNavigate();

  const handleCheckboxChange = () => {
    setRememberMe(!rememberMe);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle login logic, including the "rememberMe" option
  };

  const handleContinue = async (values) => {
    try {
      setLoading(true);
      await api.post(`/admins/admin_controll/reset_password?email=${values.email}`);
      alert("Success, Check your email to create a new password.");
      navigate("/");
      setLoading(false);
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
        alert("Error, This email is not associated with any account.");
      } else {
        alert("Error, Something happened, please try again.");
      }
    }
    setLoading(false);
  };

  const form = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: yup.object({
      email: yup
        .string()
        .email("Invalid email format.")
        .required("Email is required!"),
    }),

    onSubmit: (values) => handleContinue(values),
  });

  const handleChange = (event) => {
    form.setFieldValue("type", event.target.value);
  };

  const tgoBack = () => {
    navigate(-1);
  };

  return (
    <div className="containerForgot">
      <ReactSVG src={Logo} className="logoSett" />
      <div className="containerLeft"></div>
      <div className="containerRigt">
        <div className="containerINputLogin">
          <div className="title">
            {/* Forgot password,{" "} */}
            <span style={{ color: "#0089ED" }}> Forgot password</span>
          </div>
          <div style={{ width: "90%" }}>
            <div className="errorINputRegi" style={{ marginBottom: "20px" }}>
              <div className="subTitle">Enter your email address</div>
              <input
                className="inputLabelRegis"
                placeholder={"E-mail"}
                type={"email"}
                onChange={(e) => {
                  form.setFieldValue("email", e.target.value);
                }}
                onBlur={() => {
                  form.setFieldTouched("email", true);
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    form.handleSubmit();
                  }
                }}
              />
              {form.touched.email && form.errors.email && (
                <Typography
                  style={{ fontSize: 14, marginLeft: 5, marginTop: 5 }}
                  color={"darkred"}
                >
                  {form.errors.email}
                </Typography>
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              gap: "10px",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
            <button
              className="buttonSigin"
              onClick={() => {
                tgoBack();
              }}
              style={{ backgroundColor: "#1d4e92", width: "30%" }}
            >
              Back
            </button>
            <button
              className="buttonSigin"
              onClick={() => {
                form.handleSubmit();
              }}
              style={{ width: "50%" }}
            >
              {loading ? "Loading..." : "Send new password link"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
