import React, { useState } from "react";

import "./Payments.css";

const Payments = ({
  paymentEntries,
  paymentExits,
  clientsWithPaymentsFailed,
}) => {
  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);

    return formattedValue;
  };

  const [selectedButton, setSelectedButton] = useState("payments_fail");

  const prevWeek = () => {
    setSelectedButton("values");
  };

  const currentWeek = () => {
    setSelectedButton("payments_fail");
  };

  console.log(clientsWithPaymentsFailed);

  return (
    <div className="containerNotificationsDash">
      <div className="containerButtonsNotifications">
        <button
          onClick={currentWeek}
          style={{ color: selectedButton === "payments_fail" && "red" }}
          className={
            selectedButton === "payments_fail"
              ? "selectedButtonStyle"
              : "notSelectedButtonStyle"
          }
        >
          Waiting charge
        </button>

        <button
          onClick={prevWeek}
          className={
            selectedButton === "values"
              ? "selectedButtonStyle"
              : "notSelectedButtonStyle"
          }
        >
          Total Values
        </button>
      </div>

      {selectedButton === "values" ? (
        <div className="containerPaymentDash">
          <div style={{ flex: 1 }} className="cardTotalPayments1">
            <div>Total Entries</div> {`${formatCurrency(paymentEntries)}`}
          </div>
          <div style={{ flex: 1 }} className="cardTotalPayments2">
            <div>Total Exits</div> {`${formatCurrency(paymentExits)}`}
          </div>
        </div>
      ) : (
        <div className="containerCharge">
          {clientsWithPaymentsFailed ? (
            <div>
              {clientsWithPaymentsFailed.map((item) => (
                <div>
                  <div className="titleCharge">{`${item.firstName} ${item.lastName}`}</div>
                  <div className="subtitleCharge">{`Address: ${item.address}`}</div>
                </div>
              ))}
            </div>
          ) : (
            <div className="subtitleNone">
              There are no waiting charge.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Payments;
