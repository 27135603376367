import React, { useState } from "react";
import Modal from "@mui/material/Modal";

import "./PhotosDocumentsModal.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const PhotosDocumentsModal = ({ item }) => {
  const [selectedName, setSelectedName] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  const handleNameClick = (name) => {
    setSelectedName(name);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedName(null);
    setShowModal(false);
  };

  const handleDownload = (imageUrl) => {
    fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'imagem.jpg';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error('Erro ao baixar a imagem:', error);
        });
  };

  const handlePrintClick = () => {
    window.print();
    setShowButtons(false);
  };

  const handleMouseOver = () => {
    setShowButtons(true);
  };

  const handleMouseOut = () => {
    setShowButtons(false);
  };

  return (
    <div>
      {/* <div
        onClick={() => handleNameClick(item?.idFileUrls[0])}
        className="titleDOcument"
      >
        Id Photo
      </div> */}
      {item?.idFileUrls &&
        item?.idFileUrls.map((value, idx) => (
          <div
            onClick={() => handleNameClick(value)}
            className="titleDOcument"
          >
            {`Id Photo - ${idx + 1}`}
          </div>
        ))}
      {item?.documents &&
        item?.documents.map((value, idx) => (
          <div
            key={idx}
            onClick={() => handleNameClick(value.url)}
            className="titleDOcument"
          >
            {`Document - ${idx + 1}`}
          </div>
        ))}
      <Modal
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          {selectedName && (
            <div style={style}>
              {/* <DialogTitle>{selectedName.name}</DialogTitle> */}
              <div
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                style={{ position: "relative", minHeight: "200px" }} // Adicionado estilo
                className="containerPhotoMOdal"
              >
                <img
                  src={selectedName}
                  alt="Documentos"
                  style={{
                    width: "600px",
                    maxHeight: "100%",
                    filter: showButtons ? "blur(8px)" : "none",
                    transition: "filter 0.5s ease",
                  }}
                />
                {showButtons && (
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      gap: "15px",
                    }}
                  >
                    <button
                      onClick={() => handleDownload(selectedName)}
                      className="download buttons"
                    >
                      Download
                    </button>
                    <button
                      onClick={() => handlePrintClick()}
                      className="print buttons"
                    >
                      Print
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default PhotosDocumentsModal;
