import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import "./CardPdfDetails.css";

import iconSeta from "../../../../Assets/Union (6).svg";
import { ReactSVG } from "react-svg";

const generatePDF = (data, id) => {
  const parseData = (date) => {
    return moment(date).utc().add(-5, "hours");
  };

  const formattedDateTime = (date) => {
    return moment(date).isValid()
      ? parseData(date).format("MMM. DD, YYYY | hh:mm A")
      : "Invalid Date";
  };

  const formattedDate = (date) => {
    return moment(date).isValid()
      ? parseData(date).format("MMM. DD, YYYY")
      : "Invalid Date";
  };

  const formattedTime = (date) => {
    return moment(date).isValid()
      ? parseData(date).format("hh:mm A")
      : "Invalid Date";
  };

  const handleTableItem = (label, value) => {
    if (value) {
      switch (label) {
        case "date":
          return formattedDate(value);
        case "time":
          return formattedTime(value);
        case "next_checkin_date":
          return formattedDate(value);
        case "next_checkin_time":
          return formattedTime(value);
        case "airbnb_check_in_at":
          return formattedDateTime(value);
        case "airbnb_check_out_at":
          return formattedDateTime(value);
        case "active_sync":
         return value.toString();
        default:
          return value;
      }
    } else {
      return "none";
    }
  };

  const handleLabel = (label) => {
    switch (label) {
      case "date":
        return "checkout_date";
      case "time":
        return "checkout_time";
      default:
        return label;
    }
  };

  const columns = ["Responsible", "Event", "Date/Time", "Changes"];

  const rows = data.map((row) => [
    row.changed_by_user_id || "x",
    row.operation || "x",
    formattedDateTime(row.changes_made.updated_at.after),
    row.changes_made && Object.keys(row.changes_made).length > 0
      ? Object.keys(row.changes_made)
          .filter((item) => item !== "created_at" && item !== "updated_at")
          .map((item, index) => {
            return `${handleLabel(item)}: \n${
              handleTableItem(item, row.changes_made[item].before) ||
              "undefined"
            } to ${handleTableItem(item, row.changes_made[item].after)}`;
          })
          .join("\n\n")
      : "x",
  ]);

  const doc = new jsPDF();
  const title = `Cleaning #${id} Changelog`;
  doc.text(title, 14, 10);

  doc.autoTable({
    head: [columns],
    body: rows,
    startY: 20,
  });

  const pdfFileName = `Cleaning#${id}.pdf`;
  doc.save(pdfFileName);
};

const CardPdfDetails = ({ data, id }) => {
  return (
    <div className="buttonPdfLog">
      <button onClick={() => generatePDF(data, id)}>Export Table</button>
      <ReactSVG src={iconSeta} />
    </div>
  );
};

export default CardPdfDetails;
