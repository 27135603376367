import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  TextField,
  Button,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    maxHeight: "70vh",
    overflowY: "auto",
    borderRadius: "15px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  },
  listItem: {
    marginBottom: theme.spacing(2),
  },
  hostText: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  clientText: {
    fontStyle: "italic",
    color: theme.palette.secondary.main,
  },
  inputContainer: {
    display: "flex",
    marginTop: theme.spacing(2),
  },
  inputField: {
    flex: 1,
    marginRight: theme.spacing(2),
  },
}));

const ChatScreen = ({ data, cleanerId }) => {
  const classes = useStyles();
  const [messages, setMessages] = useState([
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
    { text: "Olá, como posso ajudar?", sender: "host" },
    { text: "Oi! Preciso de ajuda com algo.", sender: "client" },
  ]);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  console.log(data);

  return (
    <Container maxWidth="md" className={classes.container}>
      <Paper elevation={3} className={classes.paper}>
        <List>
          {data.map((message, index) => (
            <ListItem
              key={index}
              alignItems="flex-start"
              className={classes.listItem}
            >
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    className={
                      message.sender.id === cleanerId
                        ? classes.clientText
                        : classes.hostText
                    }
                  >
                    {message.sender.id === cleanerId
                      ? `Cleaner: ${message.sender.firstName} ${message.sender.lastName}`
                      : `Host: ${message.reciver.firstName} ${message.reciver.lastName}`}
                  </Typography>
                }
                secondary={message.message}
              />
            </ListItem>
          ))}
          <div ref={messagesEndRef} />
        </List>
      </Paper>
    </Container>
  );
};

export default ChatScreen;
