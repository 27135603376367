import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import "./BestTable.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    flex: 1,
  },
  blueRow: {
    backgroundColor: "#D5E7F4",
  },
}));

const BestTable = ({ data, title1, title2, treeCollum, flexNumber }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className="titleTable">{title1}</TableCell>
            <TableCell className="titleTable">{title2}</TableCell>
            {treeCollum && <TableCell className="titleTable">Rating</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) => (
            <TableRow
              key={index}
              className={index % 2 !== 0 ? "" : classes.blueRow}
            >
              <TableCell className="subtitleTable">
                {treeCollum
                  ? `${row.first_name} ${row.last_name}`
                  : row.property_address}
              </TableCell>
              <TableCell className="subtitleTable">{row.job_count}</TableCell>
              {treeCollum && (
                <TableCell className="subtitleTable">{row.rating}</TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BestTable;
