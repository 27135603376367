import React, { useContext, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import api from "../../../Service/api";
import DatePicker from "react-datepicker";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TableJobs from "./components/TableJobs";
import Autocomplete from "./components/Autocomplete";
import moment from "moment";
import AppContext from "../../../Context/AppContext";

import "./Issuers.css";

const initialFilterState = {
  dateFrom: "",
  dateTo: "",
  cleaner: '',
};

const CustomInput = ({ value, onClick, props }) => (
  <button
    onClick={onClick}
    className="containerIcon"
    style={{ cursor: "pointer", height: "55px" }}
  >
    <ExpandMoreIcon {...props} style={{ color: "#FAFAFA", fontSize: "40px" }} />
  </button>
);

const TotalJobs = ({ setListJobs, listJobs, setLoadingJobs, loadingJobs }) => {
  const [filter, setFilter] = useState(initialFilterState);
  const [searched, setSearched] = useState(false);
  const { listCleaners } = useContext(AppContext);
  const [nameCleaner, setNameCleaner] = useState([]);
  const [nameValue, setNameValue] = useState();
  const [searchTerm, setSearchTerm] = useState("");

  const newListCleaner = listCleaners
    ?.map((value) => ({
      id: value.id,
      name: `${value.attributes.firstName} ${value.attributes.lastName}`,
    }))
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  const getList = async () => {
    setLoadingJobs(true);
    setListJobs([]);
    try {
      const { data } = await api.get(
        "/admins/cleaners_reports/cleaners_reports",
        {
          params: {
            from: filter.dateFrom
              ? moment(filter.dateFrom).utc().format("DD/MM/YYYY")
              : "",
            to: filter.dateTo
              ? moment(filter.dateTo).utc().format("DD/MM/YYYY")
              : "",
            cleaner_id: filter.cleaner
          },
        }
      );
      console.log(data);
      setListJobs(data);
      setLoadingJobs(false);
    } catch (erro) {
      console.log(erro);
      setLoadingJobs(false);
      setListJobs(null);
    }
  };


  const handleSearch = () => {
    getList();
    setSearched(true);
  };

  const handleResetFilter = () => {
    setFilter(initialFilterState);
    setListJobs(null);
    setSearched(false);
    setSearchTerm('');
  };

  const handleFromDateChange = (date) => {
    setFilter((prevState) => ({
      ...prevState,
      dateFrom: moment(date).utc().format("MM/DD/YYYY"),
    }));
  };

  const handleToDateChange = (date) => {
    setFilter((prevState) => ({
      ...prevState,
      dateTo: moment(date).utc().format("MM/DD/YYYY"),
    }));
  };

  const handleDateChange = (event, type) => {
    let inputValue = event.target.value;

    inputValue = inputValue.replace(/\D/g, "");

    // Adiciona as barras conforme o usuário digita
    if (inputValue.length > 2) {
      inputValue = inputValue.substring(0, 2) + "/" + inputValue.substring(2);
    }
    if (inputValue.length > 5) {
      inputValue =
        inputValue.substring(0, 5) + "/" + inputValue.substring(5, 9);
    }

    setFilter({ ...filter, [type]: inputValue });
  };

  const handleIdByChange = (newValue) => {
    setNameCleaner(newValue.name);
    setNameValue(nameValue);

    setFilter((prevState) => ({
      ...prevState,
      cleaner: newValue.id,
    }));
  };

  return (
    <>
      <div className="cardIssuer">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "20px",
          }}
        >
          <div className="containerSelectAdmin">
            <div
              className="selectedCONtainer containerBox"
              style={{ width: "105px", padding: "5px" }}
            >
              <span className="contentText">From</span>
              <input
                type="text"
                value={filter.dateFrom}
                onChange={(date) => handleDateChange(date, "dateFrom")}
                placeholder="MM/DD/YYYY"
                style={{ width: "100px", border: "none", fontSize: "15px" }}
              />
            </div>

            <DatePicker
              onChange={handleFromDateChange}
              dateFormat="dd/MM/yyyy"
              customInput={<CustomInput />}
            />
          </div>

          <div className="containerSelectAdmin">
            <div
              className="selectedCONtainer containerBox"
              style={{ width: "105px", padding: "5px" }}
            >
              <span className="contentText">To</span>
              <input
                type="text"
                value={filter.dateTo}
                onChange={(date) => handleDateChange(date, "dateTo")}
                placeholder="MM/DD/YYYY"
                style={{ width: "100px", border: "none", fontSize: "15px" }}
              />
            </div>

            <DatePicker
              onChange={handleToDateChange}
              dateFormat="dd/MM/yyyy"
              customInput={<CustomInput />}
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Autocomplete
              options={newListCleaner}
              data={[""]}
              placeholder="Select cleaner"
              onChange={handleIdByChange}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </div>

          <button
            className="buttonFilter"
            style={{ backgroundColor: "#0e1f41" }}
            onClick={handleSearch}
          >
            Search
          </button>

          <button className="buttonFilter" onClick={handleResetFilter}>
            Clear Selection
          </button>
        </div>
      </div>

      {!loadingJobs && listJobs?.length > 0 ? (
        <>
          <TableJobs data={listJobs} />
        </>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "40vh",
            width: "100%",
          }}
        >
          {loadingJobs && listJobs !== null ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress size={50} />
            </Box>
          ) : (
            <div className="titleFilter">No matching items found.</div>
          )}
        </div>
      )}
    </>
  );
};

export default TotalJobs;
