import React, { useState, useContext } from "react";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import { Input } from "../../../../Components/Input";
import { InputsContainer } from "../../../../Components/InputsContainer";
import { TextField } from "@material-ui/core";
import api from "../../../../Service/api";
import upPhotoIcon from "../../../../Assets/Union (3).svg";

import "./AddDocuments.css";
import { ReactSVG } from "react-svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const AddDocuments = ({ item, setLOading }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const handleItens = async (values) => {
    setLoadingButton(true);

    const attributes = item?.documents ? (
      {documents: [...item?.documents, values.profilePhoto]}
    ) : (
      {documents: [values.profilePhoto]}
    );

    try {
      await api.put(`/admins/cleaners/${item?.id}`, {
        data: {
          attributes: attributes,
        },
      });

      setLoadingButton(false);
      setLOading(true);
      handleClose();
    } catch (error) {
      console.log(error);
      setLoadingButton(false);
    }
  };

  const signInForm = useFormik({
    initialValues: {
      profilePhoto: "",
    },
    onSubmit: (values) => handleItens(values),
  });

  function convertImageToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  }

  function handleFileChange(event) {
    const file = event.target.files[0];

    convertImageToBase64(file)
      .then((base64String) => {
        signInForm.setFieldValue("profilePhoto", base64String);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <div>
      <button
        className="buttonDocument"
        onClick={handleOpen}
        style={{ backgroundColor: "#0E1F41" }}
      >
        Upload Document
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className="containerModalAdminControl">
          <div className="titleModalAdmin">Add Document</div>
          <div className="lineAdminControl" />

          <div>
            <div className="titleInput">Document</div>
            <div
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                marginTop: "5px",
                borderBottom: "1px solid #8b8f93",
                paddingBottom: "6px",
              }}
            >
              <label
                htmlFor="profilePhoto"
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#0E1F41",
                  opacity: "0.3",
                  fontSize: "20px",
                  fontWeight: "400",
                  cursor: "pointer",
                }}
              >
                Upload
                <ReactSVG src={upPhotoIcon} />
              </label>
              <input
                id="profilePhoto"
                name="profilePhoto"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              {signInForm.values.profilePhoto !== "" && (
                <>
                  <span style={{ color: "red" }}>Selected document</span>
                  <button
                    onClick={() => signInForm.setFieldValue("profilePhoto", "")}
                    style={{ color: "red", textAlign: "center" }}
                  >
                    x
                  </button>
                </>
              )}
            </div>
          </div>

          <div className="containerButtonModalAdmin">
            <button className="cancel" onClick={handleClose}>
              Cancel
            </button>
            <button className="create" onClick={signInForm.submitForm}>
              {loadingButton ? "Loading" : "Add"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddDocuments;
