import React, { useContext, useState } from "react";
import { ListItem, ListItemButton, ListItemIcon, Toolbar } from "@mui/material";
import { ReactSVG } from "react-svg";
import { SidebarLink } from "./styles";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useLocation } from "react-router-dom";
import AppContext from "../../Context/AppContext";

import logo from "../../Assets/Sett Logo.svg";
import admin from "../../Assets/adminCOntrol.svg";
import calendar from "../../Assets/calendar.svg";
import cleaners from "../../Assets/cleaners.svg";
import clients from "../../Assets/Clients Icon.svg";
import coupons from "../../Assets/coupons.svg";
import dashboard from "../../Assets/dashboard.svg";
import emails from "../../Assets/emails.svg";
import news from "../../Assets/news.svg";
import overview from "../../Assets/Overview Icon.svg";
import payments from "../../Assets/payments.svg";
import pricing from "../../Assets/pricing.svg";
import push from "../../Assets/push.svg";
import regions from "../../Assets/regions.svg";
import reports from "../../Assets/reports.svg";
import seta from "../../Assets/Seta.svg";

import "./SideBar.css";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} className="acordionCOntainer"/>
))(({ theme }) => ({
  width: "200px",
  border: `none`,
  "&:not(:last-child)": {},
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => {
  const [expanded, setExpanded] = useState(false);
  const { className } = props.children.props;
  return (
    <MuiAccordionSummary
      expandIcon={
        <ReactSVG
          src={seta}
          className={className === "selectedMenu" ? "selectedMenuDash" : ""}
        />
      }
      {...props}
      onClick={() => setExpanded(!expanded)}
    />
  );
})(({ theme }) => ({
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(178deg)",
  },
  "& .MuiAccordionSummary-content": {
    // marginLeft: theme.spacing(1),
    height: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({}));

export const MainListItems = () => {
  const { user } = useContext(AppContext);
  const location = useLocation();
  const urlAtual = location.pathname;
  const [selectedItem, setSelectedItem] = useState(urlAtual.replace(/^\//, ""));
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleItemClick = (item) => {
    // if (item !== "Calendar") {
    //   setExpanded("");
    // }
    setSelectedItem(item);
  };

  function isUserAllowedAdminControll(type) {
    return type === "master" || type === "manager";
  }

  function isUserAllowedPayments(type) {
    return type === "master" || type === "director";
  }

  function isUserAllowedCoupons(type) {
    return type === "master" || type === "manager";
  }

  function isUserAllowedCleaners(type) {
    return type !== "officer";
  }

  function isUserAllowedClients(type) {
    return type !== "officer";
  }

  function isUserAllowedEmailsPushNewsReports(type) {
    return type !== "officer";
  }

  function isUserAllowedPriicngTable(type) {
    return type === "master" || type === "manager" || type === "director";
  }

  function isUserAllowedRegion(type) {
    return type === "master" || type === "director";
  }

  return (
    <div className="containerListItens">
      <SidebarLink to={"/dashboard"}>
        <ListItem
          className={selectedItem === "dashboard" ? "selectedMenuDash menuSidStyle" : "menuSidStyle"}
          onClick={() => handleItemClick("dashboard")}
          // sx={{ marginTop: '100px'}}
        >
          <ListItemIcon>
            <ReactSVG className="iconMenu" src={dashboard} />
          </ListItemIcon>
          <div
            className={
              selectedItem === "dashboard"
                ? "textMenu selectedTextMenu textNone"
                : "textMenu textNone"
            }
          >
            Dashboard
          </div>
        </ListItem>
      </SidebarLink>

      <SidebarLink to={"/overview"}>
        <ListItem
          className={selectedItem === "overview" ? "selectedMenu" : ""}
          onClick={() => handleItemClick("Overview")}
        >
          <ListItemIcon>
            <ReactSVG className="iconMenu" src={overview} />
          </ListItemIcon>
          <div
            className={
              selectedItem === "overview"
                ? "textMenu selectedTextMenu textNone"
                : "textMenu textNone"
            }
          >
            Overview
          </div>
        </ListItem>
      </SidebarLink>

      <div>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          style={{ backgroundColor: "transparent" }}
        >
          <button
            onClick={() => handleItemClick("calendar")}
            style={{
              border: "none",
              backgroundColor: "transparent",
              margin: 0,
              padding: 0,
            }}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              style={{ padding: "0" }}
            >
              <ListItem
                className={
                  selectedItem === "calendar" || selectedItem === "icalendar"
                    ? "selectedMenu"
                    : ""
                }
              >
                <ListItemIcon>
                  <ReactSVG className="iconMenu" src={calendar} />
                </ListItemIcon>
                <div
                  className={
                    selectedItem === "calendar" || selectedItem === "icalendar"
                      ? "textMenu selectedTextMenu textNone"
                      : "textMenu textNone"
                  }
                >
                  Calendar
                </div>
              </ListItem>
            </AccordionSummary>
          </button>
          <AccordionDetails
            style={{ marginTop: "0", paddingTop: "0", width: "100%" }}
          >
            <SidebarLink to={"/calendar"}>
              <div className="itemMenuCalendar">Calendar</div>
            </SidebarLink>

            <SidebarLink to={"/icalendar"}>
              <div className="itemMenuCalendar">Icalendar Accounts</div> 
            </SidebarLink>
          </AccordionDetails>
        </Accordion>
      </div>

      <div className="lineDivision" />

      {isUserAllowedAdminControll(user.adminType) && (
        <SidebarLink to={"/admin-control"}>
          <ListItem
            className={selectedItem === "admin-control" ? "selectedMenu" : ""}
            onClick={() => handleItemClick("admin-control")}
          >
            <ListItemIcon>
              <ReactSVG className="iconMenu" src={admin} />
            </ListItemIcon>
            <div
              className={
                selectedItem === "admin-control"
                  ? "textMenu selectedTextMenu textNone"
                  : "textMenu textNone"
              }
            >
              Admin Control
            </div>
          </ListItem>
        </SidebarLink>
      )}

      {isUserAllowedCleaners(user.adminType) && (
        <SidebarLink to={"/cleaners"}>
          <ListItem
            className={selectedItem === "cleaners" ? "selectedMenu" : ""}
            onClick={() => handleItemClick("cleaners")}
          >
            <ListItemIcon>
              <ReactSVG className="iconMenu" src={cleaners} />
            </ListItemIcon>
            <div
              className={
                selectedItem.includes("cleaners")
                  ? "textMenu selectedTextMenu textNone"
                  : "textMenu textNone"
              }
            >
              Cleaners
            </div>
          </ListItem>
        </SidebarLink>
      )}

      {isUserAllowedClients(user.adminType) && (
        <SidebarLink to={"/clients"}>
          <ListItem
            className={selectedItem === "clients" ? "selectedMenu" : ""}
            onClick={() => handleItemClick("clients")}
          >
            <ListItemIcon>
              <ReactSVG className="iconMenu" src={clients} />
            </ListItemIcon>
            <div
              className={
                selectedItem === "clients"
                  ? "textMenu selectedTextMenu textNone"
                  : "textMenu textNone"
              }
            >
              Clients
            </div>
          </ListItem>
        </SidebarLink>
      )}

      {isUserAllowedCoupons(user.adminType) && (
        <SidebarLink to={"/coupons"}>
          <ListItem
            className={selectedItem === "coupons" ? "selectedMenu" : ""}
            onClick={() => handleItemClick("coupons")}
          >
            <ListItemIcon>
              <ReactSVG className="iconMenu" src={coupons} />
            </ListItemIcon>
            <div
              className={
                selectedItem === "coupons"
                  ? "textMenu selectedTextMenu textNone"
                  : "textMenu textNone"
              }
            >
              Coupons
            </div>
          </ListItem>
        </SidebarLink>
      )}

      {isUserAllowedEmailsPushNewsReports(user.adminType) && (
        <SidebarLink to={"/emails"}>
          <ListItem
            className={selectedItem === "emails" ? "selectedMenu" : ""}
            onClick={() => handleItemClick("emails")}
          >
            <ListItemIcon>
              <ReactSVG className="iconMenu" src={emails} />
            </ListItemIcon>
            <div
              className={
                selectedItem === "emails"
                  ? "textMenu selectedTextMenu textNone"
                  : "textMenu textNone"
              }
            >
              Emails
            </div>
          </ListItem>
        </SidebarLink>
      )}

      {/* {isUserAllowedEmailsPushNewsReports(user.adminType) && (
        <SidebarLink to={"/news"}>
          <ListItem
            className={selectedItem === "news" ? "selectedMenu" : ""}
            onClick={() => handleItemClick("news")}
          >
            <ListItemIcon>
              <ReactSVG className="iconMenu" src={news} />
            </ListItemIcon>
            <div
              className={
                selectedItem === "news"
                  ? "textMenu selectedTextMenu textNone"
                  : "textMenu textNone"
              }
            >
              News
            </div>
          </ListItem>
        </SidebarLink>
      )} */}

      {isUserAllowedPayments(user.adminType) && (
        <SidebarLink to={"/payments"}>
          <ListItem
            className={selectedItem === "payments" ? "selectedMenu" : ""}
            onClick={() => handleItemClick("payments")}
          >
            <ListItemIcon>
              <ReactSVG className="iconMenu" src={payments} />
            </ListItemIcon>
            <div
              className={
                selectedItem === "payments"
                  ? "textMenu selectedTextMenu textNone"
                  : "textMenu textNone"
              }
            >
              Payments
            </div>
          </ListItem>
        </SidebarLink>
      )}

      {isUserAllowedPriicngTable(user.adminType) && (
        <SidebarLink to={"/pricing-table"}>
          <ListItem
            className={selectedItem === "pricing-table" ? "selectedMenu" : ""}
            onClick={() => handleItemClick("pricing-table")}
          >
            <ListItemIcon>
              <ReactSVG className="iconMenu" src={pricing} />
            </ListItemIcon>
            <div
              className={
                selectedItem === "pricing-table"
                  ? "textMenu selectedTextMenu textNone"
                  : "textMenu textNone"
              }
            >
              Pricing Table
            </div>
          </ListItem>
        </SidebarLink>
      )}

      {isUserAllowedEmailsPushNewsReports(user.adminType) && (
        <SidebarLink to={"/push-notification"}>
          <ListItem
            className={
              selectedItem === "push-notification" ? "selectedMenu" : ""
            }
            onClick={() => handleItemClick("push-notification")}
          >
            <ListItemIcon>
              <ReactSVG className="iconMenu" src={push} />
            </ListItemIcon>
            <div
              className={
                selectedItem === "push-notification"
                  ? "textMenu selectedTextMenu textNone"
                  : "textMenu textNone"
              }
            >
              Push Notification
            </div>
          </ListItem>
        </SidebarLink>
      )}

      {isUserAllowedRegion(user.adminType) && (
        <SidebarLink to={"/regions"}>
          <ListItem
            className={selectedItem === "regions" ? "selectedMenu" : ""}
            onClick={() => handleItemClick("regions")}
          >
            <ListItemIcon>
              <ReactSVG className="iconMenu" src={regions} />
            </ListItemIcon>
            <div
              className={
                selectedItem === "regions"
                  ? "textMenu selectedTextMenu textNone"
                  : "textMenu textNone"
              }
            >
              Regions
            </div>
          </ListItem>
        </SidebarLink>
      )}

      {isUserAllowedEmailsPushNewsReports(user.adminType) && (
        <SidebarLink to={"/reports"}>
          <ListItem
            className={selectedItem === "reports" ? "selectedMenu" : ""}
            onClick={() => handleItemClick("reports")}
          >
            <ListItemIcon>
              <ReactSVG className="iconMenu" src={reports} />
            </ListItemIcon>
            <div
              className={
                selectedItem === "reports"
                  ? "textMenu selectedTextMenu textNone"
                  : "textMenu textNone"
              }
            >
              Reports
            </div>
          </ListItem>
        </SidebarLink>
      )}

      <Toolbar />

      <ListItemButton>
        <ListItemIcon>
          <ReactSVG
            className="iconMenu iconeNOneDisplay"
            src={logo}
            // style={{  }}
          />
        </ListItemIcon>
      </ListItemButton>
    </div>
  );
};
