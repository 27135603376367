import React, { useState, useEffect, useContext } from "react";
import { AuthorizedLayout } from "../../Components/AuthorizedLayout";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Input from "@mui/material/Input";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import * as yup from "yup";
import AppContext from "../../Context/AppContext";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import { TextField, Checkbox, FormControlLabel } from "@mui/material";
import { TextField as MuiTextField } from "@mui/material";
import { TextField as MaterialTextField } from "@material-ui/core";
import { ExpandMore } from "@mui/icons-material";

import editButton from "../../Assets/Btn Edit (2).svg";
import cancelButton from "../../Assets/Btn Cancel.svg";

import "./PushNotification.css";
import api from "../../Service/api";

const initialFilterState = {
  name: "",
  type: "",
  region: "",
};

const PushNotification = () => {
  let navigate = useNavigate();
  const {
    listClients,
    listCleaners,
    typeCLeaner,
    setTypeCleaner,
    setTypePush,
    listRegions,
  } = useContext(AppContext);
  const [filteredCleanerData, setFilteredCleanerData] = useState([]);
  const [filteredCLientsData, setFilteredCLientsData] = useState([]);
  const [filter, setFilter] = useState(initialFilterState);
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [typeEmail, setTYpeEmail] = useState([]);
  const [filterName, setFilterName] = useState([]);
  const [emailsTemplatesClients, setEmailsTemplatesClients] = useState([]);
  const [emailsTemplatesCleaners, setEmailsTemplatesCleaners] = useState([]);
  const [editable, setEDitable] = useState(false);
  const [checkedType, setCheckedType] = useState(false);

  const handleChange = (event) => {
    formCoupons.setFieldValue("id", "");
    formCoupons.setFieldValue("title", "");
    formCoupons.setFieldValue("messageType", "");
    formCoupons.setFieldValue("body", "");

    const { id, title, message_type, body } = event.target.value;

    setTYpeEmail(event.target.value);
    formCoupons.setFieldValue("id", id);
    formCoupons.setFieldValue("title", title);
    formCoupons.setFieldValue("messageType", message_type);
    formCoupons.setFieldValue("body", body);
  };

  const handleChangeFilter = (event) => {
    setFilterName(event.target.value);
  };

  const handleTYpe = (type) => {
    setCheckedType(false);
    setFilterName([]);
    setTypeCleaner(type);
  };

  const handleStatusChange = (check) => {
    const newListCleaner = listCleaners?.map((value) => ({
      id: value.id,
      name: `${value.attributes.firstName} ${value.attributes.lastName}`,
    }));

    const newListCLients = listClients?.map((value) => ({
      id: value.id,
      name: `${value.attributes.firstName} ${value.attributes.lastName}`,
    }));

    setCheckedType(!checkedType);

    if (check === false) {
      setFilterName([]);
    }

    if (typeCLeaner === "Cleaners" && checkedType === false) {
      setFilterName([]);
      setFilterName(newListCleaner);
    } else if (typeCLeaner === "Clients" && checkedType === false) {
      setFilterName([]);
      setFilterName(newListCLients);
    }
  };

  useEffect(() => {
    const getUsers = async () => {
      setLoading(true);
      try {
        // const { data } = await api.get("/admins/admin_controll/");

        const templatesClients = await api.get(
          `/admins/message_templates?template_type=push_notification&subject_type=client`
        );

        const templatesCleaners = await api.get(
          `/admins/message_templates?template_type=push_notification&subject_type=cleaner`
        );

        // const newData = await data.map((value) => ({
        //   id: value.id,
        //   type: value.type,
        //   ...value.attributes,
        // }));

        // setListUsers(newData);
        setEmailsTemplatesCleaners(templatesCleaners);
        setEmailsTemplatesClients(templatesClients);
        setLoading(false);
      } catch (error) {
        console.log(error);
        // setListUsers(false);
        setLoading(false);
      }
    };
    if (loading) {
      getUsers();
    }
  }, [loading]);

  useEffect(() => {
    const newListCleaner = listCleaners?.map((value) => ({
      id: value.id,
      name: `${value.attributes.firstName} ${value.attributes.lastName}`,
      region: value.attributes.regions,
    }));

    let filtered = newListCleaner?.filter((item) => {
      const isType = item.adminType === filter.type;
      const isTypeNotSelected = Object.values(filter.type).every(
        (item) => item === ""
      );

      const ids = item.region && item.region.map((item) => item);

      const isRegion = ids.includes(filter.region.name);
      const isRegionNotSelected = Object.values(filter.region).every(
        (item) => item === ""
      );

      const strinName = item.name ?? "";

      return (
        (!filter.name ||
          strinName.toLowerCase().includes(filter.name.toLowerCase())) &&
        (isTypeNotSelected || isType) &&
        (isRegionNotSelected || isRegion)
      );
    });

    setFilteredCleanerData(filtered);
  }, [filter, listCleaners]);

  useEffect(() => {
    const newListCLients = listClients?.map((value) => ({
      id: value.id,
      name: `${value.attributes.firstName} ${value.attributes.lastName}`,
      region: value.attributes.regions,
    }));

    let filtered = newListCLients?.filter((item) => {
      const isType = item.adminType === filter.type;
      const isTypeNotSelected = Object.values(filter.type).every(
        (item) => item === ""
      );

      const ids = item.region && item.region.map((item) => item);

      const isRegion = ids.includes(filter.region.name);
      const isRegionNotSelected = Object.values(filter.region).every(
        (item) => item === ""
      );

      const strinName = item.name ?? "";

      return (
        (!filter.name ||
          strinName.toLowerCase().includes(filter.name.toLowerCase())) &&
        (isTypeNotSelected || isType) &&
        (isRegionNotSelected || isRegion)
      );
    });

    setFilteredCLientsData(filtered);
  }, [filter, listClients]);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleResetFilter = () => {
    setFilter(initialFilterState);
    setFilterName([]);
    setCheckedType(false);
  };

  function handleClick(typeSelected) {
    setTypePush(true);
    navigate("/push/all-templates", {
      state: { typeSelected },
    });
  }

  const handleSend = async (values) => {
    const ids = filterName.map((item) => Number(item.id));
    setLoading(true);
    try {
      await api.post(`admins/push_notifications/send_push`, {
        data: {
          attributes: {
            title: values.title,
            body: values.body,
            subjectsIds: checkedType ? [] : ids,
            subjectsTypes: typeCLeaner === "Cleaners" ? "cleaner" : "client",
            sendAll: checkedType,
          },
        },
      });
      alert("Notification push sent successfully!");
      setLoading(false);
    } catch (error) {
      console.log(error);
      alert(`Error: ${error?.response?.data?.error}`);
      setLoading(false);
    }
  };

  const formCoupons = useFormik({
    initialValues: {
      title: "",
      subjectsTypes: "",
      body: "",
      messageType: "",
    },
    validationSchema: yup.object({
      // title: yup
      //   .string()
      //   .min(2, "title must have at least 2 characters.")
      //   .max(50, "title must have at most 50 characters.")
      //   .required("title is required!"),
      // subjectsTypes: yup
      //   .string()
      //   .min(2, "title must have at least 2 characters.")
      //   .max(50, "title must have at most 50 characters.")
      //   .required("title is required!"),
      // body: yup
      //   .string()
      //   .min(10, "body must have at least 2 characters.")
      //   .max(500, "body must have at most 50 characters.")
      //   .required("body is required!"),
    }),
    onSubmit: (values) => handleSend(values),
  });

  const setTampleteEmail = async () => {
    const attributes = {
      title: formCoupons.values.title,
      messageType: formCoupons.values.messageType,
      body: formCoupons.values.body,
      subjectType: typeCLeaner === "Cleaners" ? "cleaner" : "client",
      templateType: "push_notification",
      protected: true,
      active: true,
    };

    const formValues = {
      data: { attributes },
    };

    setLoadingSave(true);

    try {
      await api.post(`/admins/message_templates/`, formValues);
      setLoading(true);
      setLoadingSave(false);
      alert("Push notification template saved successfully!");
    } catch (error) {
      console.log(error);
      setLoadingSave(false);
    }
  };

  return (
    <AuthorizedLayout>
      <div className="containerOverview containerAdminControl">
        <div className="containerBox"></div>
        <div className="containerFilter">
          <div className="textTitle">Push Notifications</div>

          <div className="titleTextEmailfilter">Send to</div>

          <Input
            placeholder="Name"
            name="name"
            value={filter.name}
            onChange={handleFilterChange}
            disabled={
              typeCLeaner === "Cleaners"
                ? filteredCleanerData.length <= 0
                  ? true
                  : false
                : filteredCLientsData.length <= 0
                ? true
                : false
            }
          />

          <div className="titleFilter">Select by region</div>

          <MaterialTextField
            name="region"
            label={filter.region !== "" ? "" : "Region"}
            select
            value={filter.region}
            onChange={handleFilterChange}
            style={{ borderBottom: "none", width: "100%" }}
            disabled={
              typeCLeaner === "Cleaners"
                ? filteredCleanerData.length <= 0
                  ? true
                  : false
                : filteredCLientsData.length <= 0
                ? true
                : false
            }
          >
            <option value="">None</option>
            {listRegions?.map((value, idx) => (
              <option value={value} key={idx} style={{ cursor: "pointer" }}>
                {value.name}
              </option>
            ))}
          </MaterialTextField>

          <div
            className="titleTextEmailfilter"
            style={{ margin: "15px 0 10px 0" }}
          >
            Sending to
          </div>
          <div className="containerFIlterEmails">
            <FormControl sx={{ width: "100%" }}>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={filterName}
                disabled={
                  typeCLeaner === "Cleaners"
                    ? filteredCleanerData.length <= 0
                      ? true
                      : false
                    : filteredCLientsData.length <= 0
                    ? true
                    : false
                }
                onChange={handleChangeFilter}
                inputProps={{ "aria-label": "Without label" }}
                renderValue={(selected) => (
                  <div className="selectedItemSendEMail">
                    {selected.slice(0, 3).map((value) => (
                      <div
                        key={value}
                        style={{ marginRight: "10px" }}
                        className="name"
                      >
                        {value.name}
                      </div>
                    ))}
                    {selected.length > 3 && (
                      <div style={{ marginRight: "10px" }} className="name">
                        {`+${selected.slice(3).length}`}
                      </div>
                    )}
                  </div>
                )}
                displayEmpty
                IconComponent={ExpandMore}
                sx={{ "& svg": { fontSize: 35, color: "#0E1F41" } }}
              >
                {typeCLeaner === "Cleaners"
                  ? filteredCleanerData
                      ?.slice()
                      .sort((a, b) => a.name.localeCompare(b.name))
                      ?.map((item, idx) => (
                        <MenuItem key={idx} value={item}>
                          {item.name}
                        </MenuItem>
                      ))
                  : filteredCLientsData
                      ?.slice()
                      .sort((a, b) => a.name.localeCompare(b.name))
                      ?.map((item, idx) => (
                        <MenuItem key={idx} value={item}>
                          {item.name}
                        </MenuItem>
                      ))}
              </Select>
            </FormControl>
          </div>

          <FormControlLabel
            control={
              <Checkbox
                checked={checkedType}
                onChange={() => handleStatusChange(!checkedType)}
                name="canceled"
                color="default"
                style={{ color: "#0E1F41", paddingTop: 0, paddingBottom: 0 }}
              />
            }
            label={
              <span
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "20px",
                  color: "#0E1F41",
                }}
              >
                Send to all
              </span>
            }
            style={{ paddingTop: 0, paddingBottom: 0, marginTop: "20px" }}
            align="left"
          />
          <button
            className="buttonFilter"
            onClick={handleResetFilter}
            style={{ marginTop: "30px" }}
          >
            Clear Selection
          </button>
        </div>

        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div className="containerMenuHeaderEmails">
            <div
              className={
                typeCLeaner === "Clients"
                  ? "selectedCLeaner"
                  : "notSelectedCLeaner"
              }
              onClick={() => handleTYpe("Clients")}
            >
              Clients
            </div>
            <div
              className={
                typeCLeaner === "Cleaners"
                  ? "selectedCLeaner"
                  : "notSelectedCLeaner"
              }
              onClick={() => handleTYpe("Cleaners")}
            >
              Cleaners
            </div>
          </div>
          <div className="containerEmails">
            <div className="containerHeaderEmails">
              <div className="titleTextEmail">Push Type</div>
              <div className="button" onClick={() => handleClick(typeCLeaner)}>
                See all templates
              </div>
            </div>

            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              {editable ? (
                <FormControl sx={{ width: "100%" }}>
                  <OutlinedInput
                    placeholder="Title"
                    value={formCoupons.values.title}
                    onChange={(event) =>
                      formCoupons.setFieldValue("title", event.target.value)
                    }
                  />
                </FormControl>
              ) : (
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    value={typeEmail}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty
                    IconComponent={ExpandMore}
                    sx={{ "& svg": { fontSize: 35, color: "#0E1F41" } }}
                    disabled={
                      (typeCLeaner === "Cleaners" &&
                        emailsTemplatesCleaners.length <= 0) ||
                      (typeCLeaner === "Clients" &&
                        emailsTemplatesClients.length <= 0)
                    }
                  >
                    {(typeCLeaner === "Cleaners"
                      ? emailsTemplatesCleaners
                      : emailsTemplatesClients
                    )?.map((item, idx) => (
                      <MenuItem key={idx} value={item}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <div
                style={{ cursor: "pointer" }}
                onClick={() => setEDitable(!editable)}
              >
                {editable ? (
                  <ReactSVG src={cancelButton} />
                ) : (
                  <ReactSVG src={editButton} />
                )}
              </div>
            </div>

            <div className="titleTextEmail">Push Subject</div>

            <Box component="form" noValidate autoComplete="off">
              <FormControl sx={{ width: "100%" }}>
                <OutlinedInput
                  placeholder="push subject"
                  value={formCoupons.values.messageType}
                  onChange={(event) =>
                    formCoupons.setFieldValue("messageType", event.target.value)
                  }
                />
              </FormControl>
            </Box>

            <div className="titleTextEmail">Message</div>

            <Box component="form" noValidate autoComplete="off">
              <TextField
                id="outlined-multiline-static"
                // label="Multiline"
                sx={{ width: "100%" }}
                multiline
                rows={10}
                value={formCoupons.values.body}
                onChange={(event) =>
                  formCoupons.setFieldValue("body", event.target.value)
                }
                placeholder="Message"
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
              />
            </Box>
            <div
              style={{
                display: "flex",
                gap: "20px",
                justifyContent: "flex-end",
              }}
            >
              <div
                className="cancelEmails"
                style={{ backgroundColor: "#66CDAA", cursor: "pointer" }}
                onClick={() => setTampleteEmail()}
              >
                {loadingSave ? "Loading..." : "Save Template"}
              </div>
              <div
                onClick={formCoupons.submitForm}
                className="cancelEmails"
                style={{ backgroundColor: "#0e1f41", cursor: "pointer" }}
              >
                {loading ? "Loading..." : "Send push"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default PushNotification;
