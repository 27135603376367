import React, { useContext, useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import moment from "moment";
import { AuthorizedLayout } from "../AuthorizedLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Table from "./Components/Table";
import CardPdfDetails from "./Components/CardPdfDetails";

import voltarIcon from "../../Assets/Union (4).svg";
// import "./DetailsClient.css";
import api from "../../Service/api";
import DetailsCleaningModal from "../DetailsCleaningModal";
import DetailsScheduleModal from "../DetailsScheduleModal";
import bedroomsIcon from "../../Assets/bedroomsAmount.svg";
import bathroomsIcon from "../../Assets/bathrooms.svg";
import { Refresh } from "@material-ui/icons";
import { IconButton, Tooltip } from "@mui/material";

const ViewLog = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingCleaning, setLoadingCleaning] = useState(true);
  const [data, setData] = useState([]);
  const [cleaningData, setCleaningData] = useState([]);

  const tgoBack = () => {
    setLoading(true);
    navigate(-1);
  };

  const fetchViewLog = async () => {
    setLoading(true);
    try {
      const data = await api.get(`/admins/schedules_audit/${id}`);
      setData(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getCleaning = async () => {
    setLoadingCleaning(true);
    try {
      const { schedules: data } = await api.get(`/admins/overview?id=${id}`);
      setCleaningData(data[0].table);
      setLoadingCleaning(false);
    } catch (error) {
      console.log(error);
      setLoadingCleaning(false);
    }
  };

  const getData = () => {
    fetchViewLog();
    getCleaning();
  };

  const formattedNextDate = (date, time) => {
    return `${moment(date).utc().format("MMM. DD, YYYY")} - ${time}`;
  };

  const updateItens = async () => {
    setCleaningData([]);
    setLoadingCleaning(true);
  };

  const CleaningCard = ({ item }) => {
    return (
      <div
        style={{
          width: "65%",
          backgroundColor: "white",
          position: "relative",
          borderRadius: "5px",
          padding: "15px",
          alignSelf: "center",
          boxShadow: "0px 3px 10px rgba(125, 163, 180, 0.5)",
        }}
      >
        <div className="cardInfos">
          <div className="">
            {`#${item.id}`}
            <div
              className="textCardCleaner"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "15px",
                color: "#66cdaa",
              }}
            >
              {item.status || ""}
            </div>
          </div>
          <div
            className="textDate"
            style={{ fontSize: "25px", textTransform: "uppercase" }}
          >
            {item.address_nickname || "No nickname yet"}
          </div>
          <div className="textDate">
            {formattedNextDate(item.date, item.time)}
          </div>
          <div style={{ display: "flex", gap: "15px" }}>
            <div className="textCardOver">
              {`$${Number(item.value).toFixed(2)}`}
            </div>
            <div
              className="textCardOver"
              style={{ display: "flex", gap: "5px" }}
            >
              <ReactSVG src={bathroomsIcon} />
              {item.bathroomsAmount}
            </div>
            <div
              className="textCardOver"
              style={{ display: "flex", gap: "5px" }}
            >
              <ReactSVG src={bedroomsIcon} />
              {item.bedroomsAmount}
            </div>
          </div>

          <div className="textCardOver">{item.address}</div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="textCardCleaner">
              <div className="userPhoto"></div>
              {item.cleaner || "No Cleaner"}
            </div>

            <div style={{ marginRight: "-5px" }}>
              {item.status === "done" ? (
                <DetailsCleaningModal
                  item={item}
                  audit={false}
                  setLoading={setLoadingCleaning}
                />
              ) : (
                <DetailsScheduleModal
                  data={item}
                  audit={false}
                  updateItens={updateItens}
                  isLoading={loadingCleaning}
                  setIsLOading={setLoadingCleaning}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <AuthorizedLayout>
      {/* <Chat /> */}
      <div className="containerDetailCleaner">
        <div
          className="containerButtonTopHeader"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <button className="buttonbACK" onClick={() => tgoBack()}>
            {" "}
            <ReactSVG src={voltarIcon} /> Back
          </button>

          <CardPdfDetails data={data} id={id} />
        </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "80vh",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <CircularProgress size={50} />
            </Box>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
            }}
          >
            <div
              className="titleModal"
              style={{ alignSelf: "center", marginBottom: "20px" }}
            >{`Cleaning #${id} Changelog`}</div>
            {!loadingCleaning && <CleaningCard item={cleaningData} />}
            <div style={{ alignSelf: "end", marginTop: "5px" }}>
              <Tooltip title="Refresh">
                <IconButton onClick={getData}>
                  <Refresh
                    htmlColor="#66cdaa"
                    style={{ height: "36px", width: "36px" }}
                  />
                </IconButton>
              </Tooltip>
            </div>
            <Table data={data} id={id} />
          </div>
        )}
      </div>
    </AuthorizedLayout>
  );
};

export default ViewLog;
