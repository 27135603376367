import React, { useState, useContext, useEffect } from "react";
import AppContext from "../../../../Context/AppContext";
import { ModalContainer } from "../../../../Components/ModalContainer";
import { ReactSVG } from "react-svg";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useFormik } from "formik";
import * as yup from "yup";

import iconDelete from "../../../../Assets/Btn Delete.svg";

import "./Regions.css";
import api from "../../../../Service/api";

const statusPayments = ["João", "Maria", "José", "Matheus", "Esau"];

const renderCustomIcon = (props) => {
  return (
    <ExpandMoreIcon {...props} style={{ color: "#FAFAFA", fontSize: "40px" }} />
  );
};

const Regions = () => {
  const { listRegions, setRegionId, setIsLoadingId, regionId, getRegions } =
    useContext(AppContext);
  const [regionSelected, setRegionSelected] = useState(regionId);
  const [user, setUser] = React.useState("");
  const [loading, setLoading] = useState(true);
  const [listUses, setListUsers] = useState([]);

  const handleChange = (event) => {
    setUser(event.target.value);
    setRegionId(event.target.value);
  };

  const handleRegion = (event) => {
    setRegionSelected(event);
    setRegionId(event);
    setIsLoadingId(true);
  };

  useEffect(() => {
    const getUsers = async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/admins/admin_controll/");

        const newData = await data?.map((value) => ({
          id: value.id,
          type: value.type,
          ...value.attributes,
        }));

        setListUsers(newData);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setListUsers(false);
      }
    };

    if (loading) {
      getUsers();
    }
  }, [loading]);

  const addAdminRegion = async (values) => {
    try {
      await api.post(`admins/regions/${regionSelected.id}/addAdmin`, {
        data: { attributes: { adminId: Number(user.id) } },
      });
      alert("Admin has successfully added the region.");
      setLoading(true);
      setIsLoadingId(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const deleteRegions = async () => {
    try {
      await api.delete(`/admins/regions/${regionSelected.id}`);
      getRegions();
      alert("The region has been successfully deleted.");
      setLoading(true);
      setIsLoadingId(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const createRegion = async (values) => {
    try {
      setLoading(true);
      await api.post("/admins/regions", {
        data: { attributes: { ...values } },
      });
      getRegions();
      alert("Region has been successfully created.");
      setLoading(false);
    } catch (error) {
      console.log(error);
      alert(
        error?.response?.data.error?.cleaning_service[0]
          ? error.response.data.error.cleaning_service[0]
          : "Error"
      );
      setLoading(false);
    }
  };

  const form = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .min(2, "City must have at least 2 characters.")
        .max(50, "City must have at most 50 characters.")
        .required("City is required!"),
    }),
    onSubmit: (values) => createRegion(values),
  });

  return (
    <div className="containerCardRegions" style={{ flex: 1, height: "100%" }}>
      <div className="header">
        <div className="title">Regions</div>
        <ModalContainer
          button="Add Region Admin"
          type={false}
          title="New Regional Admin"
          onClickSave={addAdminRegion}
        >
          <div className="titleINputModal">Admin</div>
          <div className="containerSelectAdmin">
            <div className="selectedCONtainer">
              <div>{user && `${user.firstName} ${user.lastName}`}</div>
            </div>
            <Select
              className="containerIcon"
              onChange={handleChange}
              style={{ height: "50px" }}
              displayEmpty
              renderValue={() => null}
              IconComponent={renderCustomIcon}
            >
              {listUses?.map((item, idx) => (
                <MenuItem value={item} key={idx}>
                  {`${item.firstName} ${item.lastName}`}
                </MenuItem>
              ))}
            </Select>
          </div>
        </ModalContainer>
      </div>
      <div
        className="containerAllCardCities"
        style={{ flexDirection: "column" }}
      >
        {listRegions?.map((region, idx) => (
          <div
            key={idx}
            onClick={() => handleRegion(region)}
            className={`card ${
              regionSelected.name === region?.name ? "selected" : "notSelected"
            }`}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {region?.name}
            <ModalContainer
              customButton={<ReactSVG src={iconDelete} />}
              type={true}
              title="Delete Region"
              onClickSave={() => deleteRegions()}
              deleteButton={true}
            >
              <div className="titleINputModal" style={{fontWeight: '400'}}>
                Are you sure you want to delete the <strong>{regionSelected.name}</strong> region?
              </div>
            </ModalContainer>
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
        }}
      >
        <ModalContainer
          button="+ New Region"
          type={true}
          title="New Region"
          onClickSave={form.submitForm}
        >
          <div className="titleINputModal">Region Name</div>
          <div className="containerInputText">
            <input
              type="text"
              className="inputText"
              value={form.values.name}
              onChange={(event) =>
                form.setFieldValue("name", event.target.value)
              }
            />
          </div>
          {/* <div className="titleINputModal">Region Admin</div>
          <div className="containerSelectAdmin">
            <div className="selectedCONtainer">
              <div>{user}</div>
            </div>
            <Select
              className="containerIcon"
              onChange={handleChange}
              style={{ height: "50px" }}
              displayEmpty
              renderValue={() => null}
              IconComponent={renderCustomIcon}
            >
              {statusPayments?.map((item, idx) => (
                <MenuItem value={item} key={idx}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </div> */}
        </ModalContainer>
      </div>
    </div>
  );
};

export default Regions;
