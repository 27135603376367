import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import "./CardTable.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    flex: 1,
  },
  blueRow: {
    backgroundColor: "#D5E7F4",
  },
}));

const CardTable = ({ data, title }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className="titleTable">{title}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.blueRow}>
            <TableCell className="subtitleTable" style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>Total:</div>{" "}
              <div style={{ flex: 1 }}>{data?.total}</div>
            </TableCell>
          </TableRow>

          <TableRow className={""}>
            <TableCell className="subtitleTable" style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>New:</div>{" "}
              <div style={{ flex: 1 }}>{data?.new}</div>
            </TableCell>
          </TableRow>

          <TableRow className={classes.blueRow}>
            <TableCell className="subtitleTable" style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>Inactive:</div>{" "}
              <div style={{ flex: 1 }}>{data?.inactive}</div>
            </TableCell>
          </TableRow>

          <TableRow className={""}>
            <TableCell className="subtitleTable" style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>Growth:</div>{" "}
              <div style={{ flex: 1 }}>{data?.growth_rate}%</div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CardTable;
