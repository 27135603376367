import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import deleteIcon from "../../../../Assets/Group 1825.svg";

import "./Step3.css";

const Step3 = ({ form }) => {
  const [newItem, setNewItem] = useState("");

  const optionsCompliment = [
    "Punctual",
    "Attentive",
    "Polite",
    "Followed Instructions",
  ];

  const handleUpdate = (newItem, idx, type, item) => {
    if (item.id) {
      const updatedItems = [...form.values[type]];
      updatedItems[idx].description = newItem;
      form.setFieldValue(`${type}`, updatedItems);
    } else {
      const updatedItems = [...form.values[type]];
      updatedItems[idx] = {description: newItem};
      form.setFieldValue(`${type}`, updatedItems);
    }
  };


  const handleAddItem = (type) => {
    form.setFieldValue(`${type}`, [...form.values[type], newItem]);
    setNewItem("");
  };

  const handleDelete = (index, type, item) => {
    if (item.id) {
      const updatedItems = [...form.values[type]];
      updatedItems[index].delete = true;
      form.setFieldValue(`${type}`, updatedItems);
    } else {
      const updatedItems = form.values[type].filter((_, i) => i !== index);
      form.setFieldValue(`${type}`, updatedItems);
    }
  };


  return (
    <div className="containerStep1">
      <div className="containerCompliment" style={{ marginTop: "20px" }}>
        <div className="title">Consumables</div>

        <div className="checklist">
        <div className="containerCheklist" style={{ height: '400px'}}>
          {form.values.consumablesAttributes.length > 0 &&
            form.values.consumablesAttributes.map((item, idx) => (
              <div
                className="containerInputText"
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <input
                  type="text"
                  className="inputText"
                  value={item.description}
                  style={{
                    textDecoration: `${item.delete ? "line-through" : ""}`,
                  }}
                  onChange={(event) =>
                    handleUpdate(
                      event.target.value,
                      idx,
                      "consumablesAttributes",
                      item
                    )
                  }
                />
                <button
                  onClick={() =>
                    handleDelete(idx, "consumablesAttributes", item)
                  }
                >
                  <ReactSVG src={deleteIcon} style={{ fontSize: "30px" }} />
                </button>
              </div>
            ))}
        </div>
        <div
          className="containerInputText"
          style={{ display: "flex", alignItems: "center", gap: "10px" }}
        >
          <button
            onClick={() => handleAddItem("consumablesAttributes")}
            className="addAnother"
          >
            + Add another
          </button>
        </div>
      </div>
      </div>

    </div>
  );
};

export default Step3;
