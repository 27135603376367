import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import AppContext from "../Context/AppContext";

import Login from "../Pages/Login";
import Dashboard from "../Pages/Dashboard";
import Overview from "../Pages/Overview";
import AdminControl from "../Pages/AdminControl";
import Calendar from "../Pages/Calendar/Calendar";
import Icalendar from "../Pages/Calendar/Icalendar";
import Cleaners from "../Pages/Cleaners";
import DetailsCleaner from "../Pages/Cleaners/DetailsCleaner";
import Clients from "../Pages/Clients";
import DetailsClient from "../Pages/Clients/DetailsClient";
import Coupons from "../Pages/Coupons";
import PushNotification from "../Pages/PushNotification";
import Emails from "../Pages/Emails";
import AllTemplates from "../Pages/Emails/AllTemplates";
import EditCreate from "../Pages/Emails/Components/EditCreate";
import Payments from "../Pages/Payments";
import Regions from "../Pages/Regions";
import Reports from "../Pages/Reports";
import PriceTable from "../Pages/PriceTable";
import NewService from "../Pages/PriceTable/components/NewService";
import NewServiceNotId from "../Pages/PriceTable/components/NewService/indexNotId";
import News from "../Pages/News";
import ViewLog from "../Components/ViewLog";
import ViewChat from "../Components/ViewChat";
import ForgotPassword from '../Pages/ForgotPassword';

const Routers = () => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />

      <Route
        exact
        path="/forgot-password"
        element={
          <PublicRoute>
            <ForgotPassword />
          </PublicRoute>
        }
      />

      <Route
        exact
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/overview"
        element={
          <PrivateRoute>
            <Overview />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/admin-control"
        element={
          <PrivateRoute>
            <AdminControl />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/calendar"
        element={
          <PrivateRoute>
            <Calendar />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/icalendar"
        element={
          <PrivateRoute>
            <Icalendar />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/cleaners"
        element={
          <PrivateRoute>
            <Cleaners />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/cleaners/info/:id"
        element={
          <PrivateRoute>
            <DetailsCleaner />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/clients"
        element={
          <PrivateRoute>
            <Clients />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/clients/info/:id"
        element={
          <PrivateRoute>
            <DetailsClient />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/coupons"
        element={
          <PrivateRoute>
            <Coupons />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/emails"
        element={
          <PrivateRoute>
            <Emails />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/news"
        element={
          <PrivateRoute>
            <News />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/:category/all-templates"
        element={
          <PrivateRoute>
            <AllTemplates />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/:category/all-templates/edit/:id"
        element={
          <PrivateRoute>
            <EditCreate />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/:category/all-templates/new"
        element={
          <PrivateRoute>
            <EditCreate />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/payments"
        element={
          <PrivateRoute>
            <Payments />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/pricing-table"
        element={
          <PrivateRoute>
            <PriceTable />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/pricing-table/new-service"
        element={
          <PrivateRoute>
            <NewServiceNotId />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/pricing-table/new-service/:id"
        element={
          <PrivateRoute>
            <NewService />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/push-notification"
        element={
          <PrivateRoute>
            <PushNotification />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/regions"
        element={
          <PrivateRoute>
            <Regions />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/reports"
        element={
          <PrivateRoute>
            <Reports />
          </PrivateRoute>
        }
      />

      <Route
        path="/:activity/log/:id"
        element={
          <PrivateRoute>
            <ViewLog />
          </PrivateRoute>
        }
      />

      <Route
        path="/:activity/chat/:id"
        element={
          <PrivateRoute>
            <ViewChat />
          </PrivateRoute>
        }
      />

      <Route path="*" element={<h1>404 Not found</h1>} />
    </Routes>
  );
};

export default Routers;

function PublicRoute({ children }) {
  const { user } = useContext(AppContext);

  if (user) {
    const previousPath = localStorage.getItem("previousPath") || "/dashboard";
    return <Navigate to={previousPath} />;
  }

  return children;
}

function PrivateRoute({ children }) {
  const { user, signOut } = useContext(AppContext);

  if (!user) {
    return <Navigate to="/" />;
  }

  if (!user) {
    signOut();
  }

  const currentPath = window.location.pathname;

  const firstPart = currentPath.split("/")[1];

  if (firstPart) {
    localStorage.setItem("previousPath", "/" + firstPart);
  }

  return children;
}
