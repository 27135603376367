import React, { useState, useEffect, useContext } from "react";
import { AuthorizedLayout } from "../../Components/AuthorizedLayout";
import { useNavigate } from "react-router-dom";
import AppContext from "../../Context/AppContext";

import RegionsCard from './components/Regions';
import RegionalAdmin from './components/RegionalAdmin';
import Cities from './components/Cities';

import "./Regions.css";
import api from "../../Service/api";


const Regions = () => {
  let navigate = useNavigate();
  const { listRegions } = useContext(AppContext);

  return (
    <AuthorizedLayout>
      <div className="containerRegions">
        <RegionsCard />
        <Cities />
        <RegionalAdmin />
      </div>
    </AuthorizedLayout>
  );
};

export default Regions;

