import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import moment from "moment";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import ImgPLus from "../../../../Assets/Group 1778.svg";
import ImgLess from "../../../../Assets/Group 1778 (1).svg";
import cleanerNamePayments from "../../../../Assets/cleanerNamePayments.svg";
import cleanerPayments from "../../../../Assets/cleanerPayments.svg";
import mapPayments from "../../../../Assets/mapPayments.svg";
import timePayments from "../../../../Assets/timePayments.svg";
import userPayments from "../../../../Assets/userPayments.svg";
import valuePayments from "../../../../Assets/valuePayments.svg";

import "./CardPayments.css";

const styles = {
  selectedValue: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "28px",
  },
};

// const statusPayments = [
//   { name: "pending", code: 0 },
//   { name: "succeeded", code: 1 },
//   { name: "failed", code: 2 },
//   { name: "refunded", code: 3 },
//   { name: "paid", code: 4 },
//   { name: "waiting_charge", code: 5 },
//   { name: "waiting_capture", code: 6 },
//   { name: "pending_invoice", code: 7 },
// ];

const CardPayments = ({ data, key, entries, setStatus, statusPayments }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 775);
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const checkYpePayout = (ent) => {
    if (ent) {
      return "paymentStatus";
    }
    return "payout";
  };

  const [statusSelected, setStatusSelected] = useState(
    statusPayments.find(
      (value) => value.name === data?.[checkYpePayout(entries)]
    )
  );

  // console.log(data);

  const formattedDate = (date) => {
    return moment(date).utc().format("MMM. DD, YYYY");
  };

  const formattedTtme = (time) => {
    return moment(time).utc().format("HH:mm");
  };

  const handleStatus = (event) => {
    const { value } = event.target;
    setStatusSelected(value);
    setStatus(value.code, data.id);
  };

  function formatString(s) {
    s = s.replace(/_/g, " ");
    s = s.charAt(0).toUpperCase() + s.slice(1);
    s = s.replace(/\s/g, " ");
    return s;
  }

  return (
    <div className={isMobile ? 'containerPaymentCardMobile' : 'containerPaymentCard'}>
      <div className="itemValue">
        <ReactSVG src={entries ? ImgPLus : ImgLess} />
        <div className="title">{`${Number(data?.serviceValue).toFixed(
          2
        )} USD`}</div>
      </div>

      <div className="card">
        <div className="cardItens">
          <div className="containerImg">
            <ReactSVG src={userPayments} />
          </div>
          <div>
            <div className="subtitle">Host</div>
            <div className="title">{data.host ? data.host : "No Host Yet"}</div>
          </div>
        </div>

        <div className="cardItens">
          <div className="containerImg">
            <ReactSVG src={mapPayments} />
          </div>

          <div>
            <div className="subtitle">Region</div>
            <div className="title">
              {data?.region ? data?.region?.name : "Null"}
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="cardItens">
          <div className="containerImg">
            <ReactSVG src={userPayments} />
          </div>

          <div>
            <div className="subtitle">Cleaner</div>
            <div className="title">
              {data.cleaner ? data.cleaner : "No Cleaner Yet"}
            </div>
          </div>
        </div>

        <div className="cardItens">
          <div className="containerImg">
            <ReactSVG src={timePayments} />
          </div>

          <div>
            <div className="subtitle">{formattedTtme(data?.cleaningTime)}</div>
            <div className="title">{formattedDate(data?.cleaningDate)}</div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="cardItens">
          <div className="containerImg">
            <ReactSVG src={cleanerNamePayments} />
          </div>

          <div>
            <div className="subtitle">Cleaning</div>
            <div className="title">{data?.cleaningKind}</div>
          </div>
        </div>

        <div className="cardItens">
          <div className="containerImg">
            <ReactSVG src={valuePayments} />
          </div>

          <div>
            <div className="subtitle">Payment Status</div>
            <div
              style={
                statusSelected
                  ? {
                      ...styles.selectedValue,
                      color:
                        statusSelected.name === "failed" ||
                        statusSelected.name === "refunded" ||
                        statusSelected.name === 'waiting_charge'
                          ? "red"
                          : "#0E1F41",
                    }
                  : {}
              }
            >
              {statusSelected.name || ""}
            </div>
            {/* <TextField
              select
              value={statusSelected}
              onChange={handleStatus}
              style={{ borderBottom: "none", width: "" }}
              InputProps={{
                style: statusSelected ? styles.selectedValue : {},
              }}
            >
              {statusPayments?.map((value, idx) => (
                <option value={value} key={idx} style={{ cursor: "pointer" }}>
                  {formatString(value.name)}
                </option>
              ))}
            </TextField> */}
          </div>
        </div>
      </div>

      <div
        style={{
          flex: 0.5,
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          flexDirection: "column",
          marginRight: "10px",
          height: "70%",
        }}
      >
        <div className="id">{`#${data?.cleaningId}`}</div>
        {/* {entries && data?.paymentStatus === "refunded" && (
          <button style={{ backgroundColor: "red" }}  className="cardButton">Refund</button>
        )}

        {entries && data?.paymentStatus === "failed" && (
          <button style={{ backgroundColor: "red" }} className="cardButton">
            failed
          </button>
        )} */}
      </div>
    </div>
  );
};

export default CardPayments;
