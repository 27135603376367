import React from "react";
import { Bar } from "react-chartjs-2";
import Chart from 'chart.js/auto';

import './GrapCard.css';

const GraphCard = ({ dataMetric }) => {

  // console.log(dataMetric);

  const ListNames = dataMetric?.map((value) => `${value.firstName}`);
  const schedulesCount = dataMetric?.map((value) => value.schedulesCount || value.ratting);

  // console.log(ListNames);

  const data = {
    labels: ListNames,
    datasets: [
      {
        label: '',
        data: schedulesCount,
        backgroundColor: '#75CBEE',
        borderColor: '#75CBEE',
        borderWidth: 0,
      },
    ],
  };
  
  const options = {
    scales: {
      x: {
        type: 'category',
        labels: ListNames,
        display: false,
        grid: {
          display: false,
          drawBorder: true,
          borderWidth: 5,
          borderColor: '#000000',
          tick: {
            width: 0,
            padding: 10,
            font: {
              size: 12,
            },
            color: 'black',
            position: 'inside',
          },
        },
      },
      y: {
        display: false,
        grid: {
          borderColor: 'black',
          borderWidth: 0,
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
      line: {
        tension: 0,
      },
    },
  };
  

  return (
    <div style={{ padding: '20px'}}>
      <Bar
        data={data}
        options={options}
        style={{
          borderBottom: "1px solid #000000",
          borderLeft: "1px solid #000000",
        }}
      />
    </div>
  );
};

export default GraphCard;
