import React, { useState, useContext, useEffect } from "react";
import moment from "moment";
import Modal from "@mui/material/Modal";
import AppContext from "../../../../../Context/AppContext";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { Grid, makeStyles } from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Stack, Tooltip } from "@mui/material";
import Rating from "@mui/material/Rating";
import { ModalContainer } from "../../../../../Components/ModalContainer";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import PhotoCardCarousel from "../../../../../Components/DetailsCleaningModal/Components/PhotoCardCarousel";
import * as yup from "yup";
import api from "../../../../../Service/api";
import DeleteTemplate from "../../../../../Components/DeleteTemplate/DeleteTemplate";

import "./DetailsSchedule.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const styles = {
  selectedValue: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    // lineHeight: "20px",
    color: "#0E1F41",
    backgroundColor: "#86C71C",
    height: "100%",
  },
};

const useStyles = makeStyles((theme) => ({
  gridItem: {
    padding: theme.spacing(2),
  },
}));

const data = {
  id: 169,
  type: "scheduleOverview",
  time: " 8:00am -  9:00am",
  date: "2021-02-25T00:00:00.000-05:00",
  status: "Paid",
  cleanerId: 44,
  cleaner: "Lara  Machado",
  clientId: 185,
  client: "Guilherme Passos",
  acceptanceStatus: "accepted",
  nextCheckinDate: "2021-02-25T00:00:00.000-05:00",
  nextCheckinTime: "2021-02-25T00:00:00.000-05:00",
  address: "Texas, 123, Red Street",
  getIn: {
    id: 75,
    title: "someone_is_home",
    description: "no description",
    address_id: 187,
    created_at: "2021-02-23T15:30:41.667-05:00",
    updated_at: "2021-02-23T15:30:41.667-05:00",
    deleted_at: null,
  },
  bedroomsAmount: "1.0",
  bathroomsAmount: "1.0",
  value: "120.0",
  checkList: ["Item 01", "Item 02", "Item 03", "Item 04"],
  ratting: 3,
  photos: [
    "https://system.soprojetos.com.br/files/1491/project_page_e/PAD-COD43-FOTO-1-WEB.jpg?1659015535",
    "https://system.soprojetos.com.br/files/1491/project_page_e/PAD-COD43-FOTO-1-WEB.jpg?1659015535",
    "https://system.soprojetos.com.br/files/1491/project_page_e/PAD-COD43-FOTO-1-WEB.jpg?1659015535",
    "https://system.soprojetos.com.br/files/1491/project_page_e/PAD-COD43-FOTO-1-WEB.jpg?1659015535",
    "https://system.soprojetos.com.br/files/1491/project_page_e/PAD-COD43-FOTO-1-WEB.jpg?1659015535",
    "https://system.soprojetos.com.br/files/1491/project_page_e/PAD-COD43-FOTO-1-WEB.jpg?1659015535",
  ],
  hostReview:
    "“I hired an outstanding cleaning service that exceeded my expectations. Their professionalism, attention to detail, and exceptional service left me thoroughly impressed. The team transformed my space into a spotless sanctuary, showcasing their skill and dedication. I highly recommend this cleaning service for their outstanding service and meticulousness. A well-deserved five-star experience!”",
};

// const statusPayments = [
//   { name: "pending", code: 0 },
//   { name: "succeeded", code: 1 },
//   { name: "failed", code: 2 },
//   { name: "refunded", code: 3 },
//   { name: "paid", code: 4 },
//   { name: "waiting_charge", code: 5 },
//   { name: "waiting_capture", code: 6 },
//   { name: "pending_invoice", code: 7 },
// ];

const DetailsScheduleModal = ({
  button,
  item,
  entries,
  status,
  setLoading,
  statusPayments,
}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { listCleaners, listRegions } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [nameCleaner, setNameCleaner] = useState("");
  const handleClose = () => setOpen(false);
  const [loadingDetails, setLoadingDetail] = useState(false);
  const [detailsSchedule, setDetailsSchedule] = useState(null);
  const [metadata, setMetadata] = useState({});
  const [openData, setOpenData] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState({});
  const [photosSchedule, setPhotosSchedule] = useState([]);
  const [loadingPhotos, setLoadingPhotos] = useState(false);

  const checkYpePayout = (ent) => {
    if (ent) {
      return "paymentStatus";
    }
    return "payout";
  };

  const [statusSelected, setStatusSelected] = useState(
    statusPayments?.find(
      (value) => value.name === item[checkYpePayout(entries)]
    )
  );

  const handleCloseData = () => {
    setOpenData(false);
    setMetadata({});
  };

  const getDetailUser = async () => {
    setLoadingDetail(true);

    try {
      setLoadingDetail(true);
      const { data } = await api.get(`/admins/schedules/${item.cleaningId}`);

      const newData = {
        id: data.id,
        type: data.type,
        ...data.attributes,
      };

      setDetailsSchedule(newData);
      setLoadingDetail(false);
    } catch (error) {
      console.log(error);
      setLoadingDetail(false);
    }
  };

  const getDetailPhotos = async () => {
    setLoadingPhotos(true);

    try {
      setLoadingPhotos(true);
      const { data } = await api.get(
        `/admins/schedules_images/${item.cleaningId}`
      );

      setPhotosSchedule(data.attributes);
      setLoadingPhotos(false);
    } catch (error) {
      console.log(error);
      setLoadingPhotos(false);
    }
  };

  const handleOpen = () => {
    getDetailUser();
    getDetailPhotos();
    setOpen(true);
  };

  const setStatus = async (code) => {
    try {
      if (entries) {
        await api.patch(`/admins/payments/${item.id}/entries`, {
          data: {
            attributes: {
              status: Number(code),
            },
          },
        });
        alert("Payment status has been successfully changed.");
      } else {
        await api.patch(`/admins/payments/${item.id}/exits`, {
          data: {
            attributes: {
              status: Number(code),
            },
          },
        });
        alert("Payment status has been successfully changed.");
      }
      setLoading(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // console.log(item);

  const handleStatus = (event) => {
    const { value } = event.target;
    setStatusSelected(value);
    setStatus(value.code);
  };

  const newListCleaner = listCleaners?.map((value) => ({
    id: value.id,
    name: `${value.attributes.firstName} ${value.attributes.lastName}`,
  }));

  const inviteCleaner = async () => {
    const selectedId = newListCleaner?.find(
      (value) => value.name === nameCleaner
    );

    const payload = {
      data: {
        attributes: {
          cleanerId: Number(selectedId.id),
        },
      },
    };

    try {
      await api.patch(`/admins/overview/${data.id}/invite_cleaner`, payload);
    } catch (error) {
      console.log(error);
      alert("Error: Cleaner was not added.");
    }
  };

  const cancelCleaning = async () => {
    try {
      await api.patch(`admins/overview/${data.id}/cancel_schedule`);
    } catch (error) {
      console.log(error);
    }
  };

  const formattedDate = (date) => {
    return moment(date).utc().format("MMM. DD, YYYY | hh:mm A");
  };

  const formattedDateNext = (date) => {
    return moment(date).utc().format("MMM. DD, YYYY");
  };

  const capitalize = (str) => {
    if (typeof str !== "string") {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.substr(1);
  };

  function formatText(text) {
    var words = text.split("_");
    words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
    return words.join(" ");
  }

  const handleIdByChange = (event) => {
    setNameCleaner(event.target.value);
  };

  function formatString(s) {
    s = s.replace(/_/g, " ");
    s = s.charAt(0).toUpperCase() + s.slice(1);
    s = s.replace(/\s/g, " ");
    return s;
  }

  const setNewValue = async (values) => {
    try {
      await api.post(`/admins/payments/extra_payment`, {
        data: { attributes: { ...values } },
      });
      setDetailsSchedule();
      getDetailUser();
      alert("Extra payment added successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const newExtraPaymentForm = useFormik({
    initialValues: {
      schedule_id: item?.cleaningId,
      service_value: "",
      observation: "",
      deep_cleaning_extra: false,
    },
    validationSchema: yup.object({
      service_value: yup.string().required("Service value is required!"),
      observation: yup
        .string()
        .min(2, "observation must have at least 2 characters.")
        .max(500, "observation must have at most 500 characters.")
        .required("observation is required!"),
    }),
    onSubmit: (values) => setNewValue(values),
  });

  function navegateLog(id) {
    navigate(`/calendar/log/${id}`);
  }

  const navegateChat = (id) => {
    navigate(`/calendar/chat/${id}`, {
      state: { cleanerId: detailsSchedule.cleanerId },
    });
  };

  function navegateHost(id) {
    navigate(`/clients/info/${id}`, {
      state: { listRegions },
    });
  }

  const handlePhotos = async (values) => {
    try {
      await api.post(`/admins/schedules/${item.cleaningId}/add_clean_photos`, {
        data: {
          attributes: {
            cleanPlacePhotos:
              values.cleanPlacePhotos.map((item) => ({
                data: item.base64,
              })) || [],
            objectPhotos:
              values.objectPhotos.map((item) => ({
                data: item.base64,
              })) || [],

            deepCleanPhotos:
              values.deepCleanPhotos.map((item) => ({
                data: item.base64,
              })) || [],
          },
        },
      });
      getDetailPhotos();
      alert("Photos successfully saved");
    } catch (err) {
      console.log(err);
      alert("error");
    }
  };

  const seeData = (photo) => {
    setSelectedPhoto(photo);
    handleMetadata(photo?.metadata);
    setOpenData(true);
  };

  const handleMetadata = (metadata) => {
    if (metadata) {
      const dataObject = {
        device:
          metadata?.make &&
          metadata?.model &&
          `${metadata?.make} ${metadata?.model}`,

        lens: metadata?.lens_model,

        dateTime:
          (metadata?.date_time || metadata?.date_time_original) &&
          moment(
            metadata?.date_time || metadata?.date_time_original,
            "YYYY-MM-DD HH:mm:ss"
          ).format("MMM DD YYYY · HH:mm:ss"),

        latitude:
          metadata?.gps_latitude &&
          metadata?.gps_longitude &&
          metadata?.gps_latitude !== "0" &&
          metadata?.gps_longitude !== "0" &&
          metadata?.gps_latitude,

        longitude:
          metadata?.gps_latitude &&
          metadata?.gps_longitude &&
          metadata?.gps_latitude !== "0" &&
          metadata?.gps_longitude !== "0" &&
          metadata?.gps_longitude,
      };
      setMetadata(dataObject);
    }
  };

  const openMap = (latitude, longitude) => {
    const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(url, "_blank");
  };

  const formPhotos = useFormik({
    initialValues: {
      cleanPlacePhotos: [],
      objectPhotos: [],
      deepCleanPhotos: [],
      typePhoto: "cleanPlacePhotos",
    },
    // validationSchema: yup.object({
    //   postTitle: yup
    //     .string()
    //     .min(2, "Post Title must have at least 2 characters.")
    //     .max(50, "Post Title must have at most 50 characters.")
    //     .required("Post Title is required!"),
    // }),
    onSubmit: (values) => handlePhotos(values),
  });

  console.log(formPhotos.values.typePhoto);

  const handleChangeType = (event) => {
    formPhotos.setFieldValue(
      event.target.value,
      formPhotos.values[formPhotos.values.typePhoto]
    );
    formPhotos.setFieldValue(formPhotos.values.typePhoto, []);
    formPhotos.setFieldValue("typePhoto", event.target.value);
  };

  const TypePhotoDropdown = (currentUserLevel, handleChangeType) => {
    const adminOptions = [
      { value: "cleanPlacePhotos", label: "Clean place" },
      { value: "objectPhotos", label: "Broken object" },
      { value: "deepCleanPhotos", label: "Deep clean" },
    ];

    return (
      <div className="autocomplete">
        <select
          name="Type of admin"
          value={formPhotos.values.typePhoto}
          onChange={handleChangeType}
          style={{
            borderBottom: "none",
            width: "100%",
            height: "40px",
            borderRadius: "0px",
            color: "black",
          }}
          type=""
        >
          {adminOptions.map((option) => (
            <option
              key={option.value}
              value={option.value}
              style={{ cursor: "pointer" }}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
      // </TextField>
    );
  };

  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [openDeleteCleanPlace, setOpenDeleteCleanPlace] = useState(false);

  const toggleSelectPhoto = (index) => {
    if (selectedPhotos.includes(index)) {
      setSelectedPhotos(selectedPhotos.filter((item) => item !== index));
    } else {
      setSelectedPhotos([...selectedPhotos, index]);
    }
  };

  const cancelDeletePhotos = () => {
    setOpenDeleteCleanPlace(false);
    setSelectedPhotos([]);
  };

  const deletePhotos = async () => {
    try {
      await api.delete("/admins/schedules/delete_photos", {
        data: { photo_ids: selectedPhotos.map((item) => item) },
      });
      getDetailPhotos();
      alert("Photos successfully deleted");
      setOpenDeleteCleanPlace(false);
      setSelectedPhotos([]);
    } catch (err) {
      console.log(err);
      if (err?.response?.data.error) {
        alert(`error: ${err?.response?.data.error}`);
      } else {
        alert("Something unexpected happened. Please try again.");
      }
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div onClick={handleOpen} className="containerButtonPayments">
        {button}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="containerModalDetails" style={style}>
          {detailsSchedule ? (
            <>
              <div className="containerHeader">
                <div className="title">Cleaning Information</div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="text">{`#${detailsSchedule?.id} - `}</div>
                  <button
                    className="buttonViewChat"
                    onClick={() => navegateChat(detailsSchedule.id)}
                  >
                    View chat
                  </button>
                </div>
              </div>
              <div className="containerInfo">
                <div style={{ flex: 1 }} className="containerTexts">
                  <div className="text">
                    <strong>Date:</strong>
                    {` ${formattedDate(detailsSchedule?.date)}`}
                  </div>
                  <div className="text">
                    <strong>Cleaner:</strong>
                    {` ${
                      detailsSchedule?.cleaner
                        ? detailsSchedule?.cleaner
                        : "No Cleaner Yet"
                    }`}
                  </div>

                  <div
                    className="text"
                    onClick={() => navegateHost(detailsSchedule?.clientId)}
                  >
                    <strong>Host:</strong>
                    <span className="houverLinkHost">{` ${
                      detailsSchedule?.host
                        ? detailsSchedule?.host
                        : "No Host Yet"
                    }`}</span>
                  </div>
                  {/* <div className="text">
                    <strong>Next Check-in:</strong>
                    {` ${formattedDateNext(
                      detailsSchedule?.nextCheckinDate
                    )}` || "No next check-in Yet"}
                  </div> */}

                  <ModalContainer
                    onClickSave={newExtraPaymentForm.submitForm}
                    customButton={
                      <button
                        className="buttonAddPayments"
                        style={{ width: "100%" }}
                      >
                        Add Extra Payment
                      </button>
                    }
                    type={false}
                    title="Add Extra Payment"
                  >
                    <div className="titleINputModal">Service value:</div>
                    <div
                      className="containerInputText"
                      style={{ marginBottom: "10px" }}
                    >
                      <input
                        type="number"
                        className="inputText"
                        value={newExtraPaymentForm.values.service_value}
                        onChange={(event) =>
                          newExtraPaymentForm.setFieldValue(
                            "service_value",
                            event.target.value
                          )
                        }
                      />
                    </div>
                    <div className="titleINputModal">Observation:</div>
                    <div
                      className="containerInputText"
                      style={{ marginBottom: "10px" }}
                    >
                      <input
                        type="text"
                        className="inputText"
                        value={newExtraPaymentForm.values.observation}
                        onChange={(event) =>
                          newExtraPaymentForm.setFieldValue(
                            "observation",
                            event.target.value
                          )
                        }
                      />
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() =>
                            newExtraPaymentForm.setFieldValue(
                              "deep_cleaning_extra",
                              !newExtraPaymentForm.values.deep_cleaning_extra
                            )
                          }
                          checked={
                            newExtraPaymentForm.values.deep_cleaning_extra
                          }
                          name="type"
                          color="default"
                          style={{
                            color: "#0E1F41",
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                        />
                      }
                      label={
                        <span
                          style={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "20px",
                            color: "#0E1F41",
                          }}
                        >
                          Deep cleaning extra
                        </span>
                      }
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                      align="left"
                    />
                  </ModalContainer>
                  <button
                    className="completeButtonModal"
                    style={{ backgroundColor: "#0e1f41", width: "100%" }}
                    onClick={() => navegateLog(item.id)}
                  >
                    View Log
                  </button>

                  <ModalContainer
                    onClickSave={formPhotos.submitForm}
                    customButton={
                      <button
                        className="buttonAddPayments"
                        style={{ width: "100%" }}
                      >
                        Add Photos
                      </button>
                    }
                    type={false}
                    title="Add Photos"
                  >
                    <div className="titleINputModal">Type:</div>
                    {TypePhotoDropdown("cleanPlacePhotos", handleChangeType)}
                    <div
                      className="titleINputModal"
                      style={{ marginTop: "10px" }}
                    >
                      Photos:
                    </div>
                    <div
                      className="containerInputText"
                      style={{ marginBottom: "10px" }}
                    >
                      <PhotoCardCarousel
                        form={formPhotos}
                        objectPhotos={formPhotos.values.typePhoto}
                      />
                    </div>
                  </ModalContainer>
                </div>

                <div style={{ flex: 1 }} className="containerTexts">
                  <div className="text">
                    <strong>Address:</strong>
                    {` ${
                      detailsSchedule?.address
                        ? detailsSchedule?.address
                        : "No address Yet"
                    }`}
                  </div>
                  <div className="text">
                    {detailsSchedule?.getIn?.title && (
                      <>
                        <strong>{`${formatText(
                          detailsSchedule?.getIn?.title
                        )}:`}</strong>
                        {` ${formatText(detailsSchedule?.getIn?.description)}`}
                      </>
                    )}
                  </div>
                  <div className="text">
                    <strong>Rooms:</strong>
                    {` ${
                      detailsSchedule?.rooms
                        ? detailsSchedule?.rooms
                        : "No Rooms Yet"
                    }`}
                  </div>
                  <div className="text">
                    <strong>Bathrooms:</strong>
                    {` ${
                      detailsSchedule?.bathrooms
                        ? detailsSchedule?.bathrooms
                        : "No Bathrooms Yet"
                    }`}
                  </div>
                  <div className="text">
                    <strong>Value:</strong>
                    {`${Number(item?.serviceValue).toFixed(2)} USD`}
                  </div>

                  {statusPayments ? (
                    <div className="text">
                      <strong>Status:</strong>
                      <TextField
                        select
                        value={statusSelected}
                        onChange={handleStatus}
                        style={{
                          borderBottom: "none",
                          width: "",
                          height: "25px",
                        }}
                        InputProps={{
                          style: statusSelected ? styles.selectedValue : {},
                        }}
                      >
                        {statusPayments?.map((value, idx) => (
                          <option
                            value={value}
                            key={idx}
                            style={{ cursor: "pointer", marginBottom: "10px" }}
                          >
                            {formatString(value.name)}
                          </option>
                        ))}
                      </TextField>
                    </div>
                  ) : (
                    <div className="text">
                      <strong>Status:</strong>
                      <div>{item.paymentStatus}</div>
                    </div>
                  )}

                  {detailsSchedule?.extraPayments && (
                    <div className="text">
                      <strong style={{ marginBottom: "8px" }}>
                        Extra Payments:
                      </strong>
                      {detailsSchedule?.extraPayments?.map((item, key) => (
                        <div className="text">
                          <strong>Value:</strong>
                          {` ${item?.value}.00 USD `}
                          <strong>Reason:</strong>
                          {` ${item?.observation || ""}`}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div>
                <div className="containerReview">
                  <div className="containerHeaderReview">
                    <div className="title">Host Review</div>
                    <div>
                      <Stack spacing={1}>
                        <Rating
                          name="half-rating-read"
                          defaultValue={
                            Number(detailsSchedule?.cleanerReview?.nps) || 0
                          }
                          precision={0.5}
                          readOnly
                          sx={{ color: "#0E1F41", fontSize: "26px" }}
                        />
                      </Stack>
                    </div>
                  </div>

                  <div className="text">
                    {detailsSchedule?.hostReview || ""}
                  </div>
                </div>

                <div className="containerCuston">
                  <div className="containerCheckList" style={{ flex: 1 }}>
                    <div className="title">Custom Check List</div>

                    {detailsSchedule?.customChecklist.length > 0 ? (
                      detailsSchedule?.customChecklist?.map((item, idx) => (
                        <FormControlLabel
                          key={idx}
                          control={
                            <Checkbox
                              checked={true}
                              // onChange={handleStatusChange}
                              name={item.description}
                              color="default"
                              style={{
                                color: "#0E1F41",
                                paddingTop: 0,
                                paddingBottom: 0,
                              }}
                            />
                          }
                          label={
                            <span
                              className="titleSapnMObile"
                              style={{
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "20px",
                                color: "#0E1F41",
                              }}
                            >
                              {item.description}
                            </span>
                          }
                          style={{ paddingTop: 0, paddingBottom: "0" }}
                          align="left"
                        />
                      ))
                    ) : (
                      <div className="text">Nothing to show</div>
                    )}
                  </div>

                  <div style={{ flex: 1 }}>
                    <div className="title">Damage Reports</div>
                    <div className="text">
                      {detailsSchedule?.cleanerReview?.broken_objects === true
                        ? detailsSchedule.cleanerReview.incident_description
                        : "Nothing to show"}
                    </div>

                    <div className="title">Inventory Report</div>
                    {detailsSchedule.inventoryReport.length > 0 ? (
                      detailsSchedule?.inventoryReport?.map((item, idx) => (
                        <FormControlLabel
                          key={idx}
                          control={
                            <Checkbox
                              checked={true}
                              // onChange={handleStatusChange}
                              name={item.description}
                              color="default"
                              style={{
                                color: "#0E1F41",
                                paddingTop: 0,
                                paddingBottom: 0,
                              }}
                            />
                          }
                          label={
                            <span
                              className="titleSapnMObile"
                              style={{
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "20px",
                                color: "#0E1F41",
                              }}
                            >
                              {item.description}
                            </span>
                          }
                          style={{ paddingTop: 0, paddingBottom: "0" }}
                          align="left"
                        />
                      ))
                    ) : (
                      <div className="text">Nothing to show</div>
                    )}
                  </div>
                </div>

                <div className="titleAll">Lost and Found</div>
                <div className="text">
                  {detailsSchedule?.cleanerReview?.broken_objects === true
                    ? detailsSchedule.cleanerReview.incident_description
                    : "Nothing to show"}
                </div>

                {(photosSchedule?.deepCleanPhotos?.length > 0 ||
                  photosSchedule?.objectPhotos?.length > 0 ||
                  photosSchedule?.cleanPlacePhotos?.length > 0) && (
                  <div className="containerHeaderCheck">
                    <div className="titleAll"></div>
                    {openDeleteCleanPlace ? (
                      <div style={{ display: "flex", gap: "10px" }}>
                        <button onClick={cancelDeletePhotos}>Cancel</button>

                        <DeleteTemplate
                          funcionDelete={deletePhotos}
                          title={
                            "Are you sure you want to delete these photos?"
                          }
                        />
                      </div>
                    ) : (
                      <button
                        onClick={() => setOpenDeleteCleanPlace(true)}
                        style={{
                          backgroundColor: "#dd3545",
                          width: "150px",
                        }}
                      >
                        Delete Photos
                      </button>
                    )}
                  </div>
                )}

                <div>
                  {photosSchedule?.deepCleanPhotos &&
                    photosSchedule?.deepCleanPhotos?.length > 0 && (
                      <>
                        <div className="titleAll">Deep clean Photos</div>

                        <Grid container spacing={2}>
                          {photosSchedule?.deepCleanPhotos?.map(
                            (photo, index) => (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                key={index}
                                className={classes.gridItem}
                              >
                                <Tooltip
                                  title={"Click on image to see metadata"}
                                  enterDelay={1000}
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <img
                                      src={photo.url}
                                      alt={`schdule ${index + 1}`}
                                      style={{ width: "100%" }}
                                      onClick={() => seeData(photo)}
                                    />
                                    {openDeleteCleanPlace && (
                                      <Checkbox
                                        style={{
                                          position: "absolute",
                                          top: 5,
                                          right: 5,
                                          color: "white",
                                        }}
                                        checked={selectedPhotos.includes(
                                          photo.id
                                        )}
                                        onChange={() =>
                                          toggleSelectPhoto(photo.id)
                                        }
                                      />
                                    )}
                                  </div>
                                </Tooltip>
                              </Grid>
                            )
                          )}
                        </Grid>
                      </>
                    )}
                </div>

                <div className="containerCleaningsPhotos">
                  <div>
                    {photosSchedule?.objectPhotos &&
                      photosSchedule?.objectPhotos?.length > 0 && (
                        <>
                          <div className="titleAll">Broken Photos</div>

                          <Grid container spacing={2}>
                            {photosSchedule?.objectPhotos.map(
                              (photo, index) => (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  key={index}
                                  className={classes.gridItem}
                                >
                                  <Tooltip
                                    title={"Click on image to see metadata"}
                                    enterDelay={1000}
                                  >
                                    <div
                                      style={{
                                        position: "relative",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <img
                                        src={photo.url}
                                        alt={`schdule ${index + 1}`}
                                        style={{ width: "100%" }}
                                        onClick={() => seeData(photo)}
                                      />
                                      {openDeleteCleanPlace && (
                                        <Checkbox
                                          style={{
                                            position: "absolute",
                                            top: 5,
                                            right: 5,
                                            color: "white",
                                          }}
                                          checked={selectedPhotos.includes(
                                            photo.id
                                          )}
                                          onChange={() =>
                                            toggleSelectPhoto(photo.id)
                                          }
                                        />
                                      )}
                                    </div>
                                  </Tooltip>
                                </Grid>
                              )
                            )}
                          </Grid>
                        </>
                      )}
                  </div>

                  <div>
                    {photosSchedule?.cleanPlacePhotos?.length > 0 && (
                      <>
                        <div className="titleAll">Clean Place Photos</div>

                        <Grid container spacing={2}>
                          {photosSchedule?.cleanPlacePhotos?.map(
                            (photo, index) => (
                              <Grid item xs={12} sm={6} md={4} key={index}>
                                <Tooltip
                                  title="Click on image to see metadata"
                                  enterDelay={1000}
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <img
                                      src={photo.url}
                                      alt={`schdule ${index + 1}`}
                                      style={{ width: "100%" }}
                                      onClick={() => seeData(photo)}
                                    />
                                    {openDeleteCleanPlace && (
                                      <Checkbox
                                        style={{
                                          position: "absolute",
                                          top: 5,
                                          right: 5,
                                          color: "white",
                                        }}
                                        checked={selectedPhotos.includes(
                                          photo.id
                                        )}
                                        onChange={() =>
                                          toggleSelectPhoto(photo.id)
                                        }
                                      />
                                    )}
                                  </div>
                                </Tooltip>
                              </Grid>
                            )
                          )}
                        </Grid>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <CircularProgress size={50} />
              </Box>
            </div>
          )}
        </div>
      </Modal>

      <Modal
        open={openData}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseData();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="containerModalData" style={style}>
          <button
            onClick={handleCloseData}
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",

              backgroundColor: "#E64242",
              width: "45px",
              height: "45px",

              borderRadius: "2.5px",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "36px",
              lineHeight: "19px",

              color: "#F1FCFF",
              border: "none",
              cursor: "pointer",
              textAlign: "center",
            }}
          >
            X
          </button>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              style={{
                justifyContent: "center",
                width: "55%",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={selectedPhoto.url}
                style={{
                  width: "100%",
                  backgroundColor: "white",
                  padding: "10px",
                  borderRadius: "2.5px",
                }}
              />
            </div>
            <div
              style={{
                width: "45%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  justifyContent: "center",
                  marginLeft: "30px",
                  width: "100%",

                  borderRadius: "2.5px",
                  padding: "10px",
                }}
              >
                {metadata?.dateTime ? (
                  <div className="containerInfo">
                    <div style={{ flex: 1 }} className="containerTexts">
                      {metadata?.device && (
                        <div className="text">
                          <strong>Device</strong>
                          {metadata?.device}
                        </div>
                      )}

                      {metadata?.lens && (
                        <div className="text">
                          <strong>Lens</strong>
                          {metadata?.lens}
                        </div>
                      )}

                      {metadata?.dateTime && (
                        <div className="text">
                          <strong>Date and Time</strong>
                          {metadata?.dateTime}
                        </div>
                      )}

                      {metadata?.latitude && metadata?.longitude && (
                        <>
                          <div className="text">
                            <strong>Latitude</strong>
                            {metadata?.latitude}
                          </div>
                          <div className="text">
                            <strong>Longitude</strong>
                            {metadata?.longitude}
                          </div>
                          <button
                            className="completeButtonModal"
                            style={{
                              backgroundColor: "#66CDAA",
                              width: "100%",
                            }}
                            onClick={() =>
                              openMap(metadata?.latitude, metadata?.longitude)
                            }
                          >
                            See on Google Maps
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  "No metadata found"
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DetailsScheduleModal;
