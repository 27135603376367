import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import Input from "@mui/material/Input";
import { ModalContainer } from "../../../Components/ModalContainer";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import AppContext from "../../../Context/AppContext";
import DetailsScheduleModal from "./components/DetailsScheduleModal";
import CardPdfDetails from "./components/CardPdfDetails";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import iconSeta from "../../../Assets/Union (6).svg";
import jsPDF from "jspdf";

import CardPayments from "./components/CardPayments";

import "./CardsEntrieAndExits.css";
import api from "../../../Service/api";
import { ReactSVG } from "react-svg";
const XLSX = require("xlsx");

const initialFilterState = {
  fromDate: "",
  toDate: "",
  cleanerName: "",
  hostName: "",
  status: {
    pending: false,
    succeeded: false,
    failed: false,
    refunded: false,
    paid: false,
    waiting_charge: false,
    waiting_capture: false,
    pending_invoice: false,
    deepCleaning: false,
  },
  region: "",
  city: "",
};

const styles = {
  selectedValue: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "28px",
    color: "#0E1F41",
  },
};

// const statusPayments = ["refunded", "pending", "paid", "succeeded", "failed"];

// const statusPayments = [
//   { name: "pending", code: 0 },
//   { name: "succeeded", code: 1 },
//   { name: "failed", code: 2 },
//   { name: "refunded", code: 3 },
//   { name: "paid", code: 4 },
//   { name: "waiting_charge", code: 5 },
//   { name: "waiting_capture", code: 6 },
//   { name: "pending_invoice", code: 7 },
// ];

const statusEntrie = [
  { name: "pending", code: 0 },
  { name: "succeeded", code: 1 },
  { name: "failed", code: 2 },
  { name: "refunded", code: 3 },
  { name: "paid", code: 4 },
  { name: "waiting_charge", code: 5 },
  { name: "waiting_capture", code: 6 },
  { name: "pending_invoice", code: 7 },
];

const statusExits = [
  { name: "pending", code: 0 },
  { name: "paid", code: 1 },
  { name: "penalty", code: 2 },
  { name: "partially", code: 3 },
];

const CardsEntrieAndExits = ({
  data,
  entries,
  setStatus,
  setLoading,
  filter,
  setFilter,
  handleSearch,
  searched,
  handleResetFilter,
  handleChange,
  total,
  currentPage,
  isLoading,
  statusSelected,
  setStatusSelected,
  selectedCards,
  setSelectedCards,
  selected,
}) => {
  // const [data, setdata] = useState([]);
  const [open, setOpen] = useState(false);
  const { listRegions, listCities } = useContext(AppContext);
  const [statusSelectedFetch, setStatusSelectedFetch] = useState("");
  const [loadingDoc, setLoadingDoc] = useState(false);
  const [dataDoc, setDataDoc] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 775);
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const statusPage = entries ? statusEntrie : statusExits;

  const checkYpePayout = (ent) => {
    if (ent) {
      return "paymentStatus";
    }
    return "payout";
  };

  const handleCheckboxChange = (cardId) => (event) => {
    // console.log(cardId);
    setSelectedCards((prevSelectedCards) => ({
      ...prevSelectedCards,
      [cardId]: event.target.checked,
    }));
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const generateExcel = (data) => {
    const formattedDate = (date) => {
      return moment(date).utc().format("MM/DD/YYYY");
    };

    // console.log(data);

    const workbook = XLSX.utils.book_new();
    const worksheetData = [
      [
        "ID",
        "Host",
        "Property nick name",
        "Date",
        "Value",
        "Cleaner",
        "Payment Status",
        "Deep clean",
        "Region",
      ],
      ...data.map((row) => [
        row.cleaningId,
        row.host ? row.host : "No Host Yet",
        row.propertyNickname ? row.propertyNickname : "No nick name Yet",
        `${formattedDate(row?.cleaningDate)}`,
        { t: "n", v: Number(row?.serviceValue).toFixed(2) },
        row.cleaner ? row.cleaner : "No Cleaner Yet",
        row?.[checkYpePayout(entries)],
        row?.deepClean ? "Y" : "N",
        row?.region ? row?.region?.name : "No Region Yet",
      ]),
    ];

    // console.log(data);

    const ws = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, ws, "Payment Report");

    // Define os filtros para as colunas A até H (0 a 7)
    const filterRange = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 7, r: data.length },
    });
    ws["!autofilter"] = { ref: filterRange };

    const excelFileName = "payment_report.xlsx";
    XLSX.writeFile(workbook, excelFileName);
  };

  // useEffect(() => {
  //   setIsLOading(true);
  //   setFilteredData([]);
  //   let filtered = data?.filter((item) => {
  //     const itemDate = moment(item.cleaningDate);

  //     // const idsCIties = item?.cities?.map((city) => city.id);
  //     const idsRegions = Number(item?.region?.id);

  //     // const isCity = idsCIties.includes(Number(filter.city));
  //     // const isCityNotSelected = Object.values(filter.city).every(
  //     //   (item) => item === ""
  //     // );

  //     const isRegions = idsRegions === Number(filter.region);
  //     const isRegionNotSelected = Object.values(filter.region).every(
  //       (item) => item === ""
  //     );

  //     const allFalse = Object.values(filter.status).every(
  //       (item) => item === false
  //     );
  //     const isrefunded =
  //       filter.status.refunded && item[checkYpePayout(entries)] === "refunded";
  //     const ispending =
  //       filter.status.pending && item[checkYpePayout(entries)] === "pending";
  //     const ispaid = filter.status.paid && item[checkYpePayout(entries)] === "paid";
  //     const issucceeded =
  //       filter.status.succeeded && item[checkYpePayout(entries)] === "succeeded";
  //     const isfailed = filter.status.failed && item[checkYpePayout(entries)] === "failed";
  //     const ischarge =
  //       filter.status.waiting_charge &&
  //       item[checkYpePayout(entries)] === "waiting_charge";
  //     const iscapture =
  //       filter.status.waiting_capture &&
  //       item[checkYpePayout(entries)] === "waiting_capture";
  //     const isinvoice =
  //       filter.status.pending_invoice &&
  //       item[checkYpePayout(entries)] === "pending_invoice";
  //     const isDeepCleaning = filter.status.deepCleaning && item?.deepClean;

  //     const cleanerString = item.cleaner ?? "";
  //     const clientString = item.host ?? "";

  //     const fromDate = moment(filter.fromDate);
  //     const toDate = moment(filter.toDate);
  //     const isWithinRange =
  //       (filter.fromDate === "" || itemDate.isSameOrAfter(fromDate, "day")) &&
  //       (filter.toDate === "" || itemDate.isSameOrBefore(toDate, "day"));

  //     return (
  //       (!filter.cleanerName ||
  //         cleanerString
  //           .toLowerCase()
  //           .includes(filter.cleanerName.toLowerCase())) &&
  //       (!filter.hostName ||
  //         clientString.toLowerCase().includes(filter.hostName.toLowerCase())) &&
  //       (isRegions || isRegionNotSelected) &&
  //       (allFalse ||
  //         issucceeded ||
  //         isfailed ||
  //         isDeepCleaning ||
  //         ispaid ||
  //         ispending ||
  //         ischarge ||
  //         iscapture ||
  //         isinvoice ||
  //         isrefunded) &&
  //       isWithinRange
  //     );
  //   });

  //   if (filter.sortBy === "nameCleaner") {
  //     filtered = filtered.sort((a, b) => (a.cleaner > b.cleaner ? 1 : -1));
  //   } else if (filter.sortBy === "nameHost") {
  //     filtered = filtered.sort((a, b) => (a.client > b.client ? 1 : -1));
  //   } else if (filter.sortBy === "dateFrom") {
  //     filtered = filtered.sort((a, b) =>
  //       new Date(a.date) > new Date(b.date) ? 1 : -1
  //     );
  //   }

  //   setFilteredData(filtered);

  //   if (data.length > 0) {
  //     setIsLOading(false);
  //   }
  // }, [data, filter]);

  const handleStatus = (event) => {
    const { value } = event.target;

    if (value === "All") {
      setStatusSelected(value);

      const allIds = data.map((item) => item.id);

      allIds.forEach((cardId) => {
        const event = { target: { checked: true } };
        handleCheckboxChange(cardId)(event);
      });
    } else {
      setStatusSelected(value);

      const allIds = data.map((item) => item.id);

      allIds.forEach((cardId) => {
        const event = { target: { checked: false } };
        handleCheckboxChange(cardId)(event);
      });

      const ids = data
        ?.filter((item) => item[checkYpePayout(entries)] === value)
        .map((item) => item.id);

      ids.forEach((cardId) => {
        const event = { target: { checked: true } };
        handleCheckboxChange(cardId)(event);
      });
    }
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter((prevState) => ({ ...prevState, [name]: value }));
  };

  // const handleStatusChange = (event) => {
  //   const { name, checked } = event.target;
  //   setFilter((prevState) => ({
  //     ...prevState,
  //     status: { ...prevState.status, [name]: checked },
  //   }));
  // };

  const handleStatusChange = (event) => {
    const { name, checked } = event.target;
    setFilter((prevState) => ({
      ...prevState,
      status: Object.keys(prevState.status).reduce((acc, key) => {
        acc[key] = key === name ? checked : false;
        return acc;
      }, {}),
    }));
  };

  const handleStatusChangeFtech = (event) => {
    const { value } = event.target;
    setStatusSelectedFetch(value);
  };

  // const handleResetFilter = () => {
  //   setFilter(initialFilterState);
  //   setSelectedCards([]);
  //   setStatusSelected("");
  // };

  // const allCityIds =
  //   data.length > 0
  //     ? data
  //         ?.map((cleaner) => cleaner.cities?.map((city) => city.id))
  //         .filter((ids) => ids.length !== 0)
  //         .flat()
  //     : [];

  // const allRegionsIds =
  //   data.length > 0
  //     ? data
  //         ?.map((cleaner) => cleaner.regions?.map((regions) => regions.id))
  //         .filter((ids) => ids.length !== 0)
  //         .flat()
  //     : [];

  function formatString(s) {
    s = s.replace(/_/g, " ");
    s = s.charAt(0).toUpperCase() + s.slice(1);
    s = s.replace(/\s/g, " ");
    return s;
  }

  const fetchStatus = async () => {
    const ids = Object.keys(selectedCards).filter(
      (key) => selectedCards[key] === true
    );

    try {
      await api.put(`/admins/payments/batch_update`, {
        data: {
          attributes: {
            entries: entries,
            ids: ids,
            status: statusSelectedFetch,
          },
        },
      });
      setSelectedCards("");
      setStatusSelected("");
      setStatusSelectedFetch("");
      setLoading(true);
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(selected);

  const fetchDataDoc = async () => {
    setLoadingDoc(true);

    try {
      const response = await api.get(`/admins/payments/${selected}/`, {
        params: {
          cleaner_name: filter.cleanerName,
          host_name: filter.hostName,
          region: filter.region,
          from: filter.fromDate
            ? moment(filter.fromDate).utc().format("DD/MM/YYYY")
            : "",
          to: filter.toDate
            ? moment(filter.toDate).utc().format("DD/MM/YYYY")
            : "",
          status: Object.keys(filter.status)
            .filter((statusName) => filter.status[statusName])
            .map(
              (statusName) =>
                statusPage.find((item) => item.name === statusName).code
            ),
          deep_cleaning: filter.deepCleaning,
        },
      });

      const newData = response?.payments?.data?.map((value) => ({
        id: value.id,
        type: value.type,
        ...value.attributes,
      }));

      // Assuming setDataDoc is an asynchronous function, use await to wait for it to complete
      await setDataDoc(newData);
      setLoadingDoc(false);

      // Now that data is set, render the CardPdfDetails component
      generateExcel(newData);
    } catch (error) {
      console.log(error);
      setLoadingDoc(false);
    }
  };

  return (
    <>
      <div className="containerOverview containerPamentsCardsAll">
        <div className="containerBox"></div>
        <div className={isMobile ? "filterMobilePayments" : "containerFilter scrolFilter"}>
          <div className="textTitle">Payments</div>

          <div className="titleFilter">Cleaner</div>

          <Input
            placeholder="Cleaner Name"
            name="cleanerName"
            value={filter.cleanerName}
            onChange={handleFilterChange}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSearch();
              }
            }}
          />

          <div className="titleFilter">Host</div>

          <Input
            placeholder="Host Name"
            name="hostName"
            value={filter.hostName}
            onChange={handleFilterChange}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSearch();
              }
            }}
          />

          <div className="titleFilter">Region</div>

          <TextField
            name="region"
            label={filter.region !== "" ? "" : "Region"}
            select
            value={filter.region}
            onChange={handleFilterChange}
            style={{ borderBottom: "none", width: "100%" }}
          >
            <option value="">None</option>
            {listRegions?.map((value, idx) => (
              <option
                value={value.name}
                key={idx}
                style={{ cursor: "pointer" }}
              >
                {value.name}
              </option>
            ))}
          </TextField>

          {/* <TextField
            name="city"
            label={filter.city !== "" ? "" : "City"}
            select
            value={filter.city}
            onChange={handleFilterChange}
            style={{ borderBottom: "none", width: "100%" }}
          >
            <option value="">None</option>
            {listCities?.map((value, idx) => (
              <option value={value.id} key={idx} style={{ cursor: "pointer" }}>
                {`${value.name}`}
              </option>
            ))}
          </TextField> */}

          <div className="titleFilter">Date</div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "5px",
            }}
          >
            <div className="inputCOntianerMObile">
              <div className="subTitleFilter">From:</div>
              <TextField
                name="fromDate"
                // label="From Date"
                type="date"
                value={filter.fromDate}
                onChange={handleFilterChange}
                className="inputMObile"
              />
            </div>

            <div className="inputCOntianerMObile">
              <div className="subTitleFilter">To:</div>
              <TextField
                name="toDate"
                // label="To Date"
                type="date"
                value={filter.toDate}
                onChange={handleFilterChange}
                className="inputMObile"
              />
            </div>
          </div>

          <div className="titleFilter" style={{ marginBottom: "5px" }}>
            Status
          </div>

          {statusPage.map((item, idx) => (
            <div key={idx}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filter.status[item.name]}
                    onChange={handleStatusChange}
                    name={item.name}
                    color="default"
                    style={{
                      color: "#0E1F41",
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                  />
                }
                label={
                  <span
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      // fontSize: "20px",
                      color: "#0E1F41",
                    }}
                    className="statusMobile"
                  >
                    {formatString(item.name)}
                  </span>
                }
                style={{ paddingTop: 0, paddingBottom: 0 }}
                align="left"
              />
            </div>
          ))}

          {!entries && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.status.deepCleaning}
                  onChange={handleStatusChange}
                  name="deepCleaning"
                  color="default"
                  style={{ color: "#0E1F41" }}
                />
              }
              label={
                <span
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: 700,
                    // fontSize: "20px",
                    color: "#0E1F41",
                  }}
                  className="statusMobile"
                >
                  Deep Cleaning
                </span>
              }
              style={{ paddingTop: 0, paddingBottom: "10px" }}
              align="left"
            />
          )}

          {entries && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.status.deepCleaning}
                  onChange={handleStatusChange}
                  name="deepCleaning"
                  color="default"
                  style={{ color: "#0E1F41" }}
                />
              }
              label={
                <span
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "20px",
                    color: "#0E1F41",
                  }}
                >
                  See only deep cleanings
                </span>
              }
              style={{ paddingTop: 0, paddingBottom: "10px" }}
              align="left"
            />
          )}

          {/* <button className="buttonFilter" onClick={handleResetFilter}>
            Clear Selection
          </button> */}

          <div style={{ display: "flex", gap: "10px" }}>
            <button className="buttonFilter" onClick={handleResetFilter}>
              Clear Selection
            </button>

            <button
              className="buttonFilter"
              style={{ backgroundColor: "#0e1f41" }}
              onClick={handleSearch}
            >
              Search
            </button>
          </div>

          {loadingDoc ? (
            <div className="buttonPdf" style={{ marginTop: "10px" }}>
              <button>Exporting...</button>
            </div>
          ) : (
            <div className="buttonPdf" style={{ marginTop: "10px" }}>
              <button onClick={() => fetchDataDoc()}>Export Table</button>
              <ReactSVG src={iconSeta} />
            </div>
          )}
        </div>
        {data.length > 0 ? (
          <div className="containerPagination">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                alignItems: "center",
                marginBottom: "10px",
              }}
              className="flexDirecitonCOllum"
            >
              {!entries && (
                <div style={{ marginBottom: "20px", width: "150px" }}>
                  <div className="titleFilter">Selected itens</div>

                  <TextField
                    select
                    value={statusSelected}
                    label="select"
                    onChange={handleStatus}
                    style={{ borderBottom: "none", width: "100%" }}
                    // InputProps={{
                    //   style: statusSelected ? styles.selectedValue : {},
                    // }}
                  >
                    <option value={"All"} style={{ cursor: "pointer" }}>
                      All
                    </option>
                    {statusPage?.map((value, idx) => (
                      <option
                        value={value.name}
                        key={idx}
                        style={{ cursor: "pointer" }}
                      >
                        {formatString(value.name)}
                      </option>
                    ))}
                  </TextField>
                </div>
              )}
              {!entries && (
                <div className="updateALlSTatus">
                  <ModalContainer
                    customButton={
                      <button>Update Selected Payments' Status</button>
                    }
                    type={true}
                    title="Update the status of selected payments"
                    onClickSave={fetchStatus}
                  >
                    <div className="titleINputModal">Status:</div>
                    <TextField
                      select
                      value={statusSelectedFetch}
                      onChange={handleStatusChangeFtech}
                      style={{ borderBottom: "none", width: "100%" }}
                      InputProps={{
                        style: statusSelectedFetch ? styles.selectedValue : {},
                      }}
                    >
                      {statusPage?.map((value, idx) => (
                        <option
                          value={value.name}
                          key={idx}
                          style={{ cursor: "pointer" }}
                        >
                          {formatString(value.name)}
                        </option>
                      ))}
                    </TextField>
                  </ModalContainer>
                </div>
              )}
            </div>

            <div style={{ alignSelf: "flex-end" }}>
              <Stack spacing={2}>
                <Pagination
                  count={total}
                  page={currentPage}
                  onChange={handleChange}
                />
              </Stack>
            </div>
            <div className="containerCardPayments">
              {statusPage.map(
                (status, key) =>
                  data.filter(
                    (statu) => statu[checkYpePayout(entries)] === status.name
                  ).length > 0 && (
                    <div key={key}>
                      <div className="titleStatusPayments">
                        {formatString(status.name)}
                      </div>
                      {data
                        .filter(
                          (statu) =>
                            statu[checkYpePayout(entries)] === status.name
                        )
                        .map((item, idx) => (
                          <div
                            className="containerCardCheckbox"
                            key={idx}
                            style={{ marginBottom: "20px" }}
                          >
                            {!entries && (
                              <Checkbox
                                checked={selectedCards[item.id] || false}
                                onChange={handleCheckboxChange(item.id)}
                                style={{
                                  color: "#0E1F41",
                                  fontSize: "32px",
                                  transform: "scale(1.5)",
                                }}
                              />
                            )}

                            <DetailsScheduleModal
                              item={item}
                              entries={entries}
                              status={item[checkYpePayout(entries)]}
                              setLoading={setLoading}
                              statusPayments={statusPage}
                              button={
                                <CardPayments
                                  data={item}
                                  entries={entries}
                                  setStatus={setStatus}
                                  statusPayments={statusPage}
                                />
                              }
                            />
                          </div>
                        ))}
                    </div>
                  )
              )}
            </div>
            <div style={{ alignSelf: "flex-end" }}>
              <Stack spacing={2}>
                <Pagination
                  count={total}
                  page={currentPage}
                  onChange={handleChange}
                />
              </Stack>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "80vh",
              width: "100%",
            }}
          >
            {isLoading ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress size={50} />
              </Box>
            ) : (
              <div className="titleFilter">No matching items found.</div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default CardsEntrieAndExits;
