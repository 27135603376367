import React from "react";
import { FormHelperText } from "@mui/material";

import "./Step1.css";

const Step1 = ({ form }) => {
  function MyFormHelperText({ text }) {
    // const { focused } = useFormControl() || {};

    const helperText = React.useMemo(() => {
      if (text) {
        return `${text}`;
      }

      return ``;
    }, [text]);

    return <FormHelperText>{helperText}</FormHelperText>;
  }

  return (
    <div className="containerStep1">
      <div style={{ marginTop: "20px" }}>
        <div>
          <div style={{ marginTop: "20px" }}>
            <div className="titleINputModalStep">Date</div>
            <div className="containerInputText">
              <input
                type="date"
                className="inputText"
                value={form.values.date}
                onChange={(event) =>
                  form.setFieldValue("date", event.target.value)
                }
              />
            </div>

            <div className="textErros">
              <MyFormHelperText text={form.errors.date && form.errors.date} />
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <div className="titleINputModalStep">Time</div>
            <div className="containerInputText">
              <input
                type="time"
                className="inputText"
                value={form.values.time}
                onChange={(event) =>
                  form.setFieldValue("time", event.target.value)
                }
              />
            </div>

            <div className="textErros">
              <MyFormHelperText text={form.errors.time && form.errors.time} />
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <div className="titleINputModalStep">Next checkin date</div>
            <div className="containerInputText">
              <input
                type="date"
                className="inputText"
                value={form.values.nextCheckinDate}
                onChange={(event) =>
                  form.setFieldValue("nextCheckinDate", event.target.value)
                }
              />
            </div>

            <div className="textErros">
              <MyFormHelperText
                text={
                  form.errors.nextCheckinDate && form.errors.nextCheckinDate
                }
              />
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <div className="titleINputModalStep">Next checkin time</div>
            <div className="containerInputText">
              <input
                type="time"
                className="inputText"
                value={form.values.nextCheckinTime}
                onChange={(event) =>
                  form.setFieldValue("nextCheckinTime", event.target.value)
                }
              />
            </div>

            <div className="textErros">
              <MyFormHelperText
                text={
                  form.errors.nextCheckinTime && form.errors.nextCheckinTime
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
