import React, { useState, useContext, useEffect } from "react";
import { AuthorizedLayout } from "../../../../Components/AuthorizedLayout";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormHelperText } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import AppContext from "../../../../Context/AppContext";
import OutlinedInput from "@mui/material/OutlinedInput";

import { ExpandMore } from "@mui/icons-material";
import voltarIcon from "../../../../Assets/Union (4).svg";
import editButton from "../../../../Assets/Btn Edit (2).svg";
import cancelButton from "../../../../Assets/Btn Cancel.svg";

import "./NewService.css";
import api from "../../../../Service/api";

const flat = [
  { value: "bedroomsAmount", und: "" },
  { value: "bathroomsAmount", und: "" },
  { value: "squareFeet", und: "" },
  { value: "price", und: "" },
  { value: "exceedPrice", und: "" },
  { value: "payout", und: "%" },
  // { value: "payoutMaxValue", und: "" },
  { value: "avgTime", und: "h" },
];

const feet = [
  { value: "squareFeet", und: "" },
  { value: "price", und: "" },
  { value: "exceedPrice", und: "" },
  { value: "payout", und: "%" },
  // { value: "payoutMaxValue", und: "" },
  { value: "avgTime", und: "h" },
];

const NewService = () => {
  const [editable, setEDitable] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const {

    region_id = 0,
    cleaning_service = false,
    pricing_type = "flat",
  } = location.state || {};

  const { listRegions } = useContext(AppContext);
  const [listService, setListService] = useState([]);
  const [regionSelected, setRegionSelected] = useState(
    listRegions?.find((item) => Number(item.id) === Number(region_id))
  );
  const [serviceSelected, setServiceSelected] = useState([]);
  const [selectedType, setSelectedType] = useState(
    pricing_type === "flat" ? true : false
  );
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([
    {
      bedroomsAmount: "",
      bathroomsAmount: "",
      moreBedrooms: false,
      moreBathrooms: false,
      pricing_type: "flat",
      squareFeet: "",
      exceedPrice: "",
      price: "",
      avgTime: "",
      payout: "",
      // payoutMaxValue: "",
    },
  ]);

  const formService = useFormik({
    initialValues: {
      cleaningServiceId: "",
      name: "",
      payoutMaxValue: 0,
    },
  });

  useEffect(() => {
    const getServcies = async () => {
      try {
        const { data } = await api.get("/admins/cleaning_service");

        const newData = await data?.map((value) => ({
          id: value.id,
          type: value.type,
          ...value.attributes,
        }));
        setListService(newData);
        setServiceSelected(
          newData?.find(
            (item) => Number(item.id) === Number(cleaning_service.id)
          )
        );
      } catch (error) {
        console.log(error);
      }
    };

    getServcies();
  }, [cleaning_service.id]);

  const handleRegion = (event) => {
    setRegionSelected(event.target.value);
  };

  const handleService = (event) => {
    formService.setFieldValue(
      "cleaningServiceId",
      Number(event.target.value.id)
    );
    setServiceSelected(event.target.value);
  };

  const tgoBack = () => {
    navigate(-1);
  };

  function MyFormHelperText({ text }) {
    // const { focused } = useFormControl() || {};

    const helperText = React.useMemo(() => {
      if (text) {
        return `${text}`;
      }

      return ``;
    }, [text]);

    return <FormHelperText>{helperText}</FormHelperText>;
  }

  const sortedArray = (a, b) => {
    if (Number(a.bedroomsAmount) < Number(b.bedroomsAmount)) {
      return -1;
    }
    if (Number(a.bedroomsAmount) > Number(b.bedroomsAmount)) {
      return 1;
    }

    if (Number(a.bathroomsAmount) < Number(b.bathroomsAmount)) {
      return -1;
    }
    if (Number(a.bathroomsAmount) > Number(b.bathroomsAmount)) {
      return 1;
    }
    return 0;
  };

  const setNewFeet = async (values) => {
    setLoading(true);

    try {
      const corpObjetc = {
        data: {
          type: "pricing-tables",
          attributes: {
            name: formService.values.name || '',
            regionId: regionSelected?.id || '',
            cleaningServiceId: formService.values.cleaningServiceId || '',
            payoutMaxValue: formService.values.payoutMaxValue || '',
            pricingType: "square_feet",
            pricingTableLinesAttributes: rows
              ?.slice()
              .sort((a, b) => sortedArray(a, b))
              .map((i, idx) => {
                return {
                  tableOrder: idx + 1 || '',
                  exceedPrice: i.exceedPrice || '',
                  price: i.price || '',
                  avgTime: i.avgTime || '',
                  payout: i.payout || '',
                  moreBedrooms: false,
                  moreBathrooms: false,
                  squareFeet: i.squareFeet || '',
                };
              }),
          },
        },
      };

      await api.post(`/admins/pricing_table`, { ...corpObjetc });
      alert("Created successfully.");
      setLoading(false);
      tgoBack();
    } catch (error) {
      console.log(error);
      alert(`Error: ${error.response.data.error}`);
      setLoading(false);
    }
  };

  const formFeet = useFormik({
    initialValues: {
      pricing_type: "square_feet",
      squareFeet: "",
      exceedPrice: "",
      price: "",
      avgTime: "",
      payout: "",
      // payoutMaxValue: payout_max_value || "",
    },
  });

  const setNewFlat = async (values) => {
    setLoading(true);

    const corpObjetc = {
      data: {
        type: "pricing-tables",
        attributes: {
          name: formService.values.name || '',
          regionId: regionSelected?.id || '',
          cleaningServiceId: formService.values.cleaningServiceId || '',
          payoutMaxValue: formService.values.payoutMaxValue || '',
          pricingType: "flat",
          pricingTableLinesAttributes: rows
            ?.slice()
            .sort((a, b) => sortedArray(a, b))
            .map((i, idx) => {
              return {
                tableOrder: idx + 1 || '',
                exceedPrice: i.exceedPrice || '',
                price: i.price || '',
                avgTime: i.avgTime || '',
                payout: i.payout || '',
                bedroomsAmount: i.bedroomsAmount || '',
                bathroomsAmount: i.bathroomsAmount || '',
                moreBedrooms: false,
                moreBathrooms: false,
                squareFeet: i.squareFeet || '',
              };
            }),
        },
      },
    };

    try {
      await api.post(`/admins/pricing_table`, { ...corpObjetc });
      alert("Created successfully.");
      setLoading(false);
      tgoBack();
    } catch (error) {
      console.log(error);
      alert(`Error: ${error.response.data.error}`);
      setLoading(false);
    }
  };

  const formFlat = useFormik({
    initialValues: {
      bedroomsAmount: "",
      bathroomsAmount: "",
      moreBedrooms: false,
      moreBathrooms: false,
      pricing_type: "flat",
      squareFeet: "",
      exceedPrice: "",
      price: "",
      avgTime: "",
      payout: "",
      // payoutMaxValue: payout_max_value || "",
    },
  });

  const handleSubmit = selectedType ? setNewFlat : setNewFeet;
  const addNewRow = () => {
    const newRow = selectedType
      ? { ...formFlat.values }
      : { ...formFeet.values };
    setRows([...rows, newRow]);
  };

  const markRowForDeletion = (index) => {
    const updatedRows = [...rows];
    updatedRows[index].deleted = true;
    setRows(updatedRows);
  };

  const funcEdit = (boo) => {
    setEDitable(boo);

    if (boo) {
      formService.setFieldValue(
        "cleaningServiceId",
        serviceSelected?.service_name || ''
      );
    } else {
      formService.setFieldValue(
        "cleaningServiceId",
        Number(serviceSelected?.id || '')
      );
    }
  };

  const removeRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  return (
    <AuthorizedLayout>
      <div className="containerNewService">
        <div className="containerHeaderNewService">
          <button onClick={() => tgoBack()}>
            {" "}
            <ReactSVG src={voltarIcon} />
            {"Back to Pricing Table"}
          </button>
        </div>

        <div className="containerInputsNewService">
          <div
            style={{ display: "flex", gap: "20px", marginBottom: "10px" }}
            className="inputsTOps"
          >
            <div style={{ flex: 2 }}>
              <div className="title">Title</div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <FormControl sx={{ width: "100%" }}>
                  <OutlinedInput
                    placeholder="Title"
                    value={formService.values.name}
                    onChange={(event) =>
                      formService.setFieldValue("name", event.target.value)
                    }
                  />
                </FormControl>
              </div>
            </div>

            <div style={{ flex: 2 }}>
              <div className="title">Maximum Payout Value</div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <FormControl sx={{ width: "100%" }}>
                  <OutlinedInput
                    placeholder="Title"
                    value={formService.values.payoutMaxValue}
                    type="number"
                    onChange={(event) =>
                      formService.setFieldValue(
                        "payoutMaxValue",
                        event.target.value
                      )
                    }
                  />
                </FormControl>
              </div>
            </div>
          </div>
          <div className="containerTop">
            <div style={{ flex: 2 }}>
              <div className="title">Service Name</div>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                {editable ? (
                  <FormControl sx={{ width: "100%" }}>
                    <OutlinedInput
                      placeholder="Title"
                      value={formService.values.cleaningServiceId}
                      onChange={(event) =>
                        formService.setFieldValue(
                          "cleaningServiceId",
                          event.target.value
                        )
                      }
                    />
                  </FormControl>
                ) : (
                  <div style={{ width: "100%" }}>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        value={serviceSelected}
                        onChange={handleService}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        IconComponent={ExpandMore}
                        sx={{
                          "& svg": { fontSize: 35, color: "#0E1F41" },
                          "& .MuiSelect-select": {
                            minHeight: "16px",
                            height: "10px",
                            lineHeight: "10px",
                            border: "1px solid #0E1F41",
                          },
                          "& .MuiInputBase-input": { height: "10px" },
                        }}
                      >
                        {listService?.map((item, itx) => (
                          <MenuItem key={itx} value={item}>
                            {item?.service_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}

                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => funcEdit(!editable)}
                >
                  {editable ? (
                    <ReactSVG src={cancelButton} />
                  ) : (
                    <ReactSVG src={editButton} />
                  )}
                </div>
              </div>
            </div>

            <div style={{ flex: 2 }}>
              <div className="title">Region default</div>
              <div>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    value={regionSelected}
                    onChange={handleRegion}
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty
                    IconComponent={ExpandMore}
                    sx={{
                      "& svg": { fontSize: 35, color: "#0E1F41" },
                      "& .MuiSelect-select": {
                        minHeight: "16px",
                        height: "10px",
                        lineHeight: "10px",
                        border: "1px solid #0E1F41",
                      },
                      "& .MuiInputBase-input": { height: "10px" },
                    }}
                  >
                    {listRegions?.map((item, itx) => (
                      <MenuItem key={itx} value={item}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div style={{ flex: 1 }}>
              <div className="title">Princing Type</div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  marginTop: "10px",
                }}
              >
                <button
                  className="buttonTYpe"
                  onClick={() => setSelectedType(true)}
                >
                  <div
                    className={`buttonSelectTYpe ${
                      selectedType && "selecteStyle"
                    }`}
                  />
                  <div
                    className={`titleTYpe ${selectedType && "selectedText"}`}
                  >
                    Flat
                  </div>
                </button>

                <button
                  className="buttonTYpe"
                  onClick={() => setSelectedType(false)}
                >
                  <div
                    className={`buttonSelectTYpe ${
                      selectedType === false && "selecteStyle"
                    }`}
                  />
                  <div
                    className={`titleTYpe ${
                      selectedType === false && "selectedText"
                    }`}
                  >
                    Square Feet
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div className="containerCenter" style={{ marginTop: "15px" }}>
            {selectedType && (
              <>
                <div className="containerHeaderTable">
                  <div>Bedrooms</div>
                  <div>Bathrooms</div>
                  <div>Square Feet</div>
                  <div>Price</div>
                  <div>Exceed price</div>
                  <div>Cleaner Payout</div>
                  {/* <div>Payout max value</div> */}
                  <div>Avg time</div>
                  <div
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      flex: 0.3,
                    }}
                  ></div>
                </div>

                {/* <div className="containerLineTable">
                  {flat.map((fieldName) => (
                    <div key={fieldName.value}>
                      <label unit={fieldName.und}>
                        <input
                          type="number"
                          className="inputTextPrice"
                          value={formFlat.values[fieldName.value]}
                          onChange={(event) =>
                            formFlat.setFieldValue(
                              fieldName.value,
                              event.target.value
                            )
                          }
                        />
                      </label>
                    </div>
                  ))}
                </div> */}

                {/* <div className="containerLineErros">
                  {flat.map((fieldName, idx) => (
                    <div className="textErros" key={idx}>
                      <MyFormHelperText
                        text={
                          formFlat.errors[fieldName.value] &&
                          formFlat.errors[fieldName.value]
                        }
                      />
                    </div>
                  ))}
                </div> */}
              </>
            )}

            {selectedType === false && (
              <>
                <div className="containerHeaderTable">
                  <div>Square Feet</div>
                  <div>Price</div>
                  <div>Exceed price</div>
                  <div>Cleaner Payout</div>
                  {/* <div>Payout max value</div> */}
                  <div>Avg time</div>
                  <div
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      flex: 0.3,
                    }}
                  ></div>
                </div>

                {/* <div className="containerLineTable">
                  {feet.map((fieldName) => (
                    <div key={fieldName}>
                      <label unit={fieldName.und}>
                        <input
                          type="number"
                          className="inputTextPrice"
                          value={formFeet.values[fieldName.value]}
                          onChange={(event) =>
                            formFeet.setFieldValue(
                              fieldName.value,
                              event.target.value
                            )
                          }
                        />
                      </label>
                    </div>
                  ))}
                </div> */}

                {/* <div className="containerLineErros">
                  {feet.map((fieldName, idx) => (
                    <div className="textErros" key={idx}>
                      <MyFormHelperText
                        text={
                          formFeet.errors[fieldName.value] &&
                          formFeet.errors[fieldName.value]
                        }
                      />
                    </div>
                  ))}
                </div> */}
              </>
            )}

            {/* <div className="buttonNewLine">+ Add new line</div> */}
          </div>

          <div className="containerCenter">
            {selectedType && (
              <>
                {rows.map((row, rowIndex) => (
                  <div
                    key={rowIndex}
                    className={
                      row.deleted
                        ? "deletedLine containerLineTable"
                        : "containerLineTable"
                    }
                  >
                    {flat.map((fieldName) => (
                      <div key={fieldName.value} className="lineSele">
                        <label unit={fieldName.und}>
                          <input
                            type="number"
                            className="inputTextPrice"
                            value={row[fieldName.value]}
                            onChange={(event) => {
                              const updatedRows = [...rows];
                              updatedRows[rowIndex] = {
                                ...row,
                                [fieldName.value]: event.target.value,
                              };
                              setRows(updatedRows);
                            }}
                          />
                        </label>
                      </div>
                    ))}
                    <div
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        flex: 0.3,
                      }}
                    >
                      <button
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                        }}
                        onClick={() => removeRow(rowIndex)}
                      >
                        <ReactSVG
                          src={cancelButton}
                          style={{ border: "none" }}
                        />
                      </button>
                    </div>
                  </div>
                ))}
                {/* <div className="containerLineErros">
                  {flat.map((fieldName, idx) => (
                    <div className="textErros" key={idx}>
                      <MyFormHelperText
                        text={
                          formFlat.errors[fieldName.value] &&
                          formFlat.errors[fieldName.value]
                        }
                      />
                    </div>
                  ))}
                </div> */}
              </>
            )}

            {selectedType === false && (
              <>
                {rows.map((row, rowIndex) => (
                  <div
                    key={rowIndex}
                    className={
                      row.deleted
                        ? "deletedLine containerLineTable"
                        : "containerLineTable"
                    }
                  >
                    {feet.map((fieldName) => (
                      <div key={fieldName.value} className="lineSele">
                        <label unit={fieldName.und}>
                          <input
                            type="number"
                            className="inputTextPrice"
                            value={row[fieldName.value]}
                            onChange={(event) => {
                              const updatedRows = [...rows];
                              updatedRows[rowIndex] = {
                                ...row,
                                [fieldName.value]: event.target.value,
                              };
                              setRows(updatedRows);
                            }}
                          />
                        </label>
                      </div>
                    ))}
                    <div
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        flex: 0.3,
                      }}
                    >
                      <button
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                        }}
                        onClick={() => removeRow(rowIndex)}
                      >
                        <ReactSVG
                          src={cancelButton}
                          style={{ border: "none" }}
                        />
                      </button>
                    </div>
                  </div>
                ))}
                {/* <div className="containerLineErros">
                  {feet.map((fieldName, idx) => (
                    <div className="textErros" key={idx}>
                      <MyFormHelperText
                        text={
                          formFeet.errors[fieldName.value] &&
                          formFeet.errors[fieldName.value]
                        }
                      />
                    </div>
                  ))}
                </div> */}
              </>
            )}

            <button className="buttonNewLine" onClick={addNewRow}>
              + Add new line
            </button>
          </div>

          {/* <div className="buttonNewLine">+ Add new line</div> */}

          <button className="buttonSaveServide" onClick={handleSubmit}>
            {loading ? "Loading..." : "Save Service"}
          </button>
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default NewService;
