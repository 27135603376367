import React, { useContext, useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import moment from "moment";
import { AuthorizedLayout } from "../../Components/AuthorizedLayout";
import { ModalContainer } from "../../Components/ModalContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Rating from "@mui/material/Rating";
import AppContext from "../../Context/AppContext";

import CardListCIty from "./Components/CardListCIty";
import CalendarCard from "./Components/CalendarCard";
import Chat from "./Components/Chat";
import EditModal from "./Components/EditModal";
import CardProperty from "./Components/CardProperty";
import EditSchedule from "./Components/EditSchedule";

import DetailsScheduleModal from "../../Components/DetailsScheduleModal";
import DetailsCleaningModal from "../../Components/DetailsCleaningModal";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Input } from "../../Components/Input";
import { InputsContainer } from "../../Components/InputsContainer";
import { useFormik } from "formik";
import * as yup from "yup";

import StikeIcon from "../../Assets/Strike Icon.svg";
import CleaningIcon from "../../Assets/Cleaning Icon2.svg";
import MedalIcon from "../../Assets/Vector (7).svg";
import bedroomsIcon from "../../Assets/bedroomsAmount.svg";
import bathroomsIcon from "../../Assets/bathrooms.svg";
import voltarIcon from "../../Assets/Union (4).svg";
import iconOffDay from "../../Assets/Day Off Icon.svg";

import "./DetailsClient.css";
import api from "../../Service/api";

const daysOfTheWeek = ["S", "M", "T", "W", "T", "F", "S"];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const DetailsClient = () => {
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { item, listCities, listRegions } = location.state;
  const [selectedSchedule, setSelectedSchedule] = useState("History");
  const [selectedProperty, setSelectedProperty] = useState("Properties");
  const [loading, setLoading] = useState(false);
  const [detailClients, setDetailClient] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadignCOntracts, setLoadingContracts] = useState(false);
  const [statusINvoice, setStatusInvoice] = useState();
  const [statusFreeze, setStatusFreeze] = useState();
  const [listProperties, setListProperties] = useState([]);
  const [currentPageProperties, setCurrentPageeProperties] = useState(1);
  const [listHistory, setListHistory] = useState([]);
  const [totalHistory, setTotalHistory] = useState([]);
  const [currentPageHistory, setCurrentPageeHistory] = useState(1);
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [loadingProperties, setLoadingProperties] = useState(true);

  const fetchListProperties = async () => {
    setListProperties([]);
    setLoadingProperties(true);
    try {
      const { client } = await api.get(`/admins/clients/${id}/properties`);
      setListProperties({
        id: client.data.id,
        type: client.data.type,
        ...client.data.attributes,
      });
      setLoadingProperties(false);
    } catch (error) {
      console.error("Error:");
      setLoadingProperties(false);
    }
  };

  const fetchListHistory = async (page) => {
    setListHistory([]);
    setLoadingHistory(true);
    try {
      const { client, pagination } = await api.get(
        `/admins/clients/${id}/history`,
        {
          params: {
            page: page,
            per_page: 10,
          },
        }
      );
      setTotalHistory(pagination.total_pages);
      setListHistory({
        id: client.data.id,
        type: client.data.type,
        ...client.data.attributes,
      });
      setLoadingHistory(false);
    } catch (error) {
      console.error(error);
      setLoadingHistory(false);
    }
  };

  const fetchListUpcoming = async (page) => {
    setListHistory([]);
    setLoadingHistory(true);
    try {
      const { client, pagination } = await api.get(
        `/admins/clients/${id}/upcoming`,
        {
          params: {
            page: page,
            per_page: 10,
          },
        }
      );
      setTotalHistory(pagination.total_pages);
      setListHistory({
        id: client.data.id,
        type: client.data.type,
        ...client.data.attributes,
      });
      setLoadingHistory(false);
    } catch (error) {
      console.error(error);
      setLoadingHistory(false);
    }
  };

  useEffect(() => {
    fetchListProperties();
  }, [id]);

  useEffect(() => {
    if (selectedSchedule === "History") {
      fetchListHistory(1);
    } else {
      fetchListUpcoming(1);
    }
  }, [id, selectedSchedule]);

  const handleChangeProperties = (event, value) => {
    setCurrentPageeProperties(value);
    fetchListProperties(value);
  };

  const handleChangeHistory = (event, value) => {
    setCurrentPageeHistory(value);
    if (selectedSchedule === "History") {
      fetchListHistory(value);
    } else {
      fetchListUpcoming(value);
    }
  };

  useEffect(() => {
    const getDetailUser = async (type) => {
      try {
        setLoading(true);
        const { data } = await api.get(`/admins/clients/${id}`);

        setDetailClient({ id: data.id, type: data.type, ...data.attributes });
        setStatusInvoice(data.attributes.invoicePayment);
        setStatusFreeze(data.attributes.freeze);
        setLoading(false);
        setLoadingButton(false);
        setLoadingContracts(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getDetailUser();
  }, [id, setDetailClient, loadingButton, loadignCOntracts]);

  function converterHorario(horarioOriginal) {
    const horarioConvertido = moment(horarioOriginal)
      .subtract(moment().utcOffset(), "minutes")
      .format("h:mm A");
    return horarioConvertido;
  }

  // const allCityIds = listCleaners
  //   ?.map((cleaner) => cleaner.cities.map((city) => city.id))
  //   .filter((ids) => ids.length !== 0)
  //   .flat();

  const citiesList = detailClients?.cities?.map((item) => item.name);
  const regionsListIds = detailClients?.regions?.map((item) => item.id);
  const filteredRegion = listRegions
    ?.filter((item) => regionsListIds?.includes(Number(item.id)))
    .map((regi) => regi.name);

  const formattedDate = (date) => {
    return moment(date).utc().format("MMM. DD, YYYY | hh:mm A");
    return moment(date).utc().format("MMM. DD, YYYY | hh:mm A");
  };

  function getDatesBetween(initialDate, endDate) {
    const dates = [];
    let currentDate = new Date(initialDate);
    while (currentDate <= new Date(endDate)) {
      dates.push(currentDate.toISOString().slice(0, 10));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  const timeOfssDate = detailClients?.timeOffs?.reduce((dates, timeOff) => {
    const { initialDate, endDate } = timeOff;
    const datesBetween = getDatesBetween(initialDate, endDate);
    return [...dates, ...datesBetween].filter((date, index, array) => {
      return array.indexOf(date) === index;
    });
  }, []);

  const dateSchedules = detailClients?.schedules
    ?.map((item) => moment(item.date).utc().format("YYYY-MM-DD"))
    .filter((date, index, array) => {
      return array.indexOf(date) === index;
    });

  // console.log(detailClients);

  const tgoBack = () => {
    setLoading(true);
    navigate(-1);
  };

  const filteredSchedules = listHistory?.upcomingCleanings;

  const schedulesCards =
    selectedSchedule === "Upcoming" ? filteredSchedules : listHistory?.history;

  const proprertiesList = listProperties?.properties || [];

  const handleNote = async (values) => {
    setLoadingButton(true);
    try {
      await api.post(`admins/cleaners/${detailClients?.id}/create_note`, {
        data: { attributes: { note: values.note } },
      });
      noteForm.setFieldValue("note", "");
      handleClose();
      setLoading(true);
      setLoadingButton(false);
    } catch (error) {
      console.log(error);
      setLoadingButton(false);
    }
  };

  const noteForm = useFormik({
    initialValues: {
      note: "",
    },
    validationSchema: yup.object({
      note: yup
        .string()
        .min(2, "Description must have at least 5 characters.")
        .required("Description is required!"),
    }),
    onSubmit: (values) => handleNote(values),
  });

  const aproveContract = async () => {
    try {
      setLoading(true);
      await api.patch(`/admins/cleaners/${id}/accept_cleaner`);
      setLoading(false);
      setLoadingContracts(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const endContract = async () => {
    try {
      setLoading(true);
      await api.patch(`/admins/cleaners/${id}/end_contract`);
      setLoading(false);
      setLoadingContracts(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const reactivateContract = async () => {
    try {
      setLoading(true);
      await api.patch(`/admins/cleaners/${id}/reactivate_contract`);
      setLoading(false);
      setLoadingContracts(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const addStrike = async (values) => {
    try {
      await api.patch(`/admins/clients/${id}/strike`, {
        data: { attributes: { ...values } },
      });
      alert("Strike has been successfully added.");
      setLoadingButton(true);
    } catch (error) {
      console.log(error);
      alert("Error: Failed to add the strike.");
    }
  };

  const form = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema: yup.object({
      reason: yup
        .string()
        .min(2, "City must have at least 2 characters.")
        .max(50, "City must have at most 50 characters.")
        .required("City is required!"),
    }),
    onSubmit: (values) => addStrike(values),
  });

  const handleStatusInvoice = async (values) => {
    setStatusInvoice(!statusINvoice);
    try {
      await api.put(`admins/clients/${values.id}`, {
        data: {
          attributes: {
            invoicePayment: !statusINvoice,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusFreeze = async (values) => {
    setStatusFreeze(!statusFreeze);
    try {
      await api.patch(`/admins/clients/${values.id}/toggle_freeze`, {});
    } catch (error) {
      console.log(error);
    }
  };

  const deleteClient = async (values) => {
    try {
      await api.delete(`/admins/clients/${values.id}`, {});
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  const hadleSelectedSchedule = (value) => {
    setSelectedSchedule(value);
    setCurrentPageeHistory(1);
  };

  return (
    <AuthorizedLayout>
      {/* <Chat /> */}
      <div className="containerDetailCleaner">
        <div className="containerButtonTopHeader">
          <button className="buttonbACK" onClick={() => tgoBack()}>
            {" "}
            <ReactSVG src={voltarIcon} /> Back to clients
          </button>
          {/* <button className="buttonSend">Send Message</button> */}
        </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "80vh",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <CircularProgress size={50} />
            </Box>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flex: 1,
              gap: "20px",
              flexDirection: "column",
            }}
          >
            <div>
              <div
                style={{ display: "flex", flex: 1, gap: "20px" }}
                className="flexDirecitonCOllum"
              >
                <div
                  style={{
                    flex: 5,
                    gap: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="conatainerHeader propertiesItensCOntainer">
                    <div className="containerUserHeader">
                      {detailClients?.avatar === null ? (
                        <div
                          className="userPhoto"
                          style={{
                            width: "64px",
                            height: "64px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "30px",
                            color: "#e5ebf2",
                          }}
                        >
                          ?
                        </div>
                      ) : (
                        <img
                          src={detailClients?.avatar}
                          alt="user"
                          className="userPhoto"
                          style={{
                            width: "64px",
                            height: "64px",
                            objectFit: "cover",
                          }}
                        />
                      )}
                      <div>
                        <div className="title">{`${detailClients?.firstName} ${detailClients?.lastName}`}</div>
                        <div>
                          <Stack spacing={1}>
                            <Rating
                              name="half-rating-read"
                              defaultValue={detailClients?.ratting}
                              precision={0.5}
                              readOnly
                              sx={{ color: "#0E1F41" }}
                              size="small"
                            />
                          </Stack>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        gap: "5px",
                        flexDirection: "column",
                        justifyContent: "center",
                        // alignItems: 'center',
                      }}
                      className="propertiesItens"
                    >
                      <div className="containerIcon">
                        <ReactSVG src={MedalIcon} />
                        {detailClients?.numberOfProperties || 0} Properties
                      </div>
                      <div className="containerIcon">
                        <ReactSVG src={CleaningIcon} />{" "}
                        {detailClients?.numberOfCleanings || 0} Cleanings
                      </div>
                      <div className="containerIcon">
                        <ReactSVG src={StikeIcon} />{" "}
                        {detailClients?.strikes || 0} Strikes
                      </div>
                    </div>

                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                      className="cntainerBUttonDetailsCLients"
                    >
                      <ModalContainer
                        customButton={
                          <button
                            className="button buttonModalMObile"
                            style={{ backgroundColor: "#0E1F41" }}
                          >
                            Strike Client
                          </button>
                        }
                        type={true}
                        title="Add Strike"
                        onClickSave={form.submitForm}
                      >
                        <div className="titleINputModal">Reason:</div>
                        <div className="containerInputText">
                          <input
                            type="text"
                            className="inputText"
                            value={form.values.reason}
                            onChange={(event) =>
                              form.setFieldValue("reason", event.target.value)
                            }
                          />
                        </div>
                      </ModalContainer>

                      <button
                        className="button"
                        style={{ backgroundColor: "#66CDAA" }}
                        onClick={() => handleStatusInvoice(detailClients)}
                      >
                        {`Invoice: ${statusINvoice ? "On" : "Off"}`}
                      </button>

                      <button
                        className="button"
                        style={{
                          backgroundColor: statusFreeze ? "#0e1f41" : "#e74242",
                        }}
                        onClick={() => handleStatusFreeze(detailClients)}
                      >
                        {statusFreeze ? "Reactivate" : "Freeze client"}
                      </button>
                    </div>

                    <ModalContainer
                      customButton={
                        <button
                          className="button buttonModalMObile"
                          style={{ backgroundColor: "#e74242" }}
                        >
                          DELETE
                        </button>
                      }
                      type={true}
                      title="Delete"
                      onClickSave={() => deleteClient(detailClients)}
                    >
                      <div className="titleINputModal">
                        Are you certain you wish to delete the client?
                      </div>
                    </ModalContainer>
                  </div>
                  <div className="containerInfo flexDirecitonCOllum">
                    <div className="containerLeft">
                      <div className="card" style={{ flex: 1 }}>
                        <div className="title">General Information</div>
                        <div className="subtile">
                          <strong>Email:</strong>
                          {` ${detailClients?.email}`}
                        </div>
                        <div className="subtile">
                          <strong>Phone:</strong>
                          {` ${detailClients?.phone}`}
                        </div>
                        <div className="subtile">
                          <strong>Client Since:</strong>
                          {` ${detailClients?.clientSince || ""}`}
                        </div>
                      </div>
                      <div className="card" style={{ flex: 1.5 }}>
                        <div className="title">Billing Adress</div>
                        <div className="subtile">
                          <strong>Street:</strong>
                          {` ${detailClients?.billingAddress?.address || ""}`}
                        </div>
                        <div className="subtile">
                          <strong>City:</strong>
                          {` ${detailClients?.billingAddress?.city || ""}, ${
                            detailClients?.billingAddress?.state || ""
                          }`}
                        </div>
                      </div>
                    </div>

                    <div className="containerLeft" style={{ flex: 2 }}>
                      <div className="card" style={{ flex: 2 }}>
                        <div className="containerCard">
                          <div className="card" style={{ flex: 3 }}>
                            <div className="titleSchedules">
                              <div
                                onClick={() =>
                                  hadleSelectedSchedule("Upcoming")
                                }
                                className={
                                  selectedSchedule === "Upcoming"
                                    ? "selectedSchedule"
                                    : "noSelectedSchedule"
                                }
                              >
                                Upcoming Cleanings
                              </div>
                              <div
                                onClick={() => hadleSelectedSchedule("History")}
                                className={
                                  selectedSchedule === "History"
                                    ? "selectedSchedule"
                                    : "noSelectedSchedule"
                                }
                              >
                                History
                              </div>
                            </div>

                            {loadingHistory ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "30vh",
                                  width: "100%",
                                }}
                              >
                                <Box sx={{ display: "flex" }}>
                                  <CircularProgress size={50} />
                                </Box>
                              </div>
                            ) : (
                              <>
                                <div
                                  style={{
                                    justifyContent: "flex-end",
                                    display: "flex",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <Stack spacing={2}>
                                    <Pagination
                                      count={totalHistory}
                                      page={currentPageHistory}
                                      onChange={handleChangeHistory}
                                    />
                                  </Stack>
                                </div>
                                <div
                                  className="containerCard"
                                  style={{ maxHeight: "700px" }}
                                >
                                  {selectedSchedule === "History" ? (
                                    schedulesCards?.length > 0 ? (
                                      schedulesCards
                                        .filter(
                                          (valu) => valu.status !== "scheduled"
                                        )
                                        .slice()
                                        .sort(
                                          (a, b) =>
                                            new Date(b.date) - new Date(a.date)
                                        )
                                        .map((item, idx) => (
                                          <div className="cardInfos" key={idx}>
                                            {/* <div className="cardId">{`#${item.id}`}</div> */}
                                            <div className="cardId">
                                              <div>{`#${item.id}`}</div>
                                              <div className="textStatusCleaning">{`${item.status}`}</div>
                                              <div
                                                className="textStatusCleaning"
                                                style={
                                                  item.paymentStatus
                                                    ? {
                                                        color:
                                                          item.paymentStatus ===
                                                            "failed" ||
                                                          item.paymentStatus ===
                                                            "refunded" ||
                                                          item.paymentStatus ===
                                                            "waiting_charge"
                                                            ? "red"
                                                            : "#0E1F41",
                                                      }
                                                    : {}
                                                }
                                              >
                                                {item.paymentStatus || ""}
                                              </div>
                                            </div>
                                            <div className="textDate">
                                              {formattedDate(item.date)}
                                            </div>

                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "15px",
                                              }}
                                            >
                                              <div className="textCardOver">
                                                {`$${Number(item.value).toFixed(
                                                  2
                                                )}`}
                                              </div>
                                              <div
                                                className="textCardOver"
                                                style={{
                                                  display: "flex",
                                                  gap: "5px",
                                                }}
                                              >
                                                <ReactSVG
                                                  src={bathroomsIcon}
                                                  style={{
                                                    backgroundColor:
                                                      "rgba(0, 0, 0, 0)",
                                                  }}
                                                />
                                                {item.bathroomsAmount}
                                              </div>
                                              <div
                                                className="textCardOver"
                                                style={{
                                                  display: "flex",
                                                  gap: "5px",
                                                }}
                                              >
                                                <ReactSVG
                                                  src={bedroomsIcon}
                                                  style={{
                                                    backgroundColor:
                                                      "rgba(0, 0, 0, 0)",
                                                  }}
                                                />
                                                {item.bedroomsAmount}
                                              </div>
                                            </div>

                                            <div className="textCardOver">
                                              {item.address}
                                            </div>

                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <div className="textCardCleaner">
                                                {detailClients?.avatar ===
                                                null ? (
                                                  <div
                                                    className="userPhoto"
                                                    style={{
                                                      width: "48px",
                                                      height: "48px",
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                      fontSize: "30px",
                                                      color: "#e5ebf2",
                                                    }}
                                                  >
                                                    ?
                                                  </div>
                                                ) : (
                                                  <img
                                                    src={detailClients?.avatar}
                                                    alt="user"
                                                    className="userPhoto"
                                                    style={{
                                                      width: "48px",
                                                      height: "48px",
                                                      objectFit: "cover",
                                                    }}
                                                  />
                                                )}
                                                {item.cleaner || "No Cleaner"}
                                              </div>

                                              {item.status === "done" ? (
                                                <DetailsCleaningModal
                                                  item={item}
                                                  audit={true}
                                                  setLoading={fetchListHistory}
                                                />
                                              ) : (
                                                <DetailsScheduleModal
                                                  data={item}
                                                  updateItens={fetchListHistory}
                                                />
                                              )}
                                            </div>
                                          </div>
                                        ))
                                    ) : (
                                      <div className="subtile">
                                        No history yet
                                      </div>
                                    )
                                  ) : schedulesCards?.length > 0 ? (
                                    schedulesCards
                                      .slice()
                                      .sort(
                                        (a, b) =>
                                          new Date(a.date) - new Date(b.date)
                                      )
                                      .map((item, idx) => (
                                        <div className="cardInfos" key={idx}>
                                          <div className="cardId">{`#${item.id}`}</div>
                                          <div className="textDate">
                                            {item.date}
                                          </div>

                                          <div
                                            style={{
                                              display: "flex",
                                              gap: "15px",
                                            }}
                                          >
                                            <div className="textCardOver">
                                              {`$${Number(item.value).toFixed(
                                                2
                                              )}`}
                                            </div>
                                            <div
                                              className="textCardOver"
                                              style={{
                                                display: "flex",
                                                gap: "5px",
                                              }}
                                            >
                                              <ReactSVG
                                                src={bathroomsIcon}
                                                style={{
                                                  backgroundColor:
                                                    "rgba(0, 0, 0, 0)",
                                                }}
                                              />
                                              {item.bathroomsAmount}
                                            </div>
                                            <div
                                              className="textCardOver"
                                              style={{
                                                display: "flex",
                                                gap: "5px",
                                              }}
                                            >
                                              <ReactSVG
                                                src={bedroomsIcon}
                                                style={{
                                                  backgroundColor:
                                                    "rgba(0, 0, 0, 0)",
                                                }}
                                              />
                                              {item.bedroomsAmount}
                                            </div>
                                          </div>

                                          <div className="textCardOver">
                                            {item.address.address &&
                                              `${item?.address?.address}, ${item?.address?.city} - ${item?.address?.state}`}
                                          </div>

                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div className="textCardCleaner">
                                              {detailClients?.avatar ===
                                              null ? (
                                                <div
                                                  className="userPhoto"
                                                  style={{
                                                    width: "48px",
                                                    height: "48px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    fontSize: "30px",
                                                    color: "#e5ebf2",
                                                  }}
                                                >
                                                  ?
                                                </div>
                                              ) : (
                                                <img
                                                  src={detailClients?.avatar}
                                                  alt="user"
                                                  className="userPhoto"
                                                  style={{
                                                    width: "48px",
                                                    height: "48px",
                                                    objectFit: "cover",
                                                  }}
                                                />
                                              )}
                                              {item.cleaner || "No Cleaner"}
                                            </div>
                                            <DetailsScheduleModal
                                              data={item}
                                              updateItens={fetchListUpcoming}
                                            />
                                          </div>
                                        </div>
                                      ))
                                  ) : (
                                    <div className="subtile">
                                      No upcoming cleanings yet
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ flex: 2 }} className="containerInfo">
                  <div style={{ flex: 1 }} className="containerLeft">
                    <div className="card" style={{ flex: 3 }}>
                      <div className="titleSchedules">
                        <div
                          onClick={() => setSelectedProperty("Properties")}
                          className={
                            selectedProperty === "Properties"
                              ? "selectedSchedule"
                              : "noSelectedSchedule"
                          }
                        >
                          Properties
                        </div>

                        <div
                          onClick={() =>
                            setSelectedProperty("Deleted Properties")
                          }
                          className={
                            selectedProperty === "Deleted Properties"
                              ? "selectedSchedule"
                              : "noSelectedSchedule"
                          }
                        >
                          Deleted Properties
                        </div>
                      </div>

                      {loadingProperties ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "30vh",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress size={50} />
                          </Box>
                        </div>
                      ) : (
                        <>
                          {selectedProperty === "Properties" && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <EditSchedule
                                setLoadingButton={fetchListProperties}
                                newProperty={true}
                                id={detailClients.id}
                              />
                            </div>
                          )}
                          <div
                            className="containerCard"
                            style={{ maxHeight: "550px" }}
                          >
                            {selectedProperty === "Properties" ? (
                              proprertiesList?.length > 0 ? (
                                proprertiesList
                                  .slice() // Cria uma cópia da lista para não afetar a original
                                  .sort((a, b) => {
                                    // Função de comparação para ordenar por datas
                                    const dateA = moment(
                                      a?.last_schedule?.date
                                    ).utc();
                                    const dateB = moment(
                                      b?.last_schedule?.date
                                    ).utc();
                                    return dateB - dateA; // Ordena em ordem decrescente
                                  })
                                  .map((item, key) => (
                                    <CardProperty
                                      property={item}
                                      key={key}
                                      setLoadingButton={fetchListProperties}
                                      id={detailClients.id}
                                      editable={true}
                                    />
                                  ))
                              ) : (
                                <div className="subtile">No properties yet</div>
                              )
                            ) : listProperties?.deletedProperties?.length >
                              0 ? (
                              listProperties.deletedProperties.map(
                                (item, key) => (
                                  <CardProperty
                                    property={item}
                                    key={key}
                                    setLoadingButton={fetchListProperties}
                                  />
                                )
                              )
                            ) : (
                              <div className="subtile">
                                No property deleted yet
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="containerInfo">
              <div className="card" style={{ flex: 1 }}>
                <div className="title">Reviews</div>
                <div className="containerReviews">
                  {detailClients?.reviews?.length > 0 ? (
                    detailClients?.reviews?.map((item, idx) => (
                      <div key={idx} style={{ marginBottom: "10px" }}>
                        <div
                          className="subtile"
                          style={{ fontWeight: "bolder" }}
                        >
                          CLEANER
                        </div>
                        <div className="subtile">
                          {item?.comment
                            ? item?.comment
                            : "Without description"}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="subtile">No reviews yet.</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div style={style} className="containerModalAdminControl">
            <div className="titleModalAdmin">Add Note</div>
            <div className="lineAdminControl" />
            <InputsContainer>
              <Input
                placeholder="Description"
                field="note"
                form={noteForm}
                assistiveText={{ color: "#0E1F41" }}
              />
            </InputsContainer>
            <div className="containerButtonModalAdmin">
              <button className="cancel" onClick={handleClose}>
                Cancel
              </button>
              <button className="create" onClick={noteForm.submitForm}>
                {loadingButton ? "Loading" : "Create"}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </AuthorizedLayout>
  );
};

export default DetailsClient;
