import React, { useState } from "react";
import Issuers from "./Issuers";
import TotalJobs from "./TotalJobs";

import "./Issuers.css";

const IndexIssuer = () => {
  const [selected, setSelected] = useState("Issuers");
  const [weekData, setWeekData] = useState(null);
  const [listJobs, setListJobs] = useState(null);
  const [loadingJobs, setLoadingJobs] = useState(true);
  const [loadingWeek, setLoadingWeek] = useState(true);

  const setStatusFetch = (status) => {
    setSelected(status);
    // fetchData(status);
    // setStatusSelected("");
    // setSelectedCards([]);
  };

  const selectScrenn = (name) => {
    switch (name) {
      case "jobs":
        return (
          <TotalJobs
            setListJobs={setListJobs}
            listJobs={listJobs}
            setLoadingJobs={setLoadingJobs}
            loadingJobs={loadingJobs}
          />
        );
      default:
        return (
          <Issuers
            setWeekData={setWeekData}
            weekData={weekData}
            setLoadingWeek={setLoadingWeek}
            loadingWeek={loadingWeek}
          />
        );
    }
  };

  return (
    <>
      <div className="containerPayments">
        <div
          className="containerButtons"
          style={{ justifyContent: "flex-start" }}
        >
          <button
            onClick={() => setSelected("Issuers")}
            className={
              selected === "Issuers" ? "selectedStyle" : "notSelectedStyle"
            }
          >
            Issuers
          </button>

          <button
            onClick={() => setStatusFetch("jobs")}
            className={
              selected === "jobs" ? "selectedStyle" : "notSelectedStyle"
            }
          >
            Total Jobs
          </button>
        </div>
        {selectScrenn(selected)}
      </div>
    </>
  );
};

export default IndexIssuer;
