import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { ReactSVG } from "react-svg";
import { AuthorizedLayout } from "../../Components/AuthorizedLayout";
import { TextField } from "@material-ui/core";
import Input from "@mui/material/Input";
import CircularProgress from "@mui/material/CircularProgress";
import { ListItem } from "@mui/material";
import Box from "@mui/material/Box";
import AppContext from "../../Context/AppContext";
import Rating from "@mui/material/Rating";
import { Stack } from "@mui/material";
import Pagination from "@mui/material/Pagination";

import loc from "../../Assets/loc.svg";
import Star from "../../Assets/Star 2.svg";
import CleaningIcon from "../../Assets/Cleaning Icon.svg";

import "./Cleaners.css";
import api from "../../Service/api";
// import ModalCard from "./components/ModalCard";

const initialFilterState = {
  name: "",
  region: "",
  city: "",
  dateFrom: "",
  dateTo: "",
  rating: "",
};

const Cleaners = () => {
  const { listRegions, listCities } = useContext(AppContext);
  const [listCleaners, setListCleaners] = useState([]);
  const [filter, setFilter] = useState(initialFilterState);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searched, setSearched] = useState(false);
  const [typeCLeaner, setTypeCleaner] = useState("Current Cleaners");

  let navigate = useNavigate();

  const fetchData = async (type, page) => {
    setListCleaners([]);
    const getType = () => {
      switch (type) {
        case "Current Cleaners":
          return "/admins/cleaners/current_cleaners";
        case "New Applies":
          return "/admins/cleaners/new_applies";
        default:
          return "/admins/cleaners/finished_contracts";
      }
    };
    setLoading(true);
    try {
      const response = await api.get(getType(typeCLeaner), {
        params: {
          page: page,
          per_page: 40,
          cleaner_name: filter.name,
          region: filter.region,
          city: filter.city,
          from: filter.dateFrom
            ? moment(filter.dateFrom).utc().format("DD/MM/YYYY")
            : "",
          to: filter.dateTo
            ? moment(filter.dateTo).utc().format("DD/MM/YYYY")
            : "",
          ratting: filter.rating,
        },
      });

      const newData = response?.cleaners?.data.map((value) => ({
        id: value.id,
        type: value.type,
        ...value.attributes,
      }));
      setTotal(response.total_pages);
      setListCleaners(newData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setListCleaners(false);
    }
  };

  useEffect(() => {
    fetchData("Current Cleaners", 1);
  }, []);

  const getTypeCleaner = (type) => {
    fetchData(type, 1);
    setTypeCleaner(type);
  };

  // console.log(listCleaners.map((value) => value.cities.map(city => city.id)));

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleRating = (value) => {
    setFilter((prevState) => ({ ...prevState, rating: value }));
  };

  // const handleChangeRegion = (event) => {
  //   setFilter((prevState) => ({ ...prevState, region: event.target.value }));
  // };

  // const handleChangeCity = (event) => {
  //   setFilter((prevState) => ({ ...prevState, city: event.target.value }));
  // };

  const cancelCleaning = async (id) => {
    try {
      await api.delete(`admins/admin_controll/${id}`);
      setLoading(true);
    } catch (error) {
      console.log(error);
    }
  };

  const allCityIds =
    listCleaners.length > 0
      ? listCleaners
          ?.map((cleaner) => cleaner.cities.map((city) => city.id))
          .filter((ids) => ids.length !== 0)
          .flat()
      : [];

  const allRegionsIds =
    listCleaners.length > 0
      ? listCleaners
          ?.map((cleaner) => cleaner.regions.map((regions) => regions.id))
          .filter((ids) => ids.length !== 0)
          .flat()
      : [];

  function handleClick(id, item) {
    navigate(`/cleaners/info/${id}`, {
      state: { item, listCities, listRegions },
    });
  }

  const handleChange = (event, value) => {
    setCurrentPage(value);
    fetchData(typeCLeaner, value);
  };

  const handleSearch = () => {
    fetchData(typeCLeaner, 1);
    setSearched(true);
  };

  const fetchDataReset = async () => {
    setListCleaners([]);
    const getType = (type) => {
      switch (type) {
        case "Current Cleaners":
          return "/admins/cleaners/current_cleaners";
        case "New Applies":
          return "/admins/cleaners/new_applies";
        default:
          return "/admins/cleaners/finished_contracts";
      }
    };
    setLoading(true);
    try {
      const response = await api.get(getType(typeCLeaner), {
        params: {
          page: 1,
          per_page: 40,
          cleaner_name: "",
          region: "",
          city: "",
          from: "",
          to: "",
        },
      });

      const newData = response?.cleaners?.data.map((value) => ({
        id: value.id,
        type: value.type,
        ...value.attributes,
      }));
      setTotal(response.total_pages);
      setListCleaners(newData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setListCleaners(false);
      setLoading(false);
    }
  };

  const handleResetFilter = () => {
    setFilter(initialFilterState);
    fetchDataReset();
    setSearched(false);
  };

  return (
    <AuthorizedLayout>
      <div className="containerOverview containerAdminControl">
        <div className="containerBox"></div>
        <div className="containerFilter">
          <div className="textTitle">Cleaners</div>

          <div className="titleFilter">Cleaners</div>

          <Input
            placeholder="Cleaner name"
            name="name"
            value={filter.name}
            onChange={handleFilterChange}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSearch();
              }
            }}
          />

          <div className="titleFilter">Region</div>

          <TextField
            name="region"
            label={filter.region !== "" ? "" : "Region"}
            select
            value={filter.region}
            onChange={handleFilterChange}
            style={{ borderBottom: "none", width: "100%" }}
          >
            <option value="">None</option>
            {listRegions
              ?.filter((region) => allRegionsIds?.includes(parseInt(region.id)))
              ?.map((value, idx) => (
                <option
                  value={value.id}
                  key={idx}
                  style={{ cursor: "pointer" }}
                >
                  {value.name}
                </option>
              ))}
          </TextField>

          <TextField
            name="city"
            label={filter.city !== "" ? "" : "City"}
            select
            value={filter.city}
            onChange={handleFilterChange}
            style={{ borderBottom: "none", width: "100%" }}
          >
            <option value="">None</option>
            {listCities
              ?.filter((city) => allCityIds?.includes(parseInt(city.id)))
              ?.map((value, idx) => (
                <option
                  value={value.id}
                  key={idx}
                  style={{ cursor: "pointer" }}
                >
                  {`${value.name}`}
                </option>
              ))}
          </TextField>

          <div className="titleFilter">Account created between</div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "5px",
            }}
          >
            <div className="inputCOntianerMObile">
              <div className="subTitleFilter">From:</div>
              <TextField
                name="dateFrom"
                // label="From Date"
                type="date"
                value={filter.dateFrom}
                onChange={handleFilterChange}
                className="inputMObile"
              />
            </div>

            <div className="inputCOntianerMObile">
              <div className="subTitleFilter">To:</div>
              <TextField
                name="dateTo"
                // label="To Date"
                type="date"
                value={filter.dateTo}
                onChange={handleFilterChange}
                className="inputMObile"
              />
            </div>
          </div>

          <div className="titleFilter">Rating</div>

          <div className="containerFIlterRating">
            {[1, 2, 3, 4].map((value, idx) => (
              <div
                key={idx}
                className={
                  filter.rating === value
                    ? "containerCardFIlter selectedRating"
                    : "containerCardFIlter"
                }
              >
                <div style={{ display: "flex", gap: "2px" }}>
                  <div className="title">{value}</div>
                  <ReactSVG src={Star} className="starIcon" />
                </div>
                <div className="containerBOli">
                  <button
                    className="bolinha"
                    onClick={() => handleRating(value)}
                  ></button>
                  <div
                    className="linha"
                    style={{ display: idx === 4 && "" }}
                  ></div>
                </div>
              </div>
            ))}
            <div
              className={
                filter.rating === 5
                  ? "containerCardFIlter selectedRating"
                  : "containerCardFIlter"
              }
              style={{ width: "auto" }}
            >
              <div style={{ display: "flex", gap: "2px" }}>
                <div className="title">5</div>
                <ReactSVG src={Star} className="starIcon" />
              </div>
              <div className="containerBOli">
                <button
                  className="bolinha"
                  onClick={() => handleRating(5)}
                ></button>
              </div>
            </div>
          </div>

          <div
            style={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "20px",
              color: "#0E1F41",
              alignSelf: "center",
              margin: "30px",
            }}
          >
            Cleaning Count: <strong>{listCleaners?.length}</strong>
          </div>

          <div
            style={{ display: "flex", gap: "10px", justifyContent: "center" }}
          >
            <button className="buttonFilter" onClick={handleResetFilter}>
              Clear Selection
            </button>

            <button
              className="buttonFilter"
              style={{ backgroundColor: "#0e1f41" }}
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div
            style={{
              display: "flex",
              alignSelf: "flex-end",
              gap: "25px",
              margin: "15px 20px",
            }}
          >
            <button
              className={
                typeCLeaner === "Current Cleaners"
                  ? "selectedButtonCLeaner"
                  : "notSelectedButtonCLeaner"
              }
              onClick={() => getTypeCleaner("Current Cleaners")}
            >
              Current Cleaners
            </button>
            <button
              className={
                typeCLeaner === "New Applies"
                  ? "selectedButtonCLeaner"
                  : "notSelectedButtonCLeaner"
              }
              onClick={() => getTypeCleaner("New Applies")}
            >
              New Applies
            </button>
            <button
              className={
                typeCLeaner === "Finished Contracts"
                  ? "selectedButtonCLeaner"
                  : "notSelectedButtonCLeaner"
              }
              onClick={() => getTypeCleaner("Finished Contracts")}
            >
              Finished Contracts
            </button>
          </div>
          {listCleaners.length > 0 ? (
            <div className="containerPagination">
              <div style={{ alignSelf: "flex-end", marginBottom: "10px" }}>
                <Stack spacing={2}>
                  <Pagination
                    count={total}
                    page={currentPage}
                    onChange={handleChange}
                  />
                </Stack>
              </div>
              <div className="containerCard">
                {listCleaners
                  ?.sort((a, b) => {
                    if (typeCLeaner === "New Applies") {
                      return new Date(b.createdAt) - new Date(a.createdAt);
                    } else {
                      const nameA = a.firstName + a.lastName;
                      const nameB = b.firstName + b.lastName;
                      return nameA.localeCompare(nameB);
                    }
                  })
                  .map((item, idx) => (
                    <div
                      className="containerCardAdmin"
                      key={idx}
                      style={{
                        flexDirection: "column",
                        gap: "0px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleClick(item.id, item)}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          width: "100%",
                          gap: "10px",
                          marginBottom: 0,
                        }}
                      >
                        {item.avatar === null ? (
                          <div
                            className="userPhoto"
                            style={{
                              width: "64px",
                              height: "64px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "30px",
                              color: "#e5ebf2",
                            }}
                          >
                            ?
                          </div>
                        ) : (
                          <img
                            src={item.avatar}
                            alt="user"
                            className="userPhoto"
                            style={{
                              width: "64px",
                              height: "64px",
                              objectFit: "cover",
                            }}
                          />
                        )}
                        <div className="containerText">
                          <div className="title">{`${item.firstName} ${item.lastName}`}</div>
                          <div
                            className="subtitle"
                            style={{ display: "flex", gap: "5px" }}
                          >
                            {typeCLeaner === "New Applies" ? (
                              <div>{`Register: ${moment(item.createdAt)
                                .utc()
                                .format("MMM. DD, YYYY")}`}</div>
                            ) : (
                              <>
                                <ReactSVG src={CleaningIcon} /> Cleanings Done:{" "}
                                {item.totalCleanings || 0}
                              </>
                            )}
                            <ReactSVG src={loc} /> {item.state || "none"}{" "}
                          </div>
                        </div>
                      </div>

                      <div
                        className="subtitle"
                        style={{
                          fontStyle: "italic",
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <Rating
                          name="half-rating-read"
                          value={Number(item?.ratting) || 0}
                          precision={0.5}
                          readOnly
                          sx={{ color: "#66CDAA", fontSize: "40px" }}
                          size="large"
                        />
                      </div>
                    </div>
                  ))}
              </div>
              <div style={{ alignSelf: "flex-end", marginBottom: "10px" }}>
                <Stack spacing={2}>
                  <Pagination
                    count={total}
                    page={currentPage}
                    onChange={handleChange}
                  />
                </Stack>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "80vh",
                width: "100%",
              }}
            >
              {loading ? (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress size={50} />
                </Box>
              ) : (
                <div className="titleFilter">No matching items found.</div>
              )}
            </div>
          )}
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default Cleaners;
