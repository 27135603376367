import React, { useState, useEffect, useContext } from "react";
import AppContext from "../../../Context/AppContext";
import moment from "moment";
import { ReactSVG } from "react-svg";
import { AuthorizedLayout } from "../../../Components/AuthorizedLayout";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import DetailsScheduleModal from "../../../Components/DetailsScheduleModal";
import bedroomsIcon from "../../../Assets/bedroomsAmount.svg";
import bathroomsIcon from "../../../Assets/bathrooms.svg";

import CalendarCard from "./components/CalendarCard";

import "./Calendar.css";
import api from "../../../Service/api";

const initialFilterState = {
  cleanerName: "",
  hostName: "",
  status: {
    canceled: false,
    scheduled: false,
    done: false,
    deepCleaning: false,
    started: false,
    withoutCleaner: false,
    closeDeadline: false,
  },
  region: "",
  city: "",
};

const Calendar = () => {
  const { listRegions, listCities } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState(initialFilterState);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(moment().month());
  const [selectedYear, setSelectedYear] = useState(moment().year());

  const toggleFilter = () => {
    setIsOpen(!isOpen);
  };

  const getMetrics = async (month, year) => {
    setLoading(true);
    setData([]);

    try {
      const response = await api.get(`/admins/schedules_infos`, {
        params: {
          month: Number(month) + 1 || selectedMonth + 1,
          year: year || selectedYear,
        },
      });
      const newData = await response?.data.map((value) => ({
        id: value.id,
        type: value.type,
        ...value.attributes,
      }));
      setLoading(false);
      setData(newData);
    } catch (erro) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMetrics();
  }, []);

  const refreshItens = () => {
    setLoading(true);
    setData([]);
    getMetrics();
  };

  useEffect(() => {
    let filtered = data?.filter((item) => {
      const allFalse = Object.values(filter.status).every(
        (item) => item === false
      );
      const isCanceled =
        filter.status.canceled && item?.scheduleStatus === "canceled";
      const isStarted =
        filter.status.started && item?.scheduleStatus === "started";

      const isscheduled =
        filter.status.scheduled && item?.scheduleStatus === "scheduled";
      const isDone = filter.status.done && item?.scheduleStatus === "done";
      const isDeepCleaning = filter.status.deepCleaning && item?.deepClean;

      const dataAtual = moment();
      const dataFutura = moment(item.nextCheckinDate).utc().add(7, "days");

      const isCloseDeadline =
        filter.status.closeDeadline &&
        dataAtual.isSameOrBefore(dataFutura, "day");

      const isWithoutCleaner =
        filter.status.withoutCleaner && item?.cleaner === null;

      const isRegion = item.region === filter.region;
      const isRegionNotSelected = Object.values(filter.region).every(
        (item) => item === ""
      );

      const isCity = item.city === filter.city;
      const isCityNotSelected = Object.values(filter.city).every(
        (item) => item === ""
      );

      const cleanerString = item.cleaner ?? "";
      const clientString = item.host ?? "";

      return (
        (!filter.cleanerName ||
          cleanerString
            .toLowerCase()
            .includes(filter.cleanerName.toLowerCase())) &&
        (!filter.hostName ||
          clientString.toLowerCase().includes(filter.hostName.toLowerCase())) &&
        (isRegionNotSelected || isRegion) &&
        (isCityNotSelected || isCity) &&
        (allFalse ||
          isCanceled ||
          isscheduled ||
          isDone ||
          isDeepCleaning ||
          isCloseDeadline ||
          isWithoutCleaner ||
          isStarted)
      );
    });
    if (filter.sortBy === "nameCleaner") {
      filtered = filtered.sort((a, b) => (a.cleaner > b.cleaner ? 1 : -1));
    } else if (filter.sortBy === "nameHost") {
      filtered = filtered.sort((a, b) => (a.client > b.client ? 1 : -1));
    } else if (filter.sortBy === "dateFrom") {
      filtered = filtered.sort((a, b) =>
        new Date(a.date) > new Date(b.date) ? 1 : -1
      );
    }
    setFilteredData(filtered);
  }, [filter, data]);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleStatusChange = (event) => {
    const { name, checked } = event.target;
    setFilter((prevState) => ({
      ...prevState,
      status: { ...prevState.status, [name]: checked },
    }));
  };

  const handleChangeRegion = (event) => {
    setFilter((prevState) => ({ ...prevState, region: event.target.value }));
  };

  const handleChangeCity = (event) => {
    setFilter((prevState) => ({ ...prevState, city: event.target.value }));
  };

  const handleResetFilter = () => {
    setFilter(initialFilterState);
  };

  const prevMonth = () => {
    const newMonth = moment()
      .year(selectedYear)
      .month(selectedMonth)
      .subtract(1, "M");
    if (newMonth.year() < 2021) {
      setSelectedMonth(moment().utc().year(2021).month(0));
      setSelectedYear(2021);
      getMetrics(moment().utc().year(2021).month(0), 2021);
    } else {
      setSelectedMonth(newMonth.month());
      setSelectedYear(newMonth.year());
      getMetrics(newMonth.month(), newMonth.year());
    }
  };

  const nextMonth = () => {
    const newMonth = moment()
      .utc()
      .year(selectedYear)
      .month(selectedMonth)
      .add(1, "M");
    if (newMonth.year() > 2025) {
      setSelectedMonth(moment().utc().year(2025).month(11));
      setSelectedYear(2025);
      getMetrics(moment().utc().year(2025).month(11), 2025);
    } else {
      setSelectedMonth(newMonth.month());
      setSelectedYear(newMonth.year());
      getMetrics(newMonth.month(), newMonth.year());
    }
  };

  const formattedDate = (date) => {
    return moment(date).utc().format("MMM. DD, YYYY | hh:mm A");
  };

  const capitalize = (str) => {
    if (typeof str !== "string") {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.substr(1);
  };

  const fetchSync = async () => {
    setLoading(true);
    try {
      await api.get("/admins/icalendar/sync_schedules");
      setLoading(false);
      alert("Schedules synchronized!");
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert("Error: Failed to synchronize schedules!");
    }
  };

  const SelectMonthYear = ({ selectedMonth, selectedYear, onChange }) => {
    const months = moment.months();
    const startYear = 2018;
    const endYear = moment().year() + 2;
    const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);

    return (
      <div style={{ display: "flex", gap: "10px", marginBottom: '10px' }}>
      <FormControl>
        <Select
          value={selectedMonth}
          onChange={(e) => onChange(e.target.value, selectedYear)}
          displayEmpty
          inputProps={{ "aria-label": "Select Month" }}
        >
          {months.map((month, index) => (
            <MenuItem key={index} value={index}>
              {month}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl>
        <Select
          value={selectedYear}
          onChange={(e) => onChange(selectedMonth, e.target.value)}
          displayEmpty
          inputProps={{ "aria-label": "Select Year" }}
        >
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
    );
  };

  return (
    <AuthorizedLayout>
      <div className="containerOverview containerCalendarstyle">
        {!isOpen && (
          <>
            <div className="containerBox"></div>
            <div className="containerFilter">
              <div className="textTitle">FILTERS</div>

              <div className="titleFilter">Cleaner</div>

              <Input
                placeholder="Cleaner Name"
                name="cleanerName"
                value={filter.cleanerName}
                onChange={handleFilterChange}
              />

              <div className="titleFilter">Host</div>

              <Input
                placeholder="Host Name"
                name="hostName"
                value={filter.hostName}
                onChange={handleFilterChange}
              />

              <div className="titleFilter">Region</div>

              <TextField
                name="region"
                label={filter.region !== "" ? "" : "Region"}
                select
                value={filter.region}
                onChange={handleChangeRegion}
                style={{ borderBottom: "none", width: "100%" }}
              >
                <option value="">None</option>
                {listRegions.map((value, idx) => (
                  <option
                    value={value.name}
                    key={idx}
                    style={{ cursor: "pointer" }}
                  >
                    {value.name}
                  </option>
                ))}
              </TextField>

              <TextField
                name="city"
                label={filter.city !== "" ? "" : "City"}
                select
                value={filter.city}
                onChange={handleChangeCity}
                style={{ borderBottom: "none", width: "100%" }}
              >
                <option value="">None</option>
                {listCities.map((value, idx) => (
                  <option
                    value={value.name}
                    key={idx}
                    style={{ cursor: "pointer" }}
                  >
                    {value.name}
                  </option>
                ))}
              </TextField>

              <div className="titleFilter" style={{ marginBottom: "5px" }}>
                Cleaning Status
              </div>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={filter.status.canceled}
                    onChange={handleStatusChange}
                    name="canceled"
                    color="default"
                    style={{
                      color: "#0E1F41",
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                  />
                }
                label={
                  <span
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "20px",
                      color: "#0E1F41",
                    }}
                  >
                    Canceled
                  </span>
                }
                style={{ paddingTop: 0, paddingBottom: 0 }}
                align="left"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={filter.status.scheduled}
                    onChange={handleStatusChange}
                    name="scheduled"
                    color="default"
                    style={{
                      color: "#0E1F41",
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                  />
                }
                label={
                  <span
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "20px",
                      color: "#0E1F41",
                    }}
                  >
                    Scheduled
                  </span>
                }
                style={{ paddingTop: 0, paddingBottom: 0 }}
                align="left"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={filter.status.done}
                    onChange={handleStatusChange}
                    name="done"
                    color="default"
                    style={{
                      color: "#0E1F41",
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                  />
                }
                label={
                  <span
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "20px",
                      color: "#0E1F41",
                    }}
                  >
                    Done
                  </span>
                }
                style={{ paddingTop: 0, paddingBottom: "0" }}
                align="left"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={filter.status.started}
                    onChange={handleStatusChange}
                    name="started"
                    color="default"
                    style={{
                      color: "#0E1F41",
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                  />
                }
                label={
                  <span
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "20px",
                      color: "#0E1F41",
                    }}
                  >
                    Started
                  </span>
                }
                style={{ paddingTop: 0, paddingBottom: 0 }}
                align="left"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={filter.status.deepCleaning}
                    onChange={handleStatusChange}
                    name="deepCleaning"
                    color="default"
                    style={{ color: "#0E1F41" }}
                  />
                }
                label={
                  <span
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "20px",
                      color: "#0E1F41",
                    }}
                  >
                    Deep Cleaning
                  </span>
                }
                style={{ paddingTop: 0, paddingBottom: "10px" }}
                align="left"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={filter.status.closeDeadline}
                    onChange={handleStatusChange}
                    name="closeDeadline"
                    color="default"
                    style={{ color: "#0E1F41" }}
                  />
                }
                label={
                  <span
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "20px",
                      color: "#0E1F41",
                    }}
                  >
                    Close to the Deadline
                  </span>
                }
                style={{ paddingTop: 0, paddingBottom: "10px" }}
                align="left"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={filter.status.withoutCleaner}
                    onChange={handleStatusChange}
                    name="withoutCleaner"
                    color="default"
                    style={{ color: "#0E1F41" }}
                  />
                }
                label={
                  <span
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "20px",
                      color: "#0E1F41",
                    }}
                  >
                    Without Cleaner
                  </span>
                }
                style={{ paddingTop: 0, paddingBottom: "10px" }}
                align="left"
              />

              <div
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "20px",
                  color: "#0E1F41",
                  alignSelf: "center",
                  margin: "30px",
                }}
              >
                Cleaning Count: <strong>{filteredData?.length}</strong>
              </div>

              <button className="buttonFilter" onClick={handleResetFilter}>
                Clear Selection
              </button>
            </div>
          </>
        )}

        <div className="containerCard">
          <div className="containerCalendar">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                // marginBottom: "10px",
              }}
              className="filterCALENDERtOP"
            >
              <div style={{ width: "160px", height: "34px" }}></div>

              <div className="">
                <SelectMonthYear
                  selectedMonth={selectedMonth}
                  selectedYear={selectedYear}
                  onChange={(month, year) => {
                    setSelectedMonth(month);
                    setSelectedYear(year);
                    getMetrics(month, year);
                  }}
                />
              </div>

              <div style={{ display: "flex", gap: "20px" }}>
                <button
                  className="buttonRunCalendar"
                  onClick={() => fetchSync()}
                >
                  {loading ? "Loading..." : "Run Icalendar"}
                </button>

                <button
                  className="buttonRunCalendar"
                  onClick={toggleFilter}
                  style={{ backgroundColor: isOpen ? "#0E1F41" : "#EF6565" }}
                >
                  {isOpen ? "OPEN FILTER" : "CLOSE FILTER"}
                </button>
              </div>
            </div>
            {!loading ? (
              <>
                <CalendarCard
                  schedules={filteredData}
                  onClick={toggleFilter}
                  isOpen={isOpen}
                  Put={refreshItens}
                  selectedMonth={selectedMonth}
                  selectedYear={selectedYear}
                />
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "50vh",
                  width: "100%",
                }}
              >
                {loading ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress size={50} />
                  </Box>
                ) : (
                  <div className="titleFilter">No matching items found.</div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default Calendar;
