import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { ReactSVG } from "react-svg";
import { Stack } from "@mui/material";
import Rating from "@mui/material/Rating";
import W9FormModal from "../W9FormModal";
import Argreement from "../Argreement";

import StikeIcon from "../../../../Assets/Strike Icon.svg";
import CleaningIcon from "../../../../Assets/Cleaning Icon2.svg";
import MedalIcon from "../../../../Assets/Vector.svg";

import PhotosDocumentsModal from "./Components/PhotosDocumentsModal";

import "./ModalDocuments.css";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const ModalIssuers = ({ item, issuers }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // console.log(data);

  return (
    <div>
      <button
        className="buttonDocument"
        onClick={handleOpen}
        style={{ backgroundColor: "#0E1F41" }}
      >
        See Issuers
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className="containerDocuments">
          <div
            className="conatainerHeader"
            style={{ display: "flex", gap: "100px" }}
          >
            <div
              className="containerUserHeader"
              style={{ display: "flex", alignItems: "center", gap: "20px" }}
            >
              {item?.avatar === null ? (
                <div
                  className="userPhoto"
                  style={{
                    width: "64px",
                    height: "64px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "30px",
                    color: "#e5ebf2",
                  }}
                >
                  ?
                </div>
              ) : (
                <img
                  src={item?.avatar}
                  alt="user"
                  className="userPhoto"
                  style={{
                    width: "64px",
                    height: "64px",
                    objectFit: "cover",
                  }}
                />
              )}
              <div>
                <div className="title">{`${item?.firstName} ${item?.lastName}`}</div>
                <div>
                  <Stack spacing={1}>
                    <Rating
                      name="half-rating-read"
                      defaultValue={item?.ratting}
                      precision={0.5}
                      readOnly
                      sx={{ color: "#0E1F41", fontSize: "25px" }}
                    />
                  </Stack>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                gap: "5px",
                justifyContent: "center",
                // alignItems: 'center',
              }}
            >
              <div className="containerIcon">
                <ReactSVG src={CleaningIcon} /> {item?.totalCleanings} Cleanings
              </div>
              <div className="containerIcon">
                <ReactSVG src={StikeIcon} /> {item?.strikes} Strikes
              </div>
              <div className="containerIcon">
                <ReactSVG src={MedalIcon} /> Level {item?.level}
              </div>
            </div>
          </div>
          <div className="file">Issuers</div>

          <div style={{ marginTop: "25px" }}>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                border: "1px solid #0E1F41",
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
                alignItems: "center",
                background: "#0E1F41",
                color: "white",
                // borderRadius: "15px",
              }}
            >
              <div
                style={{
                  width: "30%",
                  marginLeft: "10px",
                  fontSize: "16px",
                  fontWeight: "600",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                Week
              </div>
              <div
                style={{
                  width: "30%",
                  justifyContent: "center",
                  display: "flex",
                  fontSize: "16px",
                  fontWeight: "600",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                Total Revenue
              </div>

              <div style={{ width: "40%", padding: "5px" }}></div>
            </div>
            {issuers?.length > 0
              ? issuers.map((item, index) => (
                  // <div
                  //   style={{
                  //     display: "flex",
                  //     justifyContent: "center",
                  //     marginTop: "10px",
                  //     marginBottom: "10px",
                  //   }}
                  // >
                  //   <a
                  //     style={{ textDecoration: "none" }}
                  //     href={item.csv_link}
                  //     download
                  //   >
                  //     <button
                  //       className="buttonDocument"
                  //       style={{
                  //         backgroundColor: "#0E1F41",
                  //         borderRadius: "15px",
                  //       }}
                  //     >
                  //       {moment(item.week, "YYYY-MM-DD").format("MMM DD YYYY")}
                  //     </button>
                  //   </a>
                  // </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                      border: "1px solid #0E1F41",
                      borderTop: null,
                      alignItems: "center",
                      background: index % 2 === 0 ? "#fafafa" : "#d5e7f4",
                      // borderRadius: "15px",
                    }}
                  >
                    <div style={{ width: "30%", marginLeft: "12px" }}>
                      {moment(item.week, "YYYY-MM-DD").format("MMM DD YYYY")}
                    </div>
                    <div
                      style={{
                        width: "30%",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      $ {Number(item.total_revenue).toFixed(2)}
                    </div>

                    <div style={{ width: "40%", padding: "5px" }}>
                      <a
                        style={{ textDecoration: "none" }}
                        href={item.csv_link}
                        download
                      >
                        <button
                          className="buttonDocument"
                          style={{
                            display: "flex",
                            width: "100%",
                            backgroundColor: "#0E1F41",
                            borderRadius: "15px",
                            // color: "white",
                            fontSize: "16px",
                            // fontWeight: "500",
                          }}
                        >
                          Download Issuer
                        </button>
                      </a>
                    </div>
                  </div>
                ))
              : "No records found"}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalIssuers;
