import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import DeleteTemplate from "./DeleteTemplate";

import editIcon from "../../../Assets/Btn Edit.svg";
import BtnDelete from "../../../Assets/Btn Delete.svg";

import "./CardsTemplates.css";
import { ReactSVG } from "react-svg";
import api from "../../../Service/api";

const CardsTemplates = ({
  items,
  typeSelected,
  setLoading,
  typePush,
  loading,
}) => {
  const navigate = useNavigate();

  const handleChange = async (item) => {
    const attributes = {
      title: item?.title,
      messageType: item?.message_type,
      body: item?.body,
      subjectType: item.subject_type,
      templateType: item.template_type,
      protected: !item.protected,
      active: item.active,
    };

    const formValues = {
      data: { attributes },
    };

    try {
      await api.put(`/admins/message_templates/${item.id}`, formValues);
      setLoading(true);
    } catch (error) {
      console.log(error);
    }
  };

  function handleNew() {
    navigate(`/${typePush ? "push" : "emails"}/all-templates/new`, {
      state: { news: true, typeSelected, typePush },
    });
  }

  function handleEdit(id, items) {
    navigate(`/${typePush ? "push" : "emails"}/all-templates/edit/${id}`, {
      state: { news: false, items, typeSelected, typePush },
    });
  }

  return (
    <div className="containerCardTemplate">
      <div className="header">
        <div>
          {typePush ? "Push Notification Templates" : "Email Templates"}
        </div>
        <button onClick={() => handleNew()}>
          {typePush
            ? "+ New push notification templates"
            : "+ New email template"}
        </button>
      </div>

      {items.length > 0 ? (
        <div className="containerAllCards">
          {items.map((value, idx) => (
            <div key={idx} className="card">
              <div>
                <div className="title">{value.title}</div>
                <div className="subtitle">{value.message_type}</div>
              </div>

              <div>
                <div style={{ display: "flex", alignItems: 'center' }}>
                  <div>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={value.protected}
                        onChange={() => handleChange(value)}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                  <button
                    className="buttonDeleteAdmin"
                    onClick={() => handleEdit(value.id, value)}
                  >
                    <ReactSVG src={editIcon} />
                  </button>
                  <DeleteTemplate item={value} setLoading={setLoading} />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "40vh",
            width: "100%",
          }}
        >
          {loading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress size={50} />
            </Box>
          ) : (
            <div className="titleFilter">No matching items found.</div>
          )}
        </div>
      )}
    </div>
  );
};

export default CardsTemplates;
