import React, { useState, useEffect, useContext } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ModalContainer } from "../../../../Components/ModalContainer";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import editIcon from "../../../../Assets/edit 2 (1).svg";
import ContentCopySharpIcon from "@mui/icons-material/ContentCopySharp";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import { useNavigate } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "./CardTable.css";
import { ReactSVG } from "react-svg";
import AppContext from "../../../../Context/AppContext";
import api from "../../../../Service/api";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  blueRow: {
    backgroundColor: "#D5E7F4",
  },
  borderHeader: {
    borderBottom: "2px solid #0E1F41",
    borderRight: "2px solid #0E1F41",
  },
  borderContent: {
    borderBottom: "1px solid #0E1F41",
    borderRight: "2px solid #0E1F41",
  },
}));

const renderCustomIcon = (props) => {
  return (
    <ExpandMoreIcon {...props} style={{ color: "#FAFAFA", fontSize: "40px" }} />
  );
};

const CardTable = ({ filteredData, isLoading, getListServcies }) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { listRegions } = useContext(AppContext);

  const [region, setRegion] = useState({
    id: "1",
    name: "Los Angeles",
  });

  const handleChange = (event) => {
    setRegion(event.target.value);
  };

  const accordionStyle = {
    background: "#C5D4DF",
    borderRadius: "6px",
    marginTop: "20px",
  };

  const containerHeader = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
    width: "100%",
  };

  const titleStyle = {
    position: "relative",
    borderTop: "2px solid #0E1F41",
    width: "95%",
    margin: "auto",
  };

  const expandIconStyle = {
    color: "#0E1F41",
    fontSize: "44px",
  };

  const titleAccordionHeader = {
    color: "#0E1F41",
    fontFamily: "Roboto",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  };

  const sortedArray = (a, b) => {
    if (Number(a.bedroomsAmount) < Number(b.bedroomsAmount)) {
      return -1;
    }
    if (Number(a.bedroomsAmount) > Number(b.bedroomsAmount)) {
      return 1;
    }

    if (Number(a.bathroomsAmount) < Number(b.bathroomsAmount)) {
      return -1;
    }
    if (Number(a.bathroomsAmount) > Number(b.bathroomsAmount)) {
      return 1;
    }
    return 0;
  };

  function handleClick(values) {
    navigate(`/pricing-table/new-service/${values.id}`, {
      state: { ...values },
    });
  }

  const cloneTable = async () => {
    try {
      await api.post(
        `/admins/pricing_table/${filteredData.id}/clone?region_id=${region.id}`
      );
      alert("Successfully cloned service table");
    } catch (error) {
      console.log(error);
      alert("Error: Failed COPY.");
    }
  };

  const form = useFormik({
    initialValues: {},
    onSubmit: () => cloneTable(),
  });

  const deteleTable = async (id) => {
    try {
      await api.delete(`/admins/pricing_table/${id}`);
      alert("Price table deleted successfully!");
      getListServcies(filteredData.regionId);
    } catch (error) {
      console.log(error);
      alert("Error: detele table.");
    }
  };

  return (
    <div>
      <Accordion style={accordionStyle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={expandIconStyle} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div style={containerHeader}>
            <Typography style={titleAccordionHeader}>
              {filteredData.name}
            </Typography>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <ModalContainer
                customButton={
                  <ContentCopySharpIcon
                    sx={{ fontSize: 33, color: "#0d6efd" }}
                  />
                }
                type={true}
                title="Copy Table of Services"
                onClickSave={form.submitForm}
              >
                <div className="titleINputModal">Region:</div>
                <div className="containerSelectAdmin" style={{ width: "100%" }}>
                  <div className="selectedContainer" style={{ width: "100%" }}>
                    <div>{region.name}</div>
                  </div>
                  <Select
                    className="containerIcon"
                    onChange={handleChange}
                    style={{ height: "50px" }}
                    displayEmpty
                    renderValue={() => null}
                    IconComponent={renderCustomIcon}
                  >
                    {/* <MenuItem value={{name: "Region"}}>All</MenuItem> */}
                    {listRegions?.map((item, idx) => (
                      <MenuItem value={item} key={idx}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </ModalContainer>

              <div
                // className={`${classes.borderContent} subtitleTable`}
                style={{ borderRight: "none", cursor: "pointer" }}
                onClick={() => handleClick(filteredData)}
              >
                <div>
                  <ReactSVG src={editIcon} />
                </div>
              </div>

              <ModalContainer
                customButton={
                  <DeleteOutlineSharpIcon
                    sx={{ fontSize: 38, color: "#dd3545" }}
                  />
                }
                deleteButton={true}
                type={true}
                title="Delete service table"
                onClickSave={() => deteleTable(filteredData.id)}
              >
                <div className="titleModalDelete">
                  Are you sure you want to delete the{" "}
                  <strong>{filteredData.name}</strong> table?
                </div>
              </ModalContainer>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails style={titleStyle} />

        <div
          className="titleModalDelete"
          style={{ textAlign: "end", marginRight: "30px", fontSize: "20px" }}
        >
          Maximum Payout Value: $<strong>{filteredData.payoutMaxValue}</strong>
        </div>

        <div className="containerAccountsNotifications">
          <div className="">
            <TableContainer component={Paper} className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow className={classes.blueRow}>
                    <TableCell className={`${classes.borderHeader} titleTable`}>
                      Bedrooms
                    </TableCell>
                    <TableCell className={`${classes.borderHeader} titleTable`}>
                      Bathrooms
                    </TableCell>
                    <TableCell className={`${classes.borderHeader} titleTable`}>
                      Price
                    </TableCell>
                    <TableCell className={`${classes.borderHeader} titleTable`}>
                      Exceed Price
                    </TableCell>
                    <TableCell className={`${classes.borderHeader} titleTable`}>
                      Average Time
                    </TableCell>
                    <TableCell className={`${classes.borderHeader} titleTable`}>
                      Payout
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.pricingTableLines.length > 0 &&
                    filteredData.pricingTableLines
                      ?.slice()
                      .sort((a, b) => sortedArray(a, b))
                      .map((row, index) => (
                        <TableRow key={index}>
                          <TableCell
                            className={`${classes.borderContent} subtitleTable`}
                          >
                            {row.bedroomsAmount || "x"}
                          </TableCell>
                          <TableCell
                            className={`${classes.borderContent} subtitleTable`}
                          >
                            {row.bathroomsAmount || "x"}
                          </TableCell>
                          <TableCell
                            className={`${classes.borderContent} subtitleTable`}
                          >
                            {row.price !== null ? `$${row.price}` : "x"}
                          </TableCell>
                          <TableCell
                            className={`${classes.borderContent} subtitleTable`}
                          >
                            {row.exceedPrice !== null
                              ? `$${row.exceedPrice}`
                              : "x"}
                          </TableCell>
                          <TableCell
                            className={`${classes.borderContent} subtitleTable`}
                          >
                            {row.avgTime !== null ? `${row.avgTime}h` : "x"}
                          </TableCell>
                          <TableCell
                            className={`${classes.borderContent} subtitleTable`}
                          >
                            {row.payout || "x"}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            {filteredData.pricingTableLines.length <= 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // height: "30vh",
                  width: "100%",
                }}
              >
                {isLoading ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress size={50} />
                  </Box>
                ) : (
                  <div
                    className="titleFilter"
                    style={{ textAlign: "center", width: "100%" }}
                  >
                    No matching items found.
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Accordion>
    </div>
  );
};

export default CardTable;
