import React, { useState, useEffect } from "react";
import AppContext from "./AppContext";
import axios from "axios";
import api from "../Service/api";
import moment from "moment";

function AppProvider({ children }) {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [metrics, setMetrics] = useState([]);
  const [listCleaners, setListCleaners] = useState([]);
  const [listClients, setListClients] = useState([]);
  const [listRegions, setListRegions] = useState([]);
  const [listCities, setListCities] = useState([]);
  const [typeCLeaner, setTypeCleaner] = useState("Cleaners");
  const [typePush, setTypePush] = useState(false);
  const [isLoadingRegionId, setIsLoadingId] = useState(false);
  const [regionId, setRegionId] = useState({ id: 0, name: "" });

  const getMetrics = async () => {
    try {
      const { data } = await api.get("/admins/dashboard/metrics");
      // console.log(data);
      setMetrics({ id: data.id, type: data.type, ...data.attributes });
    } catch (erro) {}
  };

  // console.log(listRegions);

  const getRegions = async () => {
    try {
      const { data } = await api.get("/admins/regions");
      const newData = await data?.map((value) => ({
        id: value.id,
        type: value.type,
        ...value.attributes,
      }));

      setListRegions(newData);
      setRegionId(newData[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const getCities = async () => {
    try {
      const { data } = await api.get("/admins/cities");

      const newData = await data?.map((value) => ({
        id: value.id,
        type: value.type,
        ...value.attributes,
      }));

      setListCities(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const getListCleaner = async () => {
    try {
      const resultCleaners = await api.get("/admins/cleaners/report");
      const resultClients = await api.get("/admins/clients/report");
      setListCleaners(resultCleaners.data);
      setListClients(resultClients.data);
    } catch (erro) {
      console.log(erro);
    }
  };

  const signIn = async ({ email, password }) => {
    setLoading(true);
    
    try {
      const { data } = await api.post("/admins/authenticate", {
        email: email.trim(),
        password,
      });

      // console.log(data);

      const parsedUser = {
        id: data.id,
        ...data.attributes,
      };

      localStorage.setItem("@SettcorpAdmin: user", JSON.stringify(parsedUser));

      api.defaults.headers["Authorization"] = `Bearer ${parsedUser.authToken}`;

      setUserData(parsedUser);
      getMetrics();
      getRegions();
      getCities();
      getListCleaner();
    } catch (error) {
      console.log(error);
      // console.log(error?.response?.data?.error);
      if (error?.response?.data?.error?.admin_authentication) {
        alert("Invalid email or password. Please try again.");
      } else {
        if (
          error?.response?.data?.error &&
          error?.response?.data?.error[0]?.title
        ) {
          alert(`${error.response.data.errors[0].title}`);
        } else if (error?.response?.data?.error) {
          alert(`${error.response.data.error}`);
        } else {
          alert("Something unexpected happened. Please try again.");
        }
      }
    }
    setLoading(false);
  };

  const signOut = async () => {
    localStorage.removeItem("@SettcorpAdmin: user");
    api.defaults.headers["Authorization"] = undefined;

    setUserData(null);
  };

  useEffect(() => {
    const userStorage = localStorage.getItem("@SettcorpAdmin: user");

    if (userStorage) {
      const parsedUser = JSON.parse(userStorage);

      // Checking if the token has expired.
      if (moment(parsedUser.expiresAt).isBefore(new Date())) {
        signOut();
      } else {
        api.defaults.headers[
          "Authorization"
        ] = `Bearer ${parsedUser.authToken}`;
        setUserData(parsedUser);
        getMetrics();
        getRegions();
        getCities();
        getListCleaner();
      }
    } else {
      signOut();
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        user: userData,
        signIn,
        signOut,
        metrics,
        listCleaners,
        listClients,
        listRegions,
        getRegions,
        listCities,
        getCities,
        typeCLeaner,
        setTypeCleaner,
        typePush,
        setTypePush,
        regionId,
        setRegionId,
        isLoadingRegionId,
        setIsLoadingId,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default AppProvider;
