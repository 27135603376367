import axios from "axios";

export const baseURL = () => {
  const env =
    process.env.API_ENV === "production"
      ? process.env.REACT_APP_PRODUCTION_URL
      : process.env.REACT_APP_DEV_URL;

  return env;
};

const api = axios.create({
  baseURL: baseURL(),
});

api.interceptors.response.use((response) => {
  return response.data;
});

export const UseZipCodeAPI = async (zipcode) => {
  // console.log(zipcode.length === 5);
  // const key =
  //   "dVpIqlzJNp60bgGiq0cIMvhJ3b37Gyt6oZbPfy3gHMETzwloKlPOUIhqh2fNofQD";

  const key =
    "js-NL42CSZ719HhsjhJlKKWec5BVK9lEBT87xPKUlvnMvG9yUvPVnUjwbcMoqEuPi4q";

  if (zipcode && zipcode.length === 5) {
    try {
      const { data } = await axios.post(
        `https://www.zipcodeapi.com/rest/${key}/info.json/${zipcode}/radians`
      );
      return data;
    } catch (error) {
      return {
        city: null,
        state: null,
      };
    }
  }
};

export default api;
