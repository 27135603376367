import React from "react";
import { ReactSVG } from "react-svg";

import iconBath from "../../../../../Assets/Bath Icon1 1.svg";
import iconBad from "../../../../../Assets/Bed1 1.svg";
import iconMenos from "../../../../../Assets/Frame 1844.svg";
import iconPLus from "../../../../../Assets/Frame 1843.svg";

import "./Step1.css";

const Step1 = ({ form }) => {

  return (
    <div className="containerStep1">
      <div style={{ marginTop: "20px" }}>
        <div>
          <div style={{ marginTop: "20px" }}>
            <div className="titleINputModalStep">
              Date
            </div>
            <div className="containerInputText">
              <input
                type="date"
                className="inputText"
                value={form.values.date}
                onChange={(event) =>
                  form.setFieldValue("date", event.target.value)
                }
              />
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <div className="titleINputModalStep">
              Time
            </div>
            <div className="containerInputText">
              <input
                type="time"
                className="inputText"
                value={form.values.time}
                onChange={(event) =>
                  form.setFieldValue("time", event.target.value)
                }
              />
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <div className="titleINputModalStep">
              Next checkin date
            </div>
            <div className="containerInputText">
              <input
                type="date"
                className="inputText"
                value={form.values.nextCheckinDate}
                onChange={(event) =>
                  form.setFieldValue("nextCheckinDate", event.target.value)
                }
              />
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <div className="titleINputModalStep">
              Next checkin time
            </div>
            <div className="containerInputText">
              <input
                type="time"
                className="inputText"
                value={form.values.nextCheckinTime}
                onChange={(event) =>
                  form.setFieldValue("nextCheckinTime", event.target.value)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
