import React, { useState, useEffect } from "react";

import "./Autocomplete.css";

const Autocomplete = ({ options, placeholder, onChange, data, searchTerm, setSearchTerm,  ...props }) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setSearchTerm(data.cleaner);
  }, [data]);

  useEffect(() => {
    const filterOptions = () => {
      const filtered = options.filter((option) =>
        option.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredOptions(filtered);
    };

    if (searchTerm) {
      filterOptions();
    } else if (searchTerm?.length <= 0) {
      setFilteredOptions(options);
    }
  }, [searchTerm, options]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    setIsOpen(true);
  };

  const handleOptionClick = (option) => {
    setSearchTerm(option.name);
    setIsOpen(false);
    onChange(option);
  };

  const handleBlur = () => {
    // if (!searchTerm) {
    //   setIsOpen(false);
    // }
    setIsOpen(false);
  };

  const handleCLick = () => {
    setIsOpen(true);
  };

  return (
    <div className="autocomplete" {...props}>
      <input
        type="text"
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleInputChange}
        // onBlur={handleBlur}
        onClick={handleCLick}
      />
      {isOpen && filteredOptions.length > 0 && (
        <ul className="suggestions">
           <li
              onClick={() => handleBlur()}
              style={{ marginBottom: "10px", color: 'red' }}
            >
              CANCEL
            </li>
          {filteredOptions.map((option, key) => (
            <li
              key={key}
              onClick={() => handleOptionClick(option)}
              style={{ marginBottom: "10px" }}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Autocomplete;
