import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import { TextField } from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";

import { TextareaAutosize, FormHelperText } from "@mui/material";
import { Input } from "../../../Components/Input";
import { InputsContainer } from "../../../Components/InputsContainer";
import { ModalContainer } from "../../../Components/ModalContainer";
import EmailIcon from "../../../Assets/emailIcon.svg";
import EmailIcon2 from "../../../Assets/Component 1.svg";
import CancelIcon from "../../../Assets/Btn Cancel.svg";
import EditIcon from "../../../Assets/Btn Edit (2).svg";
import StarIcon from "../../../Assets/Star 1.svg";
import DeleteNews from './DeleteNews';

import "./CardNews.css";
import moment from "moment";
import api from "../../../Service/api";

const CardNews = ({ data, setLoading }) => {
  const [starSelected, setStarSelected] = useState(false);

  const handleItens = async (values) => {
    const attributes = {
      title: values.postTitle,
      subtitle: values.postSubtitle,
      body: values.body,
    };

    const formValues = {
      data: { attributes },
    };

    try {
      await api.put(`/admins/posts/${values.id}`, formValues);
    } catch (error) {
      console.log(error);
    }
  };

  const signInForm = useFormik({
    initialValues: {
      id: data?.id || "",
      postTitle: data?.title || "",
      postSubtitle: data?.subtitle || "",
      body: data?.body || "",
      profilePhoto: "",
    },
    validationSchema: yup.object({
      postTitle: yup
        .string()
        .min(2, "Post Title must have at least 2 characters.")
        .max(50, "Post Title must have at most 50 characters.")
        .required("Post Title is required!"),
      postSubtitle: yup
        .string()
        .min(2, "Post Subtitle must have at least 2 characters.")
        .max(50, "Post Subtitle must have at most 50 characters.")
        .required("Post Subtitle is required!"),
      body: yup
        .string()
        .min(10, "body must have at least 10 characters.")
        .max(500, "body must have at most 500 characters.")
        .required("body is required!"),
    }),
    onSubmit: (values) => handleItens(values),
  });

  function MyFormHelperText({ text }) {
    // const { focused } = useFormControl() || {};

    const helperText = React.useMemo(() => {
      if (text) {
        return `${text}`;
      }

      return ``;
    }, [text]);

    return <FormHelperText>{helperText}</FormHelperText>;
  }

  function convertImageToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  }

  function handleFileChange(event) {
    const file = event.target.files[0];

    convertImageToBase64(file)
      .then((base64String) => {
        signInForm.setFieldValue("profilePhoto", base64String);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const formattedDate = (date) => {
    return moment(date).utc().format("MMM. DD, YYYY | hh:mm A");
  };

  return (
    <>
      <ReactSVG src={EmailIcon} />
      <div className="contentTextNews">
        <div className="headerCardNews">
          <div className="title">{data?.title}</div>
          <div className="containerButtonNewsCard">
            <button onClick={() => setStarSelected(!starSelected)}>
              <ReactSVG src={StarIcon} className={starSelected && "selected"} />
            </button>

            <ModalContainer
              title="Edit Post"
              customButton={<ReactSVG src={EditIcon} />}
              newsModal={true}
              onClickSave={signInForm.submitForm}
              setLoadingButton={setLoading}
            >
              <InputsContainer>
                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      marginTop: "5px",
                      borderBottom: "1px solid #8b8f93",
                      paddingBottom: "6px",
                    }}
                  >
                    <label
                      htmlFor="profilePhoto"
                      className="containerImageNews"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#0E1F41",
                        fontSize: "20px",
                        fontWeight: "400",
                        cursor: "pointer",
                      }}
                    >
                      <div className="titleInput">Add image</div>
                      {signInForm?.values?.profilePhoto === "" ? (
                        <ReactSVG src={EmailIcon2} />
                      ) : (
                        <img
                          src={signInForm?.values?.profilePhoto}
                          alt="img"
                          style={{ width: "163px", height: "96px" }}
                        />
                      )}
                    </label>
                    <input
                      id="profilePhoto"
                      name="profilePhoto"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    {signInForm.values.profilePhoto !== "" && (
                      <>
                        <span style={{ color: "red" }}>Selected photo</span>
                        <button
                          onClick={() =>
                            signInForm.setFieldValue("profilePhoto", "")
                          }
                          style={{ color: "red", textAlign: "center" }}
                        >
                          x
                        </button>
                      </>
                    )}
                  </div>
                </div>

                <Input
                  placeholder="Post Title"
                  field="postTitle"
                  form={signInForm}
                  assistiveText={{ color: "#0E1F41" }}
                />

                <Input
                  placeholder="Post Subtitle"
                  field="postSubtitle"
                  form={signInForm}
                  assistiveText={{ color: "#0E1F41" }}
                />

                <div style={{ width: "100%", resize: "none" }}>
                  <div className="titleInput">Body</div>
                  <TextareaAutosize
                    className="textArea"
                    minRows={10}
                    maxRows={50}
                    placeholder="Body"
                    style={{ width: "100%", resize: "none" }}
                    onChange={signInForm.handleChange("body")}
                    defaultValue={signInForm.values["body"]}
                  />
                  <MyFormHelperText
                    text={
                      signInForm.errors["body"] && signInForm.errors["body"]
                    }
                  />
                </div>
              </InputsContainer>
            </ModalContainer>
            <button>
              {/* <ReactSVG src={CancelIcon} /> */}
              <DeleteNews item={data} setLoading={setLoading}/>
            </button>
          </div>
        </div>
        <div className="subtitle">{data?.subtitle}</div>
        <div className="text">{formattedDate(data?.published_at)}</div>
        <div className="text">{data?.body}</div>
      </div>
    </>
  );
};

export default CardNews;
