import React, { useState, useEffect, useContext } from "react";
import { ListItem } from "@mui/material";
import { SidebarLink } from "../../Components/MainSidebar/styles";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { ReactSVG } from "react-svg";
import { AuthorizedLayout } from "../../Components/AuthorizedLayout";
import AppContext from "../../Context/AppContext";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import GraphCard from "./components/GraphCard";
import Calendar from "./components/Calendar";
import Payments from "./components/Payments";
import Notifications from "./components/Notifications";

import homeIcon from "../../Assets/homeIcon.svg";
import cleanerIcon from "../../Assets/cleanerIcon.svg";
import userDashIcon from "../../Assets/userDashIcon.svg";
import setaGRaph from "../../Assets/Vector 25.svg";

import "./dashboard.css";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // width: "120px",
  border: `none`,
  "&:not(:last-child)": {},
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <MuiAccordionSummary
      expandIcon={
        <ReactSVG src={setaGRaph} style={{ transform: "rotate(90deg)" }} />
      }
      {...props}
      onClick={() => setExpanded(!expanded)}
    />
  );
})(({ theme }) => ({
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(178deg)",
  },
  "& .MuiAccordionSummary-content": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    margin: "0 15px",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({}));

const Dashboard = () => {
  const [shouldAnimate, setShouldAnimate] = useState(true);
  const [selectedCleaner, setSelectedCleaners] = useState("Cleanings");
  const [selectedHost, setSelectedHost] = useState("Cleanings");
  const [expanded, setExpanded] = useState("");
  const [expanded2, setExpanded2] = useState("");

  const { metrics, user } = useContext(AppContext);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // console.log(metrics);

  const handleItemClick = (event) => {
    const item = event.target.value;

    if (item === "Cleanings") {
      setSelectedCleaners("Cleanings");
      setExpanded("");
    } else {
      setSelectedCleaners("Rating");
      setExpanded("");
    }
  };

  const handleChange2 = (panel) => (event, newExpanded) => {
    setExpanded2(newExpanded ? panel : false);
  };

  const handleItemClick2 = (event) => {
    const item = event.target.value;

    if (item === "Cleanings") {
      setSelectedHost("Cleanings");
      setExpanded2("");
    } else {
      setSelectedHost("Rating");
      setExpanded2("");
    }
  };

  function NumberTransition(props) {
    const [value, setValue] = useState(0);

    useEffect(() => {
      const { TARGET_NUMBER } = props;
      let interval;
      const step = Math.ceil(TARGET_NUMBER / 100);
      interval = setInterval(() => {
        if (shouldAnimate) {
          if (value + step >= TARGET_NUMBER) {
            clearInterval(interval);
            setValue(TARGET_NUMBER);
          } else {
            setValue((prevValue) => prevValue + step);
          }
        } else {
          clearInterval(interval);
        }
      }, 10);

      return () => {
        clearInterval(interval);
      };
    }, [props, value]);

    return <div className="titleTotal">{value}</div>;
  }

  function isUserAllowed(user) {
    return (
      user.adminType === "master" ||
      user.adminType === "manager" ||
      user.adminType === "director"
    );
  }

  const atualizarPagina = () => {
    window.location.reload();
  };


  return (
    <AuthorizedLayout>
      {metrics.id ? (
        <div style={{ display: "flex", flex: 1, gap: "25px", height: "100%" }} className="flexDirecitonCOllum">
          <div
            style={{
              display: "flex",
              flex: 3,
              flexDirection: "column",
              gap: "25px",
            }}
          >
            <div style={{ flex: 3 }} className="boxShadon flexDirecitonCOllum">
              <div className="containerTitleGraph flexDirecitonCOllum">
                <SidebarLink to={"/calendar"}>
                  <ListItem style={{ margin: 0, padding: 0 }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flex: 1,
                      }}
                    >
                      <div className="titleGraph">Calendar</div>
                      <div>
                        <div
                          style={{ display: "flex", gap: "10px" }}
                          className="subTitleCalendar"
                        >
                          See more <ReactSVG src={setaGRaph} />
                        </div>
                      </div>
                    </div>
                  </ListItem>
                </SidebarLink>
              </div>
              <Calendar schedules={metrics?.calendarSchedules} Put={atualizarPagina} />
            </div>

            <div
              style={{
                display: "flex",
                flex: 4,
                flexDirection: "column",
                gap: "25px",
              }}
            >
              <div className="containerTotal flexDirecitonCOllum">
                <div className="cardTotal boxShadon">
                  <div style={{ alignSelf: "flex-end" }}>
                    <NumberTransition
                      TARGET_NUMBER={metrics?.cleanersNumber || 0}
                    />
                    <div className="subtitleTotal">Cleaners</div>
                  </div>
                  <div>
                    <ReactSVG src={cleanerIcon} />
                  </div>
                </div>
                <div className="cardTotal boxShadon">
                  <div style={{ alignSelf: "flex-end" }}>
                    <NumberTransition
                      TARGET_NUMBER={metrics?.clientsNumber || 0}
                    />
                    <div className="subtitleTotal">Hosts</div>
                  </div>
                  <div>
                    <ReactSVG src={userDashIcon} />
                  </div>
                </div>
                <div className="cardTotal boxShadon">
                  <div style={{ alignSelf: "flex-end" }}>
                    <NumberTransition
                      TARGET_NUMBER={metrics?.addressNumber || 0}
                    />
                    <div className="subtitleTotal">Properties</div>
                  </div>
                  <div>
                    <ReactSVG src={homeIcon} />
                  </div>
                </div>
              </div>

              <div style={{ flex: 2, gap: "25px", display: "flex" }} className="flexDirecitonCOllum">
                <div style={{ flex: 1 }} className="boxShadon">
                  <div className="containerTitleGraph">
                    <SidebarLink to={"/cleaners"}>
                      <ListItem style={{ margin: 0, padding: 0 }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flex: 1,
                          }}
                        >
                          <div className="titleGraph">Cleaners</div>
                          <div>
                            <ReactSVG src={setaGRaph} />
                          </div>
                        </div>
                      </ListItem>
                    </SidebarLink>
                  </div>

                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        right: 30,
                        marginTop: "10px",
                      }}
                    >
                      <div className="titleByGraph">Top Cleaners</div>
                      <Accordion
                        expanded={expanded === "panel1"}
                        onChange={handleChange("panel1")}
                        style={{ backgroundColor: "transparent" }}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                          style={{ padding: "0" }}
                        >
                          <div
                            className={"graphText"}
                          >{`By: ${selectedCleaner}`}</div>
                        </AccordionSummary>
                        <AccordionDetails
                          style={{
                            marginTop: "0",
                            paddingTop: "0",
                            width: "100%",
                          }}
                        >
                          <button
                            onClick={(event) => handleItemClick(event)}
                            value={
                              selectedCleaner !== "Cleanings"
                                ? "Cleanings"
                                : "Rating"
                            }
                            className="buttonGraph"
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                              margin: 0,
                              padding: 0,
                              cursor: "pointer",
                            }}
                          >
                            {selectedCleaner !== "Cleanings"
                              ? "Cleanings"
                              : "Rating"}
                          </button>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>

                  <GraphCard
                    dataMetric={
                      selectedCleaner === "Rating"
                        ? metrics.topCleanersRattings
                        : metrics.topCleanersCleanings
                    }
                  />
                </div>

                <div style={{ flex: 1 }} className="boxShadon flexDirecitonCOllum">
                  <div className="containerTitleGraph">
                    <SidebarLink to={"/clients"}>
                      <ListItem style={{ margin: 0, padding: 0 }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flex: 1,
                          }}
                        >
                          <div className="titleGraph">Hosts</div>
                          <div>
                            <ReactSVG src={setaGRaph} />
                          </div>
                        </div>
                      </ListItem>
                    </SidebarLink>
                  </div>

                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        right: 30,
                        marginTop: "10px",
                      }}
                    >
                      <div className="titleByGraph">Top Hosts</div>
                      <Accordion
                        expanded={expanded2 === "panel1"}
                        onChange={handleChange2("panel1")}
                        style={{ backgroundColor: "transparent" }}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                          style={{ padding: "0" }}
                        >
                          <div
                            className={"graphText"}
                          >{`By: ${selectedHost}`}</div>
                        </AccordionSummary>
                        <AccordionDetails
                          style={{
                            marginTop: "0",
                            paddingTop: "0",
                            width: "100%",
                          }}
                        >
                          <button
                            onClick={(event) => handleItemClick2(event)}
                            value={
                              selectedHost !== "Cleanings"
                                ? "Cleanings"
                                : "Rating"
                            }
                            className="buttonGraph"
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                              margin: 0,
                              padding: 0,
                              cursor: "pointer",
                            }}
                          >
                            {selectedHost !== "Cleanings"
                              ? "Cleanings"
                              : "Rating"}
                          </button>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>

                  <GraphCard
                    dataMetric={
                      selectedHost === "Rating"
                        ? metrics.topClientsRattings
                        : metrics.topClientsCleanings
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              gap: "25px",
            }}
          >
            <div
              style={{ flex: 1 }}
              className="containerPaymentsDash boxShadon"
            >
              <div className="cardCoupon">
                <div>
                  <div className="titleCoupom2">Active Coupon:</div>
                  <SidebarLink to={"/coupons"}>
                    <ListItem
                      style={{ margin: 0, padding: 0, marginTop: "8px" }}
                    >
                      <div className="subtitleCoupun">
                        Manage all promotional coupons {">"}
                      </div>
                    </ListItem>
                  </SidebarLink>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className="titleCoupom">
                    {`"${metrics?.cleaningCoupon?.code}"` || undefined}
                  </div>
                  <div className="subtitleCoupun">
                    {`Applied ${metrics?.cleaningCoupon?.applied} times` ||
                      undefined}
                  </div>
                </div>
              </div>
            </div>

            {isUserAllowed(user) && (
              <div
                style={{ flex: 1.5 }}
                className="containerPaymentsDash boxShadon"
              >
                <div className="containerTitleGraph">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <SidebarLink
                      to={user.adminType === "master" && "/payments"}
                    >
                      <div className="titleGraph">Payments</div>
                    </SidebarLink>
                  </div>
                </div>

                <div style={{ height: "58%" }}>
                  <Payments
                    paymentEntries={metrics.paymentEntries}
                    paymentExits={metrics.paymentExits}
                    clientsWithPaymentsFailed={metrics.clientsWithPaymentsFailed}
                  />
                </div>
              </div>
            )}

            <div
              style={{ flex: 2.5 }}
              className="containerPaymentsDash boxShadon"
            >
              <div className="containerTitleGraph">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flex: 1,
                  }}
                >
                  <div className="titleGraph">Notifications</div>
                </div>
              </div>
              <Notifications notifications={metrics.notifications} />
            </div>
          </div>
        </div>
      ) : (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </AuthorizedLayout>
  );
};

export default Dashboard;
